import gql from 'graphql-tag'

export default gql`
  mutation (
    $_id: MongoID!  
    $status: EnumHomeworkStatus
    $file: String
    $student: MongoID!  
    $filename: String
    $submitdate: Date

    ){
    updateHomework(_id:$_id,
      record:{
        status: $status,
        file: $file,
        student: $student
        filename :$filename
        submitdate: $submitdate

      })
      {
        recordId
      }
  }
`
import React, { Fragment, useMemo } from "react";
import { Text } from "@chakra-ui/react";
// import Header from "../sections/Header";

const PolicyLayout = (props) => {
  // console.log('landing ', props);
  return (
    <Fragment>
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
      >
        1. บทนำ
      </Text>
      <Text variant="body2">
        <div>
          คำและข้อความดังต่อไปนี้ให้มีความหมายตามที่ได้กำหนดไว้ด้านล่างเมื่อมีการใช้ในข้อกำหนดและเงื่อนไขฯ
          ฉบับนี้
        </div>
        <div>
          1.1. "เนื้อหา" หมายถึง ข้อมูลต่างๆ เช่น ข้อความ เสียง เพลง รูปภาพ
          วิดีโอ ซอฟต์แวร์ โปรแกรม รหัสคอมพิวเตอร์ และข้อมูลอื่นๆ
        </div>
        <div>
          1.2. "เนื้อหาหลัก" หมายถึง เนื้อหาที่สามารถเข้าถึงได้ผ่านทางบริการฯ
        </div>
        <div>
          1.3. "เนื้อหาจากผู้ใช้" หมายถึง เนื้อหาที่ผู้ใช้ได้ส่ง ส่งผ่าน หรือ
          อัพโหลดบนระบบการให้บริการฯ
        </div>
        <div>
          1.5. "ข้อกำหนดและเงื่อนไขฯ เพิ่มเติม" หมายถึง
          ข้อกำหนดและเงื่อนไขอื่นใดซึ่งแยกต่างหากจากข้อกำหนดและเงื่อนไขฯ ฉบับนี้
          และเกี่ยวข้องกับบริการฯ ซึ่งเผยแพร่หรืออัพโหลดโดย Nuukeng ภายใต้ชื่อ
          "ข้อตกลง" "แนวทางปฏิบัติ" "นโยบาย" หรือภายใต้ชื่ออื่นๆ
          ที่มีความหมายคล้ายคลึงกัน
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        2. การตกลงยอมรับข้อกำหนดและเงื่อนไขฯ ฉบับนี้
      </Text>
      <Text variant="body2" component="p">
        <div>
          2.1. ผู้ใช้ทุกรายจะต้องใช้บริการฯ
          ตามข้อกำหนดที่ระบุไว้ในข้อกำหนดและเงื่อนไขฯ ฉบับนี้
          โดยผู้ใช้จะไม่สามารถใช้บริการฯ
          ได้เว้นเสียแต่ผู้ใช้ได้ตกลงยอมรับข้อกำหนดและเงื่อนไขฯ ฉบับนี้แล้ว
        </div>
        <div>
          2.2. ผู้ใช้ซึ่งเป็นผู้เยาว์จะสามารถใช้บริการฯ
          ได้ก็ต่อเมื่อได้รับความยินยอมล่วงหน้าจากบิดามารดาหรือผู้แทนโดยชอบกฎหมายเท่านั้น
          นอกจากนี้ หากผู้ใช้ดังกล่าวใช้บริการฯ
          ในนามหรือเพื่อวัตถุประสงค์ขององค์กรธุรกิจใด
          ให้ถือว่าองค์กรธุรกิจดังกล่าวได้ตกลงยอมรับข้อกำหนดและเงื่อนไขฯ
          ฉบับนี้แล้วล่วงหน้า
        </div>
        <div>
          2.3. หากมีข้อกำหนดและเงื่อนไขฯ เพิ่มเติมใดๆ
          ซึ่งเกี่ยวข้องกับการให้บริการฯ
          ผู้ใช้จะต้องปฏิบัติตามข้อกำหนดและเงื่อนไขฯ
          เพิ่มเติมดังกล่าวเช่นเดียวกับข้อกำหนดและเงื่อนไขฯ ในการใช้งานฉบับนี้
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        3. การแก้ไขข้อกำหนดและเงื่อนไขฯ ฉบับนี้
      </Text>
      <Text variant="body2" component="p">
        <div>
          Nuukeng อาจเปลี่ยนแปลงแก้ไขข้อกำหนดและเงื่อนไขฯ
          ฉบับนี้ได้ตลอดเวลาตามที่ Nuukeng
          เห็นสมควรซึ่งจะอยู่ภายใต้ขอบวัตถุประสงค์ของข้อกำหนดและเงื่อนไขฯ
          ฉบับนี้ ในกรณีดังกล่าว Nuukeng
          จะแจ้งเนื้อหาของข้อกำหนดฉบับแก้ไขรวมถึงวันที่มีผลบังคับใช้บนเว็บไซต์ของ
          Nuukeng หรืออาจแจ้งให้ผู้ใช้ทราบด้วยวิธีการอื่นใดตามที่ Nuukeng
          กำหนด ทั้งนี้
          ข้อกำหนดและเงื่อนไขฉบับแก้ไขจะมีผลบังคับใช้ตามวันที่กำหนดต่อไป
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        4. บัญชี
      </Text>
      <Text variant="body2" component="p">
        <div>
          4.1. เมื่อใช้บริการฯ ผู้ใช้อาจมีความจำเป็นต้องลงทะเบียนการใช้บริการฯ
          ด้วยข้อมูลบางประการ ทั้งนี้ ผู้ใช้ต้องให้ข้อมูลที่เป็นความจริง ถูกต้อง
          ครบถ้วนและมีหน้าที่ต้องปรับปรุงและแก้ไขข้อมูลดังกล่าวให้เป็นปัจจุบันอยู่เสมอ
        </div>
        <div>
          4.2. ในกรณีที่ผู้ใช้ลงทะเบียนข้อมูลการยืนยันตัวตนใดๆ (Authentication
          information) เมื่อใช้บริการฯ
          ผู้ใช้ต้องใช้ความระมัดระวังในการจัดการข้อมูลดังกล่าวด้วยความรับผิดชอบของตน
          เพื่อให้เป็นที่แน่ใจว่าข้อมูลดังกล่าวจะไม่ถูกนำไปใช้ในลักษณะที่ไม่ชอบด้วยกฎหมาย
          ทั้งนี้ Nuukeng อาจถือว่ากิจกรรมใดๆ
          ซึ่งดำเนินการโดยการใช้ข้อมูลการรับรองตัวตนดังกล่าว
          เสมือนเป็นกิจกรรมที่ผู้เป็นเจ้าของข้อมูลได้ดำเนินการด้วยตนเองทั้งสิ้น
        </div>
        <div>
          4.3. ผู้ใช้ซึ่งลงทะเบียนใช้บริการฯ
          สามารถลบบัญชีของตนและยกเลิกการใช้บริการฯ ได้ไม่ว่าในเวลาใด
        </div>
        <div>
          4.4. Nuukeng ขอสงวนสิทธิในการลบบัญชีใดๆ
          ซึ่งไม่มีการเปิดใช้งานเป็นเวลากว่า หนึ่งปี
          หรือนานกว่านับแต่วันที่มีการเปิดใช้งานบัญชีดังกล่าวครั้งล่าสุด ทั้งนี้
          โดยไม่ต้องบอกกล่าวล่วงหน้าใดๆ แก่ผู้ใช้บัญชีดังกล่าว
        </div>
        <div>
          4.5. สิทธิใดๆ
          ของผู้ใช้บริการอาจสิ้นสุดลงเมื่อบัญชีของผู้ใช้ดังกล่าวถูกลบไม่ว่าด้วยเหตุผลประการใดๆ
          ทั้งนี้ บัญชีการใช้บริการฯ จะไม่สามารถกู้คืนได้ถึงแม้ว่าผู้ใช้บริการฯ
          จะลบบัญชีของตนโดยไม่ได้ตั้งใจก็ตาม
        </div>
        <div>
          4.6. บัญชีแต่ละบัญชีในการใช้บริการฯ นั้น
          มีไว้เพื่อการใช้งานเฉพาะบุคคลและเป็นของเจ้าของบัญชีนั้นแต่เพียงผู้เดียว
          ผู้ใช้ไม่สามารถโอน ให้ยืม
          หรือจำหน่ายสิทธิในการใช้บัญชีของตนแก่บุคคลภายนอก
          ขณะเดียวกันบุคคลภายนอกก็ไม่สามารถรับช่วงสิทธิหรือสืบทอดบัญชีจากผู้ใช้ดังกล่าวได้เช่นกัน
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        5. การคุ้มครองข้อมูลส่วนบุคคล
      </Text>
      <Text variant="body2" component="p">
        <div>
          5.1. Nuukeng ให้ความสำคัญแก่ความเป็นส่วนตัวของผู้ใช้บริการฯ
          เป็นลำดับแรก
        </div>
        <div>
          5.2. Nuukeng
          จะคุ้มครองความเป็นส่วนตัวและข้อมูลส่วนบุคคลของผู้ใช้บริการฯ
          อย่างเหมาะสมตาม "นโยบายของ Nuukeng
          ว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล"
        </div>
        <div>
          5.3. Nuukeng
          สัญญาที่จะใช้ความระมัดระวังและความเอาใส่ใจอย่างสูงสุดกับมาตรการรักษาความปลอดภัย
          เพื่อให้แน่ใจว่ามีการจัดการข้อมูลใดๆ ซึ่งเก็บรวบรวมจากผู้ใช้บริการฯ
          อย่างเหมาะสมและปลอดภัย
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        6. การให้บริการฯ
      </Text>
      <Text variant="body2" component="p">
        <div>
          6.1. ผู้ใช้จะเป็นผู้รับผิดชอบจัดหาเครื่องคอมพิวเตอร์
          อุปกรณ์สื่อสารเคลื่อนที่ สมาร์ทโฟน อุปกรณ์สื่อสารอื่นๆ ระบบปฏิบัติการ
          วิธีการเชื่อมต่อสื่อสารและกระแสไฟฟ้า ฯลฯ ซึ่งจำเป็นต่อการใช้บริการฯ
          ทั้งนี้ ผู้ใช้เป็นผู้ออกค่าใช้จ่ายเองทั้งสิ้น
        </div>
        <div>
          6.2. Nuukeng ขอสงวนสิทธิในการจำกัดการเข้าถึงการใช้บริการฯ
          ทั้งหมดหรือแต่บางส่วนของผู้ใช้บริการฯ
          การสงวนสิทธิดังกล่าวขึ้นอยู่กับเงื่อนไขที่ Nuukeng เห็นสมควร เช่น
          อายุและการระบุตัวตนของผู้ใช้บริการฯ การลงทะเบียน สถานภาพ
          และองค์ประกอบอื่นใดที่มีความคล้ายคลึงกัน
        </div>
        <div>
          6.3. Nuukeng ขอสงวนสิทธิในการแก้ไขหรือเปลี่ยนแปลงข้อกำหนดและเงื่อนฯ
          ในการใช้บริการทั้งหมดหรือแต่บางส่วนไม่ว่าในเวลาใด ตามที่ Nuukeng
          เห็นสมควร ทั้งนี้ โดยไม่ต้องบอกกล่าวล่วงหน้าแก่ผู้ใช้
        </div>
        <div>
          6.4. Nuukeng อาจหยุดการให้บริการฯ
          ทั้งหมดหรือแต่บางส่วนโดยไม่ต้องบอกกล่าวล่วงหน้าแก่ผู้ใช้ในกรณีดังต่อไปนี้
        </div>
        <div>(1) เมื่อทำการบำรุงรักษาหรือซ่อมแซมระบบ</div>
        <div>
          (2) เมื่อไม่สามารถให้บริการได้เนื่องจากเหตุสุดวิสัยบางประการ เช่น
          อุบัติเหตุ (ไฟไหม้ไฟดับ ฯลฯ ) ปรากฏการณ์ธรรมชาติ สงคราม การจลาจล
          และข้อพิพาทแรงงาน
        </div>
        <div>
          (3) เมื่อระบบเกิดความขัดข้องหรือมีการใช้งานระบบมากเกินกำลัง (Overload)
        </div>
        <div>
          (4)
          เพื่อรักษาความปลอดภัยของผู้ใช้หรือบุคคลภายนอกหรือเพื่อดำเนินภาระกิจอันเป็นประโยชน์ต่อสาธารณะในกรณีฉุกเฉิน
        </div>
        <div>
          (5) เมื่อ Nuukeng
          เห็นว่ามีความจำเป็นและเป็นการสมควรนอกจากกรณีที่ได้ระบุไว้ในข้อ (1) ถึง
          (4) ข้างต้น
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        7. การแจ้งเหตุฉุกเฉิน
      </Text>
      <Text variant="body2" component="p">
        <div>
          ผู้ใช้ไม่สามารถพึ่งพาบริการฯ นี้เป็นบริการหลักในการแจ้งเหตุฉุกเฉินได้
          ไม่ว่าจะเป็นการแจ้งเหตุไปยังหน่วยงานบังคับใช้กฎหมาย
          หน่วยงานด้านความปลอดภัยทางทะเล หน่วยงานดับเพลิง หรือหน่วยงานอื่นๆ
          ซึ่งให้บริการฉุกเฉิน
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        8. โฆษณา
      </Text>
      <Text variant="body2" component="p">
        <div>
          Nuukeng ขอสงวนสิทธิที่ในการโฆษณาของ Nuukeng
          หรือของบุคคลภายนอกบนระบบการให้บริการฯ
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        9.ผู้ให้บริการภายนอก
      </Text>
      <Text variant="body2" component="p">
        <div>
          การให้บริการฯ นี้อาจมีเนื้อหาฯ
          หรือบริการอื่นใดซึ่งให้บริการหรือจัดทำโดยผู้ให้บริการภายนอก
          ในการนี้ผู้ให้บริการภายนอกดังกล่าวจะเป็นผู้รับผิดชอบแต่เพียงผู้เดียวเกี่ยวกับเนื้อหาฯ
          และ/หรือการให้บริการดังกล่าว นอกจากนี้ เนื้อหาฯ หรือบริการฯ
          ดังกล่าวอาจตกอยู่ภายใต้ข้อกำหนด การใช้
          หรือข้อกำหนดและเงื่อนไขอื่นใดซึ่งผู้ให้บริการภายนอกได้กำหนดไว้สำหรับเนื้อหาและบริการนั้นๆ
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        10. เนื้อหา
      </Text>
      <Text variant="body2" component="p">
        <div>
          10.1. Nuukeng อนุญาตให้ผู้ใช้ใช้บริการฯ
          เนื้อหาหลักที่จัดให้เพื่อวัตถุประสงค์ในการใช้บริการฯ
          ของผู้ใช้โดยไม่จำกัดจำนวนผู้ใช้ อย่างไรก็ตามผู้ใช้ไม่สามารถส่งหรือโอน
          อนุญาตช่วง หรืออนุญาตให้ใช้สิทธิ แก่บุคคลภายนอกได้ (non-transferable,
          non-re-licensable, non-exclusive license)
        </div>
        <div>
          10.2. ผู้ใช้จะต้องปฏิบัติตามเงื่อนไขที่มีผลบังคับใช้
          เมื่อใช้เนื้อหาหลักซึ่งอยู่ภายใต้ข้อกำหนดและเงื่อนไขฯ ฉบับนี้ เช่น
          ค่าธรรมเนี่ยมเพิ่มเติม ระยะเวลาในการใช้บริการฯ
          และเงื่อนไขอื่นใดซึ่งมีความคล้ายคลึงกัน
          ถึงแม้ว่าจะมีถ้อยคำดังต่อไปนี้ปรากฏบนหน้าจอในการใช้บริการฯ เช่น
          "การซื้อ" "การขาย" และถ้อยคำอื่นๆ ซึ่งมีความหมายคล้ายคลึงกัน ในกรณีนี้
          Nuukeng จะยังคงเป็นเจ้าของสิทธิในทรัพย์สินทางปัญญารวมถึงสิทธิอื่นๆ
          ทั้งหมดในเนื้อหาหลักที่ Nuukeng
          เป็นผู้ให้บริการแก่ผู้ใช้ซึ่งสิทธิดังกล่าวจะไม่ถูกโอนไปให้แก่ผู้ใช้แต่อย่างใด
          ผู้ใช้จะได้รับสิทธิในการใช้งานตามที่ได้ระบุไว้ข้างต้นเท่านั้น
        </div>
        <div>
          10.3.
          ผู้ใช้จะต้องไม่ใช้เนื้อหาหลักเกินจากขอบวัตถุประสงค์ของการใช้บริการฯ
          (ซึ่งรวมถึงแต่ไม่จำกัดเพียงการทำสำเนา การส่ง การทำซ้ำ
          และการแก้ไขเปลี่ยนแปลง)
        </div>
        <div>
          10.4. ในกรณีที่ผู้ใช้ต้องการที่จะสำรองเนื้อหาจากผู้ใช้ (Back-up)
          ไม่ว่าทั้งหมดหรือเพียงบางส่วน ผู้ใช้จะต้องดำเนินการดังกล่าวด้วยตนเอง
          Nuukeng ไม่มีหน้าที่และภาระในการสำรองเนื้อหาจากผู้ใช้แต่อย่างใด
        </div>
        <div>
          10.5. บริการฯ อาจรวมถึงกระบวนการการทำงาน (ฟังก์ชั่น) ต่างๆ
          ซึ่งผู้ใช้หลายคนสามารถที่จะแก้ไขเนื้อหาได้ (โพสต์ ตรวจสอบความถูกต้อง
          และการลบเป็นต้น)
          ในกรณีดังกล่าวผู้ใช้ซึ่งโพสต์เนื้อหาดังกล่าวจะต้องอนุญาตให้ผู้ใช้รายอื่นแก้ไขเนื้อหาของตนได้
        </div>
        <div>
          10.6. ผู้ใช้จะยังคงมีสิทธิที่เกี่ยวกับเนื้อหาจากผู้ใช้อยู่เช่นเดิม และ
          Nuukeng จะไม่ได้มาซึ่งสิทธิใดๆ ในเนื้อหาดังกล่าว
          อย่างไรก็ตามผู้ใช้ซึ่งโพสต์เนื้อหาที่มีความเป็นสาธารณะสามารถเข้าถึงได้เป็นการทั่วไป
          ให้ถือว่าผู้ใช้ซึ่งโพสต์เนื้อหาดังกล่าวได้รับสิทธิต่างๆ จาก Nuukeng
          ให้สามารถใช้เนื้อหาดังกล่าวเพื่อให้บริการและ/หรือเพื่อวัตถุประสงค์ในการส่งเสริมการตลาดได้
          (สิทธิต่างๆ ในที่นี้รวมถึง สิทธิในการแก้ไขเนื้อหาในขอบเขตที่ Nuukeng
          เห็นว่ามีความจำเป็นและเหมาะสม เช่น การใช้อักษาย่อเป็นต้น
          และสิทธิในการให้อนุญาตช่วงให้แก่บุคคลภายนอกในการทำงานร่วมกับ Nuukeng
          เป็นต้น)
        </div>
        <div>
          10.7. ผู้ใช้ต้องไม่ใช้สิทธิของตน เช่น
          สิทธิทางศีลธรรมที่เกี่ยวข้องกับเนื้อหาจากผู้ใช้ในส่วนที่เกี่ยวกับการใช้งานโดย
          Nuukeng หรือบุคคลถายนอกภายใต้ข้อ 10.5 และ ข้อ 10.6 ข้างต้น
        </div>
        <div>
          10.8. Nuukeng
          อาจทำการตรวจสอบรายละเอียดและยืนยันเนื้อหาจากผู้ใช้ภายใต้ขอบเขตที่กฎหมายกำหนด
          หาก Nuukeng เห็นว่ามีความจำเป็นเพื่อที่จะปฏิบัติตามกฎหมาย ข้อบังคับ
          หรือข้อกำหนดและเงื่อนไขฯ ฉบับนี้  อย่างไรก็ตาม Nuukeng
          ไม่มีภาระหน้าที่จะต้องดำเนินการตรวจสอบดังกล่าวแต่อย่างไร
        </div>
        <div>
          10.9. หาก Nuukeng เห็นว่า ผู้ใช้ได้ฝ่าฝืนหรืออาจฝ่าฝืนกฎหมาย
          กฎระเบียบ หรือข้อกำหนดและเงื่อนไขฯ ฉบับนี้
          ซึ่งเกี่ยวข้องกับเนื้อหาจากผู้ใช้ หรือเกี่ยวข้องกับการดำเนินกิจการของ
          Nuukeng
          เราขอสงวนสิทธิที่จะตัดสิทธิผู้ใช้ในการที่ใช้เนื้อหาในบางลักษณะ เช่น
          การลบเนื้อหาจากผู้ใช้ โดยไม่ต้องบอกกล่าวล่วงหน้า เป็นต้น
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        11. เหรียญ
      </Text>
      <Text variant="body2" component="p">
        <div>
          11.1. ผู้ใช้จะได้รับเหรียญโดยวิธีการที่ Nuukeng กำหนด เช่น
          การซื้อในระบบการให้บริการฯ การเสนอให้เป็นพิเศษ และวิธีการอื่นๆ
          ในทำนองเดียวกัน ทั้งนี้ รายละเอียดเกี่ยวกับค่าเงิน วิธีการชำระเงิน
          และเงื่อนไขอื่นๆ
          เกี่ยวกับการได้รับเหรียญจะถูกกำหนดและแสดงอยู่บนระบบการให้บริการฯ
          หรือบนเว็ปไซต์ของ Nuukeng
        </div>
        <div>
          11.2. เหรียญนั้นไม่สามารถนำไปแลกเปลี่ยน ใช้แทนเงินหรือทรัพย์สิน
          หรือใช้เพื่อวัตถุประสงค์ทางการเงินอื่นนอกจากที่บริการฯ และเนื้อหาของ
          Nuukeng กำหนด จำนวนเหรียญที่จำเป็นต่อการรับบริการฯ
          หรือใช้เพื่อเนื้อหาหรือใช้เพื่อสิ่งอื่นใดในทำนองเดียวกันจะถูกกำหนดและแสดงอยู่บนระบบการให้บริการฯ
          หรือบนเว็ปไซต์ของ Nuukeng
        </div>
        <div>
          11.3. เฉพาะบัญชีที่รับเหรียญเท่านั้นที่จะสามารถใช้งานเหรียญได้
          นอกจากนี้ เหรียญที่ให้บริการโดยปลายทาง (เทอร์มินอล)
          ซึ่งมีระบบปฏิบัติการที่แตกต่างกันนั้นไม่สามารถนำมารวมหรือยกยอดเหรียญกันได้
          เนื่องจากประเภทของเหรียญนั้นมีความแตกต่างกันแม้ว่าเหรียญดังกล่าวจะถูกจัดประเภทให้อยู่ภายใต้ชื่อเดียวกันก็ตาม
        </div>
        <div>
          11.4. เหรียญนั้นจะไม่สามารถขอคืนเป็นเงินได้ไม่ว่าในกรณีใดๆ
          อย่างไรก็ตาม
          ข้อกำหนดนี้จะไม่มีผลใช้บังคับหากมีกฎหมายและกฏระเบียบที่เกี่ยวข้องกำหนดว่าต้องคืนเงิน
          ในกรณีดังกล่าว Nuukeng
          จะดำเนินการคืนเงินตามกฎหมายและกฏระเบียบที่เกี่ยวข้อง
          และจะมีการแสดงข้อมูลดังกล่าวไว้ในเว็บไซต์ของ Nuukeng และที่อื่นๆ
          ตามที่อาจกำหนดต่อไป
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        12. บริการสมัครสมาชิก
      </Text>
      <Text variant="body2" component="p">
        <div>
          12.1. ผู้ใช้อาจได้รับบริการฯ
          โดยมีค่าใช้จ่ายหรือค่าธรรมเนียมหรือได้รับบริการฯ
          ภายใต้ข้อกำหนดและเงื่อนไขของ Nuukeng ทั้งนี้
          ผู้ใช้อาจต้องชำระค่าบริการบางส่วนเพื่อใช้งานเนื้อหาหลักของ Nuukeng
          ภายในระยะเวลาที่กำหนด (“บริการสมัครสมาชิกฯ”)
          ผู้ใช้จะต้องปฏิบัติตามข้อกำหนดและเงื่อนไขเหล่านี้ค่าธรรมเนียมสำหรับบริการสมัครสมาชิกฯ
          วิธีการชำระเงิน และเงื่อนไขการใช้งานอื่นๆ
          ซึ่งถูกกำหนดและแสดงอยู่บนระบบการให้บริการฯ หรือบนเว็ปไซต์ของ Nuukeng
        </div>
        <div>
          12.2.
          ผู้ใช้สามารถดำเนินการตามขั้นตอนเพื่อขอยกเลิกการให้บริการสมัครสมาชิกฯ
          ได้ตลอดเวลา
          อย่างไรก็ตามหากผู้ใช้ดำเนินตามขั้นตอนเพื่อขอยกเลิกการให้บริการฯ
          ดังกล่าวก่อนระยะเวลาการใช้งานที่กำหนด
          ผู้ใช้อาจไม่สามารถยกเลิกการซื้อบริการสมัครสมาชิกฯ ดังกล่าวได้
          ในกรณีนี้
          ค่าธรรมเนียมซึ่งผู้ใช้ได้ชำระไปแล้วนั้นไม่สามารถขอเรียกรับเงินคืนได้
          อย่างไรก็ตามข้อกำหนดนี้อาจไม่มีผลบังคับใช้หากมีกฎหมายให้อำนาจไว้เป็นอื่น
        </div>
        <div>
          12.3. ในกรณีที่ผู้ใช้ไม่สามารถดำเนินการยกเลิกบริการสมัครสมาชิกฯ
          ตามวันและเวลาที่กำหนด ระยะเวลาในการให้บริการสมัครสมาชิกฯ
          ของท่านอาจได้รับการต่ออายุโดยอัตโนมัติ
          ถึงแม้ว่าจะสิ้นสุดระยะเวลาใช้บริการสมัครสมาชิกดังกล่าวแล้วก็ตาม
          ทั้งนี้ เป็นไปตามเงื่อนไขที่ Nuukeng กำหนด
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        13. ข้อจำกัดการใช้งาน
      </Text>
      <Text variant="body2" component="p">
        <div>
          ผู้ใช้ไม่สามารถกระทำการดังต่อไปนี้เมื่อใช้บริการฯ ได้ ตามที่ Nuukeng
          กำหนด
        </div>
        <div>
          13.1. การกระทำที่ฝ่าฝืนกฎหมาย ข้อบังคับ คำพิพากษา มติหรือคำสั่งศาล
          หรือมาตรการทางปกครองที่มีผลผูกพันทางกฎหมาย
        </div>
        <div>
          13.2. การกระทำที่อาจขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน
        </div>
        <div>
          13.3. การกระทำที่ละเมิดสิทธิในทรัพย์สินทางปัญญา เช่น ลิขสิทธิ์
          เครื่องหมายการค้าและสิทธิบัตร สิทธิในชื่อเสียง ความเป็นส่วนตัว
          และสิทธิอื่นๆ ทั้งหมดที่ได้รับตามกฎหมายหรือตามสัญญากับ Nuukeng
          และ/หรือบุคคลที่สาม
        </div>
        <div>
          13.4.
          การกระทำที่แสดงหรือส่งต่อซึ่งการแสดงออกที่มีลักษณะรุนแรงหรือเกี่ยวข้องกับเพศอย่างชัดเจน
          การแสดงออกซึ่งภาพอนาจารของเด็กหรือเยาวชนหรือการทารุณกรรมเด็ก
          การแสดงออกซึ่งนำไปสู่การเลือกปฏิบัติทางด้านเชื้อชาติ สัญชาติ ความเชื่อ
          เพศ สถานะทางสังคม ถิ่นกำเนิด ครอบครัว และอื่นๆ ที่เกี่ยวข้อง
          การแสดงออกที่ชักชวนหรือส่งเสริมการฆ่าตัวตาย พฤติกรรมการทำร้ายตัวเอง
          การใช้ยาในทางที่ผิด หรือการแสดงออกใดๆ
          ซึ่งเป็นการต่อต้านสังคมอันประกอบด้วยเนื้อหาที่ต่อต้านสังคมและทำให้บุคคลอื่นเกิดความไม่สบายใจเป็นต้น
        </div>
        <div>
          13.5. การกระทำที่อาจทำให้ผู้อื่นเข้าใจผิดเกี่ยวกับ Nuukeng
          และ/หรือบุคคลภายนอกหรือการจงใจเผยแพร่ข้อมูลเท็จ
        </div>
        <div>
          13.6. กระทำต่างๆ เช่น
          การส่งข้อความซึ่งมีเนื้อหาเหมือนหรือคล้ายคลึงกันไปให้แก่ผู้ใช้เป็นจำนวนมาก
          (ยกเว้นข้อความที่ได้รับความเห็นชอบจาก Nuukeng)
          การเพิ่มผู้ใช้รายอื่นเป็นเพื่อนหรือการแชทเป็นกลุ่มแบบสุ่ม
          หรือการกระทำอื่นใดที่ Nuukeng ถือว่าเป็นการส่งข้อความรบกวน (spamming)
        </div>
        <div>
          13.7. การกระทำที่เป็นการแลกเปลี่ยนสิทธิในการใช้เนื้อหาฯ เป็นเงินสด
          ทรัพย์สินหรือผลประโยชน์ทางการเงินอื่นๆ นอกเสียจากที่ Nuukeng กำหนดไว้
        </div>
        <div>
          13.8. การกระทำที่มีการใช้บริการฯ เพื่อการขาย การตลาด การโฆษณา
          การชักชวน หรือวัตถุประสงค์ทางการค้าอื่นๆ
          (ยกเว้นวัตถุประสงค์ที่ได้รับความเห็นชอบจาก Nuukeng)
          การกระทำที่มีการใช้บริการฯ เพื่อการกระทำทางเพศหรือการกระทำที่อนาจาร
          การกระทำที่มีการใช้บริการฯ
          เพื่อวัตถุประสงค์ในการพบปะหรือนัดออกเดทกับบุคคลแปลกหน้าเพื่อการมีเพศสัมพันธ์
          การกระทำที่ใช้บริการฯ เพื่อวัตถุประสงค์ในการคุกคาม
          หรือการหมิ่นประมาทใส่ร้ายผู้ใช้รายอื่นๆ หรือการกระทำใดๆ ซึ่งใช้บริการฯ
          เพื่อวัตถุประสงค์อื่นที่มิใช่จุดประสงค์ที่แท้จริงของการให้บริการฯ
        </div>
        <div>
          13.9. การกระทำที่เป็นประโยชน์ต่อหรือมีส่วนร่วมกับกลุ่มที่ต่อต้านสังคม
        </div>
        <div>
          13.10.
          การกระทำที่เกี่ยวกับกิจกรรมทางศาสนาหรือการเชื้อเชิญให้เข้ากลุ่มทางศาสนา
        </div>
        <div>
          13.11. การกระทำที่เป็นการเก็บรวบรวม เปิดเผย
          หรือการให้ข้อมูลส่วนบุคคลของบุคคลอื่น ข้อมูลที่จดทะเบียน ประวัติผู้ใช้
          หรือข้อมูลอื่นๆ ในทำนองเดียวกันโดยไม่ชอบด้วยกฎหมายหรือไม่เหมาะสม
        </div>
        <div>
          13.12.
          การกระทำซึ่งเป็นการแทรกแซงเครื่องแม่ข่ายและ/หรือระบบเครือข่ายของการให้บริการฯ
          การกระทำซึ่งเป็นการใช้บริการฯ ในทางที่ผิดด้วยการใช้บ็อท (bots)
          เป็นเครื่องมือเพื่อโกงหรือด้วยวิธีการทางเทคนิคอื่นๆ
          การกระทำที่ใช้ข้อบกพร่องของการบริการฯ โดยไตร่ตรองไว้ก่อน
          การกระทำที่ทำการสอบถามคำถามซ้ำๆ เกินความจำเป็น
          และการกระทำที่เป็นการแทรกแซงการให้บริการฯ ของ Nuukeng
          หรือการใช้บริการฯ ของผู้ใช้
        </div>
        <div>
          13.13. การถอดรหัสซอร์สโค้ดของบริการโดยวิธีวิศวกรรมย้อนกลับ (Reverse
          engineering) การถอดประกอบหรือสิ่งที่คล้ายคลึงกัน
          เพื่อวัตถุประสงค์ที่ไม่เหมาะสมหรือในลักษณะที่ไม่เป็นธรรม
        </div>
        <div>
          13.14.การกระทำที่ให้ความช่วยเหลือหรือส่งเสริมกิจกรรมใดๆ
          ที่ระบุไว้ในข้อ 13.1 ถึงข้อ 13.13 ข้างต้น
        </div>
        <div>
          13.15. การกระทำอื่นใด นอกเหนือจากที่ระบุในข้อ 13.1 ถึงข้อ 13.14
          ที่บริษัทฯ เห็นว่าไม่เหมาะสม
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        14. ความรับผิดชอบของผู้ใช้
      </Text>
      <Text variant="body2" component="p">
        <div>
          14.1. ผู้ใช้ต้องใช้บริการฯ นี้ด้วยความเสี่ยงของตน
          และเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อการกระทำทั้งหมดหรือการกระทำใดๆ
          ที่กระทำไปอันเรื่องมาจากการใช้บริการฯ นี้
        </div>
        <div>
          14.2. Nuukeng อาจระงับการให้บริการฯ ทั้งหมดหรือแต่บางส่วน
          ลบบัญชีหรือยกเลิกข้อตกลงใด ๆ เกี่ยวกับการให้บริการระหว่างผู้ใช้และ
          Nuukeng (รวมถึงแต่ไม่จำกัดเพียง ข้อตกลงใด ๆ ตามข้อกำหนดและเงื่อนไขฯ
          ฉบับนี้) หรือใช้มาตรการอื่นใดที่ Nuukeng
          พิจารณาว่ามีความจำเป็นและเหมาะสมโดยไม่ต้องแจ้งให้ผู้ใช้ทราบล่วงหน้า
          ในกรณีที่ผู้ใช้รายนั้นกระทำการหรือไม่สามารถกระทำการอย่างหนึ่งอย่างใดดังต่อไปนี้
        </div>
        <div>
          (1) ผู้ใช้ละเมิดกฎหมายที่เกี่ยวข้อง กฎระเบียบ ข้อกำหนดและเงื่อนไขฯ
          ฉบับนี้ หรือข้อกำหนดและเงื่อนไขฯ เพิ่มเติม
        </div>
        <div>
          (2)
          ผู้ใช้เป็นสมาชิกของกลุ่มต่อต้านสังคมหรือกลุ่มคนที่มีความเกี่ยวข้องกับกลุ่มดังกล่าว
        </div>
        <div>
          (3) ผู้ใช้ทำให้ Nuukeng
          เสื่อมเสียความน่าเชื่อถือโดยการเผยแพร่ข้อมูลเท็จ ใช้วิธีการโกง
          หรือวิธีการใดๆ อันไม่ชอบด้วยกฎหมาย
        </div>
        <div>
          (4) ผู้ใช้ถูกร้องเรียน ถูกสั่งห้ามชั่วคราว ถูกขายทอดตลาด
          ถูกดำเนินคดีล้มละลาย ถูกฟื้นฟูกิจการ
          หรืออยู่ในกระบวรการซึ่งมีความหมายคล้ายคลึงกัน หรือ
          ด้วยเหตุผลอื่นใดตามที่ Nuukeng
          เห็นสมควรซึ่งเกี่ยวกับความสามารถในการชำระหนี้ของผู้ใช้
        </div>
        <div>
          (5) Nuukeng สูญเสียความไว้วางใจต่อผู้ใช้ หรือเมื่อ Nuukeng
          เห็นว่าไม่ควรให้บริการฯ แก่ผู้ใช้ต่อไปด้วยเหตุผลนอกจากที่ได้กล่าวในข้อ
          (1) ถึงข้อ (4) ข้างต้น
        </div>
        <div>
          14.3. ในกรณีที่เกิดความสูญเสียหรือความเสียหายแก่ Nuukeng
          ไม่ว่าทางตรงหรือทางอ้อม (ซึ่งรวมถึงโดยไม่จำกัดเพียงค่าทนายความ)
          เนื่องจากการใช้บริการฯ ของผู้ใช้
          (ซึ่งรวมถึงโดยไม่จำกัดเพียงกรณีที่มีบุคคลภายนอกฟ้องร้องเรียกค่าเสียหายจาก
          Nuukeng เนื่องจากการใช้บริการฯ ดังกล่าว)
          ผู้ใช้มีหน้าที่ต้องชดใช้ค่าเสียหายให้แก่ Nuukeng ทันทีที่ Nuukeng
          ร้องขอ
        </div>
        <div></div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        15. ไม่รับประกันความเสียหาย
      </Text>
      <Text variant="body2" component="p">
        <div>
          Nuukeng จะไม่รับประกันใด ๆ
          ไม่ว่าโดยชัดแจ้งหรือโดยปริยายเกี่ยวกับการให้บริการฯ แก่ผู้ใช้
          (ซึ่งรวมถึงเนื้อหาหลักฯ) ว่าบริการฯ นั้นปราศจากข้อบกพร่องใดๆ
          (ข้อบกพร่องในที่นี่รวมถึงแต่ไม่จำกัดเพียง ข้อบกพร่องด้านความปลอดภัย
          ฯลฯ ข้อผิดพลาด บัค (BUGS) หรือการละเมิดสิทธิใดๆ) หรือมีความปลอดภัย
          มีความน่าเชื่อถือ มีความถูกต้องสมบูรณ์ มีประสิทธิภาพ
          และมีความเหมาะสมแก่การใช้ประโยชน์เฉพาะอย่าง ทั้งนี้ Nuukeng
          ไม่มีความรับผิดชอบในการที่จะต้องจัดหาบริการฯ
          ที่ไม่มีข้อบกพร่องดังกล่าวแต่อย่างใด
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        16. ข้อจำกัดความรับผิดของ Nuukeng
      </Text>
      <Text variant="body2" component="p">
        <div>
          16.1. Nuukeng จะไม่รับผิดชอบใดๆ
          ต่อความเสียหายอันเกิดจากการกระทำของผู้ใช้ในการใช้บริการฯ
          เว้นเสียแต่ความเสียหายดังกล่าวมีส่วนเกิดจากการกระทำโดยเจตนาหรือโดยประมาทเลินเล่ออย่างร้ายแรงของ
          Nuukeng อย่างไรก็ตาม ในกรณีที่สัญญาให้บริการฯ ระหว่างผู้ใช้และ
          Nuukeng
          เป็นสัญญาผู้บริโภคซึ่งอยู่ใต้บังคับของพระราชบัญญัติสัญญาผู้บริโภคของประเทศญี่ปุ่น
          (“สัญญาผู้บริโภคฯ”) Nuukeng
          ต้องรับผิดชอบในการชดเชยค่าเสียหายที่เกิดขึ้นเนื่องจากความประมาทเลินเล่อเฉพาะส่วนของตน
          (ไม่รวมถึงการประมาทเลินเล่ออย่างร้ายแรง)
          ซึ่งเกิดขึ้นจากสัญญาหรือการละเมิดในขอบเขตดังต่อไปนี้ (A)
          ความเสียหายที่เกิดขึ้นตามปกติธรรมดา
          (ค่าเสียประโยชน์และค่าเสียหายพิเศษอื่นๆ เป็นต้น) และ (B)
          ค่าธรรมเนียมการใช้งานสูงสุดซึ่งได้จากการใช้บริการโดยมีค่าตอบแทนสำหรับในเดือนที่ความเสียหายดังกล่าวเกิดขึ้น
        </div>
        <div>
          16.2.
          ในกรณีผู้ใช้ได้รับความเสียหายอันเนื่องมาจากการกระทำโดยประมาทเลินเล่ออย่างร้ายแรงของ
          Nuukeng Nuukeng มีหน้าที่ใช้ค่าสินไหมทดแทนอันเกิดจากการละเมิดนั้น
          อย่างไรก็ตามต้องอยู่ภายในขอบเขตดังต่อไปนี้ (A)
          ความเสียหายที่เกิดขึ้นตามปกติธรรมดา
          (ค่าเสียประโยชน์และค่าเสียหายพิเศษอื่นๆ เป็นต้น) และ (B)
          ค่าธรรมเนียมการใช้งานสูงสุดซึ่งได้จากการใช้บริการโดยมีค่าตอบแทนสำหรับในเดือนที่ความเสียหายดังกล่าวเกิดขึ้น
          อย่างไรก็ตามบทบัญญัติที่กล่าวมาข้างต้นอาจไม่มีผลใช้บังคับหาก
          สัญญาระหว่างผู้ใช้ดังกล่าวกับ Nuukeng เป็นสัญญาผู้บริโภคฯ
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        17. ความสัมพันธ์ระหว่างกฎหมาย กฎระเบียบ และข้อกำหนดและเงื่อนไขฯ ฉบับนี้
      </Text>
      <Text variant="body2" component="p">
        <div>
          ในกรณีที่ข้อกำหนดข้อหนึ่งข้อใดของข้อกำหนดและเงื่อนไขฯ
          ฉบับนี้ละเมิดกฎหมายหรือกฎระเบียบใดๆ
          ซึ่งมีผลใช้บังคับแก่ข้อตกลงระหว่างผู้ใช้และ Nuukeng
          เกี่ยวกับการให้บริการ
          (ซึ่งรวมถึงแต่ไม่จำกัดเพียงพระราชบัญญัติสัญญาผู้บริโภคของประเทศญี่ปุ่น)
          ข้อบังคับดังกล่าวไม่มีผลใช้บังคับแก่ผู้ใช้ อย่างไรก็ตาม
          ข้อบังคับอื่นนอกจากข้อบังคับดังกล่าวยังมีผลใช้บังคับ
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        18. การแจ้งเตือนและการติดต่อ
      </Text>
      <Text variant="body2" component="p">
        <div>
          18.1. สำหรับการแจ้งเตือนจาก ต่อผู้ใช้เกี่ยวกับบริการฯ Nuukeng
          อาจใช้วิธีการที่ Nuukeng เห็นว่าเหมาะสม เช่น
          การประกาศไว้ในระบบการให้บริการฯหรือเว็บไซต์ของ Nuukeng เอง
        </div>
        <div>
          18.2. สำหรับการแจ้งเตือนจากผู้ใช้ต่อ Nuukeng เกี่ยวกับบริการฯ
          ผู้ใช้จะต้องใช้แบบฟอร์มสอบถามสำหรับลูกค้าที่อยู่ในระบบการให้บริการฯ
          หรือบนเว็บไซต์ซึ่ง Nuukeng เป็นผู้ดำเนินงานหรือโดยวิธีการอื่นๆ ที่
          Nuukeng กำหนด
        </div>
      </Text>

      <br />
      <Text
        style={{ fontWeight: "bold" }}
        variant="subtitle1"
        component="p"
      >
        19. กฎหมายที่ใช้บังคับและเขตอำนาจศาล
      </Text>
      <Text variant="body2" component="p">
        <div>
          ในกรณีที่ Nuukeng ได้จัดหาคำแปลฉบับภาษาญี่ปุ่นของข้อกำหนดและเงื่อนไขฯ
          ฉบับนี้ให้แก่ผู้ใช้ ("ฉบับภาษาญี่ปุ่น")
          ให้ฉบับภาษาญี่ปุ่นมีผลใช้บังคับในเรื่องความสัมพันธ์ระหว่างผู้ใช้และ
          Nuukeng
          ในกรณีที่มีการขัดกันระหว่างฉบับภาษาญี่ปุ่นและฉบับแปลอื่นเกิดขึ้นไม่ว่าในกรณีใด
          บทบัญญัติในฉบับภาษาญี่ปุ่นจะมีผลบังคับเหนือฉบับแปลอื่นใดทั้งสิ้น
          ทั้งนี้ ข้อกำหนดและเงื่อนไขฯ ฉบับนี้จะอยู่ภายใต้บังคับของกฎหมายญี่ปุ่น
          ความขัดแย้งที่เกิดขึ้นจากหรือมีความเกี่ยวพันกับการบริการฯ
          หรือความขัดแย้งระหว่างผู้ใช้และ Nuukeng ที่เกี่ยวกับบริการฯ
          จะถูกตัดสินและอยู่ภายใต้เขตอำนาจศาลกรุงโตเกียว
        </div>
      </Text>
    </Fragment>
  );
}
export default PolicyLayout

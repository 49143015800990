import gql from 'graphql-tag'

export default gql`
query ($_id: MongoID) {
    subject(filter: { _id: $_id }){
      _id
      name
      description
      updatedAt
      photo
      passstatus
      sorting
      status
      pay_description{
        blocks
        entityMap 
      }
      pay_certificate
      course_certificate
      teacher{
          _id
          username
          firstname
          lastname
          isTeacher
          avatar
          email
          description
          facebook{
            name
            link
          }
          instagram{
            name
            link
          }
          youtube{
            name
            link
          }
      }
      certificateconfig{
        name
        certificateCondition
        timestatus
        certificatetime
        signature{
          typesign
          firstname
          lastname
          signatureURL
        }
        logoURL
        backgroundURL
        description
      }
      contents{
        subject{
          _id
          name
        }
        _id
        doctype
        duration
        name
        carddescription
        type
        text
        link
        statusActive
        expiredate
        surveyconfig {
          surveyQuestions{
            questions
          }
          description{
            blocks
            entityMap
          }
        }
      }
    }

  }
`

import gql from 'graphql-tag'

export default gql`
mutation ( $firstname: String $lastname: String $mobile: String $username: String! $avatar: String $email: String  ){
    createTeacherUser(record: {
            username: $username,
            avatar: $avatar,
            firstname:$firstname,
            lastname:$lastname,
            mobile:$mobile,
            email:$email,
            role:TEACHER,
            isTeacher: true,
        }) {
        recordId
    }
}
`

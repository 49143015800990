import { useTranslation } from 'react-i18next'
import {
    Box,
    IconButton,
    Tooltip,
    Center,
    Divider,
    Alert,
    AlertIcon,
    Flex,
    useColorMode,
    Text,
} from '@chakra-ui/react'

import { EditIcon } from '@chakra-ui/icons'
import { FaUserGraduate } from "react-icons/fa";



const CardCertificate = ({ student, openCertificateDialog }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    return (
        <Box
            p={5}
            display={'flex'}
            w={'430px'}
            bg={colorMode === 'light' ? 'brand.100' : 'brand.600'}
            color={colorMode === 'light' ? 'brand.600' : 'brand.100'}
            boxShadow={'lg'}
            borderWidth={'2px'}
            borderRadius={'15px'}
            borderColor={colorMode === 'light' ? 'brand.300' : 'brand.500'}
            position={'relative'}
        >
            <Tooltip
                label={`edit`}
                fontsize="md">
                <IconButton
                    position={'absolute'}
                    left={'390px'}
                    top={'5px'}
                    variant='link'
                    fontSize='20px'
                    colorScheme={colorMode === 'light' ? 'brand.600' : 'brand.100'}
                    icon={<EditIcon />}
                    onClick={openCertificateDialog}
                />
            </Tooltip>
            <Flex
                flexGrow={1}
                alignItems={'center'}
            >
                <FaUserGraduate
                    color={colorMode === 'light' ? 'brand.600' : 'brand.200'}
                    size='50px'
                />
            </Flex>
            <Flex flexGrow={1}>
                <Center height='auto'>
                    <Divider
                        orientation='vertical'
                        borderWidth={'1px'}
                        borderColor={colorMode === 'light' ? 'brand.500' : 'brand.200'} />
                </Center>
            </Flex>
            <Flex
                flexGrow={2}
                direction='column'
                minW={'300px'}
            >
                <Text fontWeight={700} fontSize='xl'>
                    {`Certification`}
                </Text>
                <Text fontWeight={600} fontSize='sm'>
                    {`owner: ${student?.name}`}
                </Text>

                {student.status == 'approved' ?
                    <Alert status='success' variant='subtle' borderWidth={'2px'} borderRadius={'10px'} h={'40px'} mt={2}>
                        <AlertIcon />
                        <Text fontWeight={600} fontSize='sm'>
                            {`Certificate Approved .`}
                        </Text>
                    </Alert>
                    :
                    <Alert status='warning' variant='subtle' borderWidth={'2px'} borderRadius={'10px'} h={'40px'} mt={2}>
                        <AlertIcon />
                        <Text fontWeight={600} fontSize='sm'>
                            {`Certificate Disapproved .`}
                        </Text>
                    </Alert>
                }
            </Flex>
        </Box>
    )
}

export default CardCertificate
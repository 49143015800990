import React, { Fragment, useState,useContext ,useEffect } from 'react'
import {
	Box,
	Container,
	Stack,
	Text,
	Heading,
	Button,
	Table,
	IconButton,
	Tr,
	Th,
	Td,
	Tbody,
	Center,
	Input,
	FormControl,
	FormLabel,
	Tooltip,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	useDisclosure,
	Textarea,
	GridItem,
	useToast,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react'
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'

import ListZoom from '../graphql/queries/listzoom'
import ZoomMutationById from '../graphql/mutations/updateZoom'
import ZoomMutation from '../graphql/mutations/createZoom'
import ZoomRemoveMutation from '../graphql/mutations/removeZoom'
import { useMutation, useQuery } from '@apollo/client'
import { useMultiState } from '../lib/hooks'
import { useTranslation } from 'react-i18next'
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'

const ManagerZoomPage = () => {
	const { t } = useTranslation()
	const history = useHistory()
const { user } = useContext(AuthContext)
	const initialZoom = {
		name: '',
		token: '',
		userId: '',
	}
	const toast = useToast()
	const [setZoomMutation] = useMutation(ZoomMutation)
	const [setZoomMutationById] = useMutation(ZoomMutationById)
	const [removeZoomMutation] = useMutation(ZoomRemoveMutation)
	const [userZoom, setUserZoom] = useMultiState(initialZoom)

	const {
		isOpen: isAddZoomModal,
		onOpen: onAddZoomModalOpen,
		onClose: onAddZoomModalClose,
	} = useDisclosure()

	const { data: zooms, refetch ,loading} = useQuery(ListZoom)
	useEffect(() => {
		if (user?.role !== 'ADMIN') {
			setTimeout(() => {
				history.push('/login')
			}, 100)
		}
		if (loading) {
			refetch({
				teacher: user?._id,
			})
		}
	}, [history, user, refetch])
	
	/// AddZoomUserId
	const handleAddZoomId = async (e) => {
		e.preventDefault()
		// console.log(userZoom)
		if (!userZoom?.name) {
			await toast({
				position: 'top',
				title: 'Warning',
				description: `${t('manageZoom.warningName')}`,
				status: 'warning',
				duration: 6000,
				isClosable: true,
			})
		} else if (!userZoom?.userId) {
			await toast({
				position: 'top',
				title: 'Warning',
				description: `${t('manageZoom.warningUserId')}`,
				status: 'warning',
				duration: 6000,
				isClosable: true,
			})
		} else if (!userZoom?.token) {
			await toast({
				position: 'top',
				title: 'Warning',
				description: `${t('manageZoom.warningToken')}`,
				status: 'warning',
				duration: 6000,
				isClosable: true,
			})
		} else {
			try {
				const { data: zoomdata } = await setZoomMutation({
					variables: {
						name: userZoom?.name,
						userId: userZoom?.userId,
						token: userZoom?.token,
					},
				})
				if (zoomdata) {
					await toast({
						position: 'top',
						title: 'Success',
						description: 'เพิ่มสำเร็จ',
						status: 'success',
						duration: 6000,
						isClosable: true,
					})
					// console.log(zoomdata)
					refetch()
				}
			} catch (Error) {
				// console.log('in Create submit', Error)
			}
			setUserZoom(initialZoom)
			onAddZoomModalClose()
		}
	}
	///Update zoom userId

	const [isUpdateZoomModal, setIsUpdateZoomModal] = useState('')
	const [userZoomUpdate, setUserZoomUpdate] = useMultiState(initialZoom)

	const onUpdateZoomOpen = () => {
		setIsUpdateZoomModal(true)
	}
	const onUpdateZoomClose = () => {
		setIsUpdateZoomModal(false)
	}

	const handleUpdateZoomId = async (e) => {
		e.preventDefault()
		// console.log(userZoomUpdate)
		if (!userZoomUpdate?.name) {
			await toast({
				position: 'top',
				title: 'Warning',
				description: `${t('manageZoom.warningName')}`,
				status: 'warning',
				duration: 6000,
				isClosable: true,
			})
		} else if (!userZoomUpdate?.userId) {
			await toast({
				position: 'top',
				title: 'Warning',
				description: `${t('manageZoom.warningUserId')}`,
				status: 'warning',
				duration: 6000,
				isClosable: true,
			})
		} else if (!userZoomUpdate?.token) {
			await toast({
				position: 'top',
				title: 'Warning',
				description: `${t('manageZoom.warningToken')}`,
				status: 'warning',
				duration: 6000,
				isClosable: true,
			})
		} else {
			try {
				const { data: updateZoom } = await setZoomMutationById({
					variables: {
						_id: userZoomUpdate?._id,
						name: userZoomUpdate?.name,
						userId: userZoomUpdate?.userId,
						token: userZoomUpdate?.token,
					},
				})
				if (updateZoom) {
					await toast({
						position: 'top',
						title: 'Success',
						description: `${t('manageZoom.alertEditComplete')}`,
						status: 'success',
						duration: 6000,
						isClosable: true,
					})
					// console.log(updateZoom)
					refetch()
				}
			} catch (Error) {
				// console.log('in Create submit', Error)
			}
		}
		setUserZoomUpdate(initialZoom)
		onUpdateZoomClose()
	}
	/// remove Zoom
	const [isRemoveZoomModal, setIsRemoveZoomModal] = useState('')
	const [idRemove, setIdRemove] = useState('')

	const onRemoveZoomOpen = async (id) => {
		setIdRemove(id)
		setIsRemoveZoomModal(true)
	}
	const onRemoveZoomClose = () => {
		setIsRemoveZoomModal(false)
	}
	const handleRemoveZoomId = async (e) => {
		e.preventDefault()
		const { data: zoomremovedata} = await removeZoomMutation({
			variables: {
				_id: idRemove,
			},
		})
		if (zoomremovedata) {
			await toast({
				position: 'top',
				title: 'Success',
				description: `${t('manageZoom.alertDeleteComplete')}`,
				status: 'success',
				duration: 6000,
				isClosable: true,
			})
			refetch()
			setIdRemove('')
			onRemoveZoomClose()
		}
	}
	// console.log(zooms)
	return (
		<Fragment>
			<Modal isOpen={isUpdateZoomModal} onClose={onUpdateZoomClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('manageZoom.edit')}</ModalHeader>
					<ModalCloseButton />
					<form onSubmit={handleUpdateZoomId}>
						<ModalBody>
							<FormControl id="">
								<FormLabel>{t('manageZoom.name')}</FormLabel>
								<Input
									value={userZoomUpdate?.name}
									onChange={(e) => setUserZoomUpdate({ name: e.target.value })}
								/>
								<FormLabel>{t('manageZoom.userId')}</FormLabel>
								<Input
									value={userZoomUpdate?.userId}
									onChange={(e) => setUserZoomUpdate({ userId: e.target.value })}
								/>
								<FormLabel>{t('manageZoom.token')}</FormLabel>
								<Textarea
									value={userZoomUpdate?.token}
									onChange={(e) => setUserZoomUpdate({ token: e.target.value })}
								/>
							</FormControl>
						</ModalBody>
						<ModalFooter>
							<Button onClick={onUpdateZoomClose} mr={4}>
								{t('manageZoom.closeButton')}
							</Button>
							<Button type="submit " bg={'primary.700'} color={'white'} _hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}}>
								{t('manageZoom.confirmButton')}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			<Modal isOpen={isAddZoomModal} onClose={onAddZoomModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('manageZoom.addZoomButton')}</ModalHeader>
					<ModalCloseButton />
					<form onSubmit={handleAddZoomId}>
						<ModalBody>
							<FormControl id="">
								<FormLabel>{t('manageZoom.name')}</FormLabel>
								<Input onChange={(e) => setUserZoom({ name: e.target.value })} />
								<FormLabel>{t('manageZoom.userId')}</FormLabel>
								<Input onChange={(e) => setUserZoom({ userId: e.target.value })} />
								<FormLabel>{t('manageZoom.token')}</FormLabel>
								<Textarea onChange={(e) => setUserZoom({ token: e.target.value })} />
							</FormControl>
						</ModalBody>
						<ModalFooter>
							<Button onClick={onAddZoomModalClose} mr={4}>
								{t('manageZoom.closeButton')}
							</Button>
							<Button type="submit " bg={'primary.700'} color={'white'} _hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}}>
								{t('manageZoom.confirmButton')}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			<AlertDialog isOpen={isRemoveZoomModal} onClose={onRemoveZoomClose}>
				<AlertDialogOverlay>
					<form onSubmit={handleRemoveZoomId}>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('manageZoom.deleteAlertDialogTitle')}
							</AlertDialogHeader>

							<AlertDialogBody>{t('manageZoom.deleteAlertDialogQuestion')}</AlertDialogBody>

							<AlertDialogFooter>
								<Button onClick={onRemoveZoomClose}>{t('manageZoom.deleteAlertDialogCancelButton')}</Button>
								<Button type="submit" bg={'primary.700'} color={'white'} _hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}} ml={3}>
									{t('manageZoom.deleteAlertDialogDeleteButton')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</form>
				</AlertDialogOverlay>
			</AlertDialog>


			<Box mt={20} w="100%" style={{ zIndex: 2000 }}>
				<Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
					<Stack spacing={0} align={'center'}>
						<Heading variant="h4">{t('manageZoom.title')}</Heading>
						<Text>{t('manageZoom.subtitle')}</Text>
					</Stack>
					<Box align="center">
						<Button
							bg={'primary.700'} color={'white'} _hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}}
							onClick={() => {
								onAddZoomModalOpen()
							}}
						>
							{t('manageZoom.addZoomButton')}
						</Button>
					</Box>
					<GridItem colStart={2} colEnd={6}>
						<Table variant="striped" backgroundColor="white">
							<Tr>
								<Th textAlign="center">{t('manageZoom.no')}</Th>
								<Th textAlign="center">{t('manageZoom.name')}</Th>
								<Th textAlign="center">{t('manageZoom.userId')}</Th>
								<Th textAlign="center">{t('manageZoom.manage')}</Th>
							</Tr>
							<Tbody>
								{zooms?.listzooms?.map((list, i) => (
									<Tr>
										<Td textAlign="center">{i + 1}</Td>
										<Td textAlign="center">{list?.name}</Td>
										<Td textAlign="center">{list?.userId}</Td>
										<Td>
											<Center>
												<Tooltip label={t('manageZoom.edit')} fontSize="md">
													<IconButton
														aria-label="Search database"
														icon={<EditIcon />}
														mr={4}
														onClick={(e) => {
															onUpdateZoomOpen()
															setUserZoomUpdate(list)
														}}
													/>
												</Tooltip>
												<Tooltip label={t('manageZoom.delete')} fontSize="md">
													<IconButton
														aria-label="Search database"
														icon={<DeleteIcon />}
														mr={4}
														onClick={(e) => {
															onRemoveZoomOpen(list?._id)
														}}
													/>
												</Tooltip>
											</Center>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</GridItem>
				</Container>
			</Box>
		</Fragment>
	)
}

export default ManagerZoomPage

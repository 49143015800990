import { Flex, Heading, Stack } from "@chakra-ui/layout"
import React from "react"
import TermConditionsLayout from '../components/layouts/TermConditionsLayout'


const TermConditionsPage = () => {
    return (
        <Flex mt={20} padding={20}>
            <Stack>
                <Heading>Term {`&`} Conditions</Heading>
                <TermConditionsLayout />
            </Stack>
        </Flex>
    )
}
export default TermConditionsPage

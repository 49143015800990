import gql from 'graphql-tag'

export default gql`
	mutation ($contentId: MongoID, $studentId: MongoID, $answer: JSON, $submitdate: Date) {
		createQuiz(
			record: { content: $contentId, student: $studentId, answer: $answer, submitdate:$submitdate }
		) {
			recordId
		}
	}
`

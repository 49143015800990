import gql from 'graphql-tag'

export default gql`
query ($student: MongoID ){
    studentofsubjects(filter:{student:$student}, limit: 10000){
      subject{
      _id
      name
      description
      updatedAt
      photo
      alive_subject
      teacher{
        _id
        username
        firstname
        lastname
        isTeacher
        avatar
        email
        description
        facebook{
          name
          link
        }
        instagram{
          name
          link
        }
        youtube{
          name
          link
        }
    }
    }
    student{
        _id
        firstname
      }
      
  }
}
`

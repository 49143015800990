import gql from 'graphql-tag'

export default gql`
	mutation ($_id: MongoID!, $name: String, $userId: String, $token: String) {
		updateListzoom(
			_id: $_id
			record: { name: $name, userId: $userId, token: $token }
		) {
			recordId
		}
	}
`

import React, { useState, useContext, useEffect, Fragment } from 'react'

import questionById from '../graphql/queries/question'
import { useTranslation } from 'react-i18next'

import Question from '../components/Question'
import End from '../components/End'
import AuthContext from '../contexts/AuthContext'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import {
	Box,
	chakra,
	Flex,
	useColorModeValue,
	Text,
	Button,
	VStack,
	Center,
} from '@chakra-ui/react'

const QuizPage = (props) => {
	let interval

	const { user } = useContext(AuthContext)
	const myCurrentDate = new Date()
	const param = useParams()
	const [step, setStep] = useState(1)
	const [activeQuestion, setActiveQuestion] = useState(0)
	const [answers, setAnswers] = useState({})
	const { t } = useTranslation()
	const [time, setTime] = useState(0)

	const { data: QuestionData } = useQuery(questionById, {
		variables: {
			_id: param?.contentId,
		},
	})

	let questionlist = QuestionData?.content?.questionconfig?.questionList
	useEffect(() => {
		if (step === 3) {
			clearInterval(interval)
		}
	}, [step])


	const quizStartHandler = () => {
		setStep(2)
	}

	const resetClickHandler = () => {
		setActiveQuestion(0)
		setAnswers([])
		setStep(2)
		setTime(0)
	}
	// console.log("hhhh",QuestionData?.content?.questionconfig?.description)
	return (
		<Fragment>
			<Box w="100%" px={[3, 2, 20, 20]} minHeight={'90vh'} mt={10}>
				{step === 1 ? (
					<Flex w="full" alignItems="center" justifyContent="center">
						<Box
							w="600px"
							mx="auto"
							rounded="lg"
							boxShadow="md"
							border={`1px solid rgba(90,90,90,0.3)`}
							maxW="2xl"
						>
							<VStack
								spacing={4}
								align='stretch'
							>
								<Box>
									<Center>
										<Text
											display="block"
											// color={useColorModeValue('gray.800', 'white')}
											fontWeight="bold"
											fontSize="2xl"
											mt={2}
										>
											{QuestionData?.content?.name}
										</Text>
									</Center>
								</Box>
								<Box>
									<Text mt={5} ml={10} fontSize="sm" >
										{QuestionData?.content?.questionconfig?.description}
									</Text>
								</Box>
								<Box>
									<Center>
										<Button
											bg={'tomato'}
											color={'white'}
											size="sm"
											w={'150px'}
											onClick={quizStartHandler}
											_hover={{
												bg: ['primary.100', 'primary.100', 'primary.100', 'primary.100'],
											}}
										>
											{t('QuizPage.start')}
										</Button>
									</Center>
								</Box>
								<Box >
									<Flex justifyContent="end" h={10}>
										<chakra.span
											alignItems="left"
											fontSize="xs"
											textTransform="uppercase"
											// color={useColorModeValue('brand.600', 'brand.400')}
											mr={10}
										>
											Quiz ID : {QuestionData?.content?._id}
										</chakra.span>
									</Flex>
								</Box>
							</VStack>
						</Box>
					</Flex>
				) : (
					''
				)}
				{step === 2 ? (
					<Question
						data={questionlist[activeQuestion]}
						content={param?.contentId}
						onAnswerUpdate={setAnswers}
						questionName={QuestionData?.content?.name}
						numberOfQuestions={questionlist.length}
						contentId={param?.contentId}
						subjectId={param?.subjectId}
						activeQuestion={activeQuestion}
						onSetActiveQuestion={setActiveQuestion}
						onSetStep={setStep}
					/>
				) : (
					''
				)}
				{step === 3 && (
					<End
						results={answers}
						data={questionlist}
						questionName={QuestionData?.content?.name}
						numberOfQuestions={questionlist.length}
						contentId={param?.contentId}
						subjectId={param?.subjectId}
						onReset={resetClickHandler}
					/>
				)}

			</Box>
		</Fragment >
	)
}

export default QuizPage
import React, { Fragment, useState, useEffect, useContext } from 'react'
import * as Survey from "survey-react";
import './style.css'
import "./survey.css";
import {
    Tbody,
    Thead,
    Box,
    Container,
    Stack,
    Text,
    Heading,
    Button,
    Table,
    Tr,
    Th,
    Td,
    Tag,
    TagLabel,
    Center,
    Input,
    FormControl,
    FormLabel,
    Textarea,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    useDisclosure,
    Image,
    useToast,
    HStack,
    Progress,
    InputGroup,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useColorModeValue,
    useColorMode,
    Checkbox,
    Link,
    Flex,
    Switch,
    Skeleton,
    Grid, GridItem,
    InputLeftAddon,
    Select,
    VStack,
    IconButton,
} from '@chakra-ui/react'
import "./survey.css";
import { Dropzone, FileItem } from '@dropzone-ui/react'
import { FaTrashAlt, FaPencilAlt, FaRegFile, FaSortAmountDown } from 'react-icons/fa'
import { AddIcon, DownloadIcon, CloseIcon, HamburgerIcon, EditIcon, RepeatIcon } from '@chakra-ui/icons'
import AuthContext from '../contexts/AuthContext'

import { useMutation, useQuery } from '@apollo/client'

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import renderHTML from 'react-render-html'

import ContentsBySubject from '../graphql/queries/contentsbysubject'

import Subject from '../graphql/queries/subject'
import UpdateSubject from '../graphql/mutations/updateSubject';

import ContentMutation from '../graphql/mutations/createContent'
import ContentRemoveMutation from '../graphql/mutations/removeContent'
import UpdateContent from '../graphql/mutations/updateContent'

import { useMultiState } from '../lib/hooks'
import { Link as domlink } from 'react-router-dom'
import axios from 'axios'
import { useParams } from 'react-router'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { deepOrange } from '@mui/material/colors'
import DateTimePicker from '@mui/lab/DateTimePicker'
import dayjs from 'dayjs'
import * as tus from "tus-js-client";
import { TextField } from '@mui/material'
import Iframe from 'react-iframe'
import { useHistory } from 'react-router'

import DialogSortingContent from '../components/pages/ManageContentPage/DialogSortingContent';


import { useTranslation } from 'react-i18next'

const R = require('ramda')

const initialContent = {
    name: '',
    carddescription: '',
    link: "",
    text: "",
    type: 'empty',
    description: "",
    date: "",
    statusActive: false,
}

const initialUpdateContent = {
    name: '',
    link: '',
    text: '',
    type: '',
    description: '',
    duedate: '',
    contentId: ''
}


const theme = createTheme({
    palette: {
        primary: {
            main: '#0CA25F',
        },
        secondary: deepOrange,
    },
})

const URLImage = process.env.REACT_APP_UPLOAD_IMAGE
const URLFile = process.env.REACT_APP_UPLOAD_FILE
const URLCloudFlare = process.env.REACT_APP_URL_CLOUDFLARE
const URLHomework = process.env.REACT_APP_UPLOAD_HOMEWORK

const ManageContentPage = () => {

    const bg = useColorModeValue('white', 'gray.900')
    const { colorMode } = useColorMode()
    const { user } = useContext(AuthContext)
    const [list, setList] = useState()
    const [refetchList, setRefetchList] = useState(false)
    const [isContentSorting, setIsContentSorting] = useState(false)


    const { isOpen: isUpdateContentOpen, onOpen: onUpdateContentOpen, onClose: onUpdateContentClose, } = useDisclosure()
    // const { isOpen: isContentSorting, onOpen: onSortingOpen, onClose: onSortingClose, } = useDisclosure()
    const { isOpen: isPreviewOpen, onOpen: onPreviewOpen, onClose: onPreviewClose, } = useDisclosure()
    const { isOpen: isAddContentOpen, onOpen: onAddContentOpen, onClose: onAddContentClose, } = useDisclosure()
    const { t } = useTranslation()

    const toast = useToast()
    const history = useHistory()
    const param = useParams()
    // const { user } = useContext(AuthContext)
    const [setUpdateSubject] = useMutation(UpdateSubject)
    const [setUpdateContent] = useMutation(UpdateContent)
    const [removeContentMutation] = useMutation(ContentRemoveMutation)
    const [setContentMutation] = useMutation(ContentMutation)



    const [urlCloudFlare, setUrlCloudFlare] = useMultiState({ url: '', id: '' })

    const [editorState, setEditorState] = useState(EditorState?.createEmpty());
    const [editorHomeworkState, seteditorHomeworkState] = useState(EditorState?.createEmpty());
    const [statusDate, setStatusDate] = useState(false)
    const [contentState, setContentState] = useState()
    const [contentData, setContentData] = useMultiState(initialContent)
    const [updateContent, setUpdateContentData] = useMultiState(initialUpdateContent)
    const [sortingState, setSortingState] = useState([])
    const [preview, setPreview] = useState('')
    const [duration, setDuration] = useState()
    const [typeDocHomework, setTypeDocHomework] = useState()


    const { data: Contents, loading, refetch } = useQuery(ContentsBySubject, {
        variables: {
            _id: param?.subjectId,
        },
    })

    const { data: SubjectData, refetch: reSorting } = useQuery(Subject, {
        variables: {
            _id: param?.subjectId,
        },
    })

    const sortInsert = (acc, cur) => {
        if (SubjectData?.subject?.sorting) {
            var toIdx = R.indexOf(cur?._id, SubjectData?.subject?.sorting);
            acc[toIdx] = cur;
            return acc;
        }
    }

    const onSortingOpen = () => {
        setIsContentSorting(true)
    }

    const onSortingClose = () => {
        refetch({
            subject: param?.subjectId,
        })
        reSorting({
            subject: param?.subjectId,
        })
        setIsContentSorting(false)
    }

    useEffect(async () => {
        if (user?.role === 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
        if (Contents) {
            var sort = R.reduce(sortInsert, []);
            setContentState(await sort(Contents?.contents))
        }
        if (SubjectData) {
            setSortingState(SubjectData?.subject?.sorting)
        }
        if (localStorage.getItem('refetchPage') !== true) {
            localStorage.setItem('refetchPage', false)
            refetch({
                subject: param?.subjectId,
            })
        }
        setList(Contents?.contents)
    }, [history, user, refetch, Contents, contentData, SubjectData,])

    useEffect(async () => {
        try {
            // console.log(list)
            if (refetchList) {
                // console.log("ARRAY", sortingState)
                const { data: Sorting } = await setUpdateSubject({
                    variables: {
                        _id: param?.subjectId,
                        sorting: sortingState,
                    },
                })
                if (Sorting) {
                    // console.log(QuestionData)
                    // await refetch({
                    //     subject: param?.subjectId,
                    // })
                    setRefetchList(false)
                    // await toast({
                    //     position: 'top',
                    //     title: 'Success',
                    //     description: `${t('ManageSurveyPage.altsuccesquestion')}`,
                    //     status: 'success',
                    //     duration: 3000,
                    //     isClosable: true,
                    // })
                }
            }
        } catch (error) {
            console.error(error)
        }
    }, [refetchList, sortingState])

    const [filesImg, setFilesImg] = useState([])
    const [filesVdo, setFilesVdo] = useState([])
    const [filesDoc, setFilesDoc] = useState([])

    const [newphotoData, setnewphotoData] = useMultiState({
        data: null,
        base64: '',
    })

    const [newDocumentData, setnewDocumentData] = useMultiState({
        data: null,
        base64: '',
        type: '',
    })

    const onDeleteImg = (id) => {
        setFilesImg(filesImg.filter((x) => x.id !== id))
    }

    const onDeleteDoc = (id) => {
        setFilesDoc(filesDoc.filter((x) => x.id !== id))
    }

    const onDeleteVdo = (id) => {
        setFilesVdo(filesVdo.filter((x) => x.id !== id))
    }

    const modalAddContentClose = () => {
        setContentData(initialContent)
        setUploadStage(0)
        onAddContentClose()
    }

    const modalAddContentOpen = () => {
        onAddContentOpen()
    }


    const modalUpdateContentOpen = async (content) => {
        if (content?.type === 'text') {
            setEditorState(EditorState?.createWithContent(convertFromRaw({ blocks: content?.texteditor?.blocks, entityMap: content?.texteditor?.entityMap })))
        }
        if (content?.type === 'homework') {
            seteditorHomeworkState(EditorState?.createWithContent(convertFromRaw({ blocks: content?.homeworkconfig?.description?.blocks, entityMap: content?.homeworkconfig?.description?.entityMap })))
            setTypeDocHomework(content?.homeworkconfig?.typefile)
        }
        if (content.expiredate) {
            setStatusDate(true)
        }
        setUploadStage(0)
        setUpdateContentData(content)
        onUpdateContentOpen(true)
        refetch({
            subject: param?.subjectId,
        })
    }

    const modalUpdateContentClose = () => {
        setUploadStage(0)
        setUpdateContentData(initialUpdateContent)
        onUpdateContentClose(true)
        refetch({
            subject: param?.subjectId,
        })
    }

    const handlePreviewOpen = (types) => {
        onPreviewOpen(true)
        setUpdateContentData({ type: types })
    }

    const handleAddContent = async (e) => {
        e.preventDefault()
        try {
            if (!contentData?.name) {
                await toast({
                    position: 'top',
                    title: 'Warning',
                    description: `${t('AddContentPage.toastAddWarningContent')}`,
                    status: 'warning',
                    duration: 6000,
                    isClosable: true,
                })
            } else if (contentData?.type == 'empty') {
                await toast({
                    position: 'top',
                    title: 'Warning',
                    description: `กรุณาเลือกเนื้อหา`,
                    status: 'warning',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                if (contentData?.type === 'homework') {
                    if (!contentData?.date) {
                        await toast({
                            position: 'top',
                            title: 'Warning',
                            description: 'ตั้งค่าวันส่งงาน',
                            status: 'warning',
                            duration: 6000,
                            isClosable: true,
                        })
                    } else {
                        console.log("TYPEFILE::", contentData?.typefile)

                        const editordata = convertToRaw(editorHomeworkState.getCurrentContent())
                        const responseDocument = await axios.post(URLFile, {
                            file: newDocumentData?.base64,
                            type: newDocumentData?.type
                        })
                        const { data: responseContent } = await setContentMutation({
                            variables: {
                                subject: param?.subjectId,
                                type: contentData?.type,
                                name: contentData?.name,
                                carddescription: contentData?.carddescription,
                                text: contentData?.text,
                                description: editordata,
                                duedate: contentData?.date,
                                typefile: contentData?.typefile,
                                exampleFile: responseDocument ? responseDocument?.data?.url : "",
                                exampleFiletype: newDocumentData?.type ? newDocumentData?.type?.toString() : "",
                                expiredate: statusDate === true ? dayjs(contentData?.expiredate) : null,
                                statusActive: contentData?.statusActive,
                            },
                        });
                        if (responseContent) {
                            handlesorting(responseContent?.createContent?.recordId)
                            await toast({
                                position: 'top',
                                title: 'success',
                                description: `${t('AddContentPage.toastAddSuccessContent')}`,
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                            })
                        }
                        setContentData(initialContent)
                        setFilesDoc([])
                        setnewDocumentData({ data: null, base64: '', name: '', type: '', })
                        refetch({
                            subject: param?.subjectId,
                        })
                        onAddContentClose()
                    }

                } else if (contentData?.type === 'photo') {
                    const responseImage = await axios.post(URLImage, {
                        photo: newphotoData?.base64,
                    })

                    const { data: responseContent } = await setContentMutation({
                        variables: {
                            subject: param?.subjectId,
                            type: contentData?.type,
                            name: contentData?.name,
                            carddescription: contentData?.carddescription,
                            link: responseImage ? responseImage?.data?.url : "",
                            expiredate: statusDate === true ? dayjs(contentData?.expiredate) : null,
                            statusActive: contentData?.statusActive,
                        },
                    });
                    if (responseContent) {
                        handlesorting(responseContent?.createContent?.recordId)
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('AddContentPage.toastAddSuccessContent')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                    }
                    setContentData(initialContent)
                    setFilesImg([])
                    refetch({
                        subject: param?.subjectId,
                    })
                    onAddContentClose()
                } else if (contentData?.type === 'document') {
                    const responseDocument = await axios.post(URLFile, {
                        file: newDocumentData?.base64,
                        type: newDocumentData?.type
                    })
                    const { data: responseContent } = await setContentMutation({
                        variables: {
                            subject: param?.subjectId,
                            type: contentData?.type,
                            name: contentData?.name,
                            doctype: newDocumentData?.type ? newDocumentData?.type?.toString() : "",
                            carddescription: contentData?.carddescription,
                            link: responseDocument ? responseDocument?.data?.url : "",
                            expiredate: statusDate === true ? dayjs(contentData?.expiredate) : null,
                            statusActive: contentData?.statusActive,
                        },
                    });
                    if (responseContent) {
                        handlesorting(responseContent?.createContent?.recordId)
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('AddContentPage.toastAddSuccessContent')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                    }
                    setContentData(initialContent)
                    setFilesDoc([])
                    setnewDocumentData({ data: null, base64: '', name: '', type: '', })
                    refetch({
                        subject: param?.subjectId,
                    })
                    onAddContentClose()
                } else if (contentData?.type === 'video') {
                    console.log("DDD", duration)
                    const { data: responseContent } = await setContentMutation({
                        variables: {
                            subject: param?.subjectId,
                            type: contentData?.type,
                            name: contentData?.name,
                            carddescription: contentData?.carddescription,
                            duration: parseInt(duration),
                            link: urlCloudFlare?.url,
                            expiredate: statusDate === true ? dayjs(contentData?.expiredate) : null,
                            statusActive: contentData?.statusActive,
                        },
                    });
                    if (responseContent) {
                        handlesorting(responseContent?.createContent?.recordId)
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('AddContentPage.toastAddSuccessContent')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                    }
                    setContentData(initialContent)
                    setUploadStage(0)
                    setFilesVdo([])
                    refetch({
                        subject: param?.subjectId,
                    })
                    onAddContentClose()

                } else if (contentData?.type === 'text') {
                    const editordata = convertToRaw(editorState.getCurrentContent())
                    const { data: responseContent } = await setContentMutation({
                        variables: {
                            subject: param?.subjectId,
                            type: contentData?.type,
                            name: contentData?.name,
                            carddescription: contentData?.carddescription,
                            texteditor: editordata,
                            expiredate: statusDate === true ? dayjs(contentData?.expiredate) : null,
                            statusActive: contentData?.statusActive,
                        },
                    });
                    if (responseContent) {
                        handlesorting(responseContent?.createContent?.recordId)
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('AddContentPage.toastAddSuccessContent')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                    }
                    setContentData(initialContent)
                    refetch({
                        subject: param?.subjectId,
                    })
                    onAddContentClose()

                } else if (contentData?.type === 'link') {
                    // console.log("LINKKKKKKKKKKKKKKKKKKKKKK", param?.subjectId)
                    const { data: responseContent } = await setContentMutation({
                        variables: {
                            subject: param?.subjectId,
                            type: contentData?.type,
                            name: contentData?.name,
                            carddescription: contentData?.carddescription,
                            link: contentData?.link,
                            expiredate: statusDate === true ? dayjs(contentData?.expiredate) : null,
                            statusActive: contentData?.statusActive,
                        },
                    });
                    if (responseContent) {
                        handlesorting(responseContent?.createContent?.recordId)
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('AddContentPage.toastAddSuccessContent')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                    }
                    setContentData(initialContent)
                    refetch({
                        subject: param?.subjectId,
                    })
                    onAddContentClose()
                } else if (contentData?.type === 'survey') {
                    const editordata = convertToRaw(editorHomeworkState.getCurrentContent())
                    const { data: responseContent } = await setContentMutation({
                        variables: {
                            subject: param?.subjectId,
                            type: contentData?.type,
                            name: contentData?.name,
                            surveydescription: editordata,
                            carddescription: contentData?.carddescription,
                            expiredate: statusDate === true ? dayjs(contentData?.expiredate) : null,
                            statusActive: contentData?.statusActive,
                        },
                    });
                    if (responseContent) {
                        handlesorting(responseContent?.createContent?.recordId)
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('AddContentPage.toastAddSuccessContent')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        history.push(`managesurvey/${responseContent?.createContent?.recordId}`)
                    }

                } else if (contentData?.type === 'quiz') {
                    const { data: responseContent } = await setContentMutation({
                        variables: {
                            subject: param?.subjectId,
                            type: contentData?.type,
                            name: contentData?.name,
                            carddescription: contentData?.carddescription,
                            expiredate: statusDate === true ? dayjs(contentData?.expiredate) : null,
                            statusActive: contentData?.statusActive,
                        },
                    });
                    if (responseContent) {
                        handlesorting(responseContent?.createContent?.recordId)
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('AddContentPage.toastAddSuccessContent')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        history.push(`managequestion/${responseContent?.createContent?.recordId}`)
                    }
                }
            }
        } catch (error) {
            console.log(error)
            toast({
                position: "top",
                title: "ERROR",
                description: 'error',
                status: "error",
                duration: 6000,
                isClosable: true,
            })
        }
    }

    const handleUpdateContent = async (e) => {
        e.preventDefault()
        try {
            if (!updateContent?.name) {
                await toast({
                    position: 'top',
                    title: 'Warning',
                    description: `${t('managecontentPage.toastAddWarningSubject')}`,
                    status: 'warning',
                    duration: 6000,
                    isClosable: true,
                })
            } else {
                if (updateContent?.type === 'homework') {
                    const editordata = convertToRaw(editorHomeworkState.getCurrentContent())
                    const responseDocument = await axios.post(URLFile, {
                        file: newDocumentData?.base64,
                        type: newDocumentData?.type
                    })
                    const { data: ContentAddData } = await setUpdateContent({
                        variables: {
                            _id: updateContent?._id,
                            type: updateContent?.type,
                            name: updateContent?.name,
                            carddescription: updateContent?.carddescription,
                            text: updateContent?.text,
                            description: editordata,
                            duedate: updateContent?.duedate ? updateContent?.duedate : updateContent?.homeworkconfig?.duedate,
                            typefile: typeDocHomework,
                            exampleFile: responseDocument ? responseDocument?.data?.url : "",
                            exampleFiletype: newDocumentData?.type ? newDocumentData?.type?.toString() : "",
                            statusActive: updateContent?.statusActive,
                            expiredate: statusDate === true ? dayjs(updateContent.expiredate) : null,
                        },
                    })
                    if (ContentAddData) {
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('managecontentPage.toastEditSuccessCourse')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        setUpdateContentData(initialUpdateContent)
                        setnewphotoData({ data: null, base64: '' })
                        seteditorHomeworkState(EditorState.createEmpty())
                        setFilesDoc([])
                        modalUpdateContentClose()
                        refetch({
                            subject: param?.subjectId,
                        })
                    }
                } else if (updateContent?.type === 'text') {
                    const editordata = convertToRaw(editorState.getCurrentContent())
                    const { data: ContentUpdate } = await setUpdateContent({
                        variables: {
                            _id: updateContent?._id,
                            type: updateContent?.type,
                            name: updateContent?.name,
                            carddescription: updateContent?.carddescription,
                            texteditor: editordata,
                            statusActive: updateContent?.statusActive
                        },
                    })
                    if (ContentUpdate) {
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('managecontentPage.toastEditSuccessCourse')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        setUpdateContentData(initialUpdateContent)
                        setEditorState(EditorState.createEmpty())
                        refetch({
                            subject: param?.subjectId,
                        })
                        modalUpdateContentClose()
                    }
                } else if (updateContent?.type === 'photo') {
                    const responseImage = await axios.post(URLImage, {
                        photo: newphotoData?.base64,
                    })
                    const { data: ContentUpdate } = await setUpdateContent({
                        variables: {
                            subject: param?.subjectId,
                            type: updateContent?.type,
                            name: updateContent?.name,
                            carddescription: updateContent?.carddescription,
                            link: responseImage ? responseImage?.data?.url : "",
                            expiredate: statusDate === true ? dayjs(updateContent.expiredate) : null,
                            statusActive: updateContent?.statusActive
                        },
                    })

                    if (ContentUpdate) {
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('managecontentPage.toastEditSuccessCourse')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        setUpdateContentData(initialUpdateContent)
                        setFilesImg([])
                        refetch({
                            subject: param?.subjectId,
                        })
                        modalUpdateContentClose()
                    }

                } else if (updateContent?.type === 'document') {
                    const responseDocument = await axios.post(URLFile, {
                        file: newDocumentData?.base64,
                        type: newDocumentData?.type
                    })

                    const { data: ContentUpdate } = await setUpdateContent({
                        variables: {
                            _id: updateContent?._id,
                            type: updateContent?.type,
                            name: updateContent?.name,
                            doctype: newDocumentData?.type ? newDocumentData?.type?.toString() : "",
                            carddescription: updateContent?.carddescription,
                            link: responseDocument ? responseDocument?.data?.url : "",
                            expiredate: statusDate === true ? dayjs(updateContent.expiredate) : null,
                            statusActive: updateContent?.statusActive,
                        },
                    })

                    if (ContentUpdate) {
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('managecontentPage.toastEditSuccessCourse')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        setUpdateContentData(initialUpdateContent)
                        setFilesDoc([])
                        setnewDocumentData({ data: null, base64: '', name: '', type: '', })
                        refetch({
                            subject: param?.subjectId,
                        })
                        modalUpdateContentClose()
                    }
                } else if (updateContent?.type === 'video') {
                    if (!filesVdo?.length === 0) {
                        // console.log("ACTICE", filesVdo?.length)
                        await getVideoDuration()
                    }
                    const { data: ContentUpdate } = await setUpdateContent({
                        variables: {
                            _id: updateContent?._id,
                            type: updateContent?.type,
                            name: updateContent?.name,
                            carddescription: updateContent?.carddescription,
                            duration: filesVdo ? parseInt(duration) : updateContent?.duration,
                            link: urlCloudFlare.url ? urlCloudFlare?.url : updateContent?.link,
                            expiredate: statusDate === true ? dayjs(updateContent.expiredate) : null,
                            statusActive: updateContent?.statusActive
                        },
                    })
                    if (ContentUpdate) {
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('managecontentPage.toastEditSuccessCourse')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })

                        modalUpdateContentClose()
                        setFilesVdo([])
                        refetch({
                            subject: param?.subjectId,
                        })
                    }

                } else if (updateContent?.type === 'link') {
                    const { data: ContentUpdate } = await setUpdateContent({
                        variables: {
                            _id: updateContent?._id,
                            type: updateContent?.type,
                            name: updateContent?.name,
                            carddescription: updateContent?.carddescription,
                            link: updateContent?.link,
                            expiredate: statusDate === true ? dayjs(updateContent.expiredate) : null,
                            statusActive: updateContent?.statusActive
                        },
                    })

                    if (ContentUpdate) {
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('managecontentPage.toastEditSuccessCourse')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        setUpdateContentData(initialUpdateContent)
                        modalUpdateContentClose()
                        refetch({
                            subject: param?.subjectId,
                        })
                    }

                } else {
                    const { data: ContentAddData } = await setUpdateContent({
                        variables: {
                            _id: updateContent?._id,
                            type: updateContent?.type,
                            name: updateContent?.name,
                            carddescription: updateContent?.carddescription,
                            text: updateContent?.text,
                            expiredate: statusDate === true ? dayjs(updateContent.expiredate) : null,
                            statusActive: updateContent?.statusActive
                        },
                    })

                    // console.log(ContentAddData)
                    if (ContentAddData) {
                        await toast({
                            position: 'top',
                            title: 'success',
                            description: `${t('managecontentPage.toastEditSuccessCourse')}`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        setUpdateContentData(initialUpdateContent)
                        setnewphotoData({ data: null, base64: '' })
                        setnewDocumentData({ data: null, base64: '', name: '', type: '', })
                        setFilesImg([])
                        setFilesDoc([])
                        modalUpdateContentClose()
                    }
                }
            }
        } catch (error) {
            console.error(error)
            setUpdateContentData(initialUpdateContent)
            setnewphotoData({ data: null, base64: '' })
            setnewDocumentData({ data: null, base64: '', name: '', type: '', })
            setFilesImg([])
            setFilesDoc([])
            modalUpdateContentClose()
            toast({
                position: "top",
                title: "ERROR",
                description: 'error',
                status: "error",
                duration: 6000,
                isClosable: true,
            })
        }
    }

    const updateFilesImg = (incommingFiles, e) => {
        if (incommingFiles.length !== 0) {
            new Promise((resolve, reject) => {
                setFilesImg(incommingFiles)
                setnewphotoData({ data: incommingFiles[0]?.file })
                const reader = new FileReader()
                reader.readAsDataURL(incommingFiles[0]?.file)
                reader.onload = () => {
                    resolve(reader.result)
                    setnewphotoData({
                        base64: reader.result,
                    })
                }
                reader.onerror = (error) => reject(error)
            })
        }
    }

    const updateFilesDoc = (incommingFiles, e) => {
        if (incommingFiles.length !== 0) {
            new Promise((resolve, reject) => {
                setFilesDoc(incommingFiles)
                const regex = /[^.]+$/g;
                const typefile = incommingFiles[0]?.file.name.match(regex)
                setnewDocumentData({ data: incommingFiles[0]?.file, type: typefile })
                const reader = new FileReader()
                reader.readAsDataURL(incommingFiles[0]?.file)
                reader.onload = () => {
                    resolve(reader.result)
                    setnewDocumentData({
                        base64: reader.result,
                    })
                }
                reader.onprogress = (data) => {
                    if (data.lengthComputable) {
                        var progress = parseInt(((data.loaded / data.total) * 100), 10);
                        setUploadStage(progress)
                    }
                }
                reader.onerror = (error) => reject(error)
            })
        }
    }

    const [uploadState, setUploadStage] = useState(0)
    const updateFilesVdo = async (incommingFiles) => {
        setFilesVdo(incommingFiles)
        var videoInputFile = await incommingFiles[0]?.file;
        var mediaId = ''
        var upload = new tus.Upload(videoInputFile, {
            endpoint: `${URLCloudFlare}`,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                name: incommingFiles[0]?.file?.name,
                filetype: incommingFiles[0]?.file?.type
            },
            headers: {
                'X-Auth-Email': 'kaseampong.kt@streamsouth.tech',
                'X-Auth-Key': '483ae948176fd81daa6015d16e1f7868b8b1b'
            },
            chunkSize: 100 * 1024 * 1024,
            onError: function (error) {
                console.log("Failed because: " + error);
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                var percentage = Math.floor(bytesUploaded / bytesTotal * 100);
                console.log(bytesUploaded, bytesTotal, percentage + "%")
                setUploadStage(percentage)
            },
            onSuccess: function () {
                // console.log("Download %s from %s", upload.file.name, upload.url)
            },
            onAfterResponse: function (req, res) {
                return new Promise(resolve => {
                    var mediaIdHeader = res.getHeader("stream-media-id");
                    if (mediaIdHeader) {
                        mediaId = mediaIdHeader;
                        // setUpdateContent({ link: `https://iframe.videodelivery.net/${mediaId}` })
                        setUrlCloudFlare({ url: `https://iframe.videodelivery.net/${mediaId}`, id: mediaId, file: incommingFiles[0].file })
                        getVideoDuration(incommingFiles)
                    }
                    resolve()
                })
            }
        });
        upload.start();
    }

    //Alert dialog remove content
    const [showRemoveContent, setShowRemoveContent] = useState(false)
    const [removeContentIndex, setRemoveContentIndex] = useState('')

    const handleOpenRemoveContent = (content) => {
        setRemoveContentIndex(content)
        setShowRemoveContent(true)
    }

    const handleCloseRemoveContent = () => {
        setShowRemoveContent(false)
    }

    const handleRemoveContent = async (e) => {
        e.preventDefault()
        try {
            // console.log(removeContentIndex)
            const { data: subjectremovedata } = await removeContentMutation({
                variables: {
                    _id: removeContentIndex?._id,
                },
            })
            if (subjectremovedata) {
                handleRemoveSorting(removeContentIndex?._id)
                await toast({
                    position: 'top',
                    title: 'success',
                    description: `${t('managecontentPage.toastRemoveSuccess')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    subject: param?.subjectId,
                })
                setRemoveContentIndex('')
                handleCloseRemoveContent()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }

    const handlesorting = async (content_id) => {
        try {
            Object.values(sortingState)
            sortingState?.push(content_id)
            const { data: Sorting, } = await setUpdateSubject({
                variables: {
                    _id: param?.subjectId,
                    name: SubjectData?.subject?.name,
                    sorting: sortingState,
                },
            })
            reSorting({
                subject: param?.subjectId,
            })
            setSortingState([])
        } catch (error) {
            console.error(error)
        }
    }

    const handleRemoveSorting = async (content_id) => {
        try {
            Object.values(sortingState)
            var newArray = sortingState.filter(function (f) { return f !== content_id })
            // console.log(newArray)
            const { data: Sorting, errors: streamErr } = await setUpdateSubject({
                variables: {
                    _id: param?.subjectId,
                    sorting: newArray,
                },
            })
            reSorting({
                subject: param?.subjectId,
            })
            setSortingState([])
        } catch (error) {
            console.error(error)
        }
    }

    const handleRefetchContent = () => {
        reSorting({
            subject: param?.subjectId,
        })
        refetch({
            subject: param?.subjectId,
        })
    }

    const getVideoDuration = (filesVdo) => {
        new Promise((resolve, reject) => {
            // e.preventDefault();
            var file = filesVdo[0]?.file, // selected file
                mime = file?.type, // store mime for later
                reader = new FileReader(); // create a FileReader

            reader.onload = (e) => {
                // when file has read:

                var blob = new Blob([file], { type: mime }), // create a blob of buffer
                    url = (URL || window.webkitURL).createObjectURL(blob), // create o-URL of blob
                    video = document.createElement("video"); // create video element

                video.preload = "metadata"; // preload setting
                video.addEventListener("loadedmetadata", function () {
                    // when enough data loads
                    // console.log("DDDD22222", video.duration)
                    resolve(video.duration);
                    setDuration(video.duration)
                });
                video.src = url; // start video load
            };
            reader.readAsArrayBuffer(file);
            reader.onerror = (error) => reject(error);
        });
    }

    console.log(typeDocHomework, "SSSSSSS")



    return (
        <Fragment>

            {/* //UPDATECONTENT */}
            <Modal isOpen={isUpdateContentOpen} onClose={modalUpdateContentClose} style={{ zIndex: 1 }} size={'5xl'}>
                {console.log("UPDATE", updateContent)}
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('managecontentPage.editContent')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleUpdateContent} style={{ zIndex: 2 }}>
                        <ModalBody>
                            <Box maxW="7xl" mx={'auto'} px={{ base: 2, sm: 12, md: 17 }} >
                                <Grid
                                    boxShadow={'xl'}
                                    templateRows='repeat(1, 1fr)'
                                    templateColumns='repeat(5, 1fr)'
                                    borderRadius="lg"
                                    borderWidth={'1px'}
                                >
                                    <GridItem rowSpan={1} colSpan={1} bg={'primary.900'} borderLeftRadius="lg" >
                                        <Center pt={5}>
                                            <Stack>
                                                <Flex
                                                    boxShadow={'dark-lg'}
                                                    borderWidth="5px"
                                                    borderColor={'primary.800'}
                                                    w={14}
                                                    h={14}
                                                    align={'center'}
                                                    justify={'center'}
                                                    color={'primary.900'}
                                                    rounded={'full'}
                                                    bg={'white'}
                                                    mb={1}>
                                                    <Text fontSize={'18px'} as="samp" >{'1'}</Text>
                                                </Flex>
                                            </Stack>
                                        </Center>
                                    </GridItem>
                                    <GridItem colSpan={4} >
                                        <InputGroup p={'2'}>
                                            <Input boxShadow={'md'} borderWidth="1px" placeholder={t('AddContentPage.contentname')} _placeholder={{ opacity: 1, color: colorMode == 'light' ? 'gray.500' : 'white' }}
                                                value={updateContent?.name} onChange={(e) => { setUpdateContentData({ name: e.target.value }) }} />
                                        </InputGroup>
                                        <InputGroup p={'2'} >
                                            <Input boxShadow={'md'} borderWidth="1px" placeholder={t('AddContentPage.descript')} _placeholder={{ opacity: 1, color: colorMode == 'light' ? 'gray.500' : 'white' }}
                                                value={updateContent?.carddescription} onChange={(e) => { setUpdateContentData({ carddescription: e.target.value }) }} />
                                        </InputGroup>
                                    </GridItem>
                                </Grid>
                                <Box p={'4'} borderRadius="lg" mt={'4'} borderWidth={'1px'}>
                                    {updateContent?.type === 'text' && (
                                        <FormControl id="">
                                            <InputGroup >
                                                <InputLeftAddon boxShadow={'sm'} children={t('managecontentPage.type')} w={'15%'} />
                                                <Input readOnly boxShadow={'sm'} bg={'gray.200'} value={t('managecontentPage.text')} />
                                            </InputGroup>
                                            <Box minHeight={'60vh'} border="1px solid">
                                                <Editor
                                                    editorState={editorState}
                                                    onEditorStateChange={setEditorState}
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                    }}
                                                // editorStyle={{ border: "1px solid" }}
                                                />
                                            </Box>
                                            {statusDate === true ?
                                                <ThemeProvider theme={theme}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            // disableFuture
                                                            label={t('AddContentPage.date')}
                                                            // openTo="year"
                                                            // minDate={dayjs(Date.now())}
                                                            // views={['year', 'month', 'day']}
                                                            value={updateContent.expiredate ? updateContent.expiredate : ''}
                                                            onChange={(e) => setUpdateContentData({ expiredate: e })}
                                                            renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider> : null}
                                            <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                            <FormControl>
                                                <Switch size='lg' mr={'2'} colorScheme='green' isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                                {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                            </FormControl>
                                        </FormControl>
                                    )}
                                    {updateContent?.type === "photo" && (
                                        <FormControl id="">
                                            <FormLabel >{t('managecontentPage.photoNameOld')}</FormLabel>
                                            <Box p={'4'} bg={'gray.300'} borderRadius="lg" align={'center'}>
                                                <Image src={updateContent?.link} />
                                            </Box>
                                            <FormLabel>{t('managecontentPage.photoNameNew')}</FormLabel>
                                            <Dropzone
                                                onChange={updateFilesImg}
                                                value={filesImg}
                                                accept="image/*"
                                                maxFiles="1"
                                                view="grid"
                                            //localization={"ES-es"}
                                            //disableScroll
                                            >
                                                {filesImg.map((file, i) => (
                                                    <FileItem
                                                        {...file}
                                                        key={i}
                                                        onDelete={onDeleteImg}
                                                        //localization={"EN-en"}
                                                        //resultOnTooltip
                                                        preview={true}
                                                    />
                                                ))}
                                            </Dropzone>
                                            <Checkbox mt={3} mb={3} size='md' isChecked={statusDate} colorScheme='green' onChange={(e) => setStatusDate(e.target.checked)}>
                                                {t('managecontentPage.expiredate')}
                                            </Checkbox>
                                            {statusDate === true ?
                                                <ThemeProvider theme={theme}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            // disableFuture
                                                            label={t('AddContentPage.date')}
                                                            // openTo="year"
                                                            // minDate={dayjs(Date.now())}
                                                            // views={['year', 'month', 'day']}
                                                            value={updateContent.expiredate ? updateContent.expiredate : ''}
                                                            onChange={(e) => setUpdateContentData({ expiredate: e })}
                                                            renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider> : null}
                                            <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                            <FormControl>
                                                <Switch size='lg' mr={'2'} colorScheme='green' isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                                {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                            </FormControl>
                                        </FormControl>
                                    )}
                                    {updateContent?.type === 'video' && (
                                        <FormControl id="">
                                            <FormLabel>{t('managecontentPage.videoNameOld')}</FormLabel>
                                            <Box p={'4'} borderRadius="lg" align={'center'}>
                                                <Iframe src={updateContent?.link} width={'400px'} />
                                            </Box>
                                            <FormLabel>{t('managecontentPage.videoNameNew')}</FormLabel>
                                            <Dropzone
                                                onChange={updateFilesVdo}
                                                value={filesVdo}
                                                accept="video"
                                                maxFiles="1"
                                                view="grid"
                                            //localization={"ES-es"}
                                            //disableScroll
                                            >
                                                {filesVdo.map((file, i) => (
                                                    <FileItem
                                                        {...file}
                                                        key={i}
                                                        onDelete={onDeleteVdo}
                                                        //localization={"EN-en"}
                                                        //resultOnTooltip
                                                        preview={true}
                                                    />
                                                ))}
                                            </Dropzone>
                                            {uploadState === 100 ?
                                                <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                                    <GridItem colSpan={8} >
                                                        <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                                    </GridItem>
                                                    <GridItem colSpan={1} align={'center'}>
                                                        <Text>{`${uploadState} %`}</Text>
                                                    </GridItem>
                                                </Grid>
                                                :
                                                <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                                    <GridItem colSpan={8} >
                                                        <Progress value={uploadState} hasStripe size="lg" borderRadius={'lg'} />
                                                    </GridItem>
                                                    <GridItem colSpan={1} align={'center'}>
                                                        <Text>{uploadState != 0 && `${uploadState} %`}</Text>
                                                    </GridItem>
                                                </Grid>
                                            }
                                            <Checkbox mt={3} mb={3} size='md' isChecked={statusDate} colorScheme='green' onChange={(e) => setStatusDate(e.target.checked)}>
                                                {t('managecontentPage.expiredate')}
                                            </Checkbox>
                                            {statusDate === true ?
                                                <ThemeProvider theme={theme}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            // disableFuture
                                                            label={t('AddContentPage.date')}
                                                            // openTo="year"
                                                            // minDate={dayjs(Date.now())}
                                                            // views={['year', 'month', 'day']}
                                                            value={updateContent.expiredate ? updateContent.expiredate : ''}
                                                            onChange={(e) => setUpdateContentData({ expiredate: e })}
                                                            renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider> : null}
                                            <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                            <FormControl py={'2'}>
                                                <Switch size='lg' mr={'2'} colorScheme='green' isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                                {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                            </FormControl>
                                        </FormControl>
                                    )}

                                    {updateContent?.type === 'document' && (
                                        <Box>
                                            <FormLabel>{t('managecontentPage.documentNameOld')}</FormLabel>
                                            <Box p={'4'} borderRadius="lg" align={'center'}>
                                                {/* {console.log(updateContent?.link)} */}
                                                {updateContent?.doctype == 'pdf' ? <Iframe src={updateContent?.link} width={'400px'} height={'400px'} /> :
                                                    <Link href={updateContent?.link}> <Button colorScheme='teal' variant='solid' leftIcon={<DownloadIcon />} >Dowload</Button></Link>
                                                }
                                            </Box>
                                            <FormLabel>{t('managecontentPage.documentNameNew')}</FormLabel>
                                            <Dropzone
                                                onChange={updateFilesDoc}
                                                value={filesDoc}
                                                maxFiles="1"
                                                view="grid"
                                                accept='.pdf,.doc,.docx,.xls,.xlsx'

                                            //localization={"ES-es"}
                                            //disableScroll
                                            >
                                                {filesDoc.map((file, i) => (
                                                    <FileItem
                                                        {...file}
                                                        key={i}
                                                        onDelete={onDeleteDoc}
                                                        //localization={"EN-en"}
                                                        //resultOnTooltip
                                                        preview={true}
                                                    />
                                                ))}
                                            </Dropzone>
                                            {uploadState === 100 ?
                                                <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                                    <GridItem colSpan={8} >
                                                        <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                                    </GridItem>
                                                    <GridItem colSpan={1} align={'center'}>
                                                        <Text>{`${uploadState} %`}</Text>
                                                    </GridItem>
                                                </Grid>
                                                :
                                                <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                                    <GridItem colSpan={8} >
                                                        <Progress value={uploadState} hasStripe size="lg" borderRadius={'lg'} />
                                                    </GridItem>
                                                    <GridItem colSpan={1} align={'center'}>
                                                        <Text>{uploadState != 0 && `${uploadState} %`}</Text>
                                                    </GridItem>
                                                </Grid>
                                            }
                                            <Checkbox mt={3} mb={3} size='md' isChecked={statusDate} colorScheme='green' onChange={(e) => setStatusDate(e.target.checked)}>
                                                {t('managecontentPage.expiredate')}
                                            </Checkbox>
                                            {statusDate === true ?
                                                <ThemeProvider theme={theme}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            // disableFuture
                                                            label={t('AddContentPage.date')}
                                                            // openTo="year"
                                                            // minDate={dayjs(Date.now())}
                                                            // views={['year', 'month', 'day']}
                                                            value={updateContent.expiredate ? updateContent.expiredate : ''}
                                                            onChange={(e) => setUpdateContentData({ expiredate: e })}
                                                            renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider> : null}
                                            <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                            <FormControl>
                                                <Switch size='lg' mr={'2'} colorScheme='green' isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                                {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                            </FormControl>
                                        </Box>
                                    )}

                                    {updateContent?.type === 'link' && (
                                        <Box>
                                            <FormLabel>{t('managecontentPage.link')}</FormLabel>
                                            <Input
                                                value={updateContent?.link}
                                                onChange={(e) => {
                                                    setUpdateContentData({ link: e.target.value })
                                                }}
                                                required
                                            />
                                            <Checkbox mt={3} mb={3} size='md' isChecked={statusDate} colorScheme='green' onChange={(e) => setStatusDate(e.target.checked)}>
                                                {t('managecontentPage.expiredate')}
                                            </Checkbox>
                                            {statusDate === true ?
                                                <ThemeProvider theme={theme}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            // disableFuture
                                                            label={t('AddContentPage.date')}
                                                            // openTo="year"
                                                            // minDate={dayjs(Date.now())}
                                                            // views={['year', 'month', 'day']}
                                                            value={updateContent.expiredate ? updateContent.expiredate : ''}
                                                            onChange={(e) => setUpdateContentData({ expiredate: e })}
                                                            renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider> : null}
                                            <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                            <FormControl>
                                                <Switch size='lg' mr={'2'} colorScheme='green' isReadOnly isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                                {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                            </FormControl>
                                        </Box>
                                    )}

                                    {updateContent?.type === 'homework' && (
                                        <Box style={{ zIndex: 1 }}>
                                            <Flex direction={['column', 'column', 'row', 'row']}>
                                                <FormControl p={2}>
                                                    <FormLabel>{t('managecontentPage.description')}</FormLabel>
                                                    <Box minHeight={'51vh'} borderWidth="1px" borderRadius={'lg'} p={2}>
                                                        <Editor
                                                            editorState={editorHomeworkState}
                                                            onEditorStateChange={seteditorHomeworkState}
                                                            toolbar={{
                                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                            }}
                                                        // editorStyle={{ border: "1px solid" }}
                                                        />
                                                    </Box>
                                                </FormControl>
                                                <FormControl p={2}>
                                                    <Box align={'center'} >
                                                        <FormLabel>{t('managecontentPage.documentNameOld')} :</FormLabel>
                                                        {updateContent?.homeworkconfig?.exampleFiletype == 'pdf' ? <Iframe src={updateContent?.homeworkconfig?.exampleFile} width={'400px'} height={'400px'} /> :
                                                            <Link href={updateContent?.homeworkconfig?.exampleFile}>
                                                                {updateContent?.homeworkconfig?.exampleFile &&
                                                                    <Button colorScheme='teal' variant='solid' leftIcon={<DownloadIcon />} >Dowload</Button>
                                                                }
                                                            </Link>
                                                        }
                                                    </Box>
                                                    <FormLabel>{t('managecontentPage.documentNameNew')}</FormLabel>
                                                    <Dropzone
                                                        onChange={updateFilesDoc}
                                                        value={filesDoc}
                                                        maxFiles="1"
                                                        view="grid"
                                                        accept='.pdf,.doc,.docx,.xls,.xlsx'

                                                    //localization={"ES-es"}
                                                    //disableScroll
                                                    >
                                                        {filesDoc.map((file, i) => (
                                                            <FileItem
                                                                {...file}
                                                                key={i}
                                                                onDelete={onDeleteDoc}
                                                                //localization={"EN-en"}
                                                                //resultOnTooltip
                                                                preview={true}
                                                            />
                                                        ))}
                                                    </Dropzone>
                                                </FormControl>
                                            </Flex>

                                            <Flex direction={['column', 'row', 'row', 'row']}>
                                                <FormControl p={2}>
                                                    <FormLabel>{t('managecontentPage.duedate')}</FormLabel>
                                                    <Box style={{ zIndex: 3 }} mt={'2'}>
                                                        <ThemeProvider theme={theme}  >
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <div style={{ zIndex: 9999 }}>
                                                                    <DateTimePicker
                                                                        // disableFuture
                                                                        label={t('managecontentPage.date')}
                                                                        // sx={{"#css-1ozefeb":{zIndex:"20000 !important"}}}
                                                                        // openTo="year"
                                                                        minDate={dayjs(Date.now())}
                                                                        // views={['year', 'month', 'day']}
                                                                        value={updateContent?.homeworkconfig?.duedate ? updateContent?.homeworkconfig?.duedate : ''}
                                                                        onChange={(e) => setUpdateContentData({ duedate: e })}
                                                                        renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                                    />
                                                                </div>
                                                            </LocalizationProvider>
                                                        </ThemeProvider>
                                                    </Box>
                                                </FormControl>
                                                <FormControl p={2}>
                                                    <FormLabel>{t('AddContentPage.typefile')}</FormLabel>
                                                    {console.log("LOGMAN", updateContent)}
                                                    <Select
                                                        value={typeDocHomework}
                                                        onChange={(e) => setTypeDocHomework(e.target.value)}>
                                                        <option value='pdf'>{t('AddContentPage.pdf_type')}</option>
                                                        <option value='xls'>{t('AddContentPage.xls_type')}</option>
                                                        <option value='doc'>{t('AddContentPage.doc_type')}</option>
                                                        <option value='image/*'>{t('AddContentPage.photo_type')}</option>
                                                        <option value='video'>{t('AddContentPage.video_type')}</option>
                                                        <option value='docx'>{t('AddContentPage.docx_type')}</option>
                                                    </Select>
                                                </FormControl>

                                            </Flex>
                                            <FormControl p={2}>
                                                <Checkbox size='md' isChecked={statusDate} onChange={(e) => setStatusDate(e.target.checked)}>
                                                    {t('managecontentPage.expiredate')}
                                                </Checkbox>
                                                {statusDate === true ?
                                                    <Box mt={'2'}>
                                                        <ThemeProvider theme={theme} >
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DateTimePicker
                                                                    // disableFuture
                                                                    label={t('AddContentPage.date')}
                                                                    // openTo="year"
                                                                    // minDate={dayjs(Date.now())}
                                                                    // views={['year', 'month', 'day']}
                                                                    value={updateContent.expiredate ? updateContent.expiredate : ''}
                                                                    onChange={(e) => setUpdateContentData({ expiredate: e })}
                                                                    renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </ThemeProvider>
                                                    </Box> : null
                                                }
                                            </FormControl>
                                            <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                            <FormControl>
                                                <Switch size='lg' mr={'2'} colorScheme='green' isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                                {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                            </FormControl>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <Center>
                                <Button type="submit " bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}>
                                    {t('managecontentPage.confirmButton')}
                                </Button>
                            </Center>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            {/* <Modal isOpen={isContentSorting} onClose={onSortingClose} size={'5xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('managecontentPage.sortcontent')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {contentState?.length != 0 &&
                            <Box borderWidth='2px' borderRadius='lg' overflow='hidden' p={2} >
                                <OrderGroup className={'group'} mode={'between'} >
                                    {sortingState?.map((content, i) => (
                                        list?.map((data) => {
                                            if (data?._id == content) {
                                                return (
                                                    <>
                                                        <OrderItem key={i}
                                                            index={i}
                                                            onMove={(to) => {
                                                                setRefetchList(true)
                                                                setSortingState(arrayMove(sortingState, i, to))
                                                            }}
                                                            wrapperClassName={'wrapper'}
                                                            wrapperStyle={{
                                                                width: 'revert', left: '0px', '@media screen and (maxWidth: 600px)': {
                                                                    left: '40px'
                                                                }
                                                            }}
                                                            inactiveStyle={{ background: colorMode === 'light' ? 'gray.500' : '#2f3748' }}
                                                            wrapperHoverClassName={'hover'}
                                                            className={'item'}
                                                            grabbingClassName={'grabbing'}
                                                            style={{
                                                                width: 'auto', height: "80px", borderWidth: '1px', overflowX: 'auto'
                                                            }}
                                                        >
                                                            <OrderItem.Handle className={'handle'}>
                                                                <VStack>
                                                                    <HamburgerIcon color={colorMode === 'light' ? 'black' : 'white'} />
                                                                </VStack>
                                                            </OrderItem.Handle>

                                                            <HStack p={2} justifyContent={'space-between'} spacing='10px' w='full' direction={'row'} overflowX={'auto'}>
                                                                <InputGroup colorScheme={'twitter'} size={['xs', 'sm', 'lg']} boxShadow={'md'} borderRadius={'md'} borderWidth={'1px'} maxWidth={'800px'}>
                                                                    <InputLeftAddon sx={{ fontSize: ['xs', 'sm', 'md'] }} borderRadius={'md'} children={t('managecontentPage.name')} height="3vh" p={5} />
                                                                    <Text p={'2'}
                                                                        style={{
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            maxWidth: '400px'
                                                                        }}>{data?.name}</Text>
                                                                </InputGroup>
                                                                <InputGroup colorScheme={'twitter'} size={['xs', 'sm', 'lg']} boxShadow={'md'} borderRadius={'md'} borderWidth={'1px'} maxWidth={'200px'}>
                                                                    <InputLeftAddon sx={{ fontSize: ['xs', 'sm', 'md'] }} borderRadius={'md'} children={t('managecontentPage.type')} height="3vh" p={5} />
                                                                    <Text p={'2'}>{data?.type}</Text>
                                                                </InputGroup>
                                                            </HStack>
                                                        </OrderItem>
                                                    </>)
                                            }
                                        })
                                    ))}
                                </OrderGroup>
                            </Box>
                        }
                        {contentState?.length == 0 &&
                            <Box align="center" borderRadius={'lg'} w={'full'} py={'20'} color={'gray.500'} bg={'gray.200'}><Heading>{t('managecontentPage.warningcontentempty')}</Heading></Box>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onSortingClose}>
                            {t('Button.close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal> */}

            <Modal isOpen={isPreviewOpen} onClose={onPreviewClose} style={{ zIndex: 1 }} size={'2xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> {t('managecontentPage.content')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {updateContent?.type === 'text' && (
                            <FormControl id="">
                                <FormLabel fontSize={20}>{t('managecontentPage.chapterName')}</FormLabel>
                                <Box p={'4'} bg={'gray.300'} borderRadius="lg" >
                                    {updateContent?.name}
                                </Box>
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.text')}</FormLabel>
                                <Box p={'4'} bg={'gray.300'} borderRadius="lg" >
                                    {updateContent?.texteditor ? handleConvertHTML(updateContent?.texteditor) : '-'}
                                </Box>
                                {updateContent?.expiredate !== null &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.expiredate')}</FormLabel>
                                        - {dayjs(updateContent?.expiredat).format('DD/MM/YYYY , HH:mm:ss')}
                                    </Box>
                                }
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.status')}</FormLabel>

                                <FormControl py={'2'}>
                                    <Switch isReadOnly size='lg' mr={'2'} colorScheme='green' isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                    {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                </FormControl>
                            </FormControl>
                        )}
                        {updateContent?.type === 'photo' && (
                            <FormControl id="">
                                <FormLabel fontSize={20}>{t('managecontentPage.photoName')}</FormLabel>- {updateContent?.name}
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.photo')}</FormLabel>
                                <Box p={'4'} bg={'gray.300'} borderRadius="lg" align={'center'}>
                                    <Image src={updateContent?.link} />
                                </Box>

                                {updateContent?.expiredate !== null &&
                                    <Box >
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.expiredate')}</FormLabel>
                                        - {dayjs(updateContent?.expiredat).format('DD/MM/YYYY ,  HH:mm:ss')}
                                    </Box>
                                }
                                <FormControl py={'2'}>
                                    <Switch isReadOnly size='lg' mr={'2'} colorScheme='green' isChecked={updateContent?.statusActive} />
                                    {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                </FormControl>
                            </FormControl>
                        )}
                        {updateContent?.type === 'video' && (
                            <FormControl id="">
                                <FormLabel fontSize={20}>{t('managecontentPage.videoName')}</FormLabel>- {updateContent?.name}
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.video')}</FormLabel>
                                <Box p={'4'} bg={'gray.300'} borderRadius="lg" align={'center'}>
                                    <Iframe src={updateContent?.link} width={'400px'} height={'400px'} />
                                </Box>
                                {updateContent?.expiredate !== null &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.expiredate')}</FormLabel>
                                        - {dayjs(updateContent?.expiredat).format('DD/MM/YYYY ,  HH:mm:ss')}
                                    </Box>
                                }
                                <FormControl py={'2'}>
                                    <Switch isReadOnly size='lg' colorScheme='green' isChecked={updateContent?.statusActive}>
                                        {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                    </Switch>
                                </FormControl>
                            </FormControl>
                        )}
                        {updateContent?.type === 'document' && (
                            <Box>
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.document')}</FormLabel>
                                <Box p={'4'} bg={'gray.300'} borderRadius="lg" align={'center'}>
                                    {updateContent?.doctype == 'pdf' ? <Iframe src={updateContent?.link} width={'400px'} height={'400px'} /> :
                                        <Link href={updateContent?.link}><Button colorScheme='teal' variant='solid' leftIcon={<DownloadIcon />} >Dowload</Button></Link>
                                    }
                                </Box>
                                {updateContent?.expiredate !== null &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.expiredate')}</FormLabel>
                                        - {dayjs(updateContent?.expiredate).format('DD/MM/YYYY ,  HH:mm:ss')}
                                    </Box>
                                }
                                <FormControl py={'2'}>
                                    <Switch size='lg' mr={'2'} isReadOnly colorScheme='green' isChecked={updateContent?.statusActive} />
                                    {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                </FormControl>
                            </Box>
                        )}
                        {updateContent?.type === 'link' && (
                            <>
                                <FormLabel fontSize={20}>{t('managecontentPage.content')}</FormLabel>
                                <Box p='2' borderRadius={'lg'} boxShadow={'lg'} borderWidth={'1px'}>
                                    {updateContent?.name}
                                </Box>
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.link')}</FormLabel>
                                <Box p='2' borderRadius={'lg'} boxShadow={'lg'} borderWidth={'1px'} >
                                    <Link isExternal href={`${updateContent?.link}`}>
                                        {updateContent?.link}
                                    </Link>
                                </Box>

                                {updateContent?.expiredate != null &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.expiredate')}</FormLabel>
                                        - {dayjs(updateContent?.expiredat).format('DD/MM/YYYY ,  HH:mm:ss')}
                                    </Box>
                                }
                                <FormControl py={'2'}>
                                    <FormLabel fontSize={20}>{t('managecontentPage.status')}</FormLabel>
                                    <Switch size='lg' mr={'2'} colorScheme='green' isReadOnly isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                    {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                </FormControl>
                            </>
                        )}
                        {updateContent?.type === 'homework' && (
                            <Box style={{ zIndex: 2000 }}>
                                <FormLabel fontSize={20}>{t('managecontentPage.homeworkName')}</FormLabel>- {updateContent?.name}
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.description')}</FormLabel>{handleConvertHTML(updateContent?.homeworkconfig?.description)}
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.duedate')}</FormLabel>
                                -{dayjs(updateContent?.homeworkconfig?.duedate).format('DD/MM/YYYY ,  HH:mm:ss')}
                                {updateContent?.expiredate != null &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.expiredate')}</FormLabel>
                                        - {dayjs(updateContent?.expiredat).format('DD/MM/YYYY ,  HH:mm:ss')}
                                    </Box>
                                }
                                {updateContent?.homeworkconfig?.exampleFile != null &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20} >{t('managecontentPage.exampleFile')}</FormLabel>
                                        <Iframe
                                            src={updateContent?.homeworkconfig?.exampleFile}
                                            width={'400px'}
                                            height={'400px'}
                                        />
                                    </Box>
                                }
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.status')}</FormLabel>
                                <FormControl>
                                    <Switch size='lg' mr={'2'} colorScheme='green' isReadOnly isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                    {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                </FormControl>
                            </Box>
                        )}
                        {updateContent?.type === 'quiz' && (
                            <Box style={{ zIndex: 2000 }}>
                                <FormLabel fontSize={20}>{t('managecontentPage.QuestionName')}</FormLabel>- {preview?.name}
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.description')}</FormLabel>- {preview?.questionconfig?.description}
                                {updateContent?.questionconfig?.startdate != null &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.startdate')}</FormLabel>
                                        - {dayjs(preview?.questionconfig?.startdate).format('DD/MM/YYYY , HH:mm:ss')}
                                    </Box>
                                }
                                {updateContent?.questionconfig?.duedate != null &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.duedate')}</FormLabel>
                                        - {dayjs(preview?.questionconfig?.duedate).format('DD/MM/YYYY , HH:mm:ss')}
                                    </Box>
                                }
                                {updateContent?.expiredate != null &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.expiredate')}</FormLabel>
                                        - {dayjs(preview?.questionconfig?.duedate).format('DD/MM/YYYY , HH:mm:ss')}
                                    </Box>
                                }
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.status')}</FormLabel>
                                <FormControl>
                                    <Switch size='lg' mr={'2'} colorScheme='green' isReadOnly isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContentData({ statusActive: e.target.checked })} />
                                    {updateContent?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                </FormControl>
                                {/* {dayjs(updateContent?.questionconfig?.duedate).format('DD MM YYYY , เวลา HH:mm:ss')} */}
                                {updateContent?.questionconfig?.questionList?.length != 0 &&
                                    <Box>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.example')}</FormLabel>
                                        <Stack spacing={3}>
                                            <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} >
                                                <Table variant='simple'>
                                                    <Thead height="50px">
                                                        <Tr>
                                                            <Th bg={bg}>{t('managecontentPage.question')}</Th>
                                                            <Th bg={bg}>{t('managecontentPage.choice')}</Th>
                                                            <Th bg={bg}>{t('managecontentPage.answer')}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {preview?.questionconfig?.questionList?.map((questions, i) => (
                                                            <Tr key={i}>
                                                                <Td>{questions?.question}</Td>
                                                                {/* <Td>{questions?.choices.map((choice, i) => (<Text key={i}>{i + 1}{choice}</Text>))}</Td>
                                                                <Td>{questions?.answer}</Td> */}
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </Box>
                                        </Stack>
                                    </Box>
                                }
                            </Box>
                        )}
                        {updateContent?.type === 'survey' && (
                            <Box style={{ zIndex: 2000 }}>
                                <FormLabel fontSize={20}>{t('managecontentPage.QuestionName')}</FormLabel>- {preview?.name}
                                <FormLabel mt={2} fontSize={20}>{t('managecontentPage.description')}</FormLabel>- {preview?.questionconfig?.description}
                                {updateContent?.surveyconfig?.startdate != null &&
                                    <>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.startdate')}</FormLabel>
                                        - {dayjs(preview?.surveyconfig?.startdate).format('DD/MM/YYYY , HH:mm:ss')}
                                    </>
                                }
                                {updateContent?.surveyconfig?.duedate != null &&
                                    <>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.duedate')}</FormLabel>
                                        - {dayjs(preview?.surveyconfig?.duedate).format('DD/MM/YYYY , HH:mm:ss')}
                                    </>
                                }
                                {updateContent?.expiredate != null &&
                                    <>
                                        <FormLabel mt={2} fontSize={20}>{t('managecontentPage.expiredate')}</FormLabel>
                                        - {dayjs(preview?.surveyconfig?.duedate).format('DD/MM/YYYY , HH:mm:ss')}
                                    </>
                                }
                                <Box>
                                    <FormLabel mt={2} fontSize={20}>{t('managecontentPage.example')}</FormLabel>
                                    <Survey.Survey
                                        json={preview ? { questions: preview?.surveyconfig?.surveyQuestions?.questions } : { questions: {} }}
                                        // data={previewSurvey?.surveyAnswers}
                                        showCompletedPage={false}
                                        mode="display"
                                    />
                                </Box>
                            </Box>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>


            {/* //ADDCONTENT */}
            <Modal isOpen={isAddContentOpen} onClose={modalAddContentClose} size={'5xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('managecontentPage.addcontent')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleAddContent}>
                        <ModalBody>
                            <Box maxW="7xl" mx={'auto'} px={{ base: 2, sm: 12, md: 17 }} >
                                <Grid
                                    boxShadow={'xl'}
                                    templateRows='repeat(1, 1fr)'
                                    templateColumns='repeat(5, 1fr)'
                                    borderWidth={'1px'}
                                    borderRadius="lg"
                                >
                                    <GridItem rowSpan={1} colSpan={1} bg={'primary.900'} borderLeftRadius="lg" >
                                        <Center pt={5}>
                                            <Stack>
                                                <Flex
                                                    boxShadow={'dark-lg'}
                                                    borderWidth="5px"
                                                    borderColor={'primary.800'}
                                                    w={[10, 10, 14, 20]}
                                                    h={[10, 10, 14, 20]}
                                                    align={'center'}
                                                    justify={'center'}
                                                    color={'primary.900'}
                                                    rounded={'full'}
                                                    bg={'white'}
                                                    mb={1}>
                                                    <Text fontSize={'18px'} as="samp" >{'1'}</Text>
                                                </Flex>
                                            </Stack>
                                        </Center>
                                    </GridItem>
                                    <GridItem colSpan={4} >
                                        <InputGroup p={'2'} >
                                            <Input boxShadow={'md'} borderWidth="1px" placeholder={t('AddContentPage.contentname')} _placeholder={{ opacity: 1, color: colorMode == 'light' ? 'gray.500' : 'white' }}
                                                value={contentData?.name} onChange={(e) => { setContentData({ name: e.target.value }) }} />
                                        </InputGroup>
                                        <InputGroup p={'2'} >
                                            <Input boxShadow={'md'} borderWidth="1px" placeholder={t('AddContentPage.descript')} _placeholder={{ opacity: 1, color: colorMode == 'light' ? 'gray.500' : 'white' }}
                                                value={contentData?.carddescription} onChange={(e) => { setContentData({ carddescription: e.target.value }) }} />
                                        </InputGroup>
                                    </GridItem>
                                </Grid>
                                <FormControl py={'4'}>
                                    <InputGroup >
                                        <InputLeftAddon boxShadow={'sm'} children={t('AddContentPage.type')} />
                                        <Select defaultValue='empty' onChange={(e) => { setContentData({ type: e.target.value }) }}>
                                            <option value='empty'> {t('AddContentPage.choosetype')}</option>
                                            {/* <option value='text'>{t('AddContentPage.text')}</option> */}
                                            {/* <option value='photo'>{t('AddContentPage.photo')}</option> */}
                                            <option value='video'> {t('AddContentPage.video')}</option>
                                            <option value='homework'>{t('AddContentPage.homework')}</option>
                                            <option value='document'>{t('AddContentPage.document')}</option>
                                            <option value='link'>{t('AddContentPage.link')}</option>
                                            <option value='quiz'>{t('AddContentPage.quiz')}</option>
                                            <option value='survey'>{t('AddContentPage.survey')}</option>
                                        </Select>
                                    </InputGroup>
                                </FormControl>
                                {contentData?.type !== 'empty' &&
                                    <Box p={'4'} borderRadius="lg" mt={'4'} borderWidth={'1px'}>
                                        {contentData?.type === "text" &&
                                            <Box>
                                                <FormControl>
                                                    <Box minHeight={'60vh'} border="1px solid">
                                                        <Editor
                                                            editorState={editorState}
                                                            onEditorStateChange={setEditorState}
                                                            toolbar={{
                                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                            }}
                                                        // editorStyle={{ border: "1px solid", }}
                                                        />
                                                    </Box>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                                    <Switch size='lg' mr={'2'} colorScheme='green' isChecked={contentData?.statusActive} onChange={(e) => setContentData({ statusActive: e.target.checked })} />
                                                    {contentData?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                                </FormControl>
                                            </Box>
                                        }

                                        {contentData?.type === "photo" &&
                                            <Box>
                                                <FormControl>
                                                    <Dropzone
                                                        onChange={updateFilesImg}
                                                        value={filesImg}
                                                        accept="image/*"
                                                        maxFiles="1"
                                                        view="grid"
                                                    >
                                                        {filesImg.map((file) => (
                                                            <FileItem
                                                                {...file}
                                                                key={file.id}
                                                                onDelete={onDeleteImg}
                                                                //localization={"EN-en"}
                                                                //resultOnTooltip
                                                                preview={true}
                                                            />
                                                        ))}
                                                    </Dropzone>
                                                </FormControl>
                                                <FormControl>
                                                    {/* <FormLabel>{t('managecontentPage.status')}</FormLabel> */}
                                                    <Switch size='lg' mr={'2'} colorScheme='green' isChecked={contentData?.statusActive} onChange={(e) => setContentData({ statusActive: e.target.checked })} />
                                                    {contentData?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                                </FormControl>
                                            </Box>
                                        }

                                        {contentData?.type === "video" &&
                                            <Box>
                                                <FormControl>
                                                    <Dropzone
                                                        onChange={updateFilesVdo}
                                                        value={filesVdo}
                                                        accept="video"
                                                        maxFiles="1"
                                                        view="grid"
                                                        required
                                                    //localization={"ES-es"}
                                                    //disableScroll
                                                    >
                                                        {filesVdo.map((file) => (
                                                            <FileItem
                                                                {...file}
                                                                key={file.id}
                                                                onDelete={onDeleteVdo}
                                                                //localization={"EN-en"}
                                                                //resultOnTooltip
                                                                preview={true}
                                                            />
                                                        ))}
                                                    </Dropzone>
                                                    {console.log("LOGGGG", uploadState)}

                                                    {uploadState === 100 ?
                                                        <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                                            <GridItem colSpan={8} >
                                                                <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                                            </GridItem>
                                                            <GridItem colSpan={1} align={'center'}>
                                                                <Text>{`${uploadState} %`}</Text>
                                                            </GridItem>
                                                        </Grid>
                                                        :
                                                        <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                                            <GridItem colSpan={8} >
                                                                <Progress value={uploadState} hasStripe size="lg" borderRadius={'lg'} />
                                                            </GridItem>
                                                            <GridItem colSpan={1} align={'center'}>
                                                                <Text>{uploadState != 0 && `${uploadState} %`}</Text>
                                                            </GridItem>
                                                        </Grid>

                                                    }
                                                </FormControl>
                                                <FormControl>
                                                    {/* <FormLabel>{t('managecontentPage.status')}</FormLabel> */}
                                                    <Switch size='lg' mr={'2'} colorScheme='green' isChecked={contentData?.statusActive} onChange={(e) => setContentData({ statusActive: e.target.checked })} />
                                                    {contentData?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                                </FormControl>
                                            </Box>
                                        }

                                        {contentData?.type === "homework" &&
                                            <Box>
                                                <Flex direction={['column', 'row', 'row', 'row']}>
                                                    <FormControl p={2}>
                                                        <FormLabel>{t('AddContentPage.description')}</FormLabel>
                                                        <Box minHeight={'46vh'} borderWidth="1px" p={'1'} borderRadius={'lg'}>
                                                            <Editor
                                                                editorState={editorHomeworkState}
                                                                onEditorStateChange={seteditorHomeworkState}
                                                                toolbar={{
                                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                                }}
                                                            // editorStyle={{ border: "1px solid" }}
                                                            />
                                                        </Box>
                                                    </FormControl>
                                                    <FormControl p={2}>
                                                        <FormLabel>{t('AddContentPage.exampleFile')}</FormLabel>
                                                        <Dropzone
                                                            onChange={updateFilesDoc}
                                                            value={filesDoc}
                                                            maxFiles="1"
                                                            view="grid"
                                                            // localization={"ES-es"}
                                                            // disableScroll
                                                            accept='.pdf,.doc,.docx,.xls,.xlsx'
                                                        >
                                                            {filesDoc.map((file) => (
                                                                <FileItem
                                                                    {...file}
                                                                    key={file.id}
                                                                    onDelete={onDeleteDoc}
                                                                    localization={"EN-en"}
                                                                    resultOnTooltip
                                                                    preview={true}
                                                                />
                                                            ))}
                                                        </Dropzone>
                                                    </FormControl>
                                                </Flex>
                                                <Flex direction={['column', 'row', 'row', 'row']}>
                                                    <FormControl p={2}>
                                                        <FormLabel>{t('AddContentPage.duedate')}</FormLabel>
                                                        <ThemeProvider theme={theme}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DateTimePicker
                                                                    // disableFuture
                                                                    label={t('AddContentPage.date')}
                                                                    // openTo="year"
                                                                    minDate={dayjs(Date.now())}
                                                                    // views={['year', 'month', 'day']}
                                                                    value={contentData?.date ? contentData?.date : ''}
                                                                    onChange={(e) => setContentData({ date: e })}
                                                                    renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </ThemeProvider>
                                                    </FormControl>
                                                    <FormControl p={2}>
                                                        <FormLabel>{t('AddContentPage.typefile')}</FormLabel>
                                                        <Select defaultValue="pdf" onChange={(e) => setContentData({ typefile: e.target.value })}>
                                                            <option value=''>{'กรุณาเลือกไฟล์ที่ต้องการให้ส่ง'}</option>
                                                            <option value='pdf'>{t('AddContentPage.pdf_type')}</option>
                                                            <option value='xls'>{t('AddContentPage.xls_type')}</option>
                                                            <option value='doc'>{t('AddContentPage.doc_type')}</option>
                                                            <option value='docx'>{t('AddContentPage.docx_type')}</option>
                                                            <option value='image/*'>{t('AddContentPage.photo_type')}</option>
                                                            <option value='video'>{t('AddContentPage.video_type')}</option>
                                                        </Select>
                                                    </FormControl>
                                                </Flex>
                                                <FormControl p={2}>
                                                    <Checkbox size='md' isChecked={statusDate} onChange={(e) => setStatusDate(e.target.checked)}>
                                                        {t('managecontentPage.expiredate')}
                                                    </Checkbox>
                                                    {statusDate === true ?
                                                        <Box mt={'2'}>
                                                            <ThemeProvider theme={theme} >
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DateTimePicker
                                                                        // disableFuture
                                                                        label={t('AddContentPage.date')}
                                                                        // openTo="year"
                                                                        // minDate={dayjs(Date.now())}
                                                                        // views={['year', 'month', 'day']}
                                                                        value={contentData.expiredate ? contentData?.expiredate : ''}
                                                                        onChange={(e) => setContentData({ expiredate: e })}
                                                                        renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </ThemeProvider>
                                                        </Box> : null
                                                    }
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                                    <Switch size='lg' mr={'2'} colorScheme='green' isChecked={contentData?.statusActive} onChange={(e) => setContentData({ statusActive: e.target.checked })} />
                                                    {contentData?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                                </FormControl>
                                            </Box>
                                        }

                                        {contentData?.type === "document" &&
                                            <Box>
                                                <FormControl>
                                                    <Dropzone
                                                        onChange={updateFilesDoc}
                                                        value={filesDoc}
                                                        maxFiles="1"
                                                        view="grid"
                                                        accept='.pdf,.doc,.docx,.xls,.xlsx'
                                                    //localization={"ES-es"}
                                                    //disableScroll
                                                    >
                                                        {/* {console.log("file", filesDoc)} */}
                                                        {filesDoc.map((file) => (
                                                            <FileItem
                                                                {...file}
                                                                key={file.id}
                                                                onDelete={onDeleteDoc}
                                                                //localization={"EN-en"}
                                                                //resultOnTooltip
                                                                preview={true}
                                                            />
                                                        ))}
                                                    </Dropzone>
                                                    {uploadState === 100 ?
                                                        <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                                            <GridItem colSpan={8} >
                                                                <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                                            </GridItem>
                                                            <GridItem colSpan={1} align={'center'}>
                                                                <Text>{`${uploadState} %`}</Text>
                                                            </GridItem>
                                                        </Grid>
                                                        :
                                                        <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                                            <GridItem colSpan={8} >
                                                                <Progress value={uploadState} hasStripe size="lg" borderRadius={'lg'} />
                                                            </GridItem>
                                                            <GridItem colSpan={1} align={'center'}>
                                                                <Text>{uploadState != 0 && `${uploadState} %`}</Text>
                                                            </GridItem>
                                                        </Grid>
                                                    }
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                                    <Switch size='lg' mr={'2'} colorScheme='green' isChecked={contentData?.statusActive} onChange={(e) => setContentData({ statusActive: e.target.checked })} />
                                                    {contentData?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                                </FormControl>
                                            </Box>
                                        }

                                        {contentData?.type === "link" &&
                                            <Box>
                                                <FormControl>
                                                    <Input required onChange={(e) => { setContentData({ link: e.target.value }) }} />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                                    <Switch size='lg' mr={'2'} colorScheme='green' isChecked={contentData?.statusActive} onChange={(e) => setContentData({ statusActive: e.target.checked })} />
                                                    {contentData?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                                </FormControl>
                                            </Box>
                                        }

                                        {contentData?.type === "quiz" &&
                                            <Box>
                                                <FormControl id="">
                                                    <Textarea
                                                        resize="vertical"
                                                        size="lg"
                                                        height="100px"
                                                        mt={2}
                                                        placeholder={t('managecontentPage.placeholderDescription')}
                                                        onChange={(e) => { setContentData({ description: e.target.value }) }}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                                    <Switch size='lg' mr={'2'} colorScheme='green' isDisabled isChecked={contentData?.statusActive} onChange={(e) => setContentData({ statusActive: e.target.checked })} />
                                                    {contentData?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                                </FormControl>
                                            </Box>
                                        }

                                        {contentData?.type === "survey" &&
                                            <Box>
                                                <FormLabel>{t('AddContentPage.description')}</FormLabel>
                                                <Box minHeight={'60vh'} borderWidth="1px" borderRadius={"lg"} p={1}>
                                                    <Editor
                                                        editorState={editorHomeworkState}
                                                        onEditorStateChange={seteditorHomeworkState}
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                        }}
                                                    // editorStyle={{ border: "1px solid" }}
                                                    />
                                                </Box>
                                                <FormControl>
                                                    <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                                    <Switch size='lg' mr={'2'} colorScheme='green' isDisabled isChecked={contentData?.statusActive} onChange={(e) => setContentData({ statusActive: e.target.checked })} />
                                                    {contentData?.statusActive ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                                                </FormControl>
                                            </Box>
                                        }
                                    </Box>
                                }
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <Center>
                                <Button type="submit " bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}>
                                    {t('managecontentPage.confirmButton')}
                                </Button>
                            </Center>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>


            <AlertDialog isOpen={showRemoveContent} onClose={handleCloseRemoveContent}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <form onSubmit={handleRemoveContent}>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {t('managecontentPage.deleteChapter')}
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                {t('managecontentPage.youDelete')} {removeContentIndex?.name}
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button onClick={handleCloseRemoveContent}>
                                    {t('managecontentPage.closeButton')}
                                </Button>
                                <Button bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }} type='submit' ml={3}>
                                    {t('managecontentPage.removeButton')}
                                </Button>
                            </AlertDialogFooter>
                        </form>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Box w="100%" px={[2, 10, 20, 20]} style={{ zIndex: 2000 }} minHeight={'90vh'}>
                <Container maxW={'7xl'} py={16} spacing={12}>
                    <Stack spacing={0} align={'center'}>
                        <Heading variant="h4">{t('managecontentPage.title')}</Heading>
                        <Text>{t('managecontentPage.subtitle')}</Text>
                    </Stack>
                    <Stack spacing='8px' direction={['column', 'column', 'row']} justify={"end"} mt={8}>
                        <Button
                            w={['100%', '30%', '30%', '12%']}
                            mr={2}
                            leftIcon={<AddIcon />}
                            bg={'primary.700'} color={'white'} _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                            onClick={() => { modalAddContentOpen() }}
                        >
                            {t('managecontentPage.addcontent')}
                        </Button>
                        <Button
                            leftIcon={<FaSortAmountDown />}
                            mr={2}
                            bg={'primary.700'} color={'white'} _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                            onClick={() => { onSortingOpen() }}
                        >
                            {t('managecontentPage.sortcontent')}
                        </Button>
                        <IconButton
                            icon={<RepeatIcon />}
                            bg={'primary.700'} color={'white'} _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                            onClick={(e) => {
                                e.preventDefault()
                                handleRefetchContent()
                            }}
                        />
                    </Stack>

                    {loading ?
                        <Stack py={20}>
                            <Skeleton height='20px' />
                            <Skeleton height='20px' />
                            <Skeleton height='20px' />
                        </Stack>
                        :
                        <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['none'] }} boxShadow={'xl'} borderWidth={'1px'} mt={5} >
                            <Table variant='simple'  >
                                <Thead >
                                    <Tr bg={'gray.400'} >
                                        {/* <Td textAlign="center" >{t('managecontentPage.number')}</Td> */}
                                        <Td textAlign="center">{t('managecontentPage.content')}</Td>
                                        <Td textAlign="center">{t('managecontentPage.type')}</Td>
                                        <Td textAlign="center">{t('managecontentPage.status')}</Td>
                                        <Td textAlign="center">{t('managecontentPage.manage')}</Td>
                                    </Tr>
                                </Thead>

                                {contentState?.length != 0 &&
                                    <Tbody>
                                        {contentState?.map((content, i) => (
                                            <Tr key={i}>
                                                {/* {console.log(i)} */}
                                                {/* <Td textAlign="center">{i + 1}</Td> */}
                                                <Td textAlign="center" maxW={'30vh'}>
                                                    {/* {console.log("TEEEEE", content)} */}
                                                    <Link
                                                        mr={2}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            handlePreviewOpen(content?.type)
                                                            setUpdateContentData(content)
                                                            setPreview(content)
                                                            // console.log(content)
                                                        }}
                                                    >
                                                        {content?.name}
                                                    </Link>
                                                </Td>
                                                <Td textAlign="center">{content?.type}</Td>
                                                <Td textAlign="center" maxW={'100px'}>
                                                    <Center>
                                                        <Box w='100%' color='white'>
                                                            <Center>
                                                                {content?.statusActive ?
                                                                    <Tag
                                                                        borderRadius='full'
                                                                        boxShadow={'lg'}
                                                                        variant='solid'
                                                                        colorScheme='green'
                                                                        w={"full"}
                                                                        py={1}
                                                                        justifyContent={"center"}
                                                                    >
                                                                        <TagLabel>{t("managecontentPage.public")}</TagLabel>
                                                                    </Tag>
                                                                    :
                                                                    <Tag
                                                                        borderRadius='full'
                                                                        boxShadow={'lg'}
                                                                        variant='solid'
                                                                        w={"full"}
                                                                        py={1}
                                                                        justifyContent={"center"}
                                                                    >
                                                                        <TagLabel>{t("managecontentPage.nonepublic")}</TagLabel>
                                                                    </Tag>}
                                                            </Center>
                                                        </Box>
                                                    </Center>
                                                </Td>
                                                <Td>
                                                    <Flex justify="end" >
                                                        {content?.type === 'homework' && (
                                                            <Tooltip label={t('managecontentPage.homeworkButton')} fontsize="md">
                                                                <Button
                                                                    mr={2}
                                                                    boxShadow={'lg'}
                                                                    as={domlink}
                                                                    to={`homeworkdetail/${content?._id}/${param?.subjectId}`}
                                                                >
                                                                    <FaRegFile />
                                                                </Button>
                                                            </Tooltip>
                                                        )}
                                                        {/* {content?.type === 'link' && (
                                                            <Tooltip label={t('managecontentPage.homeworkButton')} fontsize="md">
                                                                <Button
                                                                    as={domlink}
                                                                    to={`${content?.link}`}
                                                                    mr={2}>
                                                                    <FaRegFile />
                                                                </Button>
                                                            </Tooltip>
                                                        )} */}
                                                        {content.type === 'quiz' ? (
                                                            <>
                                                                <Tooltip label={t('managecontentPage.quizButton')} fontsize="md">
                                                                    <Button
                                                                        boxShadow={'lg'}
                                                                        as={domlink}
                                                                        to={`/managecontent/questions/${content?._id}`}
                                                                        mr={2}>
                                                                        <FaRegFile />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip label={t('Button.edit')} fontsize="md" >
                                                                    <Button boxShadow={'lg'} mr={2} as={domlink} to={`/managecontent/managequestion/${content?._id}`} >
                                                                        <FaPencilAlt />
                                                                    </Button>
                                                                </Tooltip>
                                                            </>
                                                        ) : content?.type === 'survey' ? (
                                                            <>
                                                                <Tooltip label={t('managecontentPage.surveyButton')} fontsize="md">
                                                                    <Button boxShadow={'lg'} mr={2} as={domlink} to={`/managecontent/surveys/${content?._id}`} >
                                                                        <FaRegFile />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip label={t('Button.edit')} fontsize="md">
                                                                    <Button boxShadow={'lg'} mr={2} as={domlink} to={`/managecontent/managesurvey/${content?._id}`}>
                                                                        <FaPencilAlt />
                                                                    </Button>
                                                                </Tooltip>
                                                            </>
                                                        ) : (
                                                            <Tooltip label={t('Button.edit')} fontsize="md">
                                                                <Button
                                                                    mr={2}
                                                                    boxShadow={'lg'}
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        modalUpdateContentOpen(content)
                                                                    }}
                                                                >
                                                                    <FaPencilAlt />
                                                                </Button>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip label={t('Button.delete')} fontsize="md">
                                                            <Button
                                                                boxShadow={'lg'}
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    handleOpenRemoveContent(content)
                                                                }}
                                                            >
                                                                <FaTrashAlt />
                                                            </Button>
                                                        </Tooltip>
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        ))
                                        }
                                    </Tbody>
                                }
                            </Table>
                            {contentState?.length == 0 &&
                                <Box align="center" w={'full'} py={'20'} color={'gray.500'} bg={'gray.200'}><Heading>{t('managecontentPage.warningcontentempty')}</Heading></Box>
                            }
                        </Box>
                    }
                </Container>
            </Box>
            <DialogSortingContent
                list={list}
                isContentSorting={isContentSorting}
                onSortingClose={onSortingClose}
                contentState={contentState}
                sortingState={sortingState}
                setSortingState={setSortingState}
                setRefetchList={setRefetchList} />
        </Fragment>
    )
}

export default ManageContentPage

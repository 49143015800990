import gql from "graphql-tag";

export default gql`
query (
    $student: MongoID
    ) {
    progresss(filter: { student: $student}) {
        _id
        progress
        student {
            _id
        } 
        content {
            _id
            name
        }
        path   
    }
}
`;
import React, { useContext, useState } from "react";
import {
    chakra,
    Box,
    Flex,
    useColorModeValue,
    SimpleGrid,
    GridItem,
    Heading,
    Text,
    Textarea,
    Stack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Avatar,
    Button,
    IconButton,
    useToast,
} from "@chakra-ui/react";
import AuthContext from "../contexts/AuthContext";
import { useMultiState } from "../lib/hooks";
import { FaPencilAlt } from 'react-icons/fa'
import UpdateUserProfile from '../graphql/mutations/updateUserProfile'
import UpdateTeacherProfile from '../graphql/mutations/updateTeacherUser'
import UpdateStudentProfile from '../graphql/mutations/updateStudentUser'
import setPasswordMutation from '../graphql/mutations/setPassword'
import axios from "axios";
import { useMutation } from "@apollo/client";
import loginMutation from '../graphql/mutations/login'
import { useTranslation } from 'react-i18next'

const URL = process.env.REACT_APP_UPLOAD_IMAGE

const initialPass = {
    old_pass: '',
    password: '',
    confirm_pass: ''
}


const ProfilePage = () => {
    const { t } = useTranslation()
    const { user } = useContext(AuthContext)
    const toast = useToast()
    const [setUpdateUserProfile] = useMutation(UpdateUserProfile)
    const [setUpdateTeacherProfile] = useMutation(UpdateTeacherProfile)
    const [setUpdateStudentProfile] = useMutation(UpdateStudentProfile)
    const [setPassword] = useMutation(setPasswordMutation)
    const [login] = useMutation(loginMutation)
    const [profileData, setProfileData] = useMultiState({
        fileavatar: {
            base64: user?.avatar,
            data: null,
            url: user?.avatar
        },
        username: user?.username,
        firstname: user?.firstname,
        lastname: user?.lastname,
        description: user?.description,
        email: user?.email,
        mobile: user?.mobile,
        role: user?.role,
    })

    const [fileavatar, setFileAvatar] = useMultiState({ data: null, base64: '' })


    const [newphotoData, setnewphotoData] = useMultiState({
        data: user?.avatar,
        base64: user?.avatar
    })

    const [passwordData, setPasswordData] = useMultiState(initialPass)
    const [passWrong, setPassWrong] = useState(false)

    const selectAvatarFile = (e) => new Promise((resolve, reject) => {
        e.preventDefault()
        const file = e.currentTarget.files[0]
        setFileAvatar({ data: file })
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            resolve(reader.result)
            setFileAvatar({ base64: reader.result })
        }
        reader.onerror = (error) => reject(error)
    })


    const handleUploadAvatar = async (base64) => {
        const response = await axios.post(URL, { photo: newphotoData?.base64 })
        setProfileData({
            ...profileData,
            fileavatar: {
                base64: base64,
                url: response?.data?.url
            },
        })
    }


    const handleUpdateUser = async (e) => {
        e.preventDefault()
        // console.log("PROFILE", profileData?.fileavatar?.base64)
        if (!profileData?.username) {
            await toast({
                position: "top",
                title: "Warning",
                description: "กรุณากรอกชื่อผู้ใช้",
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else if (!profileData?.firstname) {
            await toast({
                position: "top",
                title: "Warning",
                description: "กรุณากรอกชื่อ",
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else if (!profileData?.lastname) {
            await toast({
                position: "top",
                title: "Warning",
                description: "กรุณากรอกสกุล",
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else {
            try {
                const response = await axios.post(URL, { photo: fileavatar.base64 })
                const { data: responseUpdateUser, } = await setUpdateUserProfile({
                    variables: {
                        _id: user?._id,
                        firstname: profileData?.firstname,
                        lastname: profileData?.lastname,
                        mobile: profileData?.mobile,
                        username: profileData?.username,
                        email: profileData?.email,
                        description: profileData?.description,
                        avatar: response ? response?.data?.url : '',
                    },
                })
                if (responseUpdateUser) {
                    // console.log('finally')
                    await toast({
                        position: "top",
                        title: 'แก้ไขข้อมูลเรียบร้อย',
                        description: "กรุณาออกจากระบบแล้วเข้าสู่ระบบอีกครั้งเพื่อให้ข้อมูลอัปเดต",
                        status: 'success',
                        duration: 6000,
                        isClosable: true,
                    })
                }
            }
            catch (Error) {
                // console.log('in Create studentUser submit', Error)
                toast({
                    position: "top",
                    title: "ERROR",
                    description: `${Error?.graphQLErrors[0]?.message}`,
                    status: "error",
                    duration: 6000,
                    isClosable: true,
                })
                // enqueueSnackbar(Error, { variant: 'error' })
            }
        }
    }

    const handleChangePassword = async (e) => {
        e.preventDefault()
        try {
            // console.log(response)
            const { data } = await login({
                variables: {
                    username: user?.username,
                    password: passwordData?.old_pass
                }
            })

            if (data) {
                setPassWrong(false)
                const { data: passworddata, errors: passwordErr } = await setPassword({
                    variables: {
                        _id: user?._id,
                        password: passwordData?.password,
                    },
                })
                if (passworddata) {

                    // console.log('finally')
                    await toast({
                        position: "top",
                        title: 'แก้ไขรหัสผ่านสำเร็จ',
                        description: "กรุณาออกจากระบบแล้วเข้าสู่ระบบอีกครั้งเพื่อให้ข้อมูลอัปเดต",
                        status: 'success',
                        duration: 6000,
                        isClosable: true,
                    })
                    setPasswordData(initialPass)
                }
            }
        } catch (Error) {
            setPassWrong(true)
            // console.log('in Create studentUser submit', Error)
            toast({
                position: "top",
                title: "ERROR",
                description: `${Error?.graphQLErrors[0]?.message}`,
                status: "error",
                duration: 6000,
                isClosable: true,
            })
            // enqueueSnackbar(Error, { variant: 'error' })
        }
    }

    // console.log({
    //     username: user?.username,
    //     password: passwordData?.old_pass
    // });
    // console.log(profileData)
    return (
        <Box bg={useColorModeValue("gray.50", "inherit")} px={[4, 10, 20, 20]} >
            <Box visibility={{ base: "hidden", sm: "visible" }} aria-hidden="true">
                <Box py={5}>
                    <Box
                        borderTop="solid 1px"
                        borderTopColor={useColorModeValue("gray.200", "whiteAlpha.200")}
                    ></Box>
                </Box>
            </Box>
            <Box mt={[10, 0]} >
                <SimpleGrid
                    display={{ base: "initial", md: "grid" }}
                    columns={{ md: 3 }}
                    spacing={{ md: 6 }}

                >
                    <GridItem colSpan={{ md: 1 }}>
                        <Box px={[4, 0]}>
                            <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
                                {t('profilePage.firstTitle')}
                            </Heading>
                            {/* <Text
                                mt={1}
                                fontSize="sm"
                                color={useColorModeValue("gray.600", "gray.400")}
                            >
                                Use a permanent address where you can receive mail.
                            </Text> */}
                        </Box>
                    </GridItem>
                    <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
                        <chakra.form
                            // method="POST"
                            onSubmit={handleUpdateUser}
                            shadow="base"
                            rounded={[null, "md"]}
                            overflow={{ sm: "hidden" }}
                        >
                            <Stack
                                px={4}
                                py={5}
                                p={[null, 6]}
                                bg={useColorModeValue("white", "gray.700")}
                                spacing={6}
                            >
                                <SimpleGrid columns={6} spacing={6}>
                                    <FormControl as={GridItem} colSpan={[6, 3]}>
                                        <FormLabel
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.photo')}
                                        </FormLabel>
                                        <Flex alignItems="center" mt={1}>
                                            <label style={{ position: 'relative' }} htmlFor="photo-project-file">
                                                <Avatar
                                                    objectFit='cover'
                                                    // position='relative'
                                                    // boxShadow='md'
                                                    zIndex={1}
                                                    name={profileData?.firstname ? `${profileData?.firstname} ${profileData?.lastname}` : 'Profile'}
                                                    size='md'
                                                    src={fileavatar?.base64 ? fileavatar?.base64 : profileData?.fileavatar?.url}
                                                    alt="กรุณาเลือกรูปภาพ" />
                                                {/* <Stack
                                        direction='column'
                                        maxW={'200px'}
                                    > */}
                                                <IconButton
                                                    isRound
                                                    as='label'
                                                    bgColor='white'
                                                    htmlFor="photo-project-file"
                                                    // colorScheme='linkedin'
                                                    position='absolute'
                                                    top={7}
                                                    left={33}
                                                    // zIndex={-1}
                                                    size='xs'
                                                    fontSize={'15px'}
                                                    zIndex='banner'
                                                    boxShadow='lg'
                                                    // variant="solid"
                                                    // isDisabled
                                                    icon={<FaPencilAlt style={{ zIndex: -1 }} />}
                                                    color='primary.700'
                                                />
                                                <input
                                                    onChange={selectAvatarFile}
                                                    accept="image/*"
                                                    id="photo-project-file"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                />

                                            </label>
                                            <Button
                                                type="button"
                                                ml={5}
                                                variant="outline"
                                                size="sm"
                                                fontWeight="medium"
                                                onClick={() => {
                                                    setProfileData({
                                                        fileavatar: {
                                                            data: null,
                                                            base64: '',
                                                            url: null
                                                        }
                                                    })
                                                }}
                                                _focus={{ shadow: "none" }}
                                            >
                                                ลบ
                                            </Button>
                                        </Flex>
                                    </FormControl>
                                </SimpleGrid>
                                <SimpleGrid columns={6} spacing={6}>
                                    <FormControl as={GridItem} colSpan={[6, 4]} isRequired>
                                        <FormLabel
                                            htmlFor="username"
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.username')}
                                        </FormLabel>
                                        <Input
                                            disabled
                                            value={profileData?.username}
                                            type="text"
                                            name="username"
                                            id="username"
                                            autoComplete="none"
                                            mt={1}
                                            focusBorderColor="green.400"
                                            shadow="sm"
                                            size="sm"
                                            w="full"
                                            rounded="md"
                                        />
                                    </FormControl>

                                    <FormControl as={GridItem} colSpan={[6, 3]} isRequired>
                                        <FormLabel
                                            htmlFor="first_name"
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.name')}
                                        </FormLabel>
                                        <Input
                                            value={profileData?.firstname}
                                            onChange={(e) => {
                                                setProfileData({ firstname: e.target.value })
                                            }}
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            autoComplete="given-name"
                                            mt={1}
                                            focusBorderColor="green.400"
                                            shadow="sm"
                                            size="sm"
                                            w="full"
                                            rounded="md"
                                        />
                                    </FormControl>

                                    <FormControl as={GridItem} colSpan={[6, 3]} isRequired>
                                        <FormLabel
                                            htmlFor="last_name"
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.surname')}
                                        </FormLabel>
                                        <Input
                                            value={profileData?.lastname}
                                            onChange={(e) => {
                                                setProfileData({ lastname: e.target.value })
                                            }}
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            autoComplete="family-name"
                                            mt={1}
                                            focusBorderColor="green.400"
                                            shadow="sm"
                                            size="sm"
                                            w="full"
                                            rounded="md"
                                        />
                                    </FormControl>

                                    <FormControl as={GridItem} colSpan={[6, 4]} isRequired>
                                        <FormLabel
                                            htmlFor="email_address"
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.emailAddress')}
                                        </FormLabel>
                                        <Input
                                            value={profileData?.email}
                                            onChange={(e) => {
                                                setProfileData({ email: e.target.value })
                                            }}
                                            type="text"
                                            name="email_address"
                                            id="email_address"
                                            autoComplete="email"
                                            mt={1}
                                            focusBorderColor="green.400"
                                            shadow="sm"
                                            size="sm"
                                            w="full"
                                            rounded="md"
                                        />
                                    </FormControl>
                                    <FormControl as={GridItem} colSpan={[6, 3]}>
                                        <FormLabel
                                            htmlFor="mobile"
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.phoneNumber')}
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            name="mobile"
                                            id="mobile"
                                            value={profileData?.mobile}
                                            onChange={(e) => {
                                                setProfileData({ mobile: e.target.value })
                                            }}
                                            autoComplete="mobile"
                                            mt={1}
                                            focusBorderColor="green.400"
                                            shadow="sm"
                                            size="sm"
                                            w="full"
                                            rounded="md"
                                        />
                                    </FormControl>

                                    <FormControl as={GridItem} colSpan={[6, 4]}>
                                        <FormLabel
                                            htmlFor="description"
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.description')}
                                        </FormLabel>
                                        <Textarea
                                            value={profileData?.description}
                                            onChange={(e) => {
                                                setProfileData({ description: e.target.value })
                                            }}
                                            type="text"
                                            name="description"
                                            id="description"
                                            mt={1}
                                            focusBorderColor="green.400"
                                            shadow="sm"
                                            size="sm"
                                            w="full"
                                            rounded="md"
                                        />
                                    </FormControl>


                                </SimpleGrid>
                            </Stack>
                            <Box
                                px={{ base: 4, sm: 6 }}
                                py={3}
                                bg={useColorModeValue("gray.50", "gray.900")}
                                textAlign="right"
                            >
                                <Button
                                    type="submit"
                                    bg={'primary.700'} color={'white'} _hover={{
                                        transform: 'translateY(-2px)',
                                        boxShadow: 'lg',
                                    }}
                                    _focus={{ shadow: "" }}
                                    fontWeight="md"
                                >
                                    {t('profilePage.saveButton')}
                                </Button>
                            </Box>
                        </chakra.form>
                    </GridItem>
                </SimpleGrid>
            </Box>
            <Box visibility={{ base: "hidden", sm: "visible" }} aria-hidden="true">
                <Box py={5}>
                    <Box
                        borderTop="solid 1px"
                        borderTopColor={useColorModeValue("gray.200", "whiteAlpha.200")}
                    ></Box>
                </Box>
            </Box>
            <Box mt={[10, 0]} minHeight={'80vh'}>
                <SimpleGrid
                    display={{ base: "initial", md: "grid" }}
                    columns={{ md: 3 }}
                    spacing={{ md: 6 }}
                >
                    <GridItem colSpan={{ md: 1 }}>
                        <Box px={[4, 0]}>
                            <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
                                {t('profilePage.secondTitle')}
                            </Heading>
                            <Text
                                mt={1}
                                fontSize="sm"
                                color={useColorModeValue("gray.600", "gray.400")}
                            >
                                {t('profilePage.subSecondTitle')}
                            </Text>
                        </Box>
                    </GridItem>
                    <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
                        <chakra.form
                            onSubmit={handleChangePassword}
                            // method="POST"
                            shadow="base"
                            rounded={[null, "md"]}
                            overflow={{ sm: "hidden" }}
                        >
                            <Stack
                                px={4}
                                py={5}
                                p={[null, 6]}
                                bg={useColorModeValue("white", "gray.700")}
                                spacing={6}
                            >
                                <SimpleGrid columns={6} spacing={6}>
                                    <FormControl
                                        as={GridItem}
                                        colSpan={[6, 4]}
                                        isRequired
                                        isInvalid={passWrong}
                                    >
                                        <FormLabel
                                            htmlFor="old_password"
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.currentPassword')}
                                        </FormLabel>
                                        <Input
                                            type="password"
                                            name="old_password"
                                            id="old_password"
                                            value={passwordData?.old_pass}
                                            onChange={(e) => {
                                                setPasswordData({ old_pass: e.target.value })
                                            }}
                                            // autoComplete="email"
                                            mt={1}
                                            focusBorderColor="green.400"
                                            shadow="sm"
                                            size="sm"
                                            w="full"
                                            rounded="md"
                                        />
                                        <FormErrorMessage > {t('profilePage.incorrectPassword')}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl as={GridItem} colSpan={[6, 4]} isRequired>
                                        <FormLabel
                                            htmlFor="password"
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.newPassword')}
                                        </FormLabel>
                                        <Input
                                            value={passwordData?.password}
                                            onChange={(e) => {
                                                setPasswordData({ password: e.target.value })
                                            }}
                                            type="password"
                                            name="password"

                                            id="password"
                                            autoComplete="email"
                                            mt={1}
                                            focusBorderColor="green.400"
                                            shadow="sm"
                                            size="sm"
                                            w="full"
                                            rounded="md"
                                        />
                                    </FormControl>
                                    <FormControl
                                        as={GridItem}
                                        colSpan={[6, 4]}
                                        isRequired
                                        isDisabled={!passwordData?.password}
                                        isInvalid={passwordData?.confirm_pass && !(passwordData?.password === passwordData?.confirm_pass)}
                                    >
                                        <FormLabel
                                            htmlFor="comfirm_pass"
                                            fontSize="sm"
                                            fontWeight="md"
                                            color={useColorModeValue("gray.700", "gray.50")}
                                        >
                                            {t('profilePage.confirmPassword')}
                                        </FormLabel>
                                        <Input
                                            value={passwordData?.confirm_pass}
                                            onChange={(e) => {
                                                setPasswordData({ confirm_pass: e.target.value })
                                            }}
                                            type="password"
                                            name="comfirm_pass"
                                            id="comfirm_pass"
                                            autoComplete="email"
                                            mt={1}
                                            errorBorderColor="red.300"
                                            focusBorderColor="green.400"
                                            shadow="sm"
                                            size="sm"
                                            w="full"
                                            rounded="md"
                                        />
                                        <FormErrorMessage >{t('profilePage.notMatchPassword')}</FormErrorMessage>
                                    </FormControl>
                                </SimpleGrid>
                            </Stack>
                            <Box
                                px={{ base: 4, sm: 6 }}
                                py={3}
                                bg={useColorModeValue("gray.50", "gray.900")}
                                textAlign="right"
                            >
                                <Button
                                    type="submit"
                                    disabled={!(passwordData?.confirm_pass && (passwordData?.password === passwordData?.confirm_pass))}
                                    bg={'primary.700'} color={'white'} _hover={{
                                        transform: 'translateY(-2px)',
                                        boxShadow: 'lg',
                                    }}
                                    _focus={{ shadow: "" }}
                                    fontWeight="md"
                                >
                                    {t('profilePage.saveButton')}
                                </Button>
                            </Box>
                        </chakra.form>
                    </GridItem>
                </SimpleGrid>
            </Box>
        </Box>
    );
}
export default ProfilePage
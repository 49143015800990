import React, { Fragment, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Box,
	Flex,
	Text,
	Button,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
	Avatar,
	MenuGroup,
	Badge,
	useColorMode,
	IconButton,
	HStack,
} from '@chakra-ui/react'

import Logo from '../ui/Logo'
import { useApp } from "../../contexts/AppContext";
import { useScroll } from '../../lib/hooks'
import AuthContext from '../../contexts/AuthContext'

import { GiSecretBook, GiGraduateCap, GiBlackBook } from 'react-icons/gi'

import { BiUserCircle, BiUser, BiUserPlus } from 'react-icons/bi'
import { FiSun, FiMoon } from 'react-icons/fi'
import { ImProfile } from 'react-icons/im'
import { SiZoom } from "react-icons/si";


const MenuItemLayout = ({ children, isLast, to = '/', ...rest }) => {
	return (
		<Box
			mb={{ base: isLast ? 0 : 8, sm: 0 }}
			mr={{ base: 0, sm: isLast ? 0 : 8 }}
			// marginRight='10'
			display="block"
			fontWeight="bold"
			{...rest}
		>
			<Link replace to={to}>{children}</Link>
		</Box>
	)
}
const MenuItemLayoutButton = ({ children, isLast, to = '/', ...rest }) => {
	return (
		<Text
			mb={{ base: isLast ? 0 : 2, sm: 0 }}
			mr={{ base: 0, sm: isLast ? 0 : 2 }}
			// marginRight='10'
			display="block"
			fontWeight="bold"
			{...rest}
		>
			<Link to={to}>{children}</Link>
		</Text>
	)
}
const MenuItemIconLayoutWithoutRoute = ({
	children,
	isLast,
	icon,
	color,
	onClick,
	...rest
}) => {
	return (
		<Text
			mb={{ base: isLast ? 0 : 2, sm: 0 }}
			mr={{ base: 0, sm: isLast ? 0 : 2 }}
			// marginRight='10'
			display="block"
			fontWeight="bold"
			{...rest}
		>
			<IconButton
				variant="ghost"
				colorScheme={color}
				aria-label="Call Sage"
				fontSize="20px"
				icon={icon}
				onClick={onClick}
			/>
		</Text>
	)
}
const CloseIcon = () => (
	<svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
		<title>Close</title>
		<path
			fill="white"
			d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
		/>
	</svg>
)

const MenuIcon = () => (
	<svg
		width="24px"
		viewBox="0 0 20 20"
		xmlns="http://www.w3.org/2000/svg"
		fill="white"
	>
		<title>Menu</title>
		<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
	</svg>
)

const Header = (props) => {
	const [show, setShow] = React.useState(false)
	const toggleMenu = () => setShow(!show)
	const history = useHistory()
	const { lang, switchLang } = useApp()
	const { t } = useTranslation()
	const { scrollDirection } = useScroll()
	const { colorMode, toggleColorMode } = useColorMode()
	const [anchorEl, setAnchorEl] = useState(null)
	// console.log('lang', scrollX, scrollY, scrollDirection);
	const { user, removeToken } = useContext(AuthContext)


	// console.log("UUUUU", user?.role)
	const logout = async () => {
		// setProject(null)
		// await setLogout({ variables: { _id: user?._id } })
		removeToken()
		// history.push('/:projectCode/login')
		history.push('/login')
	}
	// const handleClickMenu = (event) => {
	//   setAnchorEl(event.currentTarget)
	// }
	const handleCloseMenu = () => {
		setAnchorEl(null)
	}
	const handleLogout = (e) => {
		e.preventDefault()
		handleCloseMenu()
		logout()
	}

	return (
		<Flex
			as="nav"
			align="center"
			justify="space-between"
			wrap="wrap"
			w="100%"
			maxW={{ xl: '1600px' }}
			mb={8}
			p={['3', '5', '3', '3']}
			boxShadow={'xl'}
			zIndex="banner"
			position="fixed"
			// visibility={scrollDirection !== "up" || scrollDirection === "right" || scrollDirection === "left" ? "visible" : "hidden"}
			// transition="all 0.5s"
			// transform={scrollDirection !== "up" || scrollDirection === "right" || scrollDirection === "left" ? "undefied" : "translateY(-100%)"}
			bg={
				colorMode === 'light'
					? scrollDirection === 'down'
						? ['primary.900', 'primary.900', 'rgba(255,255,255,1)', 'rgba(255,255,255,1)']
						: ['primary.900', 'primary.900', 'rgba(255,255,255,1)', 'rgba(255,255,255,1)']
					: scrollDirection === 'down'
						? ['primary.400', 'primary.900', 'brand.800', 'brand.800']
						: ['primary.400', 'primary.900', 'brand.800', 'brand.800']
			}
			color={
				colorMode === 'light'
					? ['rgba(255,255,255,1)', 'rgba(255,255,255,1)', 'primary.900', 'primary.900']
					: ['rgba(255,255,255,1)', 'rgba(255,255,255,1)', 'rgba(255,255,255,1)', 'rgba(255, 255, 255, 1)']
			}
			{...props}
		>
			<Flex as={Link} to="/" align="center" ml={['5vh', '10vh', '2vh', '10vh']} >
				<Logo
					w="100px"
					marginRight="-10"

				// color={["white", "white", "primary.300", "primary.300"]}
				/>
				{/* <Text color={["white", "white", "primary.300", "primary.300"]} fontSize="lg" fontWeight="bold"> |</Text> */}
				<HStack>
					{/* <GiSecretBook color='#007CBE' id='nuukeng_' /> */}
					<Text
						color={
							colorMode === 'light'
								? ['rgba(255,255,255,1)', 'rgba(255,255,255,1)', 'primary.900', 'primary.900']
								: ['rgba(255,255,255,1)', 'rgba(255,255,255,1)', 'rgba(255,255,255,1)', 'rgba(255, 255, 255, 1)']
						}
						fontSize="lg"
						fontWeight="bold"
						marginLeft="-3"
					>
						{t('headerSection.title')}
					</Text>
				</HStack>
			</Flex>

			<Box display={{ base: 'block', md: 'none' }} onClick={toggleMenu}>
				{show ? <CloseIcon /> : <MenuIcon />}
			</Box>

			<Box
				display={{ base: show ? 'block' : 'none', md: 'block' }}
				flexBasis={{ base: '100%', md: 'auto' }}
			>
				<Flex
					align="center"
					justify={['center', 'space-between', 'flex-end', 'flex-end']}
					direction={['column', 'row', 'row', 'row']}
					// visibility={scrollDirection === "down" ? "visible" : "hidden"}
					// transition="all 0.5s"
					// transform={scrollDirection === "down" ? "undefied" : "translateY(-100%)"}
					pt={[4, 4, 0, 0]}
				>
					<MenuItemLayout to="/">
						<HStack justify="center">
							<GiSecretBook color="#FBAF00" />
							<Text >{t('headerSection.homeMenu')}</Text>
						</HStack>
					</MenuItemLayout>
					<MenuItemLayout to="/courses">
						<HStack justify="center">
							<GiGraduateCap color="#FFA3AF" />
							<Text>{t('headerSection.course')}</Text>
						</HStack>
					</MenuItemLayout>
					{/* <MenuItemLayout to="/streams">
						<HStack justify="center">
							<HiOutlineStatusOnline color="#007CBE" id="nuukeng_icon_cap" />
							<Text>{t('headerSection.stream')}</Text>
						</HStack>
					</MenuItemLayout> */}
					<MenuItemLayout to="/staffs">
						<HStack justify="center">
							<BiUser color="#007CBE" id="nuukeng_icon_cap" />
							<Text>{t('headerSection.teacher')}</Text>
						</HStack>
					</MenuItemLayout>

					{/* <MenuItemLayout to="/pricing">
            <HStack justify='center'>
              <GiGraduateCap color='#00AF54' id='nuukeng_icon_cap' />
              <Text>
                Pricing
              </Text>
            </HStack>

          </MenuItemLayout> */}

					{user ? (
						<Menu anchorEl={anchorEl} mr={{ base: 0, sm: 8 }} op>
							<MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
								<Avatar
									name={user?.firstname ? `${user?.firstname} ${user?.lastname}` : ''}
									size={'sm'}
									src={user.avatar ? user.avatar : ''}
								/>
							</MenuButton>
							<MenuList>
								<MenuGroup title={`${t('headerSection.profile')}`}>
									<Badge
										fontSize="0.7em"
										top={'19px'}
										left={'70px'}
										position={'absolute'}
										variant="outline"
										colorScheme="gray"
									>
										{user?.role}
									</Badge>
									<MenuItem icon={<BiUserCircle />} as={Link} to={`/showprofile/${user?._id}`} >
										{user?.firstname ? `${user?.firstname} ${user?.lastname}` : ''}
									</MenuItem>
									{/* <MenuItem>
										<Tag colorScheme="blue" variant="solid">
											{user.role ? user?.role : ''}
										</Tag>
									</MenuItem> */}
									{user?.role === 'STUDENT' ? (

										<MenuItem as={Link} to="/coursesstudent" icon={<GiGraduateCap />}>
											{t('headerSection.mycourse')}
										</MenuItem>

									) : null}
									<MenuItem as={Link} to="/profile" icon={<ImProfile />}>
										{t('headerSection.manageprofile')}
									</MenuItem>
									{user?.role === 'TEACHER' ? (
										<Fragment>
											<MenuItem as={Link} to={`/mycourses`} icon={<GiGraduateCap />}>
												{t('headerSection.mycourse')}
											</MenuItem>

											{/* <MenuItem as={Link} to="/managestream" icon={<GiVideoConference />}>
												{t('headerSection.managestream')}
											</MenuItem> */}
											<MenuItem as={Link} to="/managesubject" icon={<GiBlackBook />}>
												{t('headerSection.managesubject')}
											</MenuItem>

										</Fragment>
									) : null}

									{user?.role === 'ADMIN' ? (
										<Fragment>
											{/* <MenuItem as={Link} to="/managepayments" icon={<FcMoneyTransfer />}>
											{t('headerSection.managepayment')}
											</MenuItem> */}
											<MenuItem as={Link} to="/controllersubject" icon={<GiBlackBook />}>
												{t('headerSection.managesubject')}
											</MenuItem>
											<MenuItem as={Link} to="/manageteacher" icon={<BiUserPlus />}>
												{t('headerSection.manageteacher')}
											</MenuItem>
											<MenuItem as={Link} to="/managestudent" icon={<BiUser />}>
												{t('headerSection.managestudent')}
											</MenuItem>
											<MenuItem as={Link} to="/managezoom" icon={<SiZoom />}>
												{t('headerSection.managezoom')}
											</MenuItem>
										</Fragment>
									) : null}
								</MenuGroup>
								<MenuDivider />
								<MenuItem onClick={handleLogout}>{t('headerSection.signout')}</MenuItem>
							</MenuList>
						</Menu>
					) : (
						<Fragment>
							<MenuItemLayoutButton to="/login">
								<Button
									size="sm"
									rounded="md"
									color={['primary.700', 'primary.700', 'white', 'white']}
									bg={['white', 'white', 'primary.700', 'primary.700']}
									_hover={{
										bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
									}}
								>
									{t('headerSection.signin')}
								</Button>
							</MenuItemLayoutButton>
							{/* <MenuItemLayout to="/signup">
								<Button
									size="sm"
									rounded="md"
									color={['primary.700', 'primary.700', 'white', 'white']}
									bg={['white', 'white', 'primary.700', 'primary.700']}
									_hover={{
										bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
									}}
								>
									{t('headerSection.create')}
								</Button>
							</MenuItemLayout> */}
						</Fragment>
					)}
					<MenuItemIconLayoutWithoutRoute
						ml={3}
						onClick={toggleColorMode}
						color={colorMode === 'light' ? 'yellow' : 'blue'}
						icon={colorMode === 'light' ? <FiSun /> : <FiMoon />}
					/>
					{/* <MenuItemLayoutButton isLast> */}
					{/* <ButtonGroup marginLeft="3">
						<Button
							color={colorMode === 'light' ? ["white", "white", "primary.400", "primary.400"]:["white", "white", "white", "white"]}
							variant='solid'
							colorScheme={lang !== 'th' ? 'primary.400' : 'white'}
							onClick={() => switchLang('th')}
							borderColor={lang === 'th' ? ["white", "white", "primary.800", "primary.800"] : ["primary.800", "primary.800", "primary.800", "white", "white"]}
							border="2px"
							mr="-px">
							TH
						</Button>
						<Button
							color={colorMode === 'light' ? ["white", "white", "primary.400", "primary.400"]:["white", "white", "white", "white"]}
							variant='solid'
							borderColor={lang === 'en' ? ["white", "white", "primary.800", "primary.800"] : ["primary.800", "primary.800", "primary.800", "white", "white"]}
							border="2px"
							// variant={lang !== 'th' ? 'contained' : undefined}
							colorScheme={lang !== 'en' ? 'primary.400' : 'white'}
							onClick={() => switchLang('en')}
						>
							EN
						</Button>
					</ButtonGroup> */}
					{/* </MenuItemLayoutButton> */}
				</Flex>
			</Box>
		</Flex >
	)
}

export default Header

import { Text, Flex, Center } from '@chakra-ui/layout'
import React, { Fragment } from 'react'



const NotFoundPage = () => (
    <Fragment>
        <Flex
            minH={'90vh'}
            align={'center'}
            justify={'center'}>
            <Center>
                <Text fontWeight='bold' w={'100%'} fontSize="3xl" >Not found Page</Text>
            </Center>
        </Flex>
    </Fragment>
)

export default NotFoundPage

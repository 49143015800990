import { Fragment, useContext, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useMultiState } from "../lib/hooks"

import {
    Box,
    Flex,
    useToast,
    useColorMode,
    useDisclosure,
} from '@chakra-ui/react';

import { useQuery, useMutation } from '@apollo/client'
import AuthContext from '../contexts/AuthContext';
import { useParams } from 'react-router'
import Subject from '../graphql/queries/subject'
import allprogress from '../graphql/queries/allprogress'
import studentEnroll from "../graphql/queries/studentenroll"
import updateStudentOfSubject from '../graphql/mutations/updateStudentOfSubject';
import studentofsubjectsRemoveMutation from '../graphql/mutations/removeStudentofSubject'


import DashboardDetail from '../components/pages/ManageEnrollSubjectPage/DashboardDetail';
import DialogListStudent from '../components/pages/ManageEnrollSubjectPage/DialogListStudent';
import DialogCertificate from '../components/pages/ManageEnrollSubjectPage/DialogCertificate';
import MenuDashboard from '../components/pages/ManageEnrollSubjectPage/MenuDashboard';
import DashboardUser from '../components/pages/ManageEnrollSubjectPage/DashboardUser';
import DialogUnEnroll from '../components/pages/ManageEnrollSubjectPage/DialogUnEnroll';
import axios from 'axios'


const URL_DASHBOARD = process.env.REACT_APP_DASHBOARD_API
const ManageEnrollSubjectPage = () => {

    const { t } = useTranslation()
    const { user } = useContext(AuthContext)
    const { colorMode } = useColorMode()
    const params = useParams()
    const history = useHistory()
    const toast = useToast()

    const [dashboard, setDashboard] = useMultiState()

    const { isOpen: unEnrollSubject, onOpen: openUnEnrollSubject, onClose: closeUnEnrollSubject } = useDisclosure()
    const { isOpen: studentListDialog, onOpen: openStudentListDialog, onClose: closeStudentListDialog, } = useDisclosure()
    const { isOpen: certificateDialog, onOpen: openCertificateDialog, onClose: closeCertificateDialog } = useDisclosure()

    const [setstudentofsubjectsRemoveMutation] = useMutation(studentofsubjectsRemoveMutation)
    const [setUpdateStudentOfSubject] = useMutation(updateStudentOfSubject)


    const [student, setStudent] = useState()
    const [progress, setProgress] = useState([])
    const [loading, setLoading] = useState(false)

    const { data: SubjectData, refetch: refetchSubject } = useQuery(Subject, {
        variables: {
            _id: params?.subjectId,
        },

    }, { fetchPolicy: "network-only", })

    const { data: StudentEnroll, refetch: refetchEnroll } = useQuery(studentEnroll, {
        variables: {
            subject: params?.subjectId
        },
    }, { fetchPolicy: "network-only", })

    const handleSubmitUnEnroll = async (e) => {
        e.preventDefault()
        try {
            const { data: responseUnEnroll } = await setstudentofsubjectsRemoveMutation({
                variables: {
                    _id: student?._id
                },
            })
            if (responseUnEnroll) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('ManageEnrollSubjectPage.toastSuccess')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                closeUnEnrollSubject()
                closeStudentListDialog()
                refetchPage()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleUpdateCertificateStatus = async (e) => {
        e.preventDefault()
        // console.log("CERTIFICATE", student)
        try {
            const { data: approvedStatus } = await setUpdateStudentOfSubject({
                variables: {
                    _id: student?._id,
                    status: student?.status == "disapproved" ? "approved" : "disapproved"
                }
            })
            if (approvedStatus) {
                await toast({
                    position: "top",
                    title: "Success",
                    description: `${t('ManageEnrollSubjectPage.update_certificate')}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                closeCertificateDialog()
                refetchPage()
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        if (user?.role === 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
    }, [])

    useEffect(async () => {
        if (!student) {
            setLoading(true)

            axios.post(`${URL_DASHBOARD}/${params?.subjectId}`)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    if (data) {
                        setDashboard(data)
                        setLoading(false)
                    }

                    // console.log(data)
                })
                .catch(error => {
                    console.log(error.response.data.error)
                })
        }
    }, [SubjectData])


    const refetchPage = () => {
        // refetchProgress()
        refetchSubject({
            _id: params?.subjectId,
        })
        refetchEnroll({
            subject: params?.subjectId
        })
    }

    return (
        <Fragment>
            <Box w="100%" h='auto' sx={{ overflowX: ['auto', 'auto', 'none'] }} >
                <Flex
                    minHeight={'90vh'}
                    templateRows='repeat(2, 1fr)'
                    templateColumns='repeat(12, 1fr)'
                    gap={0}
                    direction={['column', 'column', 'row', 'row']}
                >
                    <MenuDashboard
                        setStudent={setStudent}
                        openStudentListDialog={openStudentListDialog}
                        SubjectData={SubjectData}
                        subjectId={params?.subjectId} />
                    <Box px={['2', '5', '5', '10']} bg={colorMode === 'light' ? 'gray.100' : 'brand.700'} w={'100%'} sx={{ overflowX: ['auto', 'auto', 'none'] }}>
                        {student ?
                            <DashboardUser
                                loading={loading}
                                student={student}
                                SubjectData={SubjectData}
                                progressList={progress}
                                openCertificateDialog={openCertificateDialog} />
                            :
                            <DashboardDetail
                                loading={loading}
                                total={dashboard?.dashboard_total}
                                pass={dashboard?.dashboard_pass}
                                notpass={dashboard?.dashboard_notpass}
                                certificate={dashboard?.dashboard_certificate}
                                credit={dashboard?.dashboard_credit}
                                dashboardContent={dashboard?.content} />
                        }
                    </Box>
                </Flex>
            </Box>

            <DialogListStudent
                setLoading={setLoading}
                contents={SubjectData?.subject?.contents}
                setProgress={setProgress}
                setStudent={setStudent}
                studentList={StudentEnroll?.studentofsubjects}
                studentListDialog={studentListDialog}
                closeStudentListDialog={closeStudentListDialog}
                openUnEnrollSubject={openUnEnrollSubject} />

            <DialogCertificate
                student={student}
                handleUpdateCertificateStatus={handleUpdateCertificateStatus}
                certificateDialog={certificateDialog}
                closeCertificateDialog={closeCertificateDialog} />

            <DialogUnEnroll
                student={student}
                unEnrollSubject={unEnrollSubject}
                closeUnEnrollSubject={closeUnEnrollSubject}
                handleSubmitUnEnroll={handleSubmitUnEnroll}
            />
        </Fragment>
    );
};

export default ManageEnrollSubjectPage;
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next'
import {
    Box,
    Text,
    Stack,
    Container,
    useDisclosure,
    Flex,
    IconButton,
    HStack,
    Avatar,
    Image,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormLabel,
    FormControl,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Collapse,
    Center,
    useToast,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Divider,
    Heading
} from '@chakra-ui/react';
import { DragHandleIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'

import { useQuery } from '@apollo/client'
import subjectQuery from '../graphql/queries/subject'
import forumQuery from '../graphql/queries/forumbyID'

import AuthContext from '../contexts/AuthContext';

import { useParams } from 'react-router'
import { HiReply } from 'react-icons/hi'
import { useMultiState } from '../lib/hooks'
import { Dropzone, FileItem } from '@dropzone-ui/react'
import dayjs from 'dayjs'
import configforumbyID from '../graphql/queries/configforum'
import renderHTML from 'react-render-html'
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ForumMutaion from "../graphql/mutations/createForum";
import { useMutation } from "@apollo/client";
import axios from "axios";
import ForumRemoveMutaion from "../graphql/mutations/removeForum"
import UpdateForumMutaion from "../graphql/mutations/updateForum"


require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)



const URLImage = process.env.REACT_APP_UPLOAD_IMAGE;



export const BlogAuthor = (props) => {
    return (

        <HStack ml={1} marginTop="0" spacing="2" display="flex" alignItems="center">
            <Avatar
                borderRadius="full"
                boxSize="30px"
                src={props.avatar}
                name={`${props.name}`}
            />
            <Flex>
                <Text fontWeight="medium">{props.name}</Text>
                <Text>—</Text>
                <Text>
                    {dayjs(props?.date).format('DD MMMM YYYY , เวลา HH:mm:ss')}
                </Text>
            </Flex>
        </HStack>
    )
}

const ForumPage = () => {
    const { user } = useContext(AuthContext)
    const params = useParams()
    const toast = useToast()
    const { t } = useTranslation()
    const initialRef = React.useRef()
    const finalRef = React.useRef()
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [updateEditorState, setUpdateEditorState] = useState(EditorState.createEmpty());
    const myCurrentDate = new Date()
    const [setForumMutation] = useMutation(ForumMutaion);
    const [removeMutation] = useMutation(ForumRemoveMutaion)
    const [updateMutation] = useMutation(UpdateForumMutaion)



    const { isOpen: isAddopen, onOpen: onAddopen, onClose: onAddclose, } = useDisclosure()
    const { isOpen, onToggle, onClose: onclose, } = useDisclosure()

    const initialForum = {
        replydate: "",
    }
    const [updateForum, setupdateForum] = useMultiState(initialForum)

    const { data: subjectData } = useQuery(subjectQuery, {
        variables: {
            _id: params?.subjectId,
        },
    });
    const [filesImg, setFilesImg] = useState([])
    const onDeleteImg = (id) => {
        setFilesImg(filesImg.filter((x) => x.id !== id))
    }
    const [newphotoData, setnewphotoData] = useMultiState({
        data: null,
        base64: '',
    })

    const { data: configForumData } = useQuery(configforumbyID, {
        variables: {
            _id: params?.configforumId,
        },
    })
    const { data: ForumData, refetch } = useQuery(forumQuery, {
        variables: {
            _id: params?.configforumId,
        },
    })
    //Alert dialog
    const [showRemoveForum, setShowRemoveForum] = useState(false)
    const [removeIndex, setRemoveIndex] = useState('')

    const handleOpenRemoveForum = (index) => {
        setRemoveIndex(index)
        setShowRemoveForum(true)
    }

    const handleCloseRemoveForum = () => {
        setShowRemoveForum(false)
    }

    const closeButton = () => {
        onAddclose(true)
        onclose(true)
        setEditorState(EditorState.createEmpty())
        setFilesImg([])
    }

    const handleRemoveForum = async (e) => {
        e.preventDefault()
        let forumIdRemove = ForumData?.forums?.[removeIndex]?._id
        try {

            const { data: removeforum } = await removeMutation({
                variables: {
                    _id: forumIdRemove,
                }
            })

            if (removeforum) {

                await toast({
                    position: "top",
                    title: "Success",
                    description: `${t('ForumPage.toastAddSuccessRemove')}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    _id: params?.configforumId,
                })
                setRemoveIndex('')
                handleCloseRemoveForum()
            }

        } catch (Err) {

        }
    }


    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }
    const updateFilesImg = (incommingFiles, e) => {
        setFilesImg(incommingFiles)
        if (incommingFiles.length !== 0) {
            new Promise((resolve, reject) => {
                setnewphotoData({ data: incommingFiles[0]?.file })
                if (incommingFiles[0]?.file !== []) {
                    const reader = new FileReader()
                    reader.readAsDataURL(incommingFiles[0]?.file)
                    reader.onload = () => {
                        resolve(reader.result)
                        setnewphotoData({
                            base64: reader.result,
                        })
                    }
                    reader.onerror = (error) => reject(error)
                }
            })
        }
    }
    const handleAddForum = async (e) => {
        e.preventDefault();
        const responseImage = await axios.post(URLImage, {
            photo: newphotoData?.base64,
        });


        const editordata = convertToRaw(editorState.getCurrentContent())

        const { data: forumAddData } =
            await setForumMutation({
                variables: {
                    configforum: params?.configforumId,
                    user: user?._id,
                    image: responseImage ? responseImage?.data?.url : "",
                    comment: editordata,
                    replydate: myCurrentDate,

                },
            });

        if (forumAddData) {
            await toast({
                position: 'top',
                title: 'Success',
                description: `${t('ForumPage.toastAddCommentSuccess')}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            onclose(true)
            refetch({
                _id: params?.configforumId,
            })
            setEditorState(EditorState.createEmpty())
            setFilesImg([])
        }
    }

    const [updateIndex, setUpdateIndex] = useState('')

    const handleUpdateForum = async (e) => {
        onAddopen(true)
        setUpdateEditorState(EditorState.createWithContent(convertFromRaw({ blocks: ForumData?.forums[e].comment?.blocks, entityMap: ForumData?.forums[e].comment?.entityMap })))
        setUpdateIndex(e)
    }

    const handleUpdateCommentForum = async (e) => {
        e.preventDefault();
        const responseImage = await axios.post(URLImage, {
            photo: newphotoData?.base64,
        });
        const editordata = convertToRaw(updateEditorState.getCurrentContent())

        const { data: forumAddData } =
            await updateMutation({
                variables: {
                    _id: ForumData?.forums[updateIndex]?._id,
                    image: responseImage ? responseImage?.data?.url : "",
                    comment: editordata,
                    replydate: myCurrentDate,

                },
            });

        if (forumAddData) {
            await toast({
                position: 'top',
                title: 'Success',
                description: `${t('ForumPage.toastEditCommentSuccess')}`,

                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            onAddclose(true)
            refetch({
                _id: params?.configforumId,
            })
            setUpdateEditorState(EditorState.createEmpty())
            setFilesImg([])
        }
    }



    return (
        <Fragment>
            <Box w="100%" >
                <Container maxW={'8xl'} py={16} as={Stack} spacing={12}>
                    <Box>
                        <Box bg='gray.100'>

                            <Box mt={3}><Heading as="h1" size='lg' mb={1} ml={3}>{configForumData?.configforums[0].name}</Heading></Box>
                            <Box mt={2}
                                ml={3}>
                                <BlogAuthor
                                    name={`${subjectData?.subject?.teacher?.firstname} ${subjectData?.subject?.teacher?.lastname}`}
                                    date={configForumData?.configforums[0].updatedAt}
                                    avatar={
                                        subjectData?.subject?.teacher?.avatar ? subjectData?.subject?.teacher?.avatar : ''
                                    }
                                /></Box>
                            {configForumData?.configforums[0]?.image ?
                                <Box >
                                    <Image w='500px' mt={2} mb={2} ml={4} sizes='sm' src={configForumData?.configforums[0]?.image} alt='Dan Abramov' />
                                </Box>
                                : null
                            }
                            <Box>
                                <Box mt={1} mb={4} ml={4} >{handleConvertHTML(configForumData?.configforums[0]?.description)}</Box>
                                <Button
                                    mt={2}
                                    ml={4}
                                    mb={2}
                                    as='a'
                                    href='#commentforumtext'
                                    leftIcon={<HiReply />}
                                    bg={'primary.700'}
                                    color={'white'}
                                    _hover={{
                                        bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                                    }}
                                    onClick={onToggle}
                                    size='sm'
                                >
                                    {t('ForumPage.comment')}
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                            {ForumData?.forums.length === 0 ?
                                <Box h='150px'> </Box>
                                :
                                <Box ml={5}>
                                    {ForumData?.forums?.map((forum, i) => (
                                        <Box key={forum?._id}>
                                            <Flex mt={3}>
                                                <BlogAuthor
                                                    mt={1}
                                                    mr={5}
                                                    name={`${forum.user?.firstname} ${forum.user?.lastname}`}
                                                    date={forum?.updatedAt}
                                                    avatar={
                                                        forum.user?.avatar ? forum.user.avatar : ''
                                                    }
                                                />
                                                {forum.user._id === user?._id ?
                                                    <Box>
                                                        <Menu >
                                                            <MenuButton
                                                                as={IconButton}
                                                                aria-label='Options'
                                                                icon={<DragHandleIcon />}
                                                                variant='outline'
                                                                size='sm'
                                                                ml={2}
                                                            />
                                                            <MenuList>
                                                                <MenuItem onClick={(e) => {
                                                                    e.preventDefault()
                                                                    handleUpdateForum(i)
                                                                    setupdateForum(forum)
                                                                }} >
                                                                    <EditIcon mr={2} />
                                                                    {t('ForumPage.editButton')}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        handleOpenRemoveForum(i)
                                                                    }} >
                                                                    <DeleteIcon mr={2} />
                                                                    {t('ForumPage.deleteButton')}

                                                                </MenuItem>

                                                            </MenuList>
                                                        </Menu>
                                                    </Box>
                                                    : null
                                                }

                                            </Flex>

                                            {forum?.image ?
                                                <Box >
                                                    <Image w='500px' mt={2} mb={2} ml={7} src={forum?.image} alt='Dan Abramov' />
                                                </Box> : null
                                            }
                                            <Box>
                                                <Box mt={1} ml={7} >
                                                    {handleConvertHTML(forum?.comment)}
                                                </Box>
                                                <Divider /></Box>

                                        </Box>

                                    ))}
                                    <Box p={8}></Box>
                                </Box>
                            }
                        </Box>

                        <Box id='commentforumtext'  >
                            <Collapse in={isOpen} animateOpacity>
                                <Box>
                                    <Text as='h3' mt={5}> {t('ForumPage.comment')}</Text>
                                    <Flex mt={3}>
                                        <FormControl mb={3} mr={5} >
                                            <Box minHeight={'47vh'} border="1px solid">
                                                <Editor
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"
                                                    editorState={editorState}
                                                    onEditorStateChange={setEditorState}
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                    }}
                                                />
                                            </Box>
                                        </FormControl>
                                        <Box w='md'>
                                            <Dropzone
                                                require
                                                onChange={updateFilesImg}
                                                value={filesImg}
                                                accept='image'
                                                maxFiles="1"
                                                view="grid"

                                            >
                                                {filesImg.map((file) => (
                                                    <FileItem
                                                        {...file}
                                                        key={file.id}
                                                        onDelete={onDeleteImg}
                                                        // localization={"EN-en"}
                                                        // resultOnTooltip
                                                        preview={true}
                                                    />
                                                ))}
                                            </Dropzone>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Center>
                                        <Flex>
                                            <Center mt={3}>
                                                <Button bg={'primary.700'}
                                                    color={'white'}
                                                    _hover={{
                                                        bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                                                    }}
                                                    onClick={handleAddForum}
                                                >
                                                    {t('ForumPage.saveButton')}
                                                </Button>
                                            </Center>
                                            <Center mt={3} ml={2}>
                                                <Button
                                                    onClick={closeButton}
                                                >
                                                    {t('Button.close')}
                                                </Button>
                                            </Center>
                                        </Flex>
                                    </Center>
                                </Box>
                            </Collapse>
                        </Box>
                    </Box>
                </Container>
            </Box>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isAddopen}
                onClose={closeButton}
                size={'6xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> {t('ForumPage.comment')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Flex >
                            <Text mr={1}> {t('ForumPage.title')}: </Text>
                            <Text> {configForumData?.configforums[0].name} </Text>
                        </Flex>

                        <Flex mt={3}>
                            <FormControl mb={3} mr={5} >
                                <Box minHeight={'25vh'} border="1px solid">
                                    <Editor
                                        // editorStyle={{ border: "1px solid" }}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        editorState={updateEditorState}
                                        onEditorStateChange={setUpdateEditorState}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                        }}
                                    />
                                </Box>
                            </FormControl>
                            <Box w='md'>
                                {updateForum?.image ?
                                    <Box>
                                        <FormLabel>{t('ForumPage.photoold')}</FormLabel>
                                        <Image src={updateForum?.image} mb={1} />
                                    </Box>
                                    : null}

                                <Dropzone
                                    require
                                    onChange={updateFilesImg}
                                    value={filesImg}
                                    accept='image'
                                    maxFiles="1"
                                    view="grid"

                                >
                                    {filesImg.map((file) => (
                                        <FileItem
                                            {...file}
                                            key={file.id}
                                            onDelete={onDeleteImg}
                                            // localization={"EN-en"}
                                            // resultOnTooltip
                                            preview={true}
                                        />
                                    ))}
                                </Dropzone>
                            </Box>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} onClick={closeButton}>
                            {t('Button.close')}
                        </Button>
                        <Button bg={'primary.700'}
                            color={'white'}
                            _hover={{
                                bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                            }}
                            onClick={handleUpdateCommentForum}>
                            {t('Button.save')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <AlertDialog isOpen={showRemoveForum} onClose={handleCloseRemoveForum} >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <form onSubmit={handleRemoveForum}>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {t('ForumPage.deletecomment')}
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                {t('ForumPage.youdelete')}
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button onClick={handleCloseRemoveForum}>
                                    {t('Button.close')}
                                </Button>
                                <Button bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }} type='submit' ml={3}>
                                    {t('Button.delete')}
                                </Button>
                            </AlertDialogFooter>
                        </form>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Fragment>
    );
};

export default ForumPage;
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { useHistory } from "react-router-dom";
import {
	Box,
	FormControl,
	FormLabel,
	Text,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Icon,
	Flex,
} from '@chakra-ui/react'
import { useQuery, } from '@apollo/client'
import questionById from '../graphql/queries/question'
import AuthContext from '../contexts/AuthContext'
import scorebystudentQueries from "../graphql/queries/scorequestionbyStudent"
import { useParams } from 'react-router'



const End = ({ results, data, contentId, subjectId }) => {
	const [correctAnswers, setCorrectAnswers] = useState(0)
	const [question, setQuestion] = useState([])
	const { isOpen, onOpen, onClose } = useDisclosure()
	const param = useParams()

	const { user } = useContext(AuthContext)
	const history = useHistory()

	const { data: QuestionData } = useQuery(questionById, {
		variables: {
			_id: param?.contentId,
		},
	})

	const { data: scoreAddData, refetch } = useQuery(scorebystudentQueries, {
		variables: {
			student: user?._id,
			id: contentId
		},
	})


	localStorage.setItem('refetchPage', 'true')
	const { t } = useTranslation()

	useEffect(() => {
		let correct = 0

		Object.keys(results).map((item, i) =>
			results[item] === data[i].answer ? correct++ : null
		)
		setCorrectAnswers(correct)
		refetch({
			student: user?._id,
			id: contentId
		})
		if (QuestionData) {
			setQuestion(QuestionData?.content?.questionconfig?.questionList)
		}
	}, [])

	const handleReturnPage = async () => {
		history.push(`/subject/questiondetail/${contentId}/${subjectId}`)
	}
	return (
		<>
			<Box style={{ textAlign: "-webkit-center" }} marginTop={"20vh"}>
				<Box maxW="lg" mx="lg" p={5} boxShadow="lg" borderRadius={'lg'} >
					<FormControl as="fieldset">
						<FormLabel as="legend">{t('QuizPage.results')}</FormLabel>
						<Box py={5}>
							<Text>"ทำแบบทดสอบเสร็จสิ้น"</Text>
							<Text>
								{`${user?.firstname} ${user?.lastname}`}
							</Text>
						</Box>
						<Flex direction={'row'}>
							<Button bg={'primary.700'} color={'white'}
								_hover={{
									bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
								}} size="lg" mr={2} mt={2} onClick={onOpen}>
								{t('QuizPage.Checkyouranswers')}
							</Button>

							<Button bg={'primary.700'} color={'white'} mt={2}
								_hover={{
									bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
								}} size="lg" onClick={handleReturnPage}>
								{t('QuizPage.returnPage')}
							</Button>
						</Flex>
					</FormControl>
				</Box>

				<Modal isOpen={isOpen} onClose={onClose} size="5xl">
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>{t('QuizPage.Checkanswers')}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Table variant="simple" >
								<Thead>
									<Tr>
										<Th isNumeric>{t('QuizPage.no')}</Th>
										<Th>{t('QuizPage.question')}</Th>
										<Th>{t('QuizPage.youranswer')}</Th>
										<Th>{t('QuizPage.questionAnswer')}</Th>
										<Th>{t('QuizPage.Results')}</Th>
									</Tr>
								</Thead>
								<Tbody>

									{scoreAddData?.scores[0]?.quiz[0]?.answer.map((score, i) => (
										<Tr key={i}>

											<Td isNumeric>{i + 1}</Td>
											<Td>{question[i]?.question}</Td>
											<Td>{score?.useranswer}</Td>
											<Td>{score?.answer}</Td>
											<Td>
												{score?.status === "correct" ? (
													<Icon mr={2} w={5} h={5} color="green.500" as={CheckIcon} />
												) : (
													<Icon mr={2} w={5} h={4} color="red.500" as={CloseIcon} />
												)}
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
							<Text mb="1rem" px={4} py={3} margin={1}>
								{t('QuizPage.score')}: {scoreAddData?.scores[0]?.quiz[0]?.score}
							</Text>
						</ModalBody>
						<ModalFooter>
							<Button bg={'primary.700'} color={'white'}
								_hover={{
									bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
								}} mr={3} onClick={onClose}>
								{t('QuizPage.close')}
							</Button>
							{/* <Button variant="ghost" onClick={submitClickHandler}>
							Submit
						</Button> */}
						</ModalFooter>
					</ModalContent>
				</Modal>
			</Box>
		</>
	)
}

export default End

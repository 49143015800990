import {
    Text,
    Avatar,
    HStack,
} from '@chakra-ui/react'

import dayjs from 'dayjs'

require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export const BlogAuthor = (props) => {
    return (
        <HStack ml={10} marginTop="0" spacing="2" display="flex" alignItems="center">
            <Avatar
                borderRadius="full"
                boxSize="40px"
                src={props.avatar}
                name={`${props.name}`}
            />
            <Text fontWeight="medium">{props.name}</Text>
            <Text>—</Text>
            <Text>{dayjs(props?.date).format('DD MMMM YYYY')}</Text>
        </HStack>
    )
}
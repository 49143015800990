import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'

// import { CssBaseline } from "@mui/material";
import { createTheme } from '@mui/material/styles'
import { deepOrange } from '@mui/material/colors'


// import Landing from './pages/LandingPage'
import LandingLayout from './components/layouts/LandingLayout'
import LoginPage from './pages/LoginPage'
import DetailPage from './pages/DetailPage'
import PrivateRoute from './components/PrivateRoute'
import CoursePage from './pages/CoursePage'
import SubjectFreePage from './pages/SubjectFreePage'
import MeetingPage from './pages/MeetingPage'
import ListStreamPage from './pages/ListStreamPage'
import ManageStreamPage from './pages/ManageStreamPage'
// import NotFoundPage from "./pages/NotfoundPage";
import PricingPage from './pages/PricingPage'
import ManagePaymentsPage from './pages/ManagePaymentsPage'
import NotFoundPage from './pages/NotfoundPage'
import SignUpPage from './pages/SignupPage'
import ProfilePage from './pages/ProfilePage'
import PolicyPage from './pages/PolicyPage'
import TermConditionsPage from './pages/TermConditionsPage'
import PersonDetailPage from './pages/PersonDetailPage'
import ManageVideoPage from './pages/ManageVideoPage'
import ManageSubjectPage from './pages/ManageSubjectPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import HomeworkPage from './pages/HomeworkPage'
import ManageHomeworkPage from './pages/ManageHomeworkPage'
import ManageQuestionPage from './pages/ManageQuestionPage.js'
import QuizPage from './pages/QuizPage'
import TeacherPage from './pages/TeacherPage'
import SendHomeworkPage from './pages/SendHomeworkPage'
import PreviewHomeworkPage from './pages/PreviewHomeworkPage'
import ShowProfilePage from './pages/ShowProfilePage'
import CourseTeacherPage from './pages/CourseTeacherPage'
import QuestionPage from './pages/QuestionPage'
import ManagerZoomPage from './pages/ManagerZoomPage'
import CourseStudentPage from './pages/CoursesStudentPage'
import HomePage from './pages/HomePage'
import ManageEnrollSubjectPage from './pages/ManageEnrollSubjectPage'
import ManageForumPage from './pages/ManageForumPage'
import ForumPage from './pages/ForumPage'
import ManageTeacherPage from './pages/ManageTeacherPage'
import ManageStudentPage from './pages/ManageStudentPage'
import SubjectController from './pages/SubjectController'
import ManageSurveyPage from './pages/ManageSurveyPage'
import SendSurveyPage from './pages/SendSurveyPage'
import SurveyPage from './pages/SurveyPage'
import SurveyDetail from './pages/SurveyDetail'
import ManageCertificate from './pages/ManageCertificatePage'
import ManageContentPage from './pages/ManageContentPage'
import CertificatePage from './pages/CertificatePage'
import VideoPlayerPage from './pages/VideoPlayerPage'
import QuestionDetail from './pages/QuestionDetailPage'
import QuestionPreviewPage from './pages/QuestionPreviewPage'
import SubjectPage from './pages/SubjectPage'

// const theme = createTheme({
// 	palette: {
// 		primary: {
// 			main: '#0CA25F',
// 		},
// 		secondary: deepOrange,
// 	},
// })

export default function App() {
	console.log('env', process.env.NODE_ENV, 'url', process.env.PUBLIC_URL)
	return (
		<LandingLayout>
			<Switch>
				<Route exact path="/" component={HomePage} />

				<Route exact path="/login" component={LoginPage} />

				{/* <Route exact path="/signup" component={SignUpPage} /> */}
				<Route exact path="/forgot" component={ForgotPasswordPage} />
				<Route exact path="/reset/:token/:Id" component={ResetPasswordPage} />
				<PrivateRoute exact path="/mycourses" component={CourseTeacherPage} />
				<PrivateRoute exact path="/managesubject" component={ManageSubjectPage} />
				<PrivateRoute exact path="/managecertificate/:subjectId" component={ManageCertificate} />
				<PrivateRoute exact path="/managezoom" component={ManagerZoomPage} />
				<PrivateRoute exact path="/manageteacher" component={ManageTeacherPage} />
				<PrivateRoute exact path="/managestudent" component={ManageStudentPage} />
				<PrivateRoute exact path="/controllersubject" component={SubjectController} />
				{/* <PrivateRoute exact path="/students/:subjectId" component={EnrollSubjectPage} /> */}
				<PrivateRoute exact path="/students/:subjectId" component={ManageEnrollSubjectPage} />

				<PrivateRoute exact path="/manageforum/:subjectId" component={ManageForumPage} />
				<PrivateRoute exact path="/managecontent/:subjectId" component={ManageContentPage} />
				<PrivateRoute exact path="/managecontent/managequestion/:contentId" component={ManageQuestionPage} />
				<PrivateRoute exact path="/managecontent/questions/:contentId" component={QuestionDetail} />
				<PrivateRoute exact path="/managecontent/managesurvey/:contentId" component={ManageSurveyPage} />
				<PrivateRoute exact path="/managecontent/surveys/:contentId" component={SurveyDetail} />

				<PrivateRoute exact path='/managecontent/homeworkdetail/:contentId/:subjectId' component={HomeworkPage} />
				<PrivateRoute exact path="/managehomework/:studentId/:contentId/:subjectId" component={ManageHomeworkPage} />
				<Route exact path="/democorse" component={SubjectFreePage} />
				<Route exact path="/detail" component={DetailPage} />
				<Route exact path="/policy" component={PolicyPage} />
				<Route exact path="/term_condition" component={TermConditionsPage} />
				<Route exact path="/courses" component={CoursePage} />
				<Route exact path="/subject/:subjectId" component={SubjectPage} />
				<Route exact path="/certificate/:certificateId" component={CertificatePage} />
				<Route exact path="/video/:contentId" component={VideoPlayerPage} />
				<PrivateRoute exact path="/coursesstudent" component={CourseStudentPage} />
				<PrivateRoute exact path="/meet/:subjectId" component={MeetingPage} />
				<PrivateRoute exact path="/pricing" component={PricingPage} />
				<PrivateRoute exact path="/managepayments" component={ManagePaymentsPage} />
				<PrivateRoute exact path="/streams" component={ListStreamPage} />
				<PrivateRoute exact path="/profile" component={ProfilePage} />
				<PrivateRoute exact path="/persondetail" component={PersonDetailPage} />
				<PrivateRoute exact path="/quiz/:contentId/:subjectId" component={QuizPage} />
				<PrivateRoute exact path="/forum/:subjectId/:configforumId" component={ForumPage} />
				<PrivateRoute exact path="/managevideo/:subjectId" component={ManageVideoPage} />
				<PrivateRoute exact path="/staffs" component={TeacherPage} />
				<PrivateRoute exact path="/subject/survey/:contentId" component={SendSurveyPage} />
				<PrivateRoute exact path="/subject/sendhomework/:contentId" component={SendHomeworkPage} />
				<PrivateRoute exact path="/subject/sendhomework/preview/:contentId" component={PreviewHomeworkPage} />
				<PrivateRoute exact path="/subject/questiondetail/:contentId/:subjectId" component={QuestionPage} />
				<PrivateRoute exact path="/subject/questiondetail/preview/:contentId/:studentId" component={QuestionPreviewPage} />
				<PrivateRoute exact path="/subject/surveydetail/:contentId" component={SurveyPage} />
				<PrivateRoute exact path="/showprofile/:userId" component={ShowProfilePage} />
				{/* <ThemeProvider theme={theme}> */}
				<PrivateRoute exact path="/managestream" component={ManageStreamPage} />
				{/* </ThemeProvider> */}
				<Route component={NotFoundPage} />

			</Switch>
		</LandingLayout>
	)
}

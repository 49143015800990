import { Flex, Heading, Stack } from "@chakra-ui/layout"
import React from "react"
import PolicyLayout from "../components/layouts/PolicyLayout"

const PolicyPage = () => {
    return (
        <Flex mt={20} padding={20}>
            <Stack>
                <Heading>Privacy Policy</Heading>
                <PolicyLayout />
            </Stack>
        </Flex>
    )
}
export default PolicyPage

import React, { Fragment } from "react";
import { Avatar, Box } from "@chakra-ui/react";

export default function Logo(props) {
  return (
    <Fragment>
      <Box {...props} bg="rgba(255,255,255,0)">
        {/* <Text fontSize="lg" fontWeight="bold">
          Logo
        </Text> */}
        <Avatar
          // {...props}
          size="md"
          bg="rgba(255,255,255,0)"
          // objectFit="cover"
          name="Logo"
          colorScheme='rgba(255,255,255,0)'
          color='rgba(255,255,255,0)'
          src="./images/psuLogo.png"

        />
      </Box>
    </Fragment>
  );
}

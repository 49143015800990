import React, { Fragment, useState, useContext, useEffect } from "react"
import { useTranslation } from 'react-i18next'
import {
    Box,
    Container,
    Stack,
    Text,
    Heading,
    Button,
    Table,
    useToast,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Center,
    Input,
    FormControl,
    FormLabel,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    useDisclosure,
    Image,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    IconButton,
    Grid,
    GridItem,
    Flex,
    HStack,
    Icon,
    useColorMode,
} from "@chakra-ui/react"

import { FaTrashAlt, FaPencilAlt } from "react-icons/fa"
import { CloseIcon } from '@chakra-ui/icons'
import ConfigForumById from "../graphql/queries/configforumbyID"
import { useMutation, useQuery } from "@apollo/client"
import ForumMutation from "../graphql/mutations/createConfigForum"
import ForumUpdateMutation from "../graphql/mutations/updateConfigForum"
import ForumRemoveMutation from "../graphql/mutations/removeConfigForum"
import { useMultiState } from "../lib/hooks"
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'
import axios from "axios"
import { useParams } from 'react-router';
import { Dropzone, FileItem } from "@dropzone-ui/react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import './manageforumpage.css'


const URLImage = process.env.REACT_APP_UPLOAD_IMAGE;

const ManageForumPage = () => {
    const { colorMode } = useColorMode()
    const toast = useToast()
    const { t } = useTranslation()
    const params = useParams()
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const [setForumMutation] = useMutation(ForumMutation)
    const [setForumUpdateMutation] = useMutation(ForumUpdateMutation)
    const [removeForumMutation] = useMutation(ForumRemoveMutation)
    const {
        isOpen: isForumOpen,
        onOpen: onForumOpen,
        onClose: onForumClose,
    } = useDisclosure()

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [EditEditorState, setEditEditorState] = useState(EditorState.createEmpty());



    const { data, refetch, loading } = useQuery(ConfigForumById, {
        variables: {
            _id: params?.subjectId,
        },
    })

    useEffect(() => {
        if (user?.role === 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
        if (loading) {
            refetch({
                teacher: user?._id,
            })
        }
    }, [history, user, refetch])



    const [filesImg, setFilesImg] = useState([]);

    const [newphotoData, setnewphotoData] = useMultiState({
        data: null,
        base64: "",
    });

    const initialForum = {
        name: "",
    }

    const [forumData, setForum] = useMultiState(initialForum)
    const [updateForumData, setUpdateForum] = useMultiState(initialForum)


    const handleAddForum = async (e) => {
        e.preventDefault();
        // console.log(e);
        const responseImage = await axios.post(URLImage, {
            photo: newphotoData?.base64,
        });

        if (!forumData?.name) {
            await toast({
                position: 'top',
                title: 'Warning',
                description: `${t('ManageForumPage.altwarningtitle')}`,
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else {
            const editordata = convertToRaw(editorState.getCurrentContent())
            const { data: ForumAddData } = await setForumMutation({
                variables: {
                    subject: params?.subjectId,
                    name: forumData?.name,
                    description: editordata,
                    image: responseImage ? responseImage?.data?.url : "",
                },
            });
            if (ForumAddData) {
                await toast({
                    position: 'top',
                    title: 'success',
                    description: `${t('ManageForumPage.toastAddForumSuccess')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })

                setForum(initialForum)
                setFilesImg([])
                setnewphotoData({ data: null, base64: "" });
                setEditorState(EditorState.createEmpty())
                refetch({
                    subject: params?.subjectId,
                })

            }

        }
    };

    const handleUpdateForum = async (e) => {
        e.preventDefault();

        const responseImage = await axios.post(URLImage, {
            photo: newphotoData?.base64,
        });

        if (!updateForumData?.name) {
            await toast({
                position: 'top',
                title: 'Warning',
                description: `${t('ManageForumPage.altwarningtitle')}`,
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else {
            const EditEditordata = convertToRaw(EditEditorState.getCurrentContent())

            const { data: ForumUpdateData } = await setForumUpdateMutation({
                variables: {
                    _id: updateForumData?._id,
                    name: updateForumData?.name,
                    description: EditEditordata,
                    image: responseImage ? responseImage?.data?.url : "",
                },
            });
            if (ForumUpdateData) {
                await toast({
                    position: 'top',
                    title: 'success',
                    description: `${t('ManageForumPage.toastEditForumSuccess')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })

                setForum(initialForum)
                setEditorState(EditorState.createEmpty())
                refetch({
                    subject: params?.subjectId,
                })
                setnewphotoData({ data: null, base64: "" });
                onForumClose(true)
                setFilesImg([])
            }
        }
    };

    const updateFilesImg = (incommingFiles, e) => {
        setFilesImg(incommingFiles);
        if (incommingFiles.length !== 0) {
            new Promise((resolve, reject) => {
                setnewphotoData({ data: incommingFiles[0]?.file })
                if (incommingFiles[0]?.file !== []) {
                    const reader = new FileReader();
                    reader.readAsDataURL(incommingFiles[0]?.file);
                    reader.onload = () => {
                        resolve(reader.result);
                        setnewphotoData({
                            base64: reader.result,
                        });
                    }; reader.onerror = (error) => reject(error);
                }
            });
        }
    };


    const onDeleteImg = (id) => {
        setFilesImg(filesImg.filter((x) => x.id !== id));
    };

    const handleForumOpen = (i) => {

        setEditEditorState(EditorState.createWithContent(convertFromRaw({ blocks: data?.configforums[i].description?.blocks, entityMap: data?.configforums[i].description?.entityMap })))
        onForumOpen(true)
    }

    const [showRemoveForum, setShowRemoveForum] = useState(false)
    const [removeForumIndex, setRemoveForumIndex] = useState('')

    const handleOpenRemoveForum = (index) => {
        setRemoveForumIndex(index)
        setShowRemoveForum(true)
    }

    const handleCloseRemoveForum = () => {
        setShowRemoveForum(false)
    }

    const handleRemoveForum = async (e) => {
        e.preventDefault()
        let ForumIdRemove = data?.configforums[removeForumIndex]?._id
        try {

            const { data: removeForum, errors: streamErr } = await removeForumMutation({
                variables: {
                    _id: ForumIdRemove,
                },
            })
            if (removeForum) {
                await toast({
                    position: "top",
                    title: "Success",
                    description: `${t('ManageForumPage.toastEditForumSuccess')}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    subject: params?.subjectId,
                })
                setRemoveForumIndex('')
                handleCloseRemoveForum()
            } if (streamErr) {

            }
        } catch (Err) {
            alert(`${Err?.graphQLErrors[0]?.message}`)
        }
    }
    return (
        <Fragment>
            <AlertDialog isOpen={showRemoveForum} onClose={handleCloseRemoveForum} >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <form onSubmit={handleRemoveForum}>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {t('ManageForumPage.deleteforum')}
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                {t('ManageForumPage.youdelete')}
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button onClick={handleCloseRemoveForum}>
                                    {t('ManageForumPage.close')}
                                </Button>
                                <Button bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }} type='submit' ml={3}>
                                    {t('ManageForumPage.saveButton')}
                                </Button>
                            </AlertDialogFooter>
                        </form>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Modal isOpen={isForumOpen} onClose={onForumClose} style={{ zIndex: 1 }} size={'4xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> {t('ManageForumPage.editforum')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleUpdateForum}>
                        <ModalBody>
                            <FormControl id="">
                                <FormLabel>{t('ManageForumPage.titleforum')}</FormLabel>
                                <Input value={updateForumData?.name} onChange={(e) => setUpdateForum({ name: e.target.value })} />
                            </FormControl>
                            <FormControl id="">
                                <FormLabel>{t('ManageForumPage.text')}</FormLabel>
                                <Box minHeight={'30vh'} borderWidth="1px" borderRadius={"lg"} p={2}>
                                    <Editor
                                        editorState={EditEditorState}
                                        onEditorStateChange={setEditEditorState}
                                        toolbarStyle={{color:'black !important'}}
                                        
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                            blockType:{style:{color:'black'}}
                                        }}
                                        editorStyle={{color:colorMode === 'light' ? "black":'white' }}
                                    />
                                </Box>
                            </FormControl>

                            <Flex direction={["column", "column", "row", "row"]} w={'100%'} borderWidth={'1px'} borderRadius={'lg'} p={4} mt={2}>
                                <FormControl flex={1} p={2}>
                                    <FormLabel mb={3} mt={3}>{t('ManageForumPage.photo')}</FormLabel>
                                    <Dropzone
                                        onChange={updateFilesImg}
                                        value={filesImg}
                                        accept="image/*"
                                        maxFiles="1"
                                        view="grid"
                                        minHeight="180px"
                                    //localization={"ES-es"}
                                    //disableScroll
                                    >
                                        {filesImg.map((file) => (
                                            <FileItem
                                                {...file}
                                                key={file.id}
                                                onDelete={onDeleteImg}
                                                //localization={"EN-en"}
                                                //resultOnTooltip
                                                preview={true}
                                            />
                                        ))}
                                    </Dropzone>
                                </FormControl>
                                {updateForumData?.image ?
                                    <Box borderWidth={'1px'} borderRadius={'lg'} p={4}>
                                        <FormControl maxHeight={'30vh'} maxWidth={'30vh'} >
                                            <FormLabel mb={3} mt={3}>{t('ManageForumPage.photoold')}</FormLabel>
                                            <Center><Image src={updateForumData?.image} /></Center>
                                        </FormControl>
                                    </Box>

                                    : null
                                }
                            </Flex>

                        </ModalBody>
                        <ModalFooter>
                            <Center>
                                <Button type="submit " bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}>
                                    {t('ManageForumPage.saveButton')}
                                </Button>
                            </Center>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            <Box w="100%" px={[2, 5, 10, 20]} minHeight={'90vh'}>
                <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
                    <Stack spacing={0} align={'center'}>
                        <Heading variant="h4">
                            {t('ManageForumPage.title')}
                        </Heading>
                        <Text>
                            {t('ManageForumPage.subtitle')}
                        </Text>
                    </Stack>
                    <Box boxShadow={'xl'} p={'5'} borderRadius={'lg'} >
                        <form onSubmit={handleAddForum}>
                            <FormControl id="" mt={-5}>
                                <FormLabel>{t('ManageForumPage.titleforum')}</FormLabel>
                                <Input
                                    value={forumData?.name}
                                    onChange={(e) => setForum({ name: e.target.value })}
                                />
                            </FormControl>
                            <Flex direction={['column', 'row', 'row', 'row']}>
                                <FormControl p={2}  >
                                    <FormLabel>{t('ManageForumPage.text')}</FormLabel>
                                    <Box minHeight={'40vh'} borderWidth="1px" borderRadius={"lg"} p={1}>
                                        <Editor
                                        toolbarStyle={{color:'black !important'}}
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                            }}
                                            editorStyle={{ color: colorMode === 'light' ? "black":'white' }}
                                        />
                                    </Box>
                                </FormControl>
                                <FormControl p={2}>
                                    <FormLabel>{t('ManageForumPage.photo')}</FormLabel>
                                    <Dropzone

                                        onChange={updateFilesImg}
                                        value={filesImg}
                                        accept="image/*"
                                        maxFiles="1"
                                        view="grid"
                                        minHeight={'33vh'}

                                    >
                                        {filesImg.map((file) => (
                                            <FileItem
                                                {...file}
                                                key={file.id}
                                                onDelete={onDeleteImg}
                                                //localization={"EN-en"}
                                                //resultOnTooltip
                                                preview={true}
                                            />
                                        ))}
                                    </Dropzone>
                                </FormControl>
                            </Flex>
                            <Center>
                                <Button mt={5} type="submit " bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}>
                                    {t('Button.confirm')}
                                </Button>
                            </Center>
                        </form>
                    </Box>

                    <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} borderWidth={'1px'} >

                        <Table vvariant="striped" >
                            <Thead height="50px">
                                <Tr>
                                    <Th textAlign="center">{t('ManageForumPage.titleforum')}</Th>
                                    <Th textAlign="center">{t('ManageForumPage.manage')}</Th>
                                </Tr>
                            </Thead>
                            {data?.configforums?.map((forum, i) => (
                                <Tbody key={forum?._id}>
                                    <Tr>
                                        <Td textAlign="center" minWidth={'20vh'}>
                                            {forum?.name}
                                        </Td>
                                        <Td textAlign="center">

                                            <Tooltip label={t('Button.edit')} fontsize="md">
                                                <IconButton boxShadow={'lg'} colorScheme='blue' mr={2} icon={<FaPencilAlt />}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        handleForumOpen(i)
                                                        setUpdateForum(forum)
                                                    }}
                                                />
                                            </Tooltip>

                                            <Tooltip label={t('Button.remove')} fontsize="md">
                                                <IconButton boxShadow={'lg'} colorScheme='blue' mr={2} icon={<FaTrashAlt />}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        handleOpenRemoveForum(i)
                                                    }}
                                                />
                                            </Tooltip>

                                        </Td>
                                    </Tr>
                                </Tbody>
                            ))}
                        </Table>
                        {data?.configforums?.length == 0 &&
                            <Box w="full" p={'2'} boxShadow="lg" borderRadius={'lg'} h={"200"} bg={'gray.100'}>
                                <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} style={{ height: "100%" }}>
                                    <HStack color={'gray.500'}>
                                        <Icon as={CloseIcon} w={10} h={8} />
                                        <Text fontSize={'35'} color={'gray.500'}>{t('ManageForumPage.alt')}</Text>
                                    </HStack>
                                </Flex>
                            </Box>
                        }
                    </Box>
                </Container>
            </Box >
        </Fragment >
    )
}

export default ManageForumPage;

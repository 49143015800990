import gql from 'graphql-tag'

export default gql`
	query ($subject: MongoID, $student: MongoID) {
		studentofsubject(filter: { subject: $subject, student: $student }) {
			_id
			subject {
				_id
				name
			}
			student {
				_id
				firstname
			}
			certificatestatus
			creditstatus
			payment_image
		}
	}
`

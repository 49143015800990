import { Fragment, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Heading,
    Text,
    Container,
    Link,
    Button,
    Flex,
    Stack,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    useToast,
    useColorModeValue,
    Alert,
    AlertIcon,
} from '@chakra-ui/react'
import * as tus from "tus-js-client";

import { CheckIcon } from '@chakra-ui/icons'
import renderHTML from 'react-render-html'
import draftToHtml from 'draftjs-to-html'
import { useMutation, useQuery } from '@apollo/client'
import { useMultiState } from '../lib/hooks'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router'

import HomeworkMutation from '../graphql/mutations/createHomework'
import HomeworkRemoveMutation from '../graphql/mutations/removeHomework'
import HomeworkUpdateMutation from '../graphql/mutations/updateHomework'
import contentById from '../graphql/queries/contentbyId'
import HomeworkQueries from '../graphql/queries/homeworkbyStudent'
import scorebystudentQueries from '../graphql/queries/scorebystudent'

import dayjs from 'dayjs'

import AuthContext from '../contexts/AuthContext'
import axios from 'axios'
import { AiOutlineFilePpt, AiOutlineFileImage } from 'react-icons/ai'

import AlertDialogHomework from '../components/pages/SendHomework/AlertDialogHomework'
import DialogCreateHomwork from '../components/pages/SendHomework/DialogCreateHomwork';
import DialogUpdateHomework from '../components/pages/SendHomework/DialogUpdateHomework';

const URL_SEND = process.env.REACT_APP_SEND_HOMEWORK
const URLCloudFlare = process.env.REACT_APP_URL_CLOUDFLARE


require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const SendHomeworkPage = () => {

    const { isOpen: isAddopen, onOpen: onAddopen, onClose: onAddclose, } = useDisclosure()
    const { isOpen: isEditopen, onOpen: onEditopen, onClose: onEditclose, } = useDisclosure()
    const toast = useToast()
    const { t } = useTranslation()
    const { user } = useContext(AuthContext)


    const [statusremove, setStatusremove] = useState(false)
    const [statusEdit, setStatusEdit] = useState(false)

    const [Statusbutton, setStatusbutton] = useState(false)

    const [contentState, setContentState] = useState()
    const [homework, setHomeWork] = useState({
        url: '',
        name: ''
    })
    const [setHomeworkMutation] = useMutation(HomeworkMutation)
    const [setHomeworkRemoveMutation] = useMutation(HomeworkRemoveMutation)
    const [setHomeworkUpdateMutation] = useMutation(HomeworkUpdateMutation)
    const [uploadState, setUploadStage] = useState(0)

    const params = useParams()
    const myCurrentDate = new Date()

    const [filesVdo, setFilesVdo] = useState([])
    const [files, setFiles] = useState([])

    const onDeleteVdo = (id) => {
        setFilesVdo(filesVdo.filter((x) => x.id !== id))
    }

    const updateFilesVdo = async (incommingFiles) => {
        setFilesVdo(incommingFiles)
        var videoInputFile = await incommingFiles[0].file;
        var mediaId = ''
        var upload = new tus.Upload(videoInputFile, {
            endpoint: `${URLCloudFlare}`,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                name: incommingFiles[0].file.name,
                filetype: incommingFiles[0].file.type
            },
            headers: {
                'X-Auth-Email': 'kaseampong.kt@streamsouth.tech',
                'X-Auth-Key': '483ae948176fd81daa6015d16e1f7868b8b1b'
            },
            chunkSize: 100 * 1024 * 1024,
            onError: function (error) {
                console.log("Failed because: " + error);
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                var percentage = Math.floor(bytesUploaded / bytesTotal * 100);
                console.log(bytesUploaded, bytesTotal, percentage + "%")
                setUploadStage(percentage)
            },
            onSuccess: function () {
                // console.log("Download %s from %s", upload.file.name, upload.url)
            },
            onAfterResponse: function (req, res) {
                return new Promise(resolve => {
                    var mediaIdHeader = res.getHeader("stream-media-id");
                    if (mediaIdHeader) {
                        mediaId = mediaIdHeader;
                        // setUpdateContent({ link: `https://iframe.videodelivery.net/${mediaId}` })
                        // setUrlCloudFlare({ url: `https://iframe.videodelivery.net/${mediaId}`, id: mediaId, file: incommingFiles[0].file })
                        setHomeWork({ ...homework, url: `https://iframe.videodelivery.net/${mediaId}`, name: incommingFiles[0].file.name, })
                    }
                    resolve()
                })
            }
        });
        upload.start();
    }


    const { data: scoreAddData } = useQuery(scorebystudentQueries, {
        variables: {
            student: user?._id,
            id: params?.contentId,
        },
    })

    const onDeleteFile = (id) => {
        setFiles(files.filter((x) => x.id !== id))
    }

    const toggleEditButton = () => {
        setStatusEdit(!statusEdit)
    }

    const handleUpdateStatus = () => {
        setStatusEdit(false)
        onEditclose(true)
    }

    const { data: Content } = useQuery(contentById, {
        variables: {
            _id: params?.contentId,
        },
    })

    const { data: homeworkData, refetch } = useQuery(HomeworkQueries, {
        variables: {
            student: user?._id,
            content: params?.contentId,
        },
    })

    // console.log("SSSSS", Content)

    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }

    useEffect(() => {
        if (Content) {
            setContentState(Content?.content)
        }
    }, [Content])

    const updateFiles = (incommingFiles, e) => {
        setFiles(incommingFiles)
        if (incommingFiles.length !== 0) {
            new Promise((resolve, reject) => {
                const regex = /[^.]+$/g;
                const typeFile = incommingFiles[0]?.file.name.match(regex)
                if (incommingFiles[0]?.file !== []) {
                    const reader = new FileReader()
                    reader.readAsDataURL(incommingFiles[0]?.file)
                    reader.onprogress = (data) => {
                        if (data.lengthComputable) {
                            var progress = parseInt(((data.loaded / data.total) * 100), 10);
                            setUploadStage(progress)
                        }
                    }
                    reader.onload = () => {
                        resolve(reader.result)
                        handleUploadMediaFile(reader.result, typeFile, incommingFiles[0].file.name)
                    }
                    reader.onerror = (error) => reject(error)
                }
            })
        }
    }

    const handleUploadMediaFile = async (base64, typeFile, fileName) => {
        try {
            const responseUploadMediaFile = await axios.post(URL_SEND, {
                file: base64,
                type: typeFile
            })
            if (responseUploadMediaFile?.status == process.env.REACT_APP_API_STATUS_200) {
                setHomeWork({ ...homework, url: responseUploadMediaFile?.data?.url, name: fileName, })
            }
        } catch (error) {
            console.error(error)
        }
    }


    const handleSendhomework = async () => {
        try {
            const { data: responseCreateHomework } = await setHomeworkMutation({
                variables: {
                    content: params?.contentId,
                    file: homework?.url,
                    filename: homework?.name,
                    status: 'submit',
                    student: user?._id,
                    submitdate: myCurrentDate,
                },
            })
            if (responseCreateHomework) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('SendHomeworkPage.altadd')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (error) {
            console.error(error)
            await toast({
                position: 'top',
                title: 'Error',
                description: 'แก้ไขไม่สำเร็จ',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        refetchPage()
    }

    const handleUpdatehomework = async () => {
        try {
            const { data: responseUpdateHomework } = await setHomeworkUpdateMutation({
                variables: {
                    _id: homeworkData?.homework?._id,
                    file: homework?.url,
                    filename: homework?.name,
                    status: 'submit',
                    student: user?._id,
                    submitdate: myCurrentDate,
                },
            })
            if (responseUpdateHomework) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('SendHomeworkPage.edit')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (error) {
            console.error(error)
            await toast({
                position: 'top',
                title: 'Error',
                description: 'แก้ไขไม่สำเร็จ',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        refetchPage()
    }

    //Alert dialog remove chapter
    const [showRemoveHomework, setShowRemoveHomework] = useState(false)
    const toggleRemoveButton = async () => {
        setShowRemoveHomework(!showRemoveHomework)
    }

    const handleRemovehomework = async (e) => {
        e.preventDefault()
        try {
            const { data: responseRemoveHomework } = await setHomeworkRemoveMutation({
                variables: {
                    _id: homeworkData?.homework._id,
                },
            })
            if (responseRemoveHomework) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('SendHomeworkPage.altremove')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (Error) {
            console.log(Error)
            await toast({
                position: 'top',
                title: 'Error',
                description: 'ลบข้อมูลไม่สำเร็จ',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        refetchPage()
    }

    const statusAlert = (status) => {
        if (status == 'submit') {
            return (<Alert status='info' borderWidth={'1px'} borderRadius={'md'}>
                <CheckIcon w={6} h={6} bg={'blue.400'} color={'white'} borderRadius={'full'} p={1} />
                <Text p={2}>
                    {t('SendHomeworkPage.submit')}
                </Text>
            </Alert>)
        } else if (status == 'scoresubmit') {
            return (<Alert status='success' borderWidth={'1px'} borderRadius={'md'}>
                <AlertIcon />
                <Text p={2}>
                    {t('SendHomeworkPage.submit_score')}
                </Text>
            </Alert>)

        }
        else {
            return ''
        }
    }


    const refetchPage = () => {
        setShowRemoveHomework(false)
        setHomeWork({ url: '', name: '', })
        setFilesVdo([])
        setFiles([])
        onAddclose()
        onEditclose()
        setStatusEdit(false)
        setUploadStage(0)
        refetch({
            student: user?._id,
            content: params?.contentId,
        })
    }

    return (
        <Fragment>
            {/* <Modal
                isOpen={isAddopen}
                onClose={onAddclose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{contentState?.homeworkconfig.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        {contentState?.homeworkconfig?.typefile == 'video' ? <Box>
                            <FormControl>
                                <Dropzone
                                    onChange={updateFilesVdo}
                                    value={filesVdo}
                                    accept="video"
                                    maxFiles="1"
                                    view="grid"
                                    required
                                //localization={"ES-es"}
                                //disableScroll
                                >
                                    {filesVdo.map((file) => (
                                        <FileItem
                                            {...file}
                                            key={file.id}
                                            onDelete={onDeleteVdo}
                                            //localization={"EN-en"}
                                            //resultOnTooltip
                                            preview={true}
                                        />
                                    ))}
                                </Dropzone>
                                <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                    <GridItem colSpan={8} >
                                        <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                    </GridItem>
                                    <GridItem colSpan={1} align={'center'}>
                                        <Text>{`${uploadState} %`}</Text>
                                    </GridItem>
                                </Grid>
                            </FormControl>
                        </Box> :
                            <Box>
                                <Dropzone
                                    require
                                    onChange={updateFiles}
                                    value={files}
                                    accept={contentState?.homeworkconfig?.typefile}
                                    maxFiles="1"
                                    view="grid"
                                >
                                    {files.map((file) => (
                                        <FileItem {...file} key={file.id} onDelete={onDeleteFile} preview={true} />
                                    ))}
                                </Dropzone>

                                <FormControl>
                                    <FormLabel mt={3}>
                                        {t('SendHomeworkPage.uploadname')} : {user?.firstname} {user?.lastname}
                                    </FormLabel>
                                </FormControl>
                            </Box>}

                    </ModalBody>
                    <ModalFooter>
                        {files.length > 0 ? (
                            <Box>
                                <Button mr={3} onClick={onAddclose}>
                                    {t('SendHomeworkPage.cancel')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleSendhomework(
                                            contentState?.name,
                                            user?.firstname,
                                            user?.lastname,
                                            contentState?.homeworkconfig?.typefile,
                                            files[0].file.name
                                        )
                                    }}
                                    colorScheme="blue"
                                    mr={3}
                                >
                                    {t('SendHomeworkPage.save')}
                                </Button>
                            </Box>
                        ) : (
                            <Box>
                                <Button mr={3} onClick={onAddclose}>
                                    {t('SendHomeworkPage.cancel')}
                                </Button>
                                <Button
                                    onClick={handleSendhomework}
                                    colorScheme="blue"
                                    mr={3}
                                >
                                    {t('SendHomeworkPage.save')}
                                </Button>
                            </Box>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal> */}

            {/* 
            <Modal isOpen={isEditopen} onClose={handleUpdateStatus} >
                <ModalOverlay />
                <ModalContent>
                    <form>
                        <ModalHeader>เเก้ไข {contentState?.homeworkconfig?.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <Flex justifyContent="space-between" my={2}>
                                <Link
                                    placeSelf='center'
                                    alignItems={'center'}
                                    href={`${homeworkData?.homework?.file}`}
                                    isExternal
                                    mb={1}
                                    display={'flex'}
                                >
                                    <AiOutlineFilePpt size={20} /> <Text ml={2}>{homeworkData?.homework?.filename}</Text>
                                </Link>
                                <Box>
                                    <Tooltip label={t('SendHomeworkPage.edit')} fontsize="md">
                                        <Button backgroundColor={bg} onClick={toggleEditButton} mr={1}>
                                            <FaPencilAlt />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip label={t('SendHomeworkPage.delete')} fontsize="md">
                                        <Button
                                            backgroundColor={bg}
                                            onClick={() => {
                                                handleOpenRemoveHomework(
                                                    contentState.homeworkconfig.name,
                                                    user.firstname,
                                                    user.lastname,
                                                    contentState.homeworkconfig.typefile,
                                                    homeworkData?.file,
                                                    homeworkData?._id
                                                )
                                            }}
                                        >
                                            <FaTrashAlt />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Flex>

                            {statusremove === true && (
                                <Box>
                                    <Dropzone
                                        require
                                        onChange={updateFiles}
                                        value={files}
                                        accept={contentState.homeworkconfig.typefile}
                                        maxFiles="1"
                                        view="grid"
                                    >
                                        {files.map((file) => (
                                            <FileItem
                                                {...file}
                                                key={file.id}
                                                onDelete={onDeleteFile}

                                                preview={true}
                                            />
                                        ))}
                                    </Dropzone>
                                    <ModalFooter>
                                        {files?.length > 0 ? (
                                            <Box>
                                                <Button onClick={onEditclose}>{t('SendHomeworkPage.cancel')}</Button>
                                                <Button
                                                    onClick={handleSendhomework}
                                                    bg={'primary.700'} color={'white'}
                                                    mr={3}
                                                >
                                                    {t('SendHomeworkPage.save')}
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <Button mr={3} onClick={onEditclose}>
                                                    {t('SendHomeworkPage.cancel')}
                                                </Button>
                                                <Button disabled bg={'primary.700'} color={'white'}>
                                                    {t('SendHomeworkPage.save')}
                                                </Button>
                                            </Box>
                                        )}
                                    </ModalFooter>
                                </Box>
                            )}
                            {statusEdit === true && (
                                <Box>
                                    {contentState?.homeworkconfig?.typefile == 'video' ? <Box>
                                        <FormControl>
                                            <Dropzone
                                                onChange={updateFilesVdo}
                                                value={filesVdo}
                                                accept="video"
                                                maxFiles="1"
                                                view="grid"
                                                required

                                            >
                                                {filesVdo.map((file) => (
                                                    <FileItem
                                                        {...file}
                                                        key={file.id}
                                                        onDelete={onDeleteVdo}

                                                        preview={true}
                                                    />
                                                ))}
                                            </Dropzone>
                                            <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                                <GridItem colSpan={8} >
                                                    <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                                </GridItem>
                                                <GridItem colSpan={1} align={'center'}>
                                                    <Text>{`${uploadState} %`}</Text>
                                                </GridItem>
                                            </Grid>
                                        </FormControl>
                                    </Box> :
                                        <Box>
                                            <Dropzone
                                                require
                                                onChange={updateFiles}
                                                value={files}
                                                accept={contentState.homeworkconfig.typefile}
                                                maxFiles="1"
                                                view="grid"
                                            >
                                                {files.map((file) => (
                                                    <FileItem
                                                        {...file}
                                                        key={file.id}
                                                        onDelete={onDeleteFile}

                                                        preview={true}
                                                    />
                                                ))}
                                            </Dropzone>
                                        </Box>
                                    }
                                </Box>
                            )}
                            <FormControl>
                                <FormLabel mt={3}>
                                    {t('SendHomeworkPage.uploadname')} : {user.firstname} ${user.lastname}
                                </FormLabel>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={3} onClick={onEditclose}>
                                {t('SendHomeworkPage.cancel')}
                            </Button>
                            <Button
                                disabled={!homework?.url}
                                onClick={handleUpdateStatus}
                                colorScheme="blue"
                                mr={3}
                            >
                                {t('SendHomeworkPage.save')}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal> */}


            <Box w="100%" px={['2', '5', '10', '20']}>
                <Container maxW={'8xl'} py={16} as={Stack} spacing={12}>
                    <Heading size="xl" mt={2}>
                        {contentState?.name}
                    </Heading>
                    <Box p={'4'} bg={'gray.300'} borderRadius="lg" >
                        {handleConvertHTML(contentState?.homeworkconfig?.description)}
                    </Box>

                    {contentState?.homeworkconfig.exampleFile && (
                        <Link href={`${contentState?.homeworkconfig.exampleFile}`} isExternal>
                            <Text mt={1} ml={1}>
                                {t('SendHomeworkPage.samplefile')} <ExternalLinkIcon />
                            </Text>
                        </Link>
                    )}

                    <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['none'] }} borderWidth={'1px'} borderRadius={'lg'}  >
                        <Table variant="striped" mt={3}>
                            <Thead>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.deadline')}</Td>
                                    <Td> {dayjs(contentState?.homeworkconfig?.duedate).format('DD/MM/YYYY , HH:mm:ss')}</Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.status')}</Td>
                                    <Td>
                                        {statusAlert(homeworkData?.homework?.status)}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.senddate')}</Td>
                                    <Td>
                                        {/* {console.log(homeworkData?.homework?.file)} */}
                                        <Box>
                                            {homeworkData?.homework?.file && homeworkData?.homework?.student?._id === user._id && (
                                                <Text>{dayjs(homeworkData?.homework?.submitdate).format('DD/MM/YYYY , HH:mm:ss')}</Text>
                                            )}
                                        </Box>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.file')}</Td>
                                    <Td>
                                        <Box>
                                            <Link
                                                href={`${homeworkData?.homework?.file}`}
                                                isExternal
                                                mb={1}
                                            >
                                                <Text>{homeworkData?.homework?.filename}</Text>
                                            </Link>
                                        </Box>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.score')}</Td>
                                    <Td>
                                        {scoreAddData?.scores?.map((scorehomework) => (
                                            <Box key={scorehomework?._id} > {scorehomework?.homework[0]?.score} </Box>
                                        ))}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.comment')}</Td>
                                    <Td>
                                        {scoreAddData?.scores?.map((scorehomework) => (
                                            <Box key={scorehomework?._id} bg={'gray.300'} borderRadius={'lg'} p='7'>
                                                <Box minWidth={'100vh'}>
                                                    {handleConvertHTML(scorehomework?.homework[0]?.comment)}
                                                </Box>
                                                {scorehomework?.homework[0].file &&
                                                    <Flex mt={'4'} align="center">
                                                        <Link href={`${scorehomework?.homework[0]?.file}`} isExternal>
                                                            <Button borderRadius={'2xl'} bg='red.300' size={'sm'} boxShadow={'xl'} outline='green.300'>
                                                                <AiOutlineFilePpt />
                                                                <Text>ไฟล์แนบ</Text>
                                                            </Button>
                                                        </Link>
                                                    </Flex>
                                                }
                                            </Box>
                                        ))}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>

                    <Box align="center" mt={5}>
                        {/* {console.log("this", dayjs(myCurrentDate).format('DD/MM/YYYY , HH:mm:ss'))}
                        {console.log("duedate", dayjs(contentState?.homeworkconfig?.duedate).format('DD/MM/YYYY , HH:mm:ss'))}
                        {console.log('condition', dayjs(myCurrentDate).format('DD/MM/YYYY , HH:mm:ss') < dayjs(contentState?.homeworkconfig?.duedate).format('DD/MM/YYYY , HH:mm:ss'))}
                        {console.log(dayjs(myCurrentDate).isSameOrBefore(contentState?.homeworkconfig?.duedate))} */}
                        {dayjs(myCurrentDate).isSameOrBefore(contentState?.homeworkconfig?.duedate) ? (
                            <Box>
                                {homeworkData?.homework === null ? (
                                    <Button onClick={onAddopen} alignItems="center" w={'100px'}
                                        mt={2}
                                        bg={'primary.700'}
                                        color={'white'}
                                        _hover={{
                                            transform: 'translateY(-2px)',
                                            boxShadow: 'lg',
                                        }}>
                                        {t('SendHomeworkPage.uploadfile')}
                                    </Button>
                                ) : (
                                    <Box>
                                        {homeworkData?.homework?.file ? (
                                            <Button onClick={onEditopen} alignItems="center" bg={'primary.700'}
                                                color={'white'}>
                                                {t('SendHomeworkPage.editfile')}
                                            </Button>
                                        ) : (
                                            <Button onClick={onAddopen} alignItems="center" bg={'primary.700'}
                                                color={'white'}>
                                                {t('SendHomeworkPage.uploadfile')}
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box>
                                {Statusbutton === false && homeworkData?.homework == null ? (
                                    <Button disabled onClick={onAddopen} alignItems="center">
                                        {t('SendHomeworkPage.uploadfile')}
                                    </Button>
                                ) : (
                                    <Box>
                                        {homeworkData?.homework?.file ? (
                                            <Button onClick={onEditopen} alignItems="center" bg={'primary.700'}
                                                color={'white'}>
                                                {t('SendHomeworkPage.editfile')}
                                            </Button>
                                        ) : (
                                            <Button onClick={onAddopen} alignItems="center" bg={'primary.700'}
                                                color={'white'}>
                                                {t('SendHomeworkPage.uploadfile')}
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                </Container>
            </Box>

            <AlertDialogHomework
                showRemoveHomework={showRemoveHomework}
                toggleRemoveButton={toggleRemoveButton}
                handleRemovehomework={handleRemovehomework}
            />

            <DialogCreateHomwork
                typeSendFile={Content?.content?.homeworkconfig?.typefile==='video'?'mp4':Content?.content?.homeworkconfig?.typefile}
                isAddopen={isAddopen}
                contentState={Content?.content}
                setHomeWork={setHomeWork}
                homework={homework}
                onAddclose={onAddclose}
                handleSendhomework={handleSendhomework}
                user={`${user.firstname} ${user.lastname}`}
                uploadState={uploadState}
                onDeleteFile={onDeleteFile}
                updateFiles={updateFiles}
                updateFilesVdo={updateFilesVdo}
                files={files}
                filesVdo={filesVdo}
                onDeleteVdo={onDeleteVdo}
            />

            <DialogUpdateHomework
                typeSendFile={Content?.content?.homeworkconfig?.typefile==='video'?'mp4':Content?.content?.homeworkconfig?.typefile}
                handleUpdatehomework={handleUpdatehomework}
                isEditopen={isEditopen}
                contentState={Content?.content}
                statusEdit={statusEdit}
                setHomeWork={setHomeWork}
                homework={homework}
                onAddclose={onAddclose}
                handleUpdateStatus={handleUpdateStatus}
                homeworkData={homeworkData}
                user={`${user.firstname} ${user.lastname}`}
                uploadState={uploadState}
                onDeleteFile={onDeleteFile}
                updateFiles={updateFiles}
                updateFilesVdo={updateFilesVdo}
                files={files}
                statusremove={statusremove}
                toggleEditButton={toggleEditButton}
                filesVdo={filesVdo}
                toggleRemoveButton={toggleRemoveButton}
                onDeleteVdo={onDeleteVdo} />

        </Fragment>
    )
}
export default SendHomeworkPage

import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import {
    Grid,
    GridItem,
    Box,
    Heading,
    Text,
    Container,
    Link,
    Button,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Center,
    Flex,
    // useColorModeValue,
    useToast,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react';

import { Dropzone, FileItem } from '@dropzone-ui/react'
import { useQuery, useMutation } from '@apollo/client'
import { useMultiState } from "../lib/hooks";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import ScoreMutation from "../graphql/mutations/createScoreHomework";
import ScoreHomeworkRemoveMutation from "../graphql/mutations/removeScoreHomework";
import ScoreHomeworkUpdateMutation from "../graphql/mutations/UpdateScoreHomework";
import { useParams } from 'react-router';
import renderHTML from 'react-render-html'
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { AiOutlineFilePpt } from 'react-icons/ai'
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'

import dayjs from 'dayjs';

import ContentbyIdQueries from "../graphql/queries/contentbyId"
import HomeworkbyIdQueries from "../graphql/queries/homeworkbyID"
import scorebystudentQueries from "../graphql/queries/scorebystudent"
import progressbyId from '../graphql/queries/progressbyId'
import progressUpdate from '../graphql/mutations/createProgress'
import removeProgress from '../graphql/mutations/removeProgress'

require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
const URLFile = process.env.REACT_APP_UPLOAD_FILE
const URLHomework = process.env.REACT_APP_UPLOAD_HOMEWORK

const ManageHomeworkPage = () => {
    const { isOpen: isAddopen, onOpen: onAddopen, onClose: onAddclose } = useDisclosure()
    const { isOpen: isEditopen, onOpen: onEditopen, onClose: onEditclose } = useDisclosure()

    // const bg = useColorModeValue('white', 'gray.800')
    const toast = useToast()
    const { t } = useTranslation()
    const history = useHistory()
    const { user } = useContext(AuthContext)

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [updateEditorState, setUpdateEditorState] = useState(EditorState.createEmpty());

    const [setScoreMutation] = useMutation(ScoreMutation);

    const [setUpdateProgress] = useMutation(progressUpdate)
    const [setRemoveProgress] = useMutation(removeProgress)


    const [setScoreHomeworkRemoveMutation] = useMutation(ScoreHomeworkRemoveMutation);
    const [setScoreHomeworkUpdateMutation] = useMutation(ScoreHomeworkUpdateMutation);
    const params = useParams();


    const initialScore = {
        score: "",
    };
    const [scoreData, setScoreData] = useMultiState(initialScore);

    const [updateScoreData, setupdateScoreData] = useMultiState(initialScore)

    const [filesDoc, setFilesDoc] = useState([])

    const onDeleteDoc = (id) => {
        setFilesDoc(filesDoc.filter((x) => x.id !== id))
    }
    const [newDocumentData, setnewDocumentData] = useMultiState({
        data: null,
        base64: '',
    })

    const handleUpdateForum = async (e) => {
        onEditopen(true)
        setUpdateEditorState(EditorState.createWithContent(convertFromRaw({ blocks: scoreAddData?.scores[0]?.homework[0]?.comment?.blocks, entityMap: scoreAddData?.scores[0]?.homework[0]?.comment?.entityMap })))

    }



    const updateFilesDoc = (incommingFiles, e) => {
        if (incommingFiles.length !== 0) {
            new Promise((resolve, reject) => {

                const regex = /[^.]+$/g;
                const typefile = incommingFiles[0]?.file.name.match(regex)
                setFilesDoc(incommingFiles)
                setnewDocumentData({ data: incommingFiles[0]?.file })
                const reader = new FileReader()
                reader.readAsDataURL(incommingFiles[0]?.file)
                reader.onload = () => {
                    resolve(reader.result)
                    setnewDocumentData({
                        base64: reader.result,
                        type: typefile
                    })
                }
                reader.onerror = (error) => reject(error)
            })
        }
    }



    const { data: contentData } = useQuery(ContentbyIdQueries, {
        variables: {
            _id: params?.contentId,
        },
    })

    const { data: homeworkData } = useQuery(HomeworkbyIdQueries, {
        variables: {
            content: params?.contentId,
            student: params?.studentId,
        },
    })


    const { data: scoreAddData, refetch, loading } = useQuery(scorebystudentQueries, {
        variables: {
            student: params?.studentId,
            id: params?.contentId
        },
    })

    const { data: userprogress } = useQuery(progressbyId, {
        variables: {
            content: params?.contentId,
            student: params?.studentId,
        },
    })

    console.log(userprogress?.progress?._id)

    useEffect(() => {
        if (user?.role == 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
        if (loading) {
            refetch({
                teacher: user?._id,
            })
        }
    }, [history, user, refetch])



    const handleAddScore = async (e) => {
        e.preventDefault();
        const responseDocument = await axios.post(URLFile, {
            file: newDocumentData?.base64,
            type: newDocumentData?.type
        })
        const editordata = convertToRaw(editorState.getCurrentContent())
        const { data: scoreAddData } = await setScoreMutation({
            variables: {
                subject: params?.subjectId,
                student: params?.studentId,
                id: params?.contentId,
                name: contentData?.content?.name,
                score: parseFloat(scoreData.score),
                file: responseDocument ? responseDocument?.data?.url : "",
                comment: editordata,
            },
        });

        if (!userprogress?.progress) {
            const { data: ProgressData } = await setUpdateProgress({
                variables: {
                    content: params?.contentId,
                    student: params?.studentId,
                    progress: 100,
                    path: window.location.href
                }
            })
            if (ProgressData) {
                console.log(ProgressData)
            }
        }
        if (scoreAddData) {
            await toast({
                position: 'top',
                title: 'Success',
                description: `${t('managehomeworkPage.toastAddSuccessScore')}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            refetch({
                student: params?.studentId,
                id: params?.contentId
            })
            setScoreData(initialScore);
            setFilesDoc([])
            onAddclose()
        }
    };



    //Alert dialog remove Score

    const [showRemoveScore, setShowRemoveScore] = useState(false)
    const [removeScoreIndex, setRemoveScoreIndex] = useState('')

    const handleOpenRemoveHomework = async (id_score) => {
        setRemoveScoreIndex({ id: id_score })
        setShowRemoveScore(true)
    }

    const handleCloseRemoveScore = () => {
        setShowRemoveScore(false)
    }

    const handleRemoveScore = async (e) => {
        e.preventDefault()
        try {
            const { data: removeScore } = await setScoreHomeworkRemoveMutation({
                variables: {
                    _id: removeScoreIndex?.id
                },
            })
            if (removeScore) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('managehomeworkPage.toastAddSuccessRemove')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            }
            if (userprogress?.progress?._id) {
                const { data: removeProgress } = await setRemoveProgress({
                    variables: {
                        _id: userprogress?.progress?._id
                    },
                })
                if (removeProgress) {
                    await toast({
                        position: 'top',
                        title: 'Success',
                        description: `${t('managehomeworkPage.toastAddSuccessRemove')}`,
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                    refetch({
                        student: params?.studentId,
                        id: params?.contentId
                    })
                    handleCloseRemoveScore()
                    setRemoveScoreIndex('')
                }
            }
        } catch (error) {
            alert(`${error}`)
            console.log(error)
        }
    };

    const handleUpdateScorehomework = async (id_score) => {
        const responseDocument = await axios.post(URLFile, {
            file: newDocumentData?.base64,
            type: newDocumentData?.type
        })
        const editordata = convertToRaw(updateEditorState.getCurrentContent())
        try {
            const { data: scoreAddData } = await setScoreHomeworkUpdateMutation({
                variables: {
                    _id: id_score,
                    contentid: params?.contentId,
                    name: contentData?.content?.name,
                    score: parseFloat(updateScoreData.homework[0]?.score),
                    file: responseDocument ? responseDocument?.data?.url : scoreAddData?.scores[0]?.homework[0].file,
                    comment: editordata,
                },
            });
            if (scoreAddData) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('managehomeworkPage.toastEodtSuccessScore')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    student: params?.studentId,
                    id: params?.contentId
                })
                setScoreData(initialScore);
                onEditclose()
                setFilesDoc([])
            }
        } catch (Error) {
            console.log("in Create submit", Error);
        }
    }

    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }

    return (
        <Fragment>
            <Box w="100%" px={['2', '10', '20', '20']}>
                <Container maxW={'8xl'} py={16} as={Stack} spacing={12}>

                    <Heading size="xl" mt={2} > {contentData?.content?.name}  </Heading>
                    <Box p={'4'} bg={'gray.300'} borderRadius="lg" >
                        {handleConvertHTML(contentData?.content?.homeworkconfig?.description)}
                    </Box>
                    {contentData?.homeworkconfig?.exampleFile &&
                        <Link href={`${contentData?.homeworkconfig.exampleFile}`} isExternal >
                            <Text mt={1} ml={1}>{t('managehomeworkPage.exampleFile')} < ExternalLinkIcon /> </Text>
                        </Link>
                    }
                    {homeworkData?.homeworks?.map((homework) => (
                        <Box key={homework?._id}>
                            <Table variant='striped' mt={3} >
                                <Thead>
                                    <Tr>
                                        <Td>{t('managehomeworkPage.duedate')} </Td>
                                        <Td> {dayjs(contentData?.homeworkconfig?.duedate).format("วันที่ DD/MM/YYYY , เวลา HH:mm:ss")}</Td>

                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>{t('managehomeworkPage.status')}</Td>
                                        <Td> <Text> {homework?.status} </Text>  </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t('managehomeworkPage.submit')}</Td>
                                        <Td>
                                            {homework?.file &&
                                                <Text>
                                                    {dayjs(homework.submitdate).format("วันที่ DD/MM/YYYY , เวลา HH:mm:ss")}
                                                </Text>
                                            }
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t('managehomeworkPage.file')}</Td>
                                        <Td>

                                            <Link href={`${homework?.file}`} isExternal mb={1} >
                                                <Text>{homework?.filename} </Text>
                                            </Link>
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td>{t('managehomeworkPage.score')}</Td>
                                        <Td>
                                            {scoreAddData?.scores?.map((scorehomework) => (
                                                <Box key={scorehomework?._id}>
                                                    {scorehomework?.homework[0]?.score &&
                                                        <Flex justifyContent='space-between'>
                                                            <Center >
                                                                <Text>{scorehomework?.homework[0]?.score}</Text>

                                                            </Center>
                                                        </Flex>}
                                                </Box>
                                            ))}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t('managehomeworkPage.comment')}</Td>
                                        <Td>
                                            {scoreAddData?.scores?.map((scorehomework) => (
                                                <Box key={scorehomework?._id} bg={'gray.300'} borderRadius={'lg'} p='7'>
                                                    <Box minWidth={'100vh'}>
                                                        {handleConvertHTML(scorehomework?.homework[0]?.comment)}
                                                    </Box>
                                                    {scorehomework?.homework[0].file &&
                                                        <Flex mt={'4'} align="center">
                                                            <Link href={`${scorehomework?.homework[0]?.file}`} isExternal>
                                                                <Button borderRadius={'2xl'} bg='red.300' size={'sm'} boxShadow={'xl'} outline='green.300'>
                                                                    <AiOutlineFilePpt />
                                                                    <Text>file</Text>
                                                                </Button>
                                                            </Link>
                                                        </Flex>
                                                    }
                                                </Box>
                                            ))}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>

                            {scoreAddData?.scores?.length === 0 ?
                                <Box align="center" mt={5}>
                                    <Button bg={'primary.700'}
                                        color={'white'}
                                        _hover={{
                                            bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                                        }}
                                        onClick={onAddopen}
                                        alignItems="center"
                                    >{t('managehomeworkPage.EnterScoreandComment')}</Button>
                                </Box>
                                :
                                <Box>
                                    {scoreAddData?.scores?.map((scorehomework, i) => (
                                        <Box align="center" mt={5} key={scorehomework?._id}>
                                            {scorehomework?.homework[0]?.score ?
                                                <Center>
                                                    <Flex>
                                                        <Button
                                                            bg={'primary.700'}
                                                            mr={2}
                                                            color={'white'}
                                                            _hover={{
                                                                bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                handleUpdateForum(i)
                                                                setupdateScoreData(scoreAddData?.scores[0])
                                                            }}
                                                            alignItems="center"> {t('managehomeworkPage.editscoreButton')}</Button>
                                                        <Button
                                                            bg={'primary.700'}
                                                            color={'white'}
                                                            _hover={{
                                                                bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                                                            }}
                                                            alignItems="center"
                                                            onClick={() => { handleOpenRemoveHomework(scoreAddData?.scores[0]?._id) }}>
                                                            {t('managehomeworkPage.deleteButton')}
                                                        </Button>

                                                    </Flex>
                                                </Center>
                                                : <Button
                                                    bg={'primary.700'}
                                                    color={'white'}
                                                    _hover={{
                                                        bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                                                    }}
                                                    onClick={onAddopen} alignItems="center">{t('managehomeworkPage.EnterScoreandComment')}</Button>
                                            }
                                        </Box>
                                    ))}
                                </Box>
                            }
                        </Box>
                    ))}



                    <AlertDialog isOpen={showRemoveScore} onClose={handleCloseRemoveScore}>
                        <AlertDialogOverlay>
                            <form onSubmit={handleRemoveScore} as={GridItem} colSpan={[6, 3]}>
                                <AlertDialogContent>
                                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                        {t('managehomeworkPage.deletescore')}
                                    </AlertDialogHeader>
                                    <AlertDialogBody> {t('managehomeworkPage.youDelete')}</AlertDialogBody>
                                    <AlertDialogFooter>
                                        <Button onClick={handleCloseRemoveScore}> {t('managehomeworkPage.closeButton')}</Button>
                                        <Button bg={'primary.700'}
                                            color={'white'}
                                            _hover={{
                                                bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                                            }} type="submit" ml={3}>
                                            {t('managehomeworkPage.removeButton')}
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </form>
                        </AlertDialogOverlay>
                    </AlertDialog>


                    <Modal isOpen={isAddopen} onClose={onAddclose} size={'6xl'} >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader> {contentData?.content?.name} </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                                {homeworkData?.homeworks?.map((homework) => (
                                    <Box key={homework?._id}>
                                        <Text> {t('managehomeworkPage.name')} : {homework?.student?.firstname} {homework?.student?.lastname}  </Text>
                                        <Flex>
                                            <Text> {t('managehomeworkPage.file')} : </Text>
                                            <Link href={`${homework?.file}`} isExternal mb={1} >
                                                <Text> {homework?.filename} </Text>
                                            </Link>
                                        </Flex>
                                        <Grid
                                            templateRows='repeat(2, 1fr)'
                                            templateColumns='repeat(5, 1fr)'
                                            gap={4}
                                        >
                                            <GridItem rowSpan={2} colSpan={3} >
                                                <FormControl >
                                                    <FormLabel>{t('managehomeworkPage.placeholderscore')} </FormLabel>
                                                    <Input type="number"
                                                        placeholder={t('managehomeworkPage.placeholderscore')}
                                                        value={scoreData?.score}
                                                        onChange={(e) => setScoreData({ score: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl mb={3} mr={5} >
                                                    <FormLabel>{t('managehomeworkPage.comment')} </FormLabel>
                                                    <Box minHeight={'36vh'} border="1px solid">
                                                        <Editor
                                                            wrapperClassName="demo-wrapper"
                                                            editorClassName="demo-editor"
                                                            editorState={editorState}
                                                            onEditorStateChange={setEditorState}
                                                            toolbar={{
                                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                            }}
                                                        />
                                                    </Box>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem colSpan={2} rowSpan={2}  >
                                                <FormLabel>{t('managehomeworkPage.files')} </FormLabel>
                                                <Dropzone
                                                    onChange={updateFilesDoc}
                                                    value={filesDoc}
                                                    maxFiles="1"
                                                    view="grid"
                                                    accept="application/pdf"
                                                >
                                                    {filesDoc.map((file, i) => (
                                                        <FileItem
                                                            {...file}
                                                            key={i}
                                                            onDelete={onDeleteDoc}
                                                            //localization={"EN-en"}
                                                            //resultOnTooltip
                                                            preview={true}
                                                        />
                                                    ))}
                                                </Dropzone>
                                            </GridItem>
                                        </Grid>
                                    </Box>
                                ))}
                            </ModalBody>
                            <ModalFooter>
                                <Button mr={3} onClick={onAddclose}>{t('managehomeworkPage.closeButton')}</Button>
                                <Button onClick={handleAddScore} bg={'primary.700'}
                                    color={'white'}
                                    _hover={{
                                        bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                                    }} >
                                    {t('managehomeworkPage.saveButton')}
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <Modal isOpen={isEditopen} onClose={onEditclose} size={'6xl'} >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader> {t('managehomeworkPage.editscoreButton')}  {contentData?.content?.name} </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                                {homeworkData?.homeworks?.map((homework) => (
                                    <Box key={homework?._id}>
                                        <Text> {t('managehomeworkPage.name')} : {homework?.student?.firstname} {homework?.student?.lastname}  </Text>
                                        <Flex>
                                            <Text> {t('managehomeworkPage.file')} : </Text>
                                            <Link href={`${homework?.file}`} isExternal mb={1} >
                                                <Text> {homework?.filename} </Text>
                                            </Link>
                                        </Flex>
                                        {updateScoreData?.homework?.map((scorehomework) => (
                                            <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(5, 1fr)' gap={4} >
                                                <GridItem rowSpan={2} colSpan={3} >
                                                    <FormControl mb={3} mr={5} >
                                                        <FormLabel>{t('managehomeworkPage.placeholderscore')}</FormLabel>
                                                        <Input type="number"
                                                            placeholder={t('managehomeworkPage.placeholderscore')}
                                                            value={updateScoreData?.homework[0]?.score}
                                                            onChange={(e) => setupdateScoreData({
                                                                homework: [{
                                                                    score: e.target.value,
                                                                    contentid: scorehomework?.contentid,
                                                                    name: scorehomework?.name,
                                                                    _id: scorehomework?._id,
                                                                }]
                                                            })}
                                                        />
                                                        <FormLabel>{t('managehomeworkPage.comment')} </FormLabel>
                                                        <Box minHeight={'38vh'} border="1px solid">
                                                            <Editor
                                                                // editorStyle={{ border: "1px solid" }}
                                                                wrapperClassName="demo-wrapper"
                                                                editorClassName="demo-editor"
                                                                editorState={updateEditorState}
                                                                onEditorStateChange={setUpdateEditorState}
                                                                toolbar={{
                                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                                }}
                                                            />
                                                        </Box>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={2} rowSpan={2}  >
                                                    <Box w='lg'>
                                                        {scoreAddData?.scores?.map((scorehomework) => (
                                                            <Box key={scorehomework?._id}>
                                                                {scorehomework?.homework[0]?.file ?
                                                                    <Flex>
                                                                        <Text> {t('managehomeworkPage.fileold')}  : </Text>
                                                                        <Link
                                                                            href={`${scorehomework?.homework[0]?.file}`}
                                                                            isExternal
                                                                            mb={1}
                                                                        >
                                                                            <Text ml={2}>{t('managehomeworkPage.files')} </Text>
                                                                        </Link>
                                                                    </Flex>
                                                                    : null
                                                                }
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                    <Dropzone
                                                        onChange={updateFilesDoc}
                                                        value={filesDoc}
                                                        maxFiles="1"
                                                        view="grid"
                                                        accept="application/pdf"

                                                    //localization={"ES-es"}
                                                    //disableScroll
                                                    >
                                                        {filesDoc.map((file, i) => (
                                                            <FileItem
                                                                {...file}
                                                                key={i}
                                                                onDelete={onDeleteDoc}
                                                                //localization={"EN-en"}
                                                                //resultOnTooltip
                                                                preview={true}
                                                            />
                                                        ))}
                                                    </Dropzone>
                                                </GridItem>
                                            </Grid>
                                        ))}
                                    </Box>
                                ))}
                            </ModalBody>
                            <ModalFooter>
                                <Button mr={3} onClick={onEditclose}>{t('managehomeworkPage.closeButton')}</Button>
                                {scoreAddData?.scores?.map((scorehomework) => (
                                    <Box key={scorehomework?._id}>
                                        <Button
                                            onClick={() => { handleUpdateScorehomework(scoreAddData?.scores[0]?._id) }}
                                            bg={'primary.700'}
                                            color={'white'}
                                            _hover={{
                                                bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                                            }} >
                                            {t('managehomeworkPage.saveButton')}
                                        </Button>
                                    </Box>
                                ))}
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Container>
            </Box>
            <Box>
            </Box>
        </Fragment>
    );


}
export default ManageHomeworkPage;
import { Fragment } from "react";
import { Stack, Heading, Image, Text, useColorModeValue, } from "@chakra-ui/react";

export const PromptPay = (props) => {
    return (

        <Fragment>
            <Stack align={'center'} spacing={2} >
                <Heading
                    textTransform={'uppercase'}
                    fontSize={'2xl'}
                    color={useColorModeValue('gray.800', 'gray.200')}>
                    กรุณาสแกน QR Code ผ่าน Application เพื่อชำระเงิน
                </Heading>
                <Image w={'300px'} src="./images/logo_prompt.png" alt="Segun Adebayo" />
                {/* <Image w={'80px'} src="./images/promptpay_logo.png" alt="Segun Adebayo" /> */}
                <Image
                    mt={'-4px'}
                    borderLeft='1px'
                    borderLeftColor='green.500'
                    borderRight='1px'
                    borderRightColor='green.500'
                    borderBottom='1px'
                    borderBottomColor='green.500'
                    boxSize={'300px'} src="./images/1920600234754.svg"
                    alt="Segun "
                />

                <Text mt={10} align={'center'} fontSize={'40px'} >
                    ราคา 249 บาท
                </Text>
                <Text mt={10} align={'center'} fontSize={'lg'} color={'gray.500'}>
                    บริษัท สตรีมเซาท์เทคโนโลยี จำกัด
                </Text>
            </Stack>
        </Fragment>
    )
};
export default PromptPay
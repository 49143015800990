import React, { useEffect } from "react";
import {
    Box,
    Heading,
    Text,
    Avatar,
    useColorModeValue,
    Button,
    GridItem,
    VStack,
    SimpleGrid,
} from '@chakra-ui/react';
import { Link as Routelink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const ItemList = ({ teacherState, curPage, itemLimit, curItems, setCurItems }) => {


    const { t } = useTranslation()

    useEffect(() => {
        const offset = curPage * itemLimit;
        const getList = (curPage, itemLimit) => {
            setCurItems(teacherState.slice(offset, offset + itemLimit));
        };
        getList(curPage, itemLimit);
    }, [curPage, itemLimit, teacherState]);


    return (
        <SimpleGrid
            columns={{
                base: 1,
                sm: 2,
                md: 2,
                lg: 4,
            }}
            spacingX={{
                base: 5,
                lg: 10,
            }}
            w={'full'}
            minH={'60vh'}
        >
            {curItems?.map((row) => (
                <GridItem key={row?._id} w='100%' mt={5} >
                    <Box
                        p={2}
                        borderRadius={'lg'}
                        borderWidth={'2px'}
                        boxShadow={'2xl'}
                        marginTop={{ base: '1', sm: '1' }}
                        display="flex"
                        flexDirection={{ base: 'row', sm: 'column', lg: 'column', }}
                        alignItems="center"
                    >
                        <Avatar
                            size={'xl'}
                            src={row.avatar ? row.avatar : '../images/avatar.png'}
                            alt={'Avatar Alt'}
                            mb={4}
                            pos={'relative'}
                        />
                        <VStack
                            alignItems={'center'}

                            display="flex"
                            spacing={1}
                            minH={'150px'}
                            position={'relative'}
                            w={'full'}
                            p={'2'}
                            h='full'
                        >
                            <Heading fontSize={'15px'} fontFamily={'body'} >
                                {row?.firstname}  {row?.lastname}
                            </Heading>
                            <Text fontWeight={600} color={'gray.500'} mb={4} flex={"1"} >
                                {row?.email}
                            </Text>
                            <Button
                                w={'100px'}
                                mt={2}
                                bg={'primary.700'}
                                color={'white'}
                                rounded={'md'}
                                _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}
                                as={Routelink}
                                to={`/showprofile/${row._id}`}>
                                {t('itemlist.profile')}
                            </Button>
                        </VStack>
                    </Box>
                </GridItem>
            ))
            }

        </SimpleGrid >
    );
}

export default ItemList;

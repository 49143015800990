import gql from 'graphql-tag'

export default gql`
	query {
		listzooms {
			_id
			name
			userId
			token
		}
	}
`

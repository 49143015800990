import React, { Fragment, useState, useEffect, useContext } from "react";

import * as Survey from "survey-react";
import './style.css'
import {
    Box,
    Container,
    Stack,
    Text,
    Heading,
    Button,
    Table,
    Tr,
    Td,
    Th,
    Flex,
    Tooltip,
    useToast,
    GridItem,
    IconButton,
    useDisclosure,
    Grid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Tbody,
    Thead,
    ModalHeader,
    Modal,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    ModalContent,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Center,
    Badge,
} from "@chakra-ui/react";
import { useMultiState } from "../lib/hooks";
import draftToHtml from 'draftjs-to-html'
import renderHTML from 'react-render-html'
import { FaTrashAlt } from "react-icons/fa";
import { ViewIcon, RepeatIcon } from '@chakra-ui/icons'
import removeSurveyUser from '../graphql/mutations/removeSurveyUser'
import { useParams } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'

import surveybyContentId from "../graphql/queries/surveybyContentId"
import { useTranslation } from 'react-i18next'

const SurveyDetail = () => {

    const initialSurvey = {
        name: "",
        description: "",
        respondents: "",
        questions: ""
    }

    const initialConcludeSurvey = {
        header: [],
        // choice: [],
        // respondents: "",
        // questions: ""
    }

    const { t } = useTranslation()
    const toast = useToast();
    const param = useParams();
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const { isOpen: ModalRemove, onOpen: ModalRemoveOpen, onClose: ModalRemoveClose } = useDisclosure()
    const { isOpen: ModalPreview, onOpen: ModalPreviewOpen, onClose: ModalPreviewClose } = useDisclosure()
    const [survey, setServey] = useState("")
    const [previewSurvey, setPreviewServey] = useState("")
    const [surveyDetail, setSurveyDetail] = useMultiState(initialSurvey)

    const [setRemoveSurveyUser] = useMutation(removeSurveyUser)
    const { data: SurveyUser, refetch } = useQuery(surveybyContentId, {
        variables: {
            _id: param?.contentId,
        },
    });

    useEffect(() => {
        if (user?.role == 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
        if (SurveyUser) {
            setSurveyDetail({ name: SurveyUser?.content?.name, description: SurveyUser?.content?.surveyconfig?.description, questions: SurveyUser?.content?.surveyconfig?.surveyQuestions?.questions?.length, respondents: SurveyUser?.content?.surveys?.length })
        }
    }, [history, user, refetch, SurveyUser])

    const handleRemoveSurvey = async (e) => {
        e.preventDefault();
        try {
            // console.log(survey?._id,)
            const { data: surveyData } = await setRemoveSurveyUser({
                variables: {
                    _id: survey?._id,
                },
            })
            // console.log(removehomework)
            if (surveyData) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('homeworkDetail.alertDeleteComplete')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    _id: param?.contentId,
                })
            }
            ModalRemoveClose()
        } catch (Err) {
            // console.log(Err);
            // alert(`${Err?.graphQLErrors[0]?.message}`);
        }
    };


    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }

    // console.log(SurveyUser?.content?.surveyconfig?.surveyQuestions?.questions.filter((e => e.type !== 'text')).map((row) => (row)))
    // console.log(SurveyUser?.content?.surveys)
    const freqCount = (type, name, data, option = []) => {
        var ArrCount = []
        data.map((student) => {
            if (type === 'checkbox') {
                // console.log('checkbox', student?.surveyAnswers[name]);
                var occurrencesCheck = student?.surveyAnswers[name].reduce(function (obj, item) {
                    obj[item] = (obj[item] || 0) + 1;
                    return obj;
                }, {});
                ArrCount.push(occurrencesCheck)
            } else if (type === 'radiogroup') {
                // console.log('radiogroup', [student.surveyAnswers[name]]);

                var occurrencesRadio = [student.surveyAnswers[name]].reduce(function (obj, item) {
                    obj[item] = (obj[item] || 0) + 1;
                    return obj;
                }, {});
                ArrCount.push(occurrencesRadio)
            } else if (type === "matrix") {
                Object.keys(student.surveyAnswers[name]).map((key, index) => {
                    var occurrencesMatrix = [student.surveyAnswers[name][key]].reduce(function (obj, item) {
                        obj[`${item}${option[index]?.value}`] = (obj[`${item}${option[index]?.value}`] || 0) + 1;
                        return obj;
                    }, {});
                    console.log('log', occurrencesMatrix);
                    ArrCount.push(occurrencesMatrix)
                    return null
                })
            }
            return null;
        })
        // console.log('ArrCount', ArrCount);
        return ArrCount

    }
    const showType = (type) => {
        switch (type) {
            case 'matrix':
                return (<Badge colorScheme='green'>matrix</Badge>)
            case 'radiogroup':
                return (<Badge colorScheme='red'>radiogroup</Badge>)
            case 'checkbox':
                return (<Badge colorScheme='purple'>checkbox</Badge>)
            default:
                break;
        }
    }
    // console.log(SurveyUser?.content?.surveyconfig?.surveyQuestions?.questions.filter((e => e.type !== 'text')).map((row, index) => {
    //     let counter = {}
    //     const Arr = freqCount(row?.type, row?.name, SurveyUser?.content?.surveys)
    //     console.log('Arr', Arr);
    //     Arr.forEach(function (obj) {
    //         Object.keys(obj).map((k) => {
    //             counter[k] = (counter[k] || 0) + obj[k]
    //             return null
    //         })

    //     })
    //     return counter
    //     // row?.choices?.map((row, index) => {
    //     // })
    // }));
    return (
        <Fragment>
            <Box mt={10} w="100%" >
                <Container maxW={"7xl"} as={Stack} spacing={2}>
                    <Stack spacing={0} align={"center"}>
                        <Heading variant="h4" py={5}>{t('SurveyDetailPage.title')}</Heading>
                        <FormControl>
                            <FormLabel htmlFor='country' py={2}>{t('SurveyDetailPage.name')}</FormLabel>
                            <Input isReadOnly variant={"outline"} py={2} value={surveyDetail?.name} />
                            <FormLabel htmlFor='country' py={2}>{t('SurveyDetailPage.description')}</FormLabel>
                            <Text py={2} p={2} borderWidth='2px' borderRadius='lg' overflow='hidden' >{handleConvertHTML(surveyDetail?.description)}</Text>
                            <HStack py={2}>
                                {/* <InputGroup>
                                    <InputLeftAddon children='จำนวนข้อ' size={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} />
                                    <Input type='tel' isReadOnly value={surveyDetail?.questions} fontSize={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='ตอบกลับแบบประเมิน' />
                                    <Input type='tel' isReadOnly value={surveyDetail?.respondents} fontSize={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} />
                                </InputGroup> */}
                            </HStack>
                        </FormControl>
                    </Stack>

                    <Tabs isFitted variant='enclosed' py={10} align={"center"} width={'100%'} height={'100%'} >
                        <TabList mb='1em'>
                            <Tab>{t('SurveyDetailPage.detail')}</Tab>
                            <Tab>{t('SurveyDetailPage.surveylist')}</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Grid gap={2}>
                                    <GridItem>
                                        <Box border="10px solid" borderColor="gray.100" borderRadius="md" sx={{ overflowX: ['auto', 'auto', 'auto'], maxW: ['350px', '1200px'] }}>
                                            <Table>
                                                <Thead >
                                                    <Tr>
                                                        <Th boxSize={'120px'} >{t('SurveyDetailPage.no')}</Th>
                                                        <Th color={"#15616"}>{t('SurveyDetailPage.question')}</Th>
                                                        <Th color={"#15616"}>{t('SurveyDetailPage.type')}</Th>
                                                        <Th color={"#15616"}><Center>{t('SurveyDetailPage.data')}</Center></Th>
                                                        {/* <Th color={"#15616"}>No. of Vote</Th>
                                                    <Th color={"#15616"}>%</Th> */}

                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {SurveyUser?.content?.surveyconfig?.surveyQuestions?.questions.filter((e => e.type !== 'text')).map((row, index) => {
                                                        let counterFreq = {}
                                                        const Arr = freqCount(row?.type, row?.name, SurveyUser?.content?.surveys, row?.rows)
                                                        // console.log('Arr', Arr);
                                                        Arr.forEach(function (obj) {
                                                            Object.keys(obj).map((k) => {
                                                                counterFreq[k] = (counterFreq[k] || 0) + obj[k]
                                                                return null
                                                            })
                                                        })
                                                        console.log('Arr', counterFreq);
                                                        return (
                                                            <Tr key={index} >
                                                                <Td>{index + 1}</Td>
                                                                <Td sx={{ width: '300px', wordBreak: 'break-all' }}>{row?.title}</Td>
                                                                <Td>{showType(row?.type)}</Td>
                                                                {row?.type === 'matrix' ? (<Td>
                                                                    <Table variant='simple' >
                                                                        <Thead>
                                                                            <Tr>
                                                                                <Th>{t('SurveyDetailPage.summary_answers')}</Th>
                                                                                <Th>{t('SurveyDetailPage.no_of_vote')}</Th>                                                                                <Th ></Th>
                                                                            </Tr>
                                                                        </Thead>
                                                                        <Tbody>
                                                                            <Tr>
                                                                                <Td>
                                                                                    <Table variant='simple'>
                                                                                        <Thead>
                                                                                            <Tr>
                                                                                                <Th><Center></Center></Th>
                                                                                            </Tr>
                                                                                        </Thead>
                                                                                        <Tbody>
                                                                                            <Tr><Center>
                                                                                                <Td>
                                                                                                    {row?.rows?.map((row, index) => {
                                                                                                        return (
                                                                                                            <Tr>
                                                                                                                <Td>{row?.text}</Td>
                                                                                                            </Tr>
                                                                                                        )
                                                                                                    })}
                                                                                                </Td>
                                                                                            </Center>
                                                                                            </Tr>
                                                                                        </Tbody>
                                                                                    </Table>
                                                                                    {/* {row?.rows?.map((row, index) => {
                                                                                    return (
                                                                                        <Tbody>
                                                                                            <Tr key={index} >
                                                                                                <Td>{row?.text}</Td>
                                                                                            </Tr>
                                                                                        </Tbody>
                                                                                    )
                                                                                })} */}
                                                                                </Td>
                                                                                <Td>
                                                                                    <Table variant='simple'>
                                                                                        <Thead>
                                                                                            <Tr>
                                                                                                {row?.columns?.map((columnMatrix, indexMatrix) => {
                                                                                                    return (<Th><Center>{columnMatrix?.text}</Center></Th>
                                                                                                    )
                                                                                                })}
                                                                                            </Tr>
                                                                                        </Thead>
                                                                                        <Tbody>
                                                                                            <Tr>
                                                                                                {row?.columns?.map((columnMatrix, indexMatrix) => {
                                                                                                    return (
                                                                                                        <Td>
                                                                                                            {row?.rows?.map((rowMatrix, indexMatrix) => {
                                                                                                                return (
                                                                                                                    <Tr>
                                                                                                                        <Td>{counterFreq[`${columnMatrix?.value}${rowMatrix?.value}`] || 0}</Td>
                                                                                                                    </Tr>
                                                                                                                )
                                                                                                            })}
                                                                                                        </Td>
                                                                                                    )
                                                                                                })}
                                                                                                {/* <Td>
                                                                                                {row?.rows?.map((rowMatrix, indexMatrix) => {
                                                                                                    return (
                                                                                                        <Tr>
                                                                                                            <Td>{counterFreq[`${rowMatrix?.text}${row.columns[indexMatrix]?.text}`] || 0}</Td>
                                                                                                        </Tr>
                                                                                                    )
                                                                                                })}
                                                                                            </Td>
                                                                                            <Td>
                                                                                                {row?.rows?.map((rowMatrix, indexMatrix) => {
                                                                                                    return (
                                                                                                        <Tr>
                                                                                                            <Td>{counterFreq[`${rowMatrix?.text}${row.columns[indexMatrix]?.text}`] || 0}</Td>
                                                                                                        </Tr>
                                                                                                    )
                                                                                                })}
                                                                                            </Td> */}
                                                                                            </Tr>
                                                                                        </Tbody>
                                                                                    </Table>
                                                                                </Td>
                                                                            </Tr>
                                                                        </Tbody>
                                                                    </Table>
                                                                </Td>) : (<Fragment>
                                                                    <Td>
                                                                        <Table variant='simple'>
                                                                            <Thead>
                                                                                <Tr>
                                                                                    <Th>{t('SurveyDetailPage.summary_answers')}</Th>
                                                                                    <Th>{t('SurveyDetailPage.no_of_vote')}</Th>
                                                                                    <Th ><Center>%</Center></Th>
                                                                                </Tr>
                                                                            </Thead>
                                                                            <Tbody>
                                                                                <Tr>
                                                                                    <Td> {row?.choices?.map((row, index) => {
                                                                                        return (
                                                                                            <Tr key={index} >
                                                                                                <Td>{row}</Td>
                                                                                            </Tr>
                                                                                        )
                                                                                    })}
                                                                                    </Td>
                                                                                    <Td>
                                                                                        {row?.choices?.map((row, index) => {
                                                                                            return (
                                                                                                <Tr key={index} >
                                                                                                    <Td>{counterFreq[row] || 0}</Td>
                                                                                                </Tr>
                                                                                            )
                                                                                        })}
                                                                                    </Td>
                                                                                    <Center>
                                                                                        <Td>
                                                                                            {row?.choices?.map((row, index) => {
                                                                                                return (
                                                                                                    <Tr key={index} >
                                                                                                        <Td>{!isNaN(((counterFreq[row] * 100) / surveyDetail?.respondents)) ? ((counterFreq[row] * 100) / surveyDetail?.respondents).toFixed(2) : 0}%</Td>
                                                                                                    </Tr>
                                                                                                )
                                                                                            })}
                                                                                        </Td>
                                                                                    </Center>
                                                                                </Tr>

                                                                            </Tbody>
                                                                        </Table>
                                                                    </Td>
                                                                </Fragment>)}
                                                            </Tr>
                                                        )
                                                    })}

                                                </Tbody>
                                            </Table>
                                        </Box>
                                    </GridItem>
                                </Grid>


                            </TabPanel>
                            <TabPanel>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th textAlign="center">{t('SurveyDetailPage.name_surname')}</Th>
                                            <Th textAlign="center">{t('SurveyDetailPage.manage')}</Th>
                                        </Tr>
                                    </Thead>
                                    {SurveyUser?.content?.surveys?.length === 0 ?
                                        <Box>
                                            <Text textAlign="center">{t('SurveyDetailPage.no_survey')}</Text>
                                            <Td>-</Td>
                                        </Box>
                                        :
                                        (SurveyUser?.content?.surveys?.map((survey) => (
                                            <Tbody key={survey?._id}>
                                                <Tr>
                                                    <Td textAlign="center">{survey?.student?.firstname} {survey?.student?.lastname}</Td>
                                                    <Td>
                                                        <Flex justify="center" >
                                                            <Tooltip label={t('SurveyDetailPage.view')} fontsize="md">
                                                                <IconButton size='lg' icon={<ViewIcon />} mr={2} onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setPreviewServey(survey)
                                                                    ModalPreviewOpen()
                                                                }} />
                                                            </Tooltip>
                                                            <Tooltip label={t('SurveyDetailPage.delete')} fontsize="md">
                                                                <IconButton size='lg' icon={<FaTrashAlt />} mr={2} onClick={(e) => {
                                                                    e.preventDefault()
                                                                    ModalRemoveOpen()
                                                                    setServey(survey)
                                                                }} />
                                                            </Tooltip>
                                                        </Flex>
                                                    </Td>
                                                </Tr>
                                            </Tbody>
                                        )))
                                    }
                                </Table>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            </Box>

            <AlertDialog isOpen={ModalRemove} onClose={ModalRemoveClose}>
                <AlertDialogOverlay>
                    <form onSubmit={handleRemoveSurvey} as={GridItem} colSpan={[6, 3]}>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                {t('SurveyDetailPage.deleteAlertDialogTitle')}
                            </AlertDialogHeader>
                            <AlertDialogBody>{t('SurveyDetailPage.deleteAlertDialogSurvey')}</AlertDialogBody>
                            <AlertDialogFooter>
                                <Button onClick={ModalRemoveClose}>{t('Button.cancel')}</Button>
                                <Button colorScheme="red" type="submit" ml={3}>
                                    {t('Button.delete')}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </form>
                </AlertDialogOverlay>
            </AlertDialog>

            <Modal isOpen={ModalPreview} onClose={ModalPreviewClose} size={'5xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="extrabold">{t('SurveyDetailPage.preview')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <Text as="legend" fontSize="2xl" fontWeight="extrabold">{SurveyUser?.content?.name}</Text>
                            <Text mt={2} p={2} borderWidth='2px' borderRadius='lg' overflow='hidden'>{handleConvertHTML(SurveyUser?.content?.surveyconfig?.description)}</Text>
                            <Survey.Survey
                                json={SurveyUser ? { questions: SurveyUser?.content?.surveyconfig?.surveyQuestions?.questions } : { questions: {} }}
                                data={previewSurvey?.surveyAnswers}
                                showCompletedPage={false}
                                mode="display"
                            />
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} onClick={ModalPreviewClose}>
                            {t('Button.close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Fragment>
    )
}

export default SurveyDetail;


import { useTranslation } from 'react-i18next'
import { Fragment, useContext, useState, useEffect } from 'react'

import {
    Box,
    Table,
    Th,
    Tr,
    Progress,
    Tag,
    TagLabel,
    Td,
    Thead,
    Tbody,
    useColorMode,
    Skeleton,
    Center,
    Text,
    IconButton,
    HStack,
    Stack,
    Divider,
    Hide,
    Tooltip,
} from '@chakra-ui/react'
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa"
import { MdPeople, MdCheck, MdClose } from "react-icons/md"
import { FaCertificate } from "react-icons/fa";

import { IoMdSchool } from "react-icons/io";


const DashboardDetail = ({ total, pass, notpass, certificate, credit, dashboardContent, loading }) => {

    const { t } = useTranslation()
    const { colorMode } = useColorMode()


    return (
        <Fragment>
            <Skeleton isLoaded={!loading} borderRadius={'15px'}>
                <Box
                    mt={6}
                    p={2}
                    w='100%'
                    display={'flex'}
                    borderRadius={'15px'}
                    textAlign='-webkit-center'
                    border={'1px solid'}
                    // borderWidth={'4px'}
                    color={colorMode === 'light' ? 'brand.700' : 'brand.100'}
                    bgColor={colorMode === 'light' ? 'brand.300' : 'brand.800'}
                >
                    <Tooltip label={t('ManageEnrollSubjectPage.student')} fontsize="md">
                        <Box flexGrow={1} alignSelf={'center'}>
                            <Center>
                                <Stack direction={'row'} spacing='10px'>
                                    <MdPeople size={'30px'} />
                                    <Text as='b' alignSelf={'center'}>{total}</Text>
                                </Stack>
                            </Center>
                            <Hide below='sm'>
                                <Text as='b'>{t('ManageEnrollSubjectPage.student')}</Text>
                            </Hide>
                        </Box>
                    </Tooltip>
                    <Center height='50px' >
                        <Divider orientation='vertical' border={'2px solid'} />
                    </Center>
                    <Tooltip label={t('ManageEnrollSubjectPage.pass')} fontsize="md">
                        <Box flexGrow={1} alignSelf={'center'}>
                            <Center>
                                <Stack direction={'row'} spacing='10px'>
                                    <MdCheck size={'30px'} />
                                    <Text as='b' alignSelf={'center'}>{pass}</Text>
                                </Stack>
                            </Center>
                            <Hide below='sm'>
                                <Text as='b'>{t('ManageEnrollSubjectPage.pass')}</Text>
                            </Hide>

                        </Box>
                    </Tooltip>
                    <Center height='50px'>
                        <Divider orientation='vertical' border={'2px solid'} />
                    </Center>
                    <Tooltip label={t('ManageEnrollSubjectPage.failed')} fontsize="md">
                        <Box flexGrow={1} alignSelf={'center'}>
                            <Center>
                                <Stack direction={'row'} spacing='10px'>
                                    <MdClose size={'30px'} />
                                    <Text as='b' alignSelf={'center'}>{notpass}</Text>
                                </Stack>
                            </Center>
                            <Hide below='sm'>
                                <Text as='b'>{t('ManageEnrollSubjectPage.failed')}</Text>
                            </Hide>

                        </Box>
                    </Tooltip>
                    <Center height='50px'>
                        <Divider orientation='vertical' border={'2px solid'} />
                    </Center>
                    <Tooltip label={t('ManageEnrollSubjectPage.certificate')} fontsize="md">
                        <Box flexGrow={1} alignSelf={'center'}>
                            <Center>
                                <Stack direction={'row'} spacing='10px'>
                                    <FaCertificate size={'25px'} />
                                    <Text as='b' alignSelf={'center'}>{certificate}</Text>
                                </Stack>
                            </Center>
                            <Hide below='sm'>
                                <Text as='b'>{t('ManageEnrollSubjectPage.certificate')}</Text>
                            </Hide>
                        </Box>
                    </Tooltip>
                    <Center height='50px'>
                        <Divider orientation='vertical' border={'2px solid'} />
                    </Center>
                    <Tooltip label={t('ManageEnrollSubjectPage.credit')} fontsize="md">
                        <Box flexGrow={1} alignSelf={'center'}>
                            <Center>
                                <Stack direction={'row'} spacing='10px'>
                                    <IoMdSchool size={'30px'} />
                                    <Text as='b' alignSelf={'center'}>{credit}</Text>
                                </Stack>
                            </Center>
                            <Hide below='sm'>
                                <Text as='b'>{t('ManageEnrollSubjectPage.credit')} </Text>
                            </Hide>
                        </Box>
                    </Tooltip>
                </Box>
            </Skeleton>

            <Skeleton isLoaded={!loading} borderRadius={'5px'} >
                <Box
                    bg={colorMode === 'light' ? 'brand.300' : 'brand.800'}
                    mt={5}
                    mb={4}
                    sx={{ overflowX: ['auto', 'auto', 'auto'] }}
                    borderWidth={'5px'}
                    borderRadius={'lg'}
                    p={1}
                    maxHeight="600px">
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>{t('ManageEnrollSubjectPage.content')}</Th>
                                <Th textAlign="center">{t('ManageEnrollSubjectPage.status')}</Th>
                                <Th textAlign="center">{t('ManageEnrollSubjectPage.type')}</Th>
                                <Th textAlign="center">{t('ManageEnrollSubjectPage.failed')}</Th>
                                <Th textAlign="center">{t('ManageEnrollSubjectPage.pass')}</Th>
                                <Th textAlign="center">{t('ManageEnrollSubjectPage.progress')}</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {dashboardContent?.map((content, i) => {
                                return (
                                    <Tr key={i}>
                                        <Td maxW={'20vh'} >{content?.name}</Td>
                                        <Td textAlign="center" maxW={'100px'}>
                                            <Center>
                                                <Box w='100%' color='white'>
                                                    {content?.status ?
                                                        <Tag
                                                            borderRadius='full'
                                                            boxShadow={'lg'}
                                                            variant='solid'
                                                            colorScheme='green'
                                                            w={"full"}
                                                            py={1}
                                                            justifyContent={"center"}
                                                        >
                                                            <TagLabel>{t("managecontentPage.public")}</TagLabel>
                                                        </Tag>
                                                        :
                                                        <Tag
                                                            borderRadius='full'
                                                            boxShadow={'lg'}
                                                            variant='solid'
                                                            w={"full"}
                                                            py={1}
                                                            justifyContent={"center"}
                                                        >
                                                            <TagLabel>{t("managecontentPage.nonepublic")}</TagLabel>
                                                        </Tag>}
                                                </Box>
                                            </Center>
                                        </Td>
                                        <Td textAlign="center">{content?.type}</Td>
                                        <Td textAlign="center">{content?.notpass}</Td>
                                        <Td textAlign="center">{content?.pass}</Td>
                                        <Td textAlign="center">
                                            <Progress borderRadius={'3xl'} colorScheme='green' size='sm' value={parseInt(content?.progress)} />
                                        </Td>
                                        <Td> {`${content?.progress ? parseInt(content?.progress) : 0} %`}</Td>


                                        {/* <Td textAlign="center">{isNaN(content?.progress) ?
                                            <Progress borderRadius={'3xl'} colorScheme='green' size='sm' value={0} />
                                            :
                                            <Progress borderRadius={'3xl'} colorScheme='green' size='sm' value={content?.progress} />
                                        }
                                        </Td>
                                        {
                                            isNaN(content?.progress) ?
                                                <Td>{`0 %`}</Td>
                                                :
                                                <Td> {`${content?.progress} %`}</Td>
                                        } */}
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Box>
            </Skeleton>
        </Fragment>
    )

}

export default DashboardDetail

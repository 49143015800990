import gql from 'graphql-tag'

export default gql`
query {
    subjects(filter: { status: public , alive_subject: SUBJECT_ALIVE },sort:_ID_DESC) {
    _id
      name
      description
      updatedAt
      photo
      alive_subject
      status
      teacher{
          _id
          username
          firstname
          lastname
          isTeacher
          avatar
          email
          
      }
    }
  }
`

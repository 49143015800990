import gql from "graphql-tag";

export default gql`
  query ($teacher: MongoID , $alive_subject: EnumSubjectAlive_subject) {
    subjects(filter: { teacher: $teacher , alive_subject: $alive_subject}) {
      _id
      name
      description
      photo
      status
      pay_certificate
      course_certificate
      pay_description{
        blocks
        entityMap
      },
      teacher {
        _id
        firstname
        lastname
        avatar
      }
    }
  }
`;

import gql from 'graphql-tag'

export default gql`
query(
      $student: MongoID
      $id: String
      ){
    scores(filter: {student: $student, homework:[{contentid:$id}]}){
        _id
        student{
            _id
            firstname
          }
          homework{
            _id
            contentid
            name
            score   
            file
            comment{
              blocks
              entityMap
          }
          }
    }
  }
  




`
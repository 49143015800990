import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import {
    Box,
    Heading,
    Text,
    Container,
    IconButton,
    Flex,
    Stack,
    Spacer,
    WrapItem,
    Wrap,
    Divider,
    HStack,
    Icon,
} from '@chakra-ui/react';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import {
    Previous,
    Paginator,
    PageGroup,
    Page,
    Next,
    generatePages
} from 'chakra-paginator';
import { MdClose } from 'react-icons/md'
import teachersQuery from '../graphql/queries/teachers';
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import ItemList from '../components/Itemlist';
import { useQuery } from '@apollo/client'
import { CloseIcon } from '@chakra-ui/icons'

import SubjectLayoutSkeleton from '../components/skeleton/SubjectLayoutSkeleton';
import dayjs from 'dayjs';


require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)



const TeacherPage = () => {
    const { data: teacherData, loading } = useQuery(teachersQuery)
    const [teacherState, setTeacherState] = useState([])
    const [curItems, setCurItems] = useState([]);

    const itemLimit = 8;
    const { t } = useTranslation()

    const [pagesQuantity, setPagesQuantity] = useState(0);
    const [curPage, setCurPage] = useState(0);


    const handlePageChange = (page) => {
        setCurPage(page - 1);
    };


    useEffect(() => {
        const pagesTotal = Math.ceil(teacherData?.teacherUsers?.length / itemLimit);
        setPagesQuantity(pagesTotal);
        // console.log(teacherData?.teacherUsers?.length)

        if (teacherData) {
            setTeacherState(teacherData?.teacherUsers ? teacherData?.teacherUsers : [])
        }
    }, [teacherData, teacherData?.teacherUsers?.length, curPage, itemLimit]);
    // console.log(curItems)
    return (
        <Fragment>
            <Box w="100%" px={[1, 5, 20, 20]} minH={'90vh'} >
                <Container maxW={'8xl'} py={16} as={Stack} spacing={12} >
                    <Stack
                        w={'full'}
                        direction={["column", "column", "column", "row"]}
                        justifyContent='space-between'
                    >
                        <Stack align={{ base: 'center', md: 'flex-start' }}>
                            <Heading fontSize={['40px', '40px', '30px', '50px']}>{t('teacherPage.title')}</Heading>
                            <Text>{t('teacherPage.subtitle')}</Text>
                        </Stack>
                        <Stack align={{ base: 'center', md: "flex-end" }}>
                            <Flex
                                boxSize="full"
                                // h="100vh"
                                w={['90%', "", '400px']}
                                pos="relative"
                                // bg={useColorModeValue("gray.400", "gray.600")}
                                // p={30}
                                alignItems='center'
                                justifyContent="center"
                            >
                                <AutoComplete
                                    onChange={(v, i, I) => {
                                        // console.log(v, i, I);
                                    }}
                                    onSelectOption={(e) => { setTeacherState(teacherState?.filter((row) => (e?.optionValue === row?._id))) }}
                                    rollNavigation
                                >
                                    <AutoCompleteInput variant="filled" placeholder="Search..." autoFocus />
                                    <AutoCompleteList>
                                        {teacherData?.teacherUsers.map((option, oid) => (
                                            <AutoCompleteItem
                                                key={`option-${oid}`}
                                                label={`${option?.firstname} ${option?.lastname}`}
                                                value={`${option?._id}`}
                                                textTransform="capitalize"
                                            >
                                                {` ${option?.firstname} ${option?.lastname} `}
                                            </AutoCompleteItem>
                                        ))}
                                    </AutoCompleteList>
                                </AutoComplete>
                                <IconButton
                                    variant="ghost"
                                    colorScheme='blue'
                                    pos='absolute'
                                    right={0}
                                    onClick={() => { setTeacherState(teacherData?.teacherUsers) }}
                                    fontSize="15px"
                                    icon={<MdClose />}
                                />
                            </Flex>
                        </Stack>
                    </Stack>
                    <Divider />

                    <Wrap justify={'center'} spacing="30px">
                        {loading ? [1, 2, 3, 4].map((row) => (
                            <WrapItem key={row}>
                                <SubjectLayoutSkeleton />
                            </WrapItem>
                        )) :
                            <Box w="full">
                                {teacherState.length !== 0 ? (
                                    <WrapItem>
                                        <ItemList teacherState={teacherState} curPage={curPage} itemLimit={itemLimit} curItems={curItems} setCurItems={setCurItems} />
                                    </WrapItem>
                                )
                                    :

                                    <Box w="full" p={'2'} boxShadow="lg" borderRadius={'lg'} h={"200"} bg={'gray.100'}>
                                        <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} style={{ height: "100%" }}>
                                            <HStack color={'gray.500'}>
                                                <Icon as={CloseIcon} w={10} h={8} />
                                                <Text fontSize={'35'} color={'gray.500'}>{t('coursePage.alt_teacher')}</Text>
                                            </HStack>
                                        </Flex>
                                    </Box>
                                }
                            </Box>
                        }
                    </Wrap>

                    <Box>
                        {pagesQuantity !== 0 ? <Flex p={4} mb={4}>
                            <Spacer />
                            <Paginator
                                onPageChange={handlePageChange}
                                pagesQuantity={pagesQuantity}>
                                <Previous bg={'primary.500'} color={'white'}>
                                    <CgChevronLeft />
                                </Previous>
                                <PageGroup ml={1} mr={1}>
                                    {generatePages(pagesQuantity)?.map((page) => (
                                        <Page
                                            key={`paginator_page_${page}`}
                                            page={page}
                                        />
                                    ))}
                                </PageGroup>
                                <Next bg={'primary.500'} color={'white'}>
                                    <CgChevronRight />
                                </Next>
                            </Paginator>

                        </Flex>
                            :
                            <Box>
                                <Text fontSize={'35'} color={'gray.500'}>{t('coursePage.alt_teacher')}</Text>
                                <Box h='145px'>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Container >
            </Box >
        </Fragment >
    );
};

export default TeacherPage;

import gql from "graphql-tag";

export default gql`
  query ($content: MongoID, $student: MongoID) {
    survey(filter: { content: $content, student:$student}) {
    _id
    createdAt
    surveyAnswers
    status
    }
  }
`;

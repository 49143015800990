import React, { Fragment, useState, useContext } from "react";
import {
  Box,
  Container,
  Stack,
  Text,
  Heading,
  Button,
  Center,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";

import Iframe from "react-iframe";
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import { Image } from "@chakra-ui/react";
import { useMutation, useQuery } from "@apollo/client";
import AuthContext from "../contexts/AuthContext";
import { useParams } from "react-router";
import { useMultiState } from "../lib/hooks";
import VideoMutaion from "../graphql/mutations/createVideoOfSubject";
import videoofsubjects from "../graphql/queries/videoofsubject";
import subjectbyId from "../graphql/queries/subject";
import UpdateVideo from "../graphql/mutations/updateVideoofSubject";
import SubjectRemoveMutation from "../graphql/mutations/removeVideo";


const ManageVideoPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [open, setOpen] = useState(false);
  const param = useParams();
  const { user } = useContext(AuthContext);
  const [removeMutation] = useMutation(SubjectRemoveMutation);
  const [setUpdateVideoofSubjects] = useMutation(UpdateVideo);
  const [setVideoMutation] = useMutation(VideoMutaion);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [modalData, setModalData] = useState({});
  const { data, refetch } = useQuery(videoofsubjects, {
    variables: {
      subject: param?.subjectId,
    },
  });
  const { data: SubjectData } = useQuery(subjectbyId, {
    variables: {
      _id: param?.subjectId,
    },
  });
  // console.log(data?.videoofsubjects?.map((row) => row.name));
  const handleOnOpen = () => {
    setOpen(true);
  };
  const handleOnclose = () => {
    setOpen(false);
  };
  const initialVideo = {
    name: "",
    link: "",
    thumbnail: "",
  };

  const [videoData, setVideoData] = useMultiState(initialVideo);
  const [videoAddData, setVideoAddData] = useMultiState(initialVideo);

  const handleUpdateVideo = async (e) => {
    // console.log("handleupdatevideo");
    e.preventDefault();
    if (!videoData?.name) {
      await toast({
        position: "top",
        title: "Warning",
        description: "กรุณากรอกชื่อวิดีโอ",
        status: "warning",
        duration: 6000,
        isClosable: true,
      });
    } else if (!videoData?.link) {
      await toast({
        position: "top",
        title: "Warning",
        description: "กรุณากรอกลิงค์วิดีโอ",
        status: "warning",
        duration: 6000,
        isClosable: true,
      });
    } else if (!videoData?.thumbnail) {
      await toast({
        position: "top",
        title: "Warning",
        description: "กรุณากรอกลิงค์รูปภาพ",
        status: "warning",
        duration: 6000,
        isClosable: true,
      });
    } else {
      try {
        const { data: videodata } = await setUpdateVideoofSubjects({
          variables: {
            _id: videoData?._id,
            name: videoData?.name,
            link: videoData?.link,
            thumbnail: videoData?.thumbnail,
          },
        });
        if (videodata) {
          alert("แก้ไขข้อมูลสำเร็จ");
          refetch({
            teacher: user?._id,
          });
          setVideoData(initialVideo);
          handleOnclose();
        }
      } catch (Error) {
        // console.log("in Create submit", Error);
      }
    }
  };

  const handleRemoveVideo = async (e) => {
    // console.log("handleremove", e);
    try {
      if (window.confirm("คุณแน่ใจว่าต้องการลบข้อมูล")) {
        const { data: videodata, errors: videoErr } = await removeMutation({
          variables: {
            _id: e,
          },
        });
        if (videodata) {
          alert("ลบข้อมูลสำเร็จ");
          refetch({
            teacher: user?._id,
          });
        }
        // console.log(videoErr);
      }
    } catch (Err) {
      // console.log(Err);
      // alert(`${Err?.graphQLErrors[0]?.message}`);
    }
  };

  const handleAddVideo = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!videoAddData?.name) {
      alert("กรุณาเพิ่มชื่อวิดีโอ");
    } else if (!videoAddData?.link) {
      alert("กรุณาเพิ่มลิงค์วิดีโอ");
    } else if (!videoAddData?.thumbnail) {
      alert("กรุณาเพิ่มลิงค์รูปภาพ");
    } else {
      const { data: videoadddata, errors: videoErr } = await setVideoMutation({
        variables: {
          name: videoAddData?.name,
          link: videoAddData?.link,
          thumbnail: videoAddData?.thumbnail,
          subject: param?.subjectId,
        },
      });
      if (videoadddata) {
        alert("เพิ่มข้อมูลสำเร็จ");
        refetch({
          _id: user?._id,
        });
        setVideoAddData(initialVideo);
      }
    }
    setLoading(false);
  };
  // console.log(data);
  return (
    <div>
      <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalData?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <div style="position: relative; padding-top: 56.25%;"> */}
            <Iframe
              url={modalData?.link}
              width={"100%"}
              height={"600px"}
              display="initial"
              position="relative"
            ></Iframe>
            {/* </div> */}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={open} onClose={handleOnclose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>เเก้ไขวิดีโอ</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleUpdateVideo}>
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>เเก้ไขชื่อวิดีโอ</FormLabel>
                <Input
                  value={videoData?.name}
                  onChange={(e) => {
                    setVideoData({ name: e.target.value });
                  }}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>เเก้ไขลิงวิดีโอ</FormLabel>
                <Input
                  value={videoData?.link}
                  onChange={(e) => {
                    setVideoData({ link: e.target.value });
                  }}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>เเก้ไขรูปภาพวิดีโอ</FormLabel>
                <Input
                  value={videoData?.thumbnail}
                  onChange={(e) => {
                    setVideoData({ thumbnail: e.target.value });
                  }}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Fragment>
        <Box w="100%">
          <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
            <Stack spacing={0} align={"center"}>
              <Heading as="h3" size="lg">
                จัดการวิดีโอ
              </Heading>
              <Text colorScheme="green" color='green' fontSize='xl'> วิชา{SubjectData?.subject?.name}</Text>
              <Text>
                สามารถเพิ่ม ลบ หรือแก้ไขการสอนสดได้ตามเมนูหรือตารางด้านล่าง
              </Text>
            </Stack>
            <form onSubmit={handleAddVideo}>
              <FormLabel>เพิ่มชื่อวิดีโอ</FormLabel>
              <Input
                value={videoAddData?.name}
                placeholder="เพิ่มชื่อวิดีโอ"
                onChange={(e) => setVideoAddData({ name: e.target.value })}
              />
              <br />
              <br />
              <FormLabel>เพิ่มลิงค์วิดีโอ</FormLabel>
              <Input
                value={videoAddData?.link}
                placeholder="เพิ่มลิงค์วิดีโอ"
                onChange={(e) => setVideoAddData({ link: e.target.value })}
              />
              <br />
              <br />
              <FormLabel>เพิ่มรูปภาพวิดีโอ</FormLabel>
              <Input
                value={videoAddData?.thumbnail}
                placeholder="เพิ่มรูปภาพวิดีโอ"
                onChange={(e) => setVideoAddData({ thumbnail: e.target.value })}
              />
              <br />
              <br />
              <Stack align="center">
                <Button mt={4} colorScheme="teal" type="submit">
                  ยืนยัน
                </Button>
              </Stack>
            </form>

            <Stack align={"center"}>
              <Table w={"full"} variant="striped">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>ชื่อวิดีโอ</Th>
                    <Th>ลิงค์วิดีโอ</Th>
                    <Center>
                      <Th>จัดการ</Th>
                    </Center>
                  </Tr>
                </Thead>
                {data?.videoofsubjects?.map((row) => (
                  <Tbody>
                    <Tr>
                      <Td>#</Td>
                      <Td maxW={"500px"}>
                        <Text>{row?.name}</Text>
                      </Td>

                      <Td>
                        <Button>
                          {" "}
                          <Image
                            boxSize="40px"
                            alt={"รูปภาพ"}
                            fit={"cover"}
                            align={"center"}
                            src={row?.thumbnail}
                            onClick={() => {
                              setModalData({
                                id: row?._id,
                                name: row?.name,
                                link: row?.link,
                                thumbnail: row?.thumbnail,
                              });
                              onOpen();
                            }}
                          />
                        </Button>
                      </Td>
                      <Td>
                        <Tooltip label="แก้ไข" fontsize="md">
                          <Button
                            variant="solid"
                            size="md"
                            onClick={(e) => {
                              handleOnOpen();
                              setVideoData(row);
                            }}
                          >
                            <FaPencilAlt />
                          </Button>
                        </Tooltip>
                        <Tooltip label="ลบ" fontsize="md">
                          <Button
                            size="md"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveVideo(row?._id);
                            }}
                          >
                            <FaTrashAlt />
                          </Button>
                        </Tooltip>
                      </Td>
                    </Tr>
                  </Tbody>
                ))}
              </Table>
            </Stack>
          </Container>
        </Box>
      </Fragment>
    </div>
  );
};

export default ManageVideoPage;

import gql from 'graphql-tag'

export default gql`
	mutation (
		$_id: MongoID!
		$name: String
		$type: EnumContentType
		$description: String
		$duedate: Date
		$questionList: [JSON]
		$statusActive: Boolean
		$startdate: Date
		$expiredate: Date
	) {
		updateContent(
			_id: $_id
			record: {
				name: $name
				type: $type
				statusActive: $statusActive
				expiredate: $expiredate
				questionconfig: {
					description: $description
					startdate: $startdate
					duedate: $duedate
					questionList: $questionList
				}
			}
		) {
			recordId
		}
	}
`

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import {
    Box,
    Heading,
    Text,
    Stack,
    Container,
    Wrap,
    WrapItem,
    useDisclosure,
    Flex,
    Spacer,
    IconButton,
    Icon,
    HStack,
    Divider,
} from '@chakra-ui/react';
import {
    Previous,
    Paginator,
    PageGroup,
    Page,
    Next,
    generatePages
} from 'chakra-paginator';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import { useQuery } from '@apollo/client'
import { CloseIcon } from '@chakra-ui/icons'

import SubjectLayoutMyCourseStudent from '../components/layouts/SubjectLayoutMyCourseStudent';

import AuthContext from '../contexts/AuthContext';
import ModalPricingLayout from '../components/layouts/ModalPricingLayout';
import { MdClose } from 'react-icons/md'
import SubjectLayoutSkeleton from '../components/skeleton/SubjectLayoutSkeleton';
import studentofsubjectEnroll from "../graphql/queries/studentofsubjectEnroll"
import { useHistory } from 'react-router'


const CourseStudentPage = () => {
    const { user } = useContext(AuthContext)

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [subjectsState, setSubjectsState] = useState([])
    const { t } = useTranslation()
    const [curItems, setCurItems] = useState([]);

    const itemLimit = 8;

    const [pagesQuantity, setPagesQuantity] = useState(0);
    const [curPage, setCurPage] = useState(0);
    // console.log("subjectsState", subjectsState);

    const { data: subjectData, loading, refetch } = useQuery(studentofsubjectEnroll, {
        variables: {
            student: user?._id,
        },
    })

    const handlePageChange = (page) => {
        setCurPage(page - 1);
    };

    const history = useHistory()

    useEffect(() => {
        if (subjectData) {
            let arr = []
            subjectData?.studentofsubjects.map((item, index) => {
                if (item?.subject?.alive_subject == 'SUBJECT_ALIVE') {
                    arr.push(item.subject)
                }
            })
            setSubjectsState(arr ? arr : [])
        }
        if (user?.role !== 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
        if (loading) {
            refetch({
                student: user?._id,
            })
        }
    }, [subjectData, history, user, refetch])
    console.log(subjectsState)

    return (
        <Fragment>
            <Box w="100%" px={[1, 5, 15, 20]} minHeight={'90vh'} >
                <Container maxW={'8xl'} py={16} as={Stack} spacing={12}>
                    <Stack
                        direction={["column", "", "row"]}
                        justifyContent='space-between'
                    >
                        <Stack align={{ base: 'center', md: 'flex-start' }}>
                            <Heading fontSize={['30px', '30px', '25px', '50px']}>{t('coursestudentPage.title')}</Heading>
                            <Text>{t('coursestudentPage.subtitle')}</Text>
                        </Stack>
                        <Stack align={{ base: 'center', md: "flex-end" }}>
                            <Flex
                                boxSize="full"
                                // h="100vh"

                                w={['90%', '', '400px']}
                                pos="relative"
                                // bg={useColorModeValue("gray.400", "gray.600")}
                                // p={30}
                                alignItems='center'
                                justifyContent="center"
                            >
                                <AutoComplete
                                    onChange={(v, i, I) => {
                                        // console.log(v, i, I);

                                    }}
                                    onSelectOption={(e) => { setSubjectsState(subjectsState?.filter((row) => (e?.optionValue === row?.subject?._id))) }}
                                    rollNavigation>
                                    <AutoCompleteInput variant="filled" placeholder="Search..." autoFocus />
                                    <AutoCompleteList>

                                        {subjectData?.studentofsubjects?.map((option, oid) => (
                                            <AutoCompleteItem
                                                key={`option-${oid}`}
                                                label={` ${option?.subject?.name} ${option?.subject?.teacher?.firstname} ${option?.subject?.teacher?.lastname}  `}
                                                // getValue={e => (e || option)}
                                                // op={option}
                                                value={`${option?.subject?.name}`}
                                                // value={`${option?.code} ${option?.name} ${option?.teacher?.firstname} ${option?.teacher?.lastname} ${option?.contentfor} `}
                                                textTransform="capitalize"
                                            >
                                                {`${option?.subject?.name} ${option?.subject?.teacher?.firstname} ${option?.subject?.teacher?.lastname} `}
                                            </AutoCompleteItem>
                                        ))}
                                    </AutoCompleteList>
                                </AutoComplete>
                                <IconButton
                                    variant="ghost"
                                    colorScheme='blue'
                                    pos='absolute'
                                    right={0}
                                    onClick={() => { setSubjectsState(subjectData?.studentofsubjects) }}
                                    aria-label="Call Sage"
                                    fontSize="15px"
                                    icon={<MdClose />}
                                />
                            </Flex>
                        </Stack>
                    </Stack>
                    <Divider />
                    <Wrap spacing="30px">
                        {loading ? [1, 2, 3, 4].map((row) => (
                            <WrapItem key={row}>
                                <SubjectLayoutSkeleton />
                            </WrapItem>
                        )) :
                            <Box w='full'>
                                {
                                    subjectsState.length !== 0 ? (
                                        <WrapItem>
                                            <SubjectLayoutMyCourseStudent subjectsState={subjectsState} onOpen={onOpen} user={user} curPage={curPage} itemLimit={itemLimit} curItems={curItems} setCurItems={setCurItems} />
                                        </WrapItem>
                                    ) :
                                        <Box w="full" p={'2'} boxShadow="lg" borderRadius={'lg'} h={"200"} bg={'gray.100'}>
                                            <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} style={{ height: "100%" }}>
                                                <HStack color={'gray.500'}>
                                                    <Icon as={CloseIcon} w={10} h={8} />
                                                    <Text fontSize={'35'} >{t('coursestudentPage.alt')}</Text>
                                                </HStack>
                                            </Flex>
                                        </Box>
                                }
                            </Box>
                        }
                    </Wrap>
                    <Box>
                        {pagesQuantity !== 0 && <Flex>
                            <Spacer />
                            <Paginator
                                onPageChange={handlePageChange}
                                pagesQuantity={pagesQuantity}>
                                <Previous bg={'primary.500'} color={'white'}>
                                    <CgChevronLeft />
                                </Previous>
                                <PageGroup ml={1} mr={1}>
                                    {generatePages(pagesQuantity)?.map((page) => (
                                        <Page
                                            key={`paginator_page_${page}`}
                                            page={page}
                                        />
                                    ))}
                                </PageGroup>
                                <Next bg={'primary.500'} color={'white'}>
                                    <CgChevronRight />
                                </Next>
                            </Paginator>
                        </Flex>
                        }
                    </Box>
                </Container>
            </Box>
            <ModalPricingLayout isOpen={isOpen} onClose={onClose} />
        </Fragment>
    );
};

export default CourseStudentPage;
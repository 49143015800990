import { useTranslation } from 'react-i18next'
import {
    GridItem,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'

const AlertDialogHomework = ({ showRemoveHomework, toggleRemoveButton, handleRemovehomework }) => {

    const { t } = useTranslation()

    return (
        <AlertDialog isOpen={showRemoveHomework} >
            <AlertDialogOverlay>
                <form onSubmit={handleRemovehomework} as={GridItem} colSpan={[6, 3]}>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t('SendHomeworkPage.removefile')}
                        </AlertDialogHeader>
                        <AlertDialogBody>{t('SendHomeworkPage.removedetail')}</AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={toggleRemoveButton}>Cancel</Button>
                            <Button colorScheme="red" type="submit" ml={3}>
                                {t('SendHomeworkPage.delete')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </form>
            </AlertDialogOverlay>
        </AlertDialog>
    )

}

export default AlertDialogHomework

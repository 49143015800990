import gql from 'graphql-tag'

export default gql`
	mutation (
		$subject: MongoID
		$student: MongoID
		$content: String
		$name: String
		$score: Float
		$answer: JSON
	) {
		createScore(
			record: {
				subject: $subject
				student: $student
				quiz: { contentid: $content, name: $name, score: $score ,answer:$answer}
			}
		) {
			recordId
		}
	}
`

import gql from 'graphql-tag'

export default gql`
  mutation (
    $_id: MongoID!  
    $progress: Float
    $activetime: Float
    ){
    updateProgress(_id:$_id,
      record:{
        progress: $progress,
        activetime: $activetime,
      })
      {
        recordId
      }
  }
`
import gql from 'graphql-tag'

export default gql`
	mutation (
		$content: MongoID
		$student: MongoID
        $progress: Float
		$path: String
	) {
		createProgress(
			record: {
				content: $content
                student: $student
                progress: $progress
				path: $path
			}
		) {
			recordId
		}
	}
`

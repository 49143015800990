import gql from "graphql-tag";

export default gql`
  mutation (
    $content: MongoID
    $student: MongoID
    $status: EnumHomeworkStatus
    $file: String
    $filename: String
    $submitdate: Date
  ) {
    createHomework(
      record: {
        content: $content
        status: $status
        file: $file
        student: $student
        filename: $filename
        submitdate: $submitdate
      }
    ) {
      recordId
    }
  }
`;

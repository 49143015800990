import gql from 'graphql-tag'

export default gql`
	mutation (
		$_id: MongoID!
		$questionList: [JSON]
	) {
		updateContent(
			_id: $_id
			record: {
				questionconfig: {
					questionList: $questionList
				}
			}
		) {
			recordId
		}
	}
`

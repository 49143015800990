

import { useCallback } from 'react'
import {
    Button,
    useColorMode,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    ModalContent,
    Box,
    Text,
    Avatar,
    Stack,
    IconButton,
    Divider,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { DeleteIcon } from '@chakra-ui/icons'


const URLPROGRESS = process.env.REACT_APP_PROGRESS
const DialogListStudent = ({ contents, setProgress, setStudent, studentList, studentListDialog, closeStudentListDialog, setLoading, openUnEnrollSubject }) => {

    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const handleGetProgress = useCallback(async (studentId) => {
        try {
            setLoading(true)
            if (studentId) {
                const progress = await axios.post(URLPROGRESS, {
                    contents: contents,
                    student: studentId
                })
                if (progress) {
                    setProgress(progress?.data)
                    setLoading(false)
                }
            }
        } catch (error) {
            setLoading(false)
        }
    }, [contents])

    return (
        <Modal isOpen={studentListDialog} onClose={closeStudentListDialog} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('ManageEnrollSubjectPage.student_list')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box bg={colorMode === 'light' ? 'brand.300' : 'brand.800'} mt={5} mb={4} sx={{ overflowX: ['auto', 'auto', 'auto'] }} borderWidth={'5px'} borderRadius={'lg'} p={1} maxHeight="600px">

                        {studentList?.map((item, index) => {
                            return (
                                <Stack direction={'row'} justifyContent={'space-between'} mt={1} borderWidth={'1px'} borderRadius={'5px'}>
                                    <Button
                                        justifyContent={'start'}
                                        w={'100%'}
                                        variant={'ghost'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setStudent(item)
                                            handleGetProgress(item?.student?._id)
                                            closeStudentListDialog()
                                        }} >
                                        <Box display={'flex'} >
                                            <Avatar
                                                objectFit='cover'
                                                zIndex={1}
                                                name={item?.student?.firstname ? `${item?.student?.firstname} ${item?.student?.lastname}` : 'Profile'}
                                                size='sm'
                                                src={item?.student?.avatar}
                                                alt="กรุณาเลือกรูปภาพ" />
                                            <Text ml={2} alignSelf={'center'}> {`${item?.student?.firstname} ${item?.student?.lastname}`}</Text>
                                        </Box>
                                    </Button>
                                    <Box alignSelf={'center'} p={1}>
                                        <IconButton
                                            size={'sm'}
                                            colorScheme='red'
                                            icon={<DeleteIcon />}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setStudent(item)
                                                openUnEnrollSubject()
                                            }} />
                                    </Box>


                                </Stack>
                            )
                        })}
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )

}

export default DialogListStudent

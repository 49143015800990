import gql from 'graphql-tag'

export default gql`
	query ($_id: MongoID) {
		content(filter: { _id: $_id }) {
			_id
			name
			type
			expiredate
			statusActive
			surveyconfig {
				surveyQuestions{
					questions
				}
				description{
					blocks
					entityMap
				}
			}
			surveys{
				_id
				student{
					_id
					firstname
					lastname
				}
				surveyAnswers
			}
		}
	}
`

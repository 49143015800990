import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'
// import ProjectContext from '../contexts/ProjectContext'

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const { projectCode } = useParams()
  const { checkToken } = useContext(AuthContext)
  // const { project } = useContext(ProjectContext)
  const { user } = checkToken()
  // console.log('in Private', user, project)
  if (!user) {
    // removeToken()
    return (<Redirect to="/login" />)
  }
  // if (user?.code && (project?.code !== projectCode)) {
  //   // removeToken()
  //   return (<Redirect to={`/${project.code}/alarmboard`} />)
  // }
  // if (!project) {
  //   // removeToken()
  //   return (<Redirect to="/" />)
  // }
  return (
    <Route
      {...rest}
      render={(props) => (<Component {...props} />)}
    />
  )
}
PrivateRoute.propTypes = {
  component: PropTypes.func,
}
PrivateRoute.defaultProps = {
  component: null,
}

export default PrivateRoute

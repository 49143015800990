

import { useTranslation } from 'react-i18next'
import {
    Td,
    Tr,
    Th,
    Thead,
    Tbody,
    Text,
    Stack,
    Spinner,
    Table,
    Box,
    Progress,
    useColorMode,
    Skeleton,
} from '@chakra-ui/react'

import CardUser from '../../commons/CardUser'
import CardCertificate from './CardCertificate'


const DashboardUser = ({ SubjectData, progressList, student, openCertificateDialog, loading }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <Box
            p={2}
            mt={5}
        >
            <Stack
                mt={2}
                direction={['column', 'column', 'row', 'row']} justifyContent={['', '', 'space-between', 'space-between']}>
                <Skeleton isLoaded={!loading} borderRadius={'15px'} >
                    <CardUser student={student} />
                </Skeleton>
                {student?.certificatestatus &&
                    <Skeleton isLoaded={!loading} borderRadius={'15px'}>
                        <CardCertificate student={student} openCertificateDialog={openCertificateDialog} />
                    </Skeleton>
                }
            </Stack>
            <Box bg={colorMode === 'light' ? 'brand.300' : 'brand.800'} mt={5} mb={4} sx={{ overflowX: ['auto', 'auto', 'auto'] }} borderWidth={'5px'} borderRadius={'lg'} p={1} maxHeight="600px">                {loading ?
                <Box textAlign={'center'} display={'flex'} w={'100%'} h={'300px'} justifyContent={'center'} alignItems={'center'}>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        borderWidth={'10px'}
                        boxSize={'200px'}
                    />
                </Box> :
                <Table variant="simple">
                    <Thead >
                        <Tr>
                            <Th>{t('ManageEnrollSubjectPage.content')}</Th>
                            <Th>{t('ManageEnrollSubjectPage.type')}</Th>
                            <Th>{t('ManageEnrollSubjectPage.progress')}</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {SubjectData?.subject?.contents?.map((content, i) => {
                            let progress = progressList?.find((progressContent) => {
                                return progressContent?.content == content?._id
                            })
                            return <Tr key={i} >
                                <Td maxW={'20vh'}>
                                    <Text>{content?.name}</Text>
                                </Td>
                                <Td>{content?.type}</Td>
                                <Td alignItems={'center'}>
                                    <Progress value={progress?.progress} borderRadius="lg" borderWidth="1px" hasStripe colorScheme='green' />
                                </Td>
                                <Td>
                                    <Text >{progress?.progress ? `${progress?.progress}%` : `0%`}</Text></Td>
                            </Tr>
                        })}
                    </Tbody>
                </Table>
            }
            </Box>

            {/* {enrollState?.certificatestatus && <>
                {enrollState?.status == 'disapproved' ?
                    <HStack p={'10'} bg={'red.300'} justify="space-between" borderRadius={'lg'} boxShadow={'2xl'} mt={5}>
                        <Text fontSize={'2xl'} fontFamily={'monospace'} color={'white'}>{`${t('ManageEnrollSubjectPage.status_certificate')}  ${t('ManageEnrollSubjectPage.text_disapproved')}`}</Text>
                        <Button colorScheme='teal' boxShadow={'dark-lg'} variant='solid' bg={'green.400'} onClick={(e) => {
                            e.preventDefault()
                            setApprovedState({ _id: enrollState?._id, status: 'approved' })
                            openModalApproved()
                        }}>
                            {t('ManageEnrollSubjectPage.approved')}
                        </Button>
                    </HStack>
                    :
                    <HStack p={'10'} bg={'green.300'} justify="space-between" borderRadius={'lg'} boxShadow={'2xl'} mt={5}>
                        <Text fontSize={'2xl'} fontFamily={'monospace'} color={'white'}>{`${t('ManageEnrollSubjectPage.status_certificate')}  ${t('ManageEnrollSubjectPage.text_approved')}`}</Text>
                        <Button colorScheme='teal' boxShadow={'dark-lg'} variant='solid' bg={'red.300'} onClick={(e) => {
                            e.preventDefault()
                            setApprovedState({ _id: enrollState?._id, status: 'disapproved' })
                            openModalDisapproved()
                        }}>
                            {t('Button.cancel')}
                        </Button>
                    </HStack>
                }
            </>
            } */}
        </Box>
    )

}

export default DashboardUser

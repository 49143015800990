import React, { Fragment, useState, useEffect, useContext } from "react"
import { useTranslation } from 'react-i18next'
import './style.css'
import {
    Box,
    Container,
    Stack,
    Text,
    Heading,
    Table,
    Tr,
    Td,
    Link,
    Tbody,
    Thead,
} from "@chakra-ui/react"

import questionbyContent from "../graphql/queries/questionbyContent"
import { useParams } from "react-router"
import { useQuery } from "@apollo/client"
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'
import dayjs from "dayjs"


const QuestionDetail = () => {
    const { t } = useTranslation()
    const param = useParams()
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const [student, setStudent] = useState()

    const { data: ContentData, refetch } = useQuery(questionbyContent, {
        variables: {
            content: param?.contentId,
        },
    });
    console.log("IDDDD", param?.contentId)
    console.log(ContentData)
    useEffect(() => {
        if (user?.role == 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }

        if (ContentData) {
            setStudent(ContentData?.Quizs)
        }
    }, [history, user, refetch, ContentData])

    return (
        <Fragment>
            <Box w="100%" px={['2', '5', '15', '20']} minHeight={'90vh'}>
                <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
                    <Stack spacing={0} align={"center"}>
                        <Heading variant="h4">{t('QuestionDetailPage.quiz')}</Heading>
                        <Text>
                            {/* {t('homeworkDetail.subtitle')} {ContentData?.contents[0]?.homeworkconfig?.name} */}
                        </Text>
                    </Stack>
                </Container>
                <Stack spacing={3}>
                    <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} borderWidth={'1px'} borderRadius={'lg'} p={1} >
                        <Table variant='simple'>
                            <Thead bg={'gray.400'}>
                                <Tr>
                                    <Td textAlign="center">{t('QuestionDetailPage.name_surname')}</Td>
                                    <Td textAlign="center">{t('QuestionDetailPage.submit_date')}</Td>
                                    <Td textAlign="center">{t('QuestionDetailPage.score')}</Td>
                                </Tr>
                            </Thead>
                            {student?.length != 0 &&
                                <Tbody bg={'gray.200'}>
                                    {student?.map((student) => (
                                        <Tr key={student?._id}>
                                            <Td textAlign="center">
                                                <Link href={`/subject/questiondetail/preview/${param?.contentId}/${student?.student?._id}`} isExternal mb={1} >
                                                    {student?.student?.firstname} {student?.student?.lastname}
                                                </Link>
                                            </Td>
                                            <Td textAlign="center" maxW={'100px'}>
                                                {dayjs(student?.submitdate).format('DD MMMM YYYY')}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            }
                        </Table>
                        {student?.length == 0 &&
                            <Box align="center" w={'full'} py={'20'} color={'gray.500'} bg={'gray.200'}><Heading>{t('QuestionDetailPage.no_quiz')}</Heading></Box>
                        }
                    </Box>
                </Stack>
            </Box>
            {/* 
            <AlertDialog isOpen={showRemoveHomework} onClose={handleCloseRemoveHomework}>
                <AlertDialogOverlay>
                    <form onSubmit={handleRemovehomework} as={GridItem} colSpan={[6, 3]}>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                {t('homeworkDetail.deleteAlertDialogTitle')}
                            </AlertDialogHeader>

                            <AlertDialogBody>{t('homeworkDetail.deleteAlertDialogQuestion')}</AlertDialogBody>

                            <AlertDialogFooter>
                                <Button onClick={handleCloseRemoveHomework}>{t('homeworkDetail.deleteAlertDialogCancelButton')}</Button>
                                <Button colorScheme="red" type="submit" ml={3}>
                                    {t('homeworkDetail.deleteAlertDialogDeleteButton')}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </form>
                </AlertDialogOverlay>
            </AlertDialog> */}
        </Fragment>

    )
}

export default QuestionDetail;
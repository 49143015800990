import gql from "graphql-tag";

export default gql`
  query ($student: MongoID , $subject: MongoID) {
    studentofsubject(filter:{student: $student, subject: $subject}) {
        _id
        name
        status
        certificatestatus
        creditstatus
        payment_image
    }
  }
`;
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { BrowserRouter as Router, } from "react-router-dom";
import App from "./App";
import customTheme from "./utils/theme";
import { ApolloProvider } from "@apollo/client";
// import LandingLayout from './components/layouts/LandingLayout'
import client from './apolloClient'
import i18n from './lib/i18n'
import { I18nextProvider } from 'react-i18next'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AppProvider } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import { TokenMeetProvider } from "./contexts/TokenMeetContext";


const rootElement = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <TokenMeetProvider>
        <AuthProvider>
          <ChakraProvider theme={customTheme}>
            <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
            <I18nextProvider i18n={i18n}>
              <ApolloProvider client={client}>
                <Suspense fallback={<div />}>
                  <AppProvider>
                    {/* <LandingLayout> */}
                    <App />
                    {/* </LandingLayout> */}
                  </AppProvider>
                </Suspense>
              </ApolloProvider>
            </I18nextProvider>
          </ChakraProvider>
        </AuthProvider>
      </TokenMeetProvider>
    </Router>
  </React.StrictMode>,
  rootElement
);
serviceWorkerRegistration.register();

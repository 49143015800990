import { useTranslation } from 'react-i18next'
import {
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Switch,
    FormControl,
    ModalCloseButton,
    FormLabel,
    Input,
    Text,
    IconButton,
    HStack,
    Stack,
    Avatar,
    FormErrorMessage,
} from '@chakra-ui/react'
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa"


const DialogUpdateStudent = ({ updateDialogStatus, closeUpdateDialogStatus, handleUpdateStudent, setStudent, student, selectAvatarFile, passwordEditStatus, toggleEditPasswordStatus }) => {

    const { t } = useTranslation()

    return (
        <Modal isOpen={updateDialogStatus} onClose={closeUpdateDialogStatus} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleUpdateStudent}>
                    <ModalHeader>{t('ManageStudentPage.title')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack align='center' py={'20px'}>
                            <HStack align='flex-start'>
                                <label style={{ position: 'relative' }} htmlFor="photo-project-file">
                                    <Avatar
                                        objectFit='cover'
                                        zIndex={1}
                                        name={student?.firstname ? `${student?.firstname} ${student?.lastname}` : 'Profile'}
                                        size='lg'
                                        src={student?.avatar}
                                        alt="กรุณาเลือกรูปภาพ" />
                                    <IconButton
                                        isRound
                                        as='label'
                                        bgColor='white'
                                        htmlFor="photo-project-file"
                                        position='absolute'
                                        top={10}
                                        left={39}
                                        size='xs'
                                        fontSize={'15px'}
                                        zIndex='sticky'
                                        boxShadow='lg'
                                        icon={<FaPencilAlt style={{ zIndex: -1 }} />}
                                        color='green.500'
                                    />
                                    <input
                                        onChange={selectAvatarFile}
                                        accept="image/*"
                                        id="photo-project-file"
                                        type="file"
                                        style={{ display: 'none' }}
                                    />
                                </label>
                            </HStack>
                        </Stack>

                        <Stack spacing={4}>
                            <FormControl id="username" isRequired>
                                <FormLabel>Username / ชื่อผู้ใช้</FormLabel>
                                <Input
                                    value={student?.username}
                                    onChange={(e) => {
                                        setStudent({ username: e.target.value })
                                    }}
                                    type="text"
                                />
                            </FormControl>
                            <HStack>
                                <FormControl id="firstname" isRequired>
                                    <FormLabel>Firstname / ชื่อ</FormLabel>
                                    <Input
                                        type="text"
                                        value={student?.firstname}
                                        onChange={(e) => {
                                            setStudent({ firstname: e.target.value })
                                        }}
                                    />
                                </FormControl>
                                <FormControl id="lastname" isRequired>
                                    <FormLabel>Lastname / สกุล</FormLabel>
                                    <Input
                                        type="text"
                                        value={student?.lastname}
                                        onChange={(e) => {
                                            setStudent({ lastname: e.target.value })
                                        }}
                                    />
                                </FormControl>
                            </HStack>
                            <FormControl id="email" isRequired>
                                <FormLabel>Email / อีเมล</FormLabel>
                                <Input
                                    type="email"
                                    value={student?.email}
                                    onChange={(e) => {
                                        setStudent({ email: e.target.value })
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                id="email_confirm"
                                isDisabled={!student?.email}
                                isInvalid={student?.confirm_email && !(student?.email === student?.confirm_email)}
                                isRequired>
                                <FormLabel>Confirm Email / ยืนยันอีเมล</FormLabel>
                                <Input

                                    type="email"
                                    value={student?.confirm_email}
                                    onChange={(e) => {
                                        setStudent({ confirm_email: e.target.value })
                                    }}
                                />
                                <FormErrorMessage >อีเมลล์ไม่ตรงกัน</FormErrorMessage>
                            </FormControl>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor='email-alerts' mb='0'>
                                    Password
                                </FormLabel>
                                <Switch id='email-alerts' checked={passwordEditStatus} onChange={toggleEditPasswordStatus} />
                            </FormControl>
                            {console.log("EEEEEEEE", passwordEditStatus)}
                            {passwordEditStatus && <Box>
                                <FormControl id="password" isRequired>
                                    <FormLabel>Password / รหัสผ่าน</FormLabel>
                                    <Input
                                        type="password"
                                        value={student?.password}
                                        onChange={(e) => {
                                            setStudent({ password: e.target.value })
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    isDisabled={!student?.password}
                                    isInvalid={student?.confirm_pass && !(student?.password === student?.confirm_pass)}
                                    id="password-confirm"
                                    isRequired
                                >
                                    <FormLabel>Confirm Password / ยืนยันรหัสผ่าน</FormLabel>
                                    <Input
                                        value={student?.confirm_pass}
                                        onChange={(e) => {
                                            setStudent({ confirm_pass: e.target.value })
                                        }}
                                        errorBorderColor="red.300"
                                        type="password"
                                    />
                                    <FormErrorMessage >รหัสผ่านไม่ตรงกัน</FormErrorMessage>
                                </FormControl>
                            </Box>}
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={closeUpdateDialogStatus} mr={4}>
                            {t('ManageStudentPage.close')}
                        </Button>
                        <Button
                            type='submit'
                            bg={'primary.700'}
                            color={'white'}
                            _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                        >
                            {t('ManageStudentPage.saveButton')}
                        </Button>
                    </ModalFooter>
                </form>

            </ModalContent>
        </Modal>
    )

}

export default DialogUpdateStudent

import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react'
import { OrderGroup, OrderItem, arrayMove } from 'react-draggable-order'
import './draggable.css'
import "./survey.css";

import { v1 as uuid } from 'uuid'

import * as Survey from "survey-react";

import { useTranslation } from 'react-i18next'
import {
    VStack,
    Flex,
    HStack,
    Tooltip,
    Center,
    Box,
    Container,
    Stack,
    Text,
    Textarea,
    Input,
    RadioGroup,
    Radio,
    Heading,
    Accordion,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Select,
    InputGroup,
    InputLeftAddon,
    InputRightElement,
    useToast,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
    InputRightAddon,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Checkbox,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useColorModeValue,
    Badge,
    Switch,
} from '@chakra-ui/react'

import { useMutation, useQuery } from '@apollo/client'
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import draftToHtml from 'draftjs-to-html'
import renderHTML from 'react-render-html'
import { EditIcon, AddIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router'
import { useMultiState } from '../lib/hooks'
import updateSurvey from '../graphql/mutations/updateSurvey'
import surveybyContentId from '../graphql/queries/surveybyContentId'
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { deepOrange } from '@mui/material/colors'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'

const theme = createTheme({
    palette: {
        primary: {
            main: '#0CA25F',
        },
        secondary: deepOrange,
    },
})

const initialContent = {
    name: '',
    describe: '',
    statusActive: '',
    statusExpireDate: false,
    statusStartDate: false
}

const initialQuestion = {
    name: '',
    title: '',
    isRequired: '',

}

const ManageSurveyPage = () => {
    localStorage.setItem('refetchPage', true)
    const { t } = useTranslation()
    const param = useParams()
    const history = useHistory()
    const toast = useToast()
    const { user } = useContext(AuthContext)
    const [setUpdateSurvey] = useMutation(updateSurvey)

    const [showEditSurvey, setShowEditSurvey] = useState(false)
    const [showAddSurvey, setShowAddSurvey] = useState(false)
    const [showRemoveSurvey, setShowRemoveContent] = useState(false)

    const [showEditContent, setShowEditContent] = useState(false)
    const [editIndex, setEditIndex] = useState('')

    const [editorSurveyState, seteditorSurveyState] = useState(EditorState.createEmpty())

    const [list, setList] = useState()
    const [refetchList, setRefetchList] = useState(false)
    const [surveyData, setSurveyData] = useState('')
    const [surveyForm, setSurveyForm] = useMultiState(initialQuestion)
    const [surveyFormUpdate, setSurveyFormUpdate] = useMultiState('')
    const [content, setContent] = useMultiState(initialContent)
    const [updateContent, setUpdateContent] = useMultiState(initialContent)

    const [removeIndex, setRemoveIndex] = useState('')

    const { data: Surveys, refetch, loading } = useQuery(surveybyContentId, {
        variables: {
            _id: param?.contentId,
        },
    })

    useEffect(() => {
        if (Surveys) {
            setSurveyData(Surveys)
            setContent({
                name: Surveys?.content?.name,
                describe: Surveys?.content?.surveyconfig?.description,
                statusActive: Surveys?.content?.statusActive,
                duedate: Surveys?.content?.surveyconfig?.duedate,
                startdate: Surveys?.content?.surveyconfig?.startdate,
                expiredate: Surveys?.content?.expiredate,
            })
            setUpdateContent({
                name: Surveys?.content?.name,
                describe: Surveys?.content?.surveyconfig?.description,
                statusActive: Surveys?.content?.statusActive,
                duedate: Surveys?.content?.surveyconfig?.duedate,
                startdate: Surveys?.content?.surveyconfig?.startdate,
                expiredate: Surveys?.content?.expiredate,
            })
            setList(surveyData?.content?.surveyconfig?.surveyQuestions?.questions)
        }
        if (user?.role === 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
        if (loading) {
            refetch({
                teacher: user?._id,
            })
        }
        if (Surveys?.content?.expiredate) {
            setContent({ statusExpireDate: true })
        }
    }, [Surveys, surveyData, history, user, refetch])


    useEffect(async () => {
        try {
            if (refetchList) {
                console.log("ARRAY", list)
                const { data: SurveyData } = await setUpdateSurvey({
                    variables: {
                        _id: param?.contentId,
                        surveyQuestions: list,
                    },
                })
                if (SurveyData) {
                    // console.log(QuestionData)
                    await refetch({
                        _id: param?.contentId,
                    })
                    setRefetchList(false)
                    // await toast({
                    //     position: 'top',
                    //     title: 'Success',
                    //     description: `${t('ManageSurveyPage.altsuccesquestion')}`,
                    //     status: 'success',
                    //     duration: 3000,
                    //     isClosable: true,
                    // })
                }
            }
        } catch (Error) {
            console.log('in Create submit', Error)
        }
    }, [refetchList, list, setRefetchList])

    const handleOnOpenEditSurvey = (index, survey) => {
        setEditIndex(index)
        if (survey?.type == "radiogroup") {
            setMutiChoice(Object.values(survey?.choices)?.map(x => ({ choice: x })))
        } else if (survey?.type == "checkbox") {
            setMutiChoice(Object.values(survey?.choices)?.map(x => ({ choice: x })))
        } else if (survey?.type == "matrix") {
            // console.log(Object.values(survey?.columns)?.map(x => ({ value: x.value, text: x.text })))
            // setMutiChoice(Object.values(survey?.choices)?.map(x => ({ choice: x })))
            setMutiPoint(Object.values(survey?.columns)?.map(x => ({ value: x.value, text: x.text })))
            setMatrixChoice(Object.values(survey?.rows)?.map(x => ({ value: x.value, text: x.text })))
        }
        setShowEditSurvey(true)
    }

    const handleOnCloseEditSurvey = () => {
        setSurveyForm({ type: "", title: "", isRequired: "", choices: "" })
        setMutiChoice([{ choice: "" }])
        setMutiPoint([{ value: "", text: "" }])
        setMatrixChoice([{ value: "", text: "" }])
        setShowEditSurvey(false)
    }

    //Remove
    const handleOnOpenRemoveSurvey = (index) => {
        setRemoveIndex(index)
        setShowRemoveContent(true)
    }

    const handleOnCloseRemoveSurvey = () => {
        setShowRemoveContent(false)
    }

    // Content
    const handleOnOpenContent = (content) => {
        seteditorSurveyState(EditorState.createWithContent(convertFromRaw({ blocks: content?.describe?.blocks, entityMap: content?.describe?.entityMap })))
        setShowEditContent(true)
    }

    const handleOnCloseContent = () => {
        setShowEditContent(false)
    }

    // Add Survey
    const handleOnCloseAddSurvey = () => {
        console.log("Close")
        setSurveyForm({ type: "", title: "", isRequired: "", choices: "" })
        setMutiChoice([{ choice: "" }])
        setMutiPoint([{ value: "", text: "" }])
        setMatrixChoice([{ value: "", text: "" }])
        setShowAddSurvey(false)
    }

    const handleOnOpenAddSurvey = () => {
        setShowAddSurvey(true)
    }

    const handleAddSurveyContent = async (e) => {
        e.preventDefault()
        console.log(surveyForm)
        try {
            surveyForm.name = uuid()

            if (surveyForm?.isRequired === "") {
                surveyForm.isRequired = false
            }

            if (surveyForm?.type === "radiogroup") {
                console.log(Object.values(mutiChoice).map(x => (x.choice)))
                surveyForm.choices = []
                surveyForm?.choices?.push(...Object.values(mutiChoice).map(x => (x.choice)))
            } else if (surveyForm?.type === "checkbox") {
                console.log(Object.values(mutiChoice).map(x => (x.choice)))
                surveyForm.choices = []
                surveyForm?.choices?.push(...Object.values(mutiChoice).map(x => (x.choice)))
            } else if (surveyForm?.type === "matrix") {
                surveyForm.columns = []
                surveyForm?.columns?.push(...matrixChoice)
                surveyForm.rows = []
                surveyForm.rows?.push(...mutiPoint)
            }
            let questions = surveyData?.content?.surveyconfig?.surveyQuestions?.questions
            questions.push(surveyForm)

            const { data: SurveyData } = await setUpdateSurvey({
                variables: {
                    _id: param?.contentId,
                    surveyQuestions: questions,
                },
            })
            if (SurveyData) {
                // console.log(QuestionData)
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('ManageSurveyPage.altsuccesquestion')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    _id: param?.contentId,
                })
            }
        } catch (Error) {
            console.log('in Create submit', Error)
        }
        handleOnCloseAddSurvey()
    }

    const handleUpdateSurveyContent = async (e) => {
        e.preventDefault()
        try {
            if (surveyFormUpdate?.type === "radiogroup") {
                console.log(Object.values(mutiChoice).map(x => (x.choice)))
                surveyFormUpdate.choices = []
                surveyFormUpdate?.choices?.push(...Object.values(mutiChoice).map(x => (x.choice)))
            } else if (surveyFormUpdate?.type === "checkbox") {
                console.log(Object.values(mutiChoice).map(x => (x.choice)))
                surveyFormUpdate.choices = []
                surveyFormUpdate?.choices?.push(...Object.values(mutiChoice).map(x => (x.choice)))
            } else if (surveyFormUpdate?.type === "matrix") {
                surveyFormUpdate.columns = []
                surveyFormUpdate?.columns?.push(...matrixChoice)
                surveyFormUpdate.rows = []
                surveyFormUpdate.rows?.push(...mutiPoint)
            }

            let questions = surveyData?.content?.surveyconfig?.surveyQuestions?.questions
            questions?.splice(
                editIndex,
                1,
                surveyFormUpdate
            )
            // console.log(surveyFormUpdate)
            const { data: SurveyData } = await setUpdateSurvey({
                variables: {
                    _id: param?.contentId,
                    surveyQuestions: questions,
                },
            })
            if (SurveyData) {
                // console.log(QuestionData)
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('ManageSurveyPage.altsuccesquestion')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    _id: param?.contentId,
                })
            }
        } catch (Error) {
            console.log('in Create submit', Error)
        }
        handleOnCloseEditSurvey()
    }

    const handleRemoveSurvey = async (e) => {
        e.preventDefault()
        try {
            // console.log("Index", removeIndex)
            surveyData?.content?.surveyconfig?.surveyQuestions?.questions.splice(removeIndex, 1)
            let questions = surveyData?.content?.surveyconfig?.surveyQuestions?.questions
            const { data: SurveyData } = await setUpdateSurvey({
                variables: {
                    _id: param?.contentId,
                    surveyQuestions: questions,
                },
            })
            if (SurveyData) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('ManageSurveyPage.altsuccesquestion')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    _id: param?.contentId,
                })
            }
        } catch (Error) {
            console.log('in Create submit', Error)
        }
        handleOnCloseRemoveSurvey()
    }

    const handleContentUpdate = async (e) => {
        e.preventDefault()
        // console.log('content:', content?.name, content?.describe)
        if (!updateContent?.name) {
            await toast({
                position: 'top',
                title: 'warning',
                description: `${t('ManageSurveyPage.altwarningcontent')}`,
                status: 'warning',
                duration: 3000,
                isClosable: true,
            })
        } else {
            try {
                // console.log("SSSSSSSSSSSSSSSSS", updateContent?.duedate)
                const editordata = convertToRaw(editorSurveyState.getCurrentContent())
                const { data: ContentData } = await setUpdateSurvey({
                    variables: {
                        _id: param?.contentId,
                        name: updateContent?.name,
                        surveyname: updateContent?.name,
                        surveydescription: editordata,
                        statusActive: updateContent?.statusActive,
                        duedate: updateContent?.statusStartDate === true ? dayjs(updateContent?.duedate) : null,
                        startdate: updateContent?.statusStartDate === true ? dayjs(updateContent?.startdate) : null,
                        expiredate: updateContent?.statusExpireDate === true ? dayjs(updateContent?.expiredate) : null,
                    },
                })
                if (ContentData) {
                    await toast({
                        position: 'top',
                        title: 'Success',
                        description: `${t('ManageSurveyPage.altsuccesscontent')}`,
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                    refetch({
                        _id: param?.contentId,
                    })
                }
                handleOnCloseContent()
                seteditorSurveyState(EditorState.createEmpty())
            } catch (Error) {
                console.log('in Create submit', Error)
            }
        }
    }

    //MUTIPOIN
    const [mutiPoint, setMutiPoint] = useState([{ value: "", text: "" }])
    let handleChangeMutiPoint = (i, e) => {
        e.preventDefault()
        let newmutiPoint = [...mutiPoint]
        newmutiPoint[i]["text"] = e.target.value
        newmutiPoint[i]["value"] = uuid()
        setMutiPoint(newmutiPoint)
    }

    let addFormFieldsMutiPoint = () => {
        setMutiPoint([...mutiPoint, { value: `${uuid()}`, text: "" }])
    }

    let removeFormFieldsMutiPoint = (i) => {
        let newmutiPoint = [...mutiPoint];
        newmutiPoint.splice(i, 1);
        setMutiPoint(newmutiPoint)
    }

    //MATRIXCHOICE
    const [matrixChoice, setMatrixChoice] = useState([{ value: "", text: "" }])
    let handleChangeMatrixChoice = (i, e) => {
        e.preventDefault()
        let newMatrixChoice = [...matrixChoice]
        newMatrixChoice[i][e.target.name] = e.target.value
        newMatrixChoice[i]["value"] = i + 1
        setMatrixChoice(newMatrixChoice)
    }

    let handleChangeUpdateMatrixChoice = (i, e) => {
        e.preventDefault()
        let newMatrixChoice = [...matrixChoice]
        newMatrixChoice[i]["text"] = e.target.value
        setMatrixChoice(newMatrixChoice)
    }

    let addFormFieldsMatrixChoice = () => {
        setMatrixChoice([...matrixChoice, { value: "", text: "" }])
    }

    let removeFormFieldsMatrixChoice = (i) => {
        let newMatrixChoice = [...matrixChoice];
        newMatrixChoice.splice(i, 1);
        setMatrixChoice(newMatrixChoice)
    }
    console.log("MT::", matrixChoice)

    //MATRIXCHOICE
    const [mutiChoice, setMutiChoice] = useState([{ choice: "" }])
    let handleChangeMutiChoice = (i, e) => {
        e.preventDefault()
        let newmutiChoice = [...mutiChoice]
        newmutiChoice[i][e.target.name] = e.target.value
        setMutiChoice(newmutiChoice)
    }

    let addFormFieldsMutiChoice = () => {
        setMutiChoice([...mutiChoice, { choice: "", }])
    }

    let removeFormFieldsMutiChoice = (i) => {
        let newmutiChoice = [...mutiChoice];
        newmutiChoice.splice(i, 1);
        setMutiChoice(newmutiChoice)
    }

    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }
    const bgGrid = useColorModeValue('#E2E8F0', 'gray.900')

    return (
        <Fragment>
            <Modal isOpen={showAddSurvey} onClose={handleOnCloseAddSurvey} size={'5xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="extrabold">{t('ManageSurveyPage.addsurvey')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleAddSurveyContent} as={GridItem} colSpan={[6, 3]}>
                        <ModalBody>
                            <Select defaultValue=' ' onChange={(e) => setSurveyForm({ type: e.target.value })} >
                                <option value=' '>{t('ManageSurveyPage.choosetype')}</option>
                                <option value='radiogroup'>{t('ManageSurveyPage.radiogroup')}</option>
                                <option value='matrix'>{t('ManageSurveyPage.matrix')}</option>
                                <option value='checkbox'>{t('ManageSurveyPage.checkbox')}</option>
                                <option value='text'>{t('ManageSurveyPage.text')}</option>
                            </Select>
                            {surveyForm?.type === 'radiogroup' && (
                                <Box>
                                    <FormLabel as="legend" fontSize="lg" p={2}>
                                        {t('ManageSurveyPage.question')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Textarea
                                            resize="vertical"
                                            size="lg"
                                            placeholder={t('ManageSurveyPage.surveyplaceholder')}
                                            onChange={(e) => setSurveyForm({ title: e.target.value })}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <Switch size='lg' mr={'2'} py={'2'} colorScheme='green' isChecked={surveyForm?.isRequired} onChange={(e) => setSurveyForm({ isRequired: e.target.checked })} />
                                        {surveyForm?.isRequired ? t('ManageSurveyPage.required') : t('ManageSurveyPage.nonerequired')}
                                    </FormControl>

                                    <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                        <GridItem colStart={1} colEnd={5} py={2}>
                                            <FormLabel as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.listscore')}</FormLabel>
                                        </GridItem>
                                        <GridItem colStart={6} colEnd={12} py={2}>
                                            <Button size="sm" colorScheme="red" variant="outline" rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiChoice()} >{t('ManageSurveyPage.addchoice')}</Button>
                                        </GridItem>
                                    </Grid>

                                    {mutiChoice?.map((element, index) => (
                                        <FormControl id="">
                                            <InputGroup mt={2} key={index}>
                                                <InputLeftAddon children={index + 1} />
                                                <Input
                                                    isRequired
                                                    name="choice"
                                                    placeholder={t('ManageSurveyPage.choice')}
                                                    onChange={(e) => handleChangeMutiChoice(index, e)}
                                                />
                                                <InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiChoice} />} />
                                            </InputGroup>
                                        </FormControl>
                                    ))}
                                </Box>
                            )}
                            {surveyForm?.type === 'checkbox' && (
                                <Box>
                                    <FormLabel as="legend" fontSize="lg" p={2}>
                                        {t('ManageSurveyPage.question')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Textarea
                                            resize="vertical"
                                            size="lg"
                                            placeholder={t('ManageSurveyPage.surveyplaceholder')}
                                            onChange={(e) => setSurveyForm({ title: e.target.value })}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <Switch size='lg' mr={'2'} py={'2'} colorScheme='green' isChecked={surveyForm?.isRequired} onChange={(e) => setSurveyForm({ isRequired: e.target.checked })} />
                                        {surveyForm?.isRequired ? t('ManageSurveyPage.required') : t('ManageSurveyPage.nonerequired')}
                                    </FormControl>

                                    <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                        <GridItem colStart={1} colEnd={5} py={2}>
                                            <FormLabel as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.listscore')}</FormLabel>
                                        </GridItem>
                                        <GridItem colStart={6} colEnd={12} py={2}>
                                            <Button size="sm" colorScheme="red" variant="outline" rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiChoice()} >{t('ManageSurveyPage.addchoice')}</Button>
                                        </GridItem>
                                    </Grid>

                                    {mutiChoice?.map((element, index) => (
                                        <FormControl id="">
                                            <InputGroup mt={2} key={index}>
                                                <InputLeftAddon children={index + 1} />
                                                <Input
                                                    isRequired
                                                    name="choice"
                                                    placeholder={t('ManageSurveyPage.choice')}
                                                    onChange={(e) => handleChangeMutiChoice(index, e)}
                                                />
                                                <InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiChoice} />} />
                                            </InputGroup>
                                        </FormControl>
                                    ))}
                                </Box>
                            )}
                            {surveyForm?.type === 'matrix' && (
                                <Box>
                                    <FormLabel as="legend" fontSize="lg" p={2}>
                                        {t('ManageSurveyPage.question')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Textarea
                                            resize="vertical"
                                            size="lg"
                                            placeholder={t('ManageSurveyPage.surveyplaceholder')}
                                            onChange={(e) => setSurveyForm({ title: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Switch size='lg' mr={'2'} py={'2'} colorScheme='green' isChecked={surveyForm?.isRequired} onChange={(e) => setSurveyForm({ isRequired: e.target.checked })} />
                                        {surveyForm?.isRequired ? t('ManageSurveyPage.required') : t('ManageSurveyPage.nonerequired')}
                                    </FormControl>

                                    <Tabs variant='enclosed'  >
                                        <TabList mb='1em'>
                                            <Tab>{t('ManageSurveyPage.list')}</Tab>
                                            <Tab>{t('ManageSurveyPage.listscore')}</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel>
                                                <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                                    <GridItem colStart={1} colEnd={5} >
                                                        <FormLabel as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.list')}</FormLabel>
                                                    </GridItem>
                                                    <GridItem colStart={6} colEnd={12} >
                                                        <Button size="sm" colorScheme="red" variant="outline" rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiPoint()} >{t('ManageSurveyPage.addchoice')}</Button>
                                                    </GridItem>
                                                </Grid>
                                                {mutiPoint?.map((element, index) => (
                                                    <FormControl id="">
                                                        <InputGroup mt={2} key={index}>
                                                            <InputLeftAddon children={index + 1} />
                                                            <Input
                                                                isRequired
                                                                name="text"
                                                                placeholder={t('ManageSurveyPage.choice')}
                                                                onChange={(e) => handleChangeMutiPoint(index, e)}
                                                            />
                                                            <InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiPoint} />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                ))}
                                            </TabPanel>
                                            <TabPanel>
                                                <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                                    <GridItem colStart={1} colEnd={5} >
                                                        <FormLabel as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.listscore')}</FormLabel>
                                                    </GridItem>
                                                    <GridItem colStart={6} colEnd={12} >
                                                        <Button size="sm" colorScheme="red" variant="outline" rightIcon={<AddIcon />} onClick={() => addFormFieldsMatrixChoice()} >{t('ManageSurveyPage.addchoice')}</Button>
                                                    </GridItem>
                                                </Grid>
                                                {matrixChoice?.map((element, index) => (
                                                    <FormControl id="">
                                                        <InputGroup mt={2} key={index}>
                                                            <InputLeftAddon children={index + 1} />
                                                            <Input
                                                                isRequired
                                                                name="text"
                                                                placeholder={t('ManageSurveyPage.choice')}
                                                                onChange={(e) => handleChangeMatrixChoice(index, e)}
                                                            />
                                                            <InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMatrixChoice} />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                ))}
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </Box>
                            )}
                            {surveyForm?.type === 'text' && (
                                <Box>
                                    <FormLabel as="legend" fontSize="lg" p={2}>
                                        {t('ManageSurveyPage.question')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Textarea
                                            resize="vertical"
                                            size="lg"
                                            placeholder={t('ManageSurveyPage.surveyplaceholder')}
                                            onChange={(e) => setSurveyForm({ title: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormLabel as="legend" fontSize="lg" p={2}>
                                        {t('ManageSurveyPage.textplaceholder')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Input
                                            resize="vertical"
                                            size="lg"
                                            placeholder={t('ManageSurveyPage.placeholdertext')}
                                            onChange={(e) => setSurveyForm({ placeHolder: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Switch size='lg' mr={'2'} py={'2'} colorScheme='green' isChecked={surveyForm?.isRequired} onChange={(e) => setSurveyForm({ isRequired: e.target.checked })} />
                                        {surveyForm?.isRequired ? t('ManageSurveyPage.required') : t('ManageSurveyPage.nonerequired')}
                                    </FormControl>
                                </Box>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={3} onClick={handleOnCloseAddSurvey}>
                                {t('Button.cancel')}
                            </Button>
                            <Button type="submit" bg={'primary.700'} color={'white'} _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }} mr={3}>
                                {t('Button.add')}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            <Modal isOpen={showEditSurvey} onClose={handleOnCloseEditSurvey} size={'5xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="extrabold">{t('ManageSurveyPage.editquestion')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleUpdateSurveyContent} as={GridItem} colSpan={[6, 3]}>
                        <ModalBody>
                            {surveyFormUpdate?.type === 'radiogroup' && (
                                <Box>
                                    <FormLabel as="legend" fontSize="lg" p={2}>
                                        {t('ManageSurveyPage.question')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Textarea
                                            resize="vertical"
                                            size="lg"
                                            placeholder={t('ManageSurveyPage.questionplaceholder')}
                                            value={surveyFormUpdate?.title}
                                            onChange={(e) => setSurveyFormUpdate({ title: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Switch size='lg' mr={'2'} py={'2'} colorScheme='green' isChecked={surveyFormUpdate?.isRequired} onChange={(e) => setSurveyFormUpdate({ isRequired: e.target.checked })} />
                                        {surveyFormUpdate?.isRequired ? t('ManageSurveyPage.required') : t('ManageSurveyPage.nonerequired')}
                                    </FormControl>
                                    <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                        <GridItem colStart={1} colEnd={5} py={2}>
                                            <Text as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.list')}</Text>
                                        </GridItem>
                                        <GridItem colStart={6} colEnd={12} py={2}>
                                            <Button size="sm" colorScheme="red" variant="outline" rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiChoice()} >{t('ManageSurveyPage.addchoice')}</Button>
                                        </GridItem>
                                    </Grid>
                                    {mutiChoice?.map((element, index) => (
                                        <FormControl id="">
                                            <InputGroup mt={2}>
                                                <InputLeftAddon children={index + 1} />
                                                <Input
                                                    isRequired
                                                    name="choice"
                                                    value={element.choice}
                                                    placeholder={t('ManageSurveyPage.choice')}
                                                    onChange={(e) => handleChangeMutiChoice(index, e)}
                                                />
                                                <InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiChoice} />} />
                                            </InputGroup>
                                        </FormControl>
                                    ))}
                                </Box>
                            )}
                            {surveyFormUpdate?.type === 'checkbox' && (
                                <Box>
                                    <FormLabel as="legend" fontSize="lg" p={2}>
                                        {t('ManageSurveyPage.question')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Textarea
                                            resize="vertical"
                                            size="lg"
                                            placeholder={t('ManageSurveyPage.questionplaceholder')}
                                            value={surveyFormUpdate?.title}
                                            onChange={(e) => setSurveyFormUpdate({ title: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Switch size='lg' mr={'2'} py={'2'} colorScheme='green' isChecked={surveyFormUpdate?.isRequired} onChange={(e) => setSurveyFormUpdate({ isRequired: e.target.checked })} />
                                        {surveyFormUpdate?.isRequired ? t('ManageSurveyPage.required') : t('ManageSurveyPage.nonerequired')}
                                    </FormControl>
                                    <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                        <GridItem colStart={1} colEnd={5} py={2}>
                                            <Text as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.list')}</Text>
                                        </GridItem>
                                        <GridItem colStart={6} colEnd={12} py={2}>
                                            <Button size="sm" colorScheme="red" variant="outline" rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiChoice()} >{t('ManageSurveyPage.addchoice')}</Button>
                                        </GridItem>
                                    </Grid>
                                    {mutiChoice?.map((element, index) => (
                                        <FormControl id="">
                                            <InputGroup mt={2}>
                                                <InputLeftAddon children={index + 1} />
                                                <Input
                                                    isRequired
                                                    name="choice"
                                                    value={element?.choice}
                                                    placeholder={t('ManageSurveyPage.choice')}
                                                    onChange={(e) => handleChangeMutiChoice(index, e)}
                                                />
                                                <InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiChoice} />} />
                                            </InputGroup>
                                        </FormControl>
                                    ))}
                                </Box>
                            )}
                            {surveyFormUpdate?.type === 'matrix' && (
                                <Box>
                                    <FormLabel as="legend" fontSize="lg" p={2}>
                                        {t('ManageSurveyPage.question')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Textarea
                                            resize="vertical"
                                            size="lg"
                                            value={surveyFormUpdate?.title}
                                            placeholder={t('ManageSurveyPage.surveyplaceholder')}
                                            onChange={(e) => setSurveyForm({ title: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Switch size='lg' mr={'2'} py={'2'} colorScheme='green' isChecked={surveyFormUpdate?.isRequired} onChange={(e) => setSurveyFormUpdate({ isRequired: e.target.checked })} />
                                        {surveyFormUpdate?.isRequired ? t('ManageSurveyPage.required') : t('ManageSurveyPage.nonerequired')}
                                    </FormControl>
                                    <Tabs isFitted variant='enclosed'>
                                        <TabList mb='1em'>
                                            <Tab>{t('ManageSurveyPage.list')}</Tab>
                                            <Tab>{t('ManageSurveyPage.listscore')}</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel>
                                                <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                                    <GridItem colStart={1} colEnd={5} py={2}>
                                                        <Text as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.list')}</Text>
                                                    </GridItem>
                                                    <GridItem colStart={6} colEnd={12} py={2}>
                                                        <Button size="sm" colorScheme="red" variant="outline" rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiPoint()} >{t('ManageSurveyPage.addchoice')}</Button>
                                                    </GridItem>
                                                </Grid>
                                                {mutiPoint?.map((element, index) => (
                                                    <FormControl id="">
                                                        <InputGroup mt={2} key={index}>
                                                            <InputLeftAddon children={index + 1} />
                                                            <Input
                                                                isRequired
                                                                name="text"
                                                                value={element?.text}
                                                                placeholder={t('ManageSurveyPage.choice')}
                                                                onChange={(e) => handleChangeMutiPoint(index, e)}
                                                            />
                                                            <InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiPoint} />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                ))}
                                            </TabPanel>
                                            <TabPanel>
                                                <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                                    <GridItem colStart={1} colEnd={5} py={2}>
                                                        <Text as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.listscore')}</Text>
                                                    </GridItem>
                                                    <GridItem colStart={6} colEnd={12} py={2}>
                                                        <Button size="sm" colorScheme="red" variant="outline" rightIcon={<AddIcon />} onClick={() => addFormFieldsMatrixChoice()} >{t('ManageSurveyPage.addchoice')}</Button>
                                                    </GridItem>
                                                </Grid>
                                                {matrixChoice?.map((element, index) => (
                                                    <FormControl id="">
                                                        <InputGroup mt={2} key={index}>
                                                            <InputLeftAddon children={index + 1} />
                                                            <Input
                                                                isRequired
                                                                name="text"
                                                                value={element?.text}
                                                                placeholder={t('ManageSurveyPage.listscore')}
                                                                onChange={(e) => handleChangeUpdateMatrixChoice(index, e)}
                                                            />
                                                            <InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMatrixChoice} />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                ))}
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </Box>
                            )}
                            {surveyFormUpdate?.type === 'text' && (
                                <Box>
                                    <FormLabel as="legend" fontSize="lg">
                                        {t('ManageSurveyPage.question')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Textarea
                                            resize="vertical"
                                            size="lg"
                                            mt={2}
                                            value={surveyFormUpdate?.title}
                                            placeholder={t('ManageSurveyPage.surveyplaceholder')}
                                            onChange={(e) => setSurveyFormUpdate({ title: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormLabel as="legend" fontSize="lg">
                                        {t('ManageSurveyPage.question')}
                                    </FormLabel>
                                    <FormControl id="">
                                        <Input
                                            resize="vertical"
                                            size="lg"
                                            mt={2}
                                            value={surveyFormUpdate?.placeHolder}
                                            placeholder={t('ManageSurveyPage.placeholdertext')}
                                            onChange={(e) => setSurveyFormUpdate({ placeHolder: e.target.value })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Switch size='lg' mr={'2'} py={'2'} colorScheme='green' isChecked={surveyFormUpdate?.isRequired} onChange={(e) => setSurveyFormUpdate({ isRequired: e.target.checked })} />
                                        {surveyFormUpdate?.isRequired ? t('ManageSurveyPage.required') : t('ManageSurveyPage.nonerequired')}
                                    </FormControl>
                                </Box>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={3} onClick={handleOnCloseEditSurvey}>
                                {t('Button.cancel')}
                            </Button>
                            <Button type="submit" bg={'primary.700'} color={'white'} _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }} mr={3}>
                                {t('Button.save')}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            <Modal size={'5xl'} isOpen={showEditContent} onClose={handleOnCloseContent}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="extrabold">{t('ManageSurveyPage.editcontent')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleContentUpdate} as={GridItem} colSpan={[6, 3]}>
                        <ModalBody>
                            <FormControl id="">
                                <FormLabel as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.name')}</FormLabel>
                                <InputGroup mt={2}>
                                    <Input
                                        resize="vertical"
                                        size="lg"
                                        placeholder={t('ManageSurveyPage.contentplaceholder')}
                                        value={updateContent?.name}
                                        onChange={(e) => setUpdateContent({ name: e.target.value })}
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl id="" py={2}>
                                <FormLabel as="legend" fontSize="lg" p={2}>{t('ManageSurveyPage.description')}</FormLabel>
                                <Editor
                                    editorState={editorSurveyState}
                                    onEditorStateChange={seteditorSurveyState}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                    }}
                                    editorStyle={{ border: "1px solid" }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel as="legend" fontSize="lg">{t('ManageSurveyPage.time')}</FormLabel>
                                <Checkbox size='md' isChecked={updateContent?.statusStartDate} colorScheme='green' onChange={(e) => setUpdateContent({ statusStartDate: e.target.checked })}>
                                    {t('ManageSurveyPage.starttime')}
                                </Checkbox>
                            </FormControl>
                            <FormControl>
                                {updateContent?.statusStartDate === true ?
                                    <Box>
                                        <Box mb={3} mt={3}>
                                            <ThemeProvider theme={theme}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DateTimePicker
                                                        // disableFuture
                                                        label={t('ManageSurveyPage.datetimeStart')}
                                                        // openTo="year"
                                                        // minDate={dayjs(Date.now())}
                                                        // views={['year', 'month', 'day']}
                                                        value={updateContent.startdate ? dayjs(updateContent.startdate) : ''}
                                                        onChange={(e) => setUpdateContent({ startdate: e })}
                                                        renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </ThemeProvider>
                                        </Box>
                                        {updateContent.startdate &&
                                            <ThemeProvider theme={theme}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DateTimePicker
                                                        // disableFuture
                                                        label={t('ManageSurveyPage.datetimeEnd')}
                                                        // openTo="year"
                                                        // minDate={content.startdate ? content.startdate : ""}
                                                        minDateTime={updateContent.startdate ? dayjs(updateContent.startdate) : ""}
                                                        // views={['year', 'month', 'day']}
                                                        value={updateContent.duedate ? dayjs(updateContent.duedate) : ''}
                                                        onChange={(e) => setUpdateContent({ duedate: e })}
                                                        renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </ThemeProvider>}
                                    </Box> : null}
                            </FormControl>
                            <Checkbox isChecked={updateContent?.statusExpireDate} size='md' colorScheme='green' onChange={(e) => setUpdateContent({ statusExpireDate: e.target.checked })}>
                                {t('ManageSurveyPage.expiredate')}
                            </Checkbox>
                            <FormControl>
                                {updateContent?.statusExpireDate === true &&
                                    <Box>
                                        <Box mb={3} mt={3}>
                                            <ThemeProvider theme={theme}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DateTimePicker
                                                        label={t('ManageSurveyPage.datetimeExpiredate')}
                                                        value={updateContent.expiredate ? dayjs(updateContent.expiredate) : ""}
                                                        onChange={(e) => setUpdateContent({ expiredate: e })}
                                                        renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </ThemeProvider>
                                        </Box>
                                    </Box>
                                }
                            </FormControl>
                            <FormControl>
                                <FormLabel as="legend" fontSize="lg">{t('ManageSurveyPage.status')}</FormLabel>
                                <Switch size='lg' mr={'2'} py={'2'} colorScheme='green' isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContent({ statusActive: e.target.checked })} />
                                {updateContent?.statusActive ? t('ManageSurveyPage.public') : t('ManageSurveyPage.nonepublic')}
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={3} onClick={handleOnCloseContent}>
                                {t('Button.close')}
                            </Button>
                            <Button type="submit " bg={'primary.700'} color={'white'} _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }} mr={3}>
                                {t('Button.save')}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            <AlertDialog isOpen={showRemoveSurvey} onClose={handleOnCloseRemoveSurvey}>
                <AlertDialogOverlay>
                    <form onSubmit={handleRemoveSurvey} as={GridItem} colSpan={[6, 3]}>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                {t('ManageSurveyPage.removequestion')}
                            </AlertDialogHeader>
                            <AlertDialogBody><Center>{t('ManageSurveyPage.removedescription')}</Center></AlertDialogBody>
                            <AlertDialogFooter>
                                <Button onClick={handleOnCloseRemoveSurvey}>{t('ManageSurveyPage.buttoncancel')}</Button>
                                <Button bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }} type="submit" ml={3}>
                                    {t('ManageSurveyPage.buttondelete')}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </form>
                </AlertDialogOverlay>
            </AlertDialog>

            <Box w="100%" px={[1, 10, 20, 20]} minHeight={'90vh'}>
                <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
                    <Stack spacing={0} align={'center'} >
                        <Heading variant="h4">
                            {t('ManageSurveyPage.title')}
                        </Heading>
                        <Text>{t('ManageSurveyPage.subtitle')}</Text>
                    </Stack>
                    <Tabs isFitted variant='enclosed' py={10}>
                        <TabList>
                            <Tab>{t('ManageSurveyPage.manage')}</Tab>
                            <Tab>{t('ManageSurveyPage.preview')}</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel borderWidth='1px' overflow='hidden' >
                                <Container maxW={'7xl'} as={Stack} spacing={5}>
                                    <Box spacing={0} borderRadius='lg' overflow='hidden' p={2}>
                                        <FormControl id="">
                                            <InputGroup py={3}>
                                                <Text as="legend" fontSize="2xl" fontWeight="extrabold">{t('ManageSurveyPage.survey')} {content?.statusActive ? <Badge colorScheme='green' ml='1'>{t('ManageSurveyPage.public')}</Badge> : <Badge ml='1'>{t('ManageSurveyPage.nonepublic')}</Badge>}</Text>
                                                <InputRightElement width="4.7rem" mt={3}>
                                                    <Tooltip label={t('ManageSurveyPage.tooltipedit')} fontSize="sm">
                                                        <Button size='sm' leftIcon={<EditIcon />} fontSize="sm" colorScheme="teal" variant="solid" onClick={(e) => {
                                                            e.preventDefault()
                                                            handleOnOpenContent(content)
                                                        }}>
                                                            {t('ManageSurveyPage.edit')}
                                                        </Button>
                                                    </Tooltip>
                                                </InputRightElement>
                                            </InputGroup>
                                            <Text borderWidth='2px' borderRadius='lg' overflow='hidden' p={2} fontSize='md' mt={1} >{content?.name}</Text>
                                        </FormControl>
                                        <FormControl mt={2} id="">
                                            <FormLabel as="legend" fontSize="xl" fontWeight="extrabold">{t('ManageSurveyPage.description')}</FormLabel>
                                            <Text mt={2} minH={'15vh'} borderWidth='2px' borderRadius='lg' overflow='hidden' p={2}>{handleConvertHTML(content?.describe)}</Text>
                                        </FormControl>
                                        <Grid templateColumns="repeat(5, 1fr)" gap={4} py={3}>
                                            <GridItem colStart={1} colEnd={5} py={2}>
                                                <Text as="legend" fontSize="2xl" fontWeight="extrabold">{t('ManageSurveyPage.list')}</Text>
                                            </GridItem>
                                            <GridItem colStart={6} colEnd={12} py={2}>
                                                <Tooltip label={t('ManageSurveyPage.addsurvey')} fontSize="md">
                                                    <Button leftIcon={<AddIcon />} colorScheme="teal" variant="solid" size='sm' onClick={handleOnOpenAddSurvey}>
                                                        {t('ManageSurveyPage.addsurvey')}
                                                    </Button>
                                                </Tooltip>
                                            </GridItem>
                                        </Grid>
                                        <Box borderWidth='2px' borderRadius='lg' overflow='hidden' p={2} >
                                            <OrderGroup className={'group'} mode={'between'} >
                                                {list?.map((survey, i) => {
                                                    return (
                                                        <OrderItem key={i}
                                                            index={i}
                                                            onMove={(to) => {
                                                                setRefetchList(true)
                                                                setList(arrayMove(list, i, to))
                                                            }}
                                                            wrapperClassName={'wrapper'}
                                                            wrapperStyle={{
                                                                width: 'revert', left: '0px', '@media screen and (maxWidth: 600px)': {
                                                                    left: '40px'
                                                                }
                                                            }}
                                                            inactiveStyle={{ background: bgGrid }}
                                                            wrapperHoverClassName={'hover'}
                                                            className={'item'}
                                                            grabbingClassName={'grabbing'}
                                                            // grabbingStyle={{}}
                                                            style={{
                                                                width: 'auto', height: "150px"
                                                            }}
                                                        >
                                                            <OrderItem.Handle className={'handle'}>
                                                                <VStack >
                                                                    <HamburgerIcon color={'black'} />
                                                                    <Tooltip label={t('managesubjectPage.forum')} fontSize="md">
                                                                        <IconButton icon={<EditIcon color={'black'} />} size='25px' mr={2} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            handleOnOpenEditSurvey(i, survey)
                                                                            setSurveyFormUpdate(survey)
                                                                        }} />
                                                                    </Tooltip>
                                                                    <Tooltip label={t('managesubjectPage.forum')} fontSize="md">
                                                                        <IconButton icon={<CloseIcon color={'black'} />} size='22px' mr={2} onClick={(e) => {
                                                                            e.preventDefault()
                                                                            handleOnOpenRemoveSurvey(i)
                                                                        }} />
                                                                    </Tooltip>
                                                                </VStack>
                                                            </OrderItem.Handle>

                                                            <Grid
                                                                className={'content'} id="" py={2}
                                                                templateRows='repeat(2, 1fr)'
                                                                templateColumns='repeat(5, 1fr)'
                                                                gap={1}
                                                            // bg={bgGrid}
                                                            >
                                                                <GridItem colSpan={4} h='20%' >
                                                                    <Flex justifyContent={'center'} py={2} ml={10} borderRadius={'lg'} borderWidth={'1px'}>
                                                                        <InputGroup size={['xs', 'sm', 'lg']} borderRadius={'lg'} borderWidth={'1px'} >
                                                                            <InputLeftAddon sx={{ fontSize: ['xs', 'sm', 'md'] }} children={t('ManageSurveyPage.question')} height="3vh" p={5} />
                                                                            <Input isReadOnly fontSize={['xs', 'sm', 'lg']} value={survey?.title} height="3vh" p={5} />
                                                                        </InputGroup>
                                                                    </Flex>
                                                                </GridItem>
                                                                <GridItem colSpan={[4, 2]}>
                                                                    <Flex justifyContent={'center'} py={2} ml={10}>
                                                                        <InputGroup colorScheme={'blue'} size={['xs', 'sm', 'lg']} borderRadius={'lg'} borderWidth={'1px'}>
                                                                            <InputLeftAddon sx={{ fontSize: ['xs', 'sm', 'md'] }} children={t('ManageSurveyPage.type')} p={1} />
                                                                            <Input isReadOnly fontSize={['xs', 'sm', 'lg']} value={survey?.type} p={1} />
                                                                        </InputGroup>
                                                                    </Flex>
                                                                </GridItem>
                                                                <GridItem colSpan={[4, 2]} >
                                                                    <Flex justifyContent={'start'} py={2} ml={10}>
                                                                        <Switch size='lg' mr={'2'} colorScheme='green' isChecked={survey?.isRequired} isReadOnly />
                                                                        {survey?.isRequired ? t('ManageSurveyPage.required') : t('ManageSurveyPage.nonerequired')}
                                                                    </Flex>
                                                                </GridItem>
                                                            </Grid>
                                                        </OrderItem>
                                                    )
                                                })}
                                                {list?.length == 0 &&
                                                    <Box align="center" w={'full'} py={'20'} borderRadius={'md'} color={'gray.500'} bg={'gray.200'}><Heading>{t('managerQuestionPage.no_question')}</Heading></Box>
                                                }
                                            </OrderGroup>
                                        </Box>
                                    </Box>
                                </Container>
                            </TabPanel>
                            <TabPanel borderWidth='1px' overflow='hidden' >

                                <Box borderRadius='lg' overflow='hidden'>
                                    <Text as="legend" fontSize="2xl" fontWeight="extrabold">{content?.name}</Text>
                                    <Text mt={2} p={2} borderWidth='2px' borderRadius='lg' overflow='hidden'>{handleConvertHTML(content?.describe)}</Text>
                                </Box>
                                <Box borderWidth={'1px'} borderRadius={'lg'} mt={2} p={2}>
                                    <Survey.Survey
                                        json={surveyData ? { questions: surveyData?.content?.surveyconfig?.surveyQuestions?.questions } : { questions: {} }}
                                        showCompletedPage={false}
                                        mode="display"
                                    />
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            </Box>
        </Fragment>
    )
}

export default ManageSurveyPage
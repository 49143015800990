import { Fragment, useContext, useState, useEffect } from 'react'
import {
    Box,
    Heading,
    Text,
    Input,
    Container,
    useColorMode,
    HStack,
    Image,
    Link,
    Button,
    Tabs,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Flex,
    Divider,
    useToast,
    InputGroup,
    InputLeftAddon,
    Alert,
    AlertIcon,
} from '@chakra-ui/react'
import axios from 'axios'
import { Dropzone, FileItem } from '@dropzone-ui/react'
import { useMultiState } from "../lib/hooks"
import { useMutation, useQuery } from '@apollo/client'
import subjectQuery from '../graphql/queries/subject'
import StudentOfSubjectMutation from '../graphql/mutations/createStudentOfSubject'
import studentofsubjectupdate from '../graphql/mutations/updateStudentOfSubject'
import studentofsubjectsQueries from '../graphql/queries/studentofsubject'
import studentofsubjectsRemoveMutation from '../graphql/mutations/removeStudentofSubject'
import configforumbyID from '../graphql/queries/configforumbyID'
import progressbyscore from '../graphql/queries/progress'
import progressUpdate from '../graphql/mutations/createProgress'
import Certificate from '../graphql/queries/certificate'
import { useParams } from 'react-router'
import OneSubjectLayoutSkeleton from '../components/skeleton/OneSubjectLayoutSkeleton'
import draftToHtml from 'draftjs-to-html';
import renderHTML from 'react-render-html'
import { useHistory } from 'react-router'
import Iframe from 'react-iframe'
import AuthContext from '../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link as domlink } from 'react-router-dom'
import SubjectCard from '../components/pages/SubjectPage/SubjectCard'
import DialogEnrollSubject from '../components/pages/SubjectPage/DialogEnrollSubject'
import DialogUnEnrollSubject from '../components/pages/SubjectPage/DialogUnEnrollSubject'
import ContentCard from '../components/pages/SubjectPage/ContentCard'

import dayjs from 'dayjs'

const R = require('ramda')

require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)





const URLImage = process.env.REACT_APP_UPLOAD_IMAGE

const SubjectPage = () => {

    const myCurrentDate = new Date()

    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { user } = useContext(AuthContext)
    const params = useParams()
    const toast = useToast()
    const history = useHistory()

    const [enrollState, setEnrollState] = useMultiState({ certificatestatus: false, creditstatus: false, name: '', _id: '' })

    const [modalDisplay, setModalDisplay] = useMultiState({ name: '', type: '', link: '', doctype: '' })
    const [contentState, setContentState] = useState()
    const [progressState, setProgressState] = useState()
    const [updatePayState, setUpdatePayState] = useState()


    const { isOpen: ModalState, onOpen: openModal, onClose: closeModal } = useDisclosure()
    const { isOpen: PayModalState, onOpen: openPayModal, onClose: closePayModal } = useDisclosure()
    const { isOpen: dialogEnrollSubjectToggle, onOpen: openDialogEnrollSubject, onClose: closeDialogEnrollSubject } = useDisclosure()
    const { isOpen: dialogUnEnrollSubjectToggle, onOpen: openDialogUnEnrollSubject, onClose: closeDialogUnEnrollSubject } = useDisclosure()

    const [setStudentOfSubjectMutation] = useMutation(StudentOfSubjectMutation)
    const [setstudentofsubjectsRemoveMutation] = useMutation(studentofsubjectsRemoveMutation)
    const [setStudentofsubjectUpdateMutation] = useMutation(studentofsubjectupdate)
    const [setUpdateProgress] = useMutation(progressUpdate)



    const [filesImg, setFilesImg] = useState([])
    const [newphotoData, setnewphotoData] = useMultiState({
        data: null,
        base64: '',
    })

    const onDeleteImg = (id) => {
        setFilesImg(filesImg.filter((x) => x.id !== id))
    }




    const { data: subjectData, refetch: refetchSubject, loading } = useQuery(subjectQuery, {
        variables: {
            _id: params?.subjectId,
        },
    }, { fetchPolicy: "network-only", })

    const { data: responseForum, refetch: refetchForumData, } = useQuery(configforumbyID, {
        variables: {
            _id: params?.subjectId,
        },
    }, { fetchPolicy: "network-only", })

    const { data: responseStudentEnroll, refetch: refetchStudentEnroll } = useQuery(studentofsubjectsQueries, {
        variables: {
            subject: params?.subjectId,
            student: user?._id ? user?._id : null,
        },
    }, { fetchPolicy: "network-only", })

    const { data: progressvalue, } = useQuery(progressbyscore, {
        variables: {
            student: user?._id ? user?._id : null,
        },
    }, { fetchPolicy: "network-only", })


    const { data: certificate, refetch: refetchCertificate } = useQuery(Certificate, {
        variables: {
            subject: params?.subjectId,
            student: user?._id ? user?._id : null,
        },
    }, { fetchPolicy: "network-only", })

    useEffect(() => {
        if (progressvalue) {
            setProgressState(progressvalue)
        }
        if (certificate) {
            setEnrollState(certificate?.studentofsubject)
        }
        if (responseStudentEnroll) {
            setUpdatePayState(responseStudentEnroll?.studentofsubject?._id)
        }
    }, [progressvalue, certificate])

    const sortInsert = (acc, cur) => {
        if (subjectData?.subject?.sorting) {
            var toIdx = R.indexOf(cur?._id, subjectData?.subject?.sorting);
            acc[toIdx] = cur;
            return acc;
        }
    }

    useEffect(async () => {
        if (subjectData) {
            var sort = R.reduce(sortInsert, [])
            setContentState(await sort(subjectData?.subject?.contents))
        }
    }, [subjectData, enrollState])

    const handleSubmitEnrollSubject = async (e) => {
        e.preventDefault()
        try {
            const { data: enrollData } = await setStudentOfSubjectMutation(
                {
                    variables: {
                        subject: subjectData?.subject?._id,
                        student: user?._id,
                        certificatestatus: enrollState?.certificatestatus,
                        creditstatus: enrollState?.creditstatus,
                        name: enrollState?.certificatestatus ? enrollState?.name : ''
                    },
                }
            )
            if (enrollData) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('subjectPage.alertAddComplete')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetchPage()
            }
            closeDialogEnrollSubject()
        } catch (error) {
            console.error(error)
        }
    }

    const handleUpdatePayment = async (e) => {
        e.preventDefault()
        try {
            const responseImage = await axios.post(URLImage, {
                photo: newphotoData?.base64,
            })
            const { data: responseContent } = await setStudentofsubjectUpdateMutation({
                variables: {
                    _id: updatePayState,
                    payment_image: responseImage ? responseImage?.data?.url : "",
                },
            });
            if (responseContent) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('subjectPage.alertAddComplete')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetchPage()
            }
            closeDialogEnrollSubject()
        } catch (error) {
            console.error(error)
        }
    }

    //Alert dialog remove Enroll
    const handleSubmitUnEnrollSubject = async (e) => {
        e.preventDefault()
        try {
            const { data: enrollData } = await setstudentofsubjectsRemoveMutation({
                variables: {
                    _id: certificate?.studentofsubject?._id
                },
            })
            if (enrollData) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('subjectPage.alertDeleteComplete')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetchPage()
            }
            closeDialogUnEnrollSubject()
        } catch (error) {
            console.error(error)
        }
    }

    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }

    // const setStatusActive = async (id) => {
    // 	const { data: SubjectData } = await setContentMutation({
    // 		variables: {
    // 			_id: id,
    // 			statusActive: false,
    // 			expiredate: null
    // 		}
    // 	})
    // }


    const handleUpdateProgress = async (content, progress) => {
        try {
            const { data: ProgressData } = await setUpdateProgress({
                variables: {
                    content: content?._id,
                    student: user?._id,
                    progress: progress,
                    path: window.location.href
                }
            })
            if (ProgressData) {
                console.log(ProgressData)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const alertJoincourse = () => {
        return (toast({
            position: 'top',
            title: 'warning',
            description: `${t('subjectPage.textwarning')}`,
            status: 'warning',
            duration: 3000,
            isClosable: true,
        }))
    }


    const updateFilesImg = (incommingFiles, e) => {
        if (incommingFiles.length !== 0) {
            new Promise((resolve, reject) => {
                setFilesImg(incommingFiles)
                setnewphotoData({ data: incommingFiles[0]?.file })
                const reader = new FileReader()
                reader.readAsDataURL(incommingFiles[0]?.file)
                reader.onload = () => {
                    resolve(reader.result)
                    setnewphotoData({
                        base64: reader.result,
                    })
                }
                reader.onerror = (error) => reject(error)
            })
        }
    }

    const refetchPage = () => {
        refetchCertificate({
            subject: params?.subjectId,
            student: user?._id,
        })
        refetchSubject({
            _id: params?.subjectId,
        })
        refetchForumData({
            _id: params?.subjectId,
        })
        refetchStudentEnroll({
            subject: params?.subjectId,
            student: user?._id,
        })
    }



    return (
        <Fragment>
            <Modal size={'full'} isOpen={ModalState} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {modalDisplay?.name} {modalDisplay?.type === 'document' && <Link href={`${modalDisplay?.link}`} isExternal> <ExternalLinkIcon ml={2} /></Link>}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Iframe
                            src={modalDisplay?.link}
                            width={'100%'}
                            height={'600px'}
                            display="initial"
                            position="relative"
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={PayModalState} onClose={closePayModal} size={'3xl'}>
                <ModalOverlay />
                <form onSubmit={handleUpdatePayment}>
                    <ModalContent>
                        <ModalHeader>{t('subjectPage.payment')}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <HStack spacing='5vh'>

                                {enrollState?.payment_image &&
                                    <Image boxSize='50vh' src={enrollState?.payment_image} alt='Dan Abramov' />
                                }

                                <Dropzone
                                    onChange={updateFilesImg}
                                    value={filesImg}
                                    accept="image/*"
                                    maxFiles="1"
                                    view="grid"
                                >
                                    {filesImg.map((file) => (
                                        <FileItem
                                            {...file}
                                            key={file.id}
                                            onDelete={onDeleteImg}
                                            //localization={"EN-en"}
                                            //resultOnTooltip
                                            preview={true}
                                        />
                                    ))}
                                </Dropzone>
                            </HStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={closePayModal}>
                                {t('Button.close')}
                            </Button>
                            <Button variant='ghost' type='submit'>
                                {t('Button.submit')}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>

            <Container
                maxW={'7xl'}
                px={[50]}
                mt={10}
            >
                {loading ? (
                    <OneSubjectLayoutSkeleton />
                ) :
                    <>
                        <SubjectCard
                            openDialogUnEnrollSubject={openDialogUnEnrollSubject}
                            openDialogEnrollSubject={openDialogEnrollSubject}
                            enroll={responseStudentEnroll?.studentofsubject}
                            subjectData={subjectData}
                            user={user}
                        />

                        <Box mt='5' minHeight={'60vh'}>
                            <Tabs size="md" variant="enclosed" >
                                <TabList>
                                    <Tab> {t('subjectPage.content')}</Tab>
                                    {(user?.role === "TEACHER") || (user?.role === "ADMIN") ?
                                        <Tab > {t('subjectPage.forum')}</Tab>
                                        :
                                        <>
                                            {user && responseStudentEnroll ? (
                                                <Tab> {t('subjectPage.forum')}</Tab>
                                            ) : (
                                                <Tab isDisabled > {t('subjectPage.forum')}</Tab>
                                            )}
                                        </>
                                    }
                                    {certificate?.studentofsubject?.certificatestatus && <Tab>{t('subjectPage.status')}</Tab>}
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Box py={'10'}>
                                            <Heading as="h4" size="md">
                                                {`${subjectData?.subject?.name}`}
                                            </Heading>
                                            <Text py={'4'}>{subjectData?.subject?.description}</Text>
                                        </Box>
                                        <Heading as="h4" size="md">
                                            {t('subjectPage.content')}
                                        </Heading>
                                        <Box py={'2'}>
                                            <Divider size={'lg'} />
                                        </Box>
                                        <Box p={'4'} borderRadius="lg">
                                            {contentState?.length == 0 ? (
                                                <Box w="full" p={'2'} boxShadow="lg" borderRadius={'lg'} h={"200"} bg={'gray.100'}>
                                                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} style={{ height: "100%" }}>
                                                        <Text fontSize={'35'} color={'gray.500'}>{t('subjectPage.text_no_content')}</Text>
                                                    </Flex>
                                                </Box>) :
                                                <>
                                                    {contentState?.map((content, i) => {
                                                        const progresspercen = progressState?.progresss?.find(percen => percen?.content?._id === content?._id)
                                                        return (
                                                            <Box key={content?._id}>
                                                                {content?.expiredate === null || dayjs(myCurrentDate).format('วันที่ DD/MM/YYYY , เวลา HH:mm:ss') < dayjs(content?.expiredate).format('วันที่ DD/MM/YYYY , เวลา HH:mm:ss')
                                                                    || dayjs(myCurrentDate).format('วันที่ DD/MM/YYYY , เวลา HH:mm:ss') > dayjs(content?.expiredate).format('วันที่ DD/MM/YYYY , เวลา HH:mm:ss')
                                                                    ?
                                                                    <Box>
                                                                        {content?.statusActive &&
                                                                            <ContentCard
                                                                                index={i}
                                                                                name={content?.name}
                                                                                content={content}
                                                                                carddescription={content?.carddescription}
                                                                                progress={progresspercen?.progress ? progresspercen?.progress : 0}
                                                                                subjectId={params?.subjectId}
                                                                                enroll={responseStudentEnroll?.studentofsubject}
                                                                                alertJoincourse={alertJoincourse}
                                                                                handleUpdateProgress={handleUpdateProgress}
                                                                                user={user}
                                                                                setModalDisplay={setModalDisplay}
                                                                                openModal={openModal}
                                                                            />}
                                                                    </Box> : null}
                                                            </Box>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </Box>
                                    </TabPanel>
                                    <TabPanel>
                                        <Heading as="h4" size="md">
                                            {t('subjectPage.forum')}
                                        </Heading>
                                        <Box py={'2'} w={'100%'} mb={6}>
                                            <Divider size={'lg'} orientation='horizontal' />
                                        </Box>
                                        {responseForum?.configforums.length === 0 ?
                                            <Text fontSize={'20'} color={'gray.500'}>{t('subjectPage.alt')}</Text>
                                            :
                                            <Box borderWidth={'1px'}>
                                                <Box bg={colorMode === 'light' ? 'brand.100' : 'brand.700'} p={3} mb={2}>
                                                    <Heading as='h4' size="md"> {t('subjectPage.title')} </Heading>
                                                </Box>
                                                <Box>
                                                    {responseForum?.configforums?.map((forum) => (
                                                        <Box key={forum?._id} ml={3}>
                                                            <Link href={`/forum/${subjectData?.subject?._id}/${forum?._id}`} isExternal>
                                                                <Text color={'primary.700'}>{forum?.name}</Text>
                                                            </Link>
                                                            <Text>{t('subjectPage.by')}
                                                                <Link color={'primary.700'} href={`/showprofile/${subjectData?.subject?.teacher?._id}`} isExternal> {`${subjectData?.subject?.teacher?.firstname} ${subjectData?.subject?.teacher?.lastname}`} </Link>
                                                                {dayjs(forum?.updatedAt).format(' DD MMMM YYYY , เวลา HH:mm:ss')}
                                                            </Text>
                                                            <Box py={'2'}>
                                                                <Divider orientation='horizontal' size={'xl'} />
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        <Heading as="h4" size="md" >
                                            {t('subjectPage.status')}
                                        </Heading>
                                        <Box py={'2'} w={'100%'} mb={6}>
                                            <Divider size={'lg'} orientation='horizontal' />
                                        </Box>
                                        <Box bg={colorMode === 'light' ? 'gray.200' : 'brand.600'} p={'5'} borderRadius={'lg'} mt={5}>
                                            <HStack justify={'space-between'}>
                                                <InputGroup size='lg' py={'2'}>
                                                    <InputLeftAddon children={t('subjectPage.name')} bg={colorMode === 'light' ? 'gray.100' : 'brand.800'} color={colorMode === 'light' ? 'gray.800' : 'white'} />
                                                    <Input value={certificate?.studentofsubject?.name} bg={colorMode === 'light' ? 'gray.300' : 'brand.500'} isReadOnly />
                                                </InputGroup>
                                                <InputGroup size='lg' py={'2'} >
                                                    <InputLeftAddon children={t('subjectPage.status')} bg={colorMode === 'light' ? 'gray.100' : 'brand.800'} color={colorMode === 'light' ? 'gray.800' : 'white'} />
                                                    {certificate?.studentofsubject?.status == 'disapproved' ?
                                                        <Alert status='warning' variant='left-accent' >
                                                            <AlertIcon />
                                                            {t('subjectPage.disapproved')}
                                                        </Alert>
                                                        :
                                                        <Alert status='success' variant='left-accent' >
                                                            <AlertIcon />
                                                            {t('subjectPage.approved')}
                                                        </Alert>
                                                    }
                                                </InputGroup>
                                            </HStack>
                                            {certificate?.studentofsubject?.status == 'approved' &&
                                                <Box w='full' py={'2'}>
                                                    <Button as={domlink} to={`/certificate/${certificate?.studentofsubject?._id}`} w='full' bg={'green.300'} boxShadow={'lg'}>Dowload</Button>
                                                </Box>
                                            }
                                        </Box>
                                        {subjectData?.subject?.course_certificate && <>
                                            <Heading as="h4" size="md" mb={3}>
                                                {t(' Payment ')}
                                            </Heading>
                                            <Box bg={colorMode === 'light' ? 'gray.200' : 'brand.600'} p={'5'} borderRadius={'lg'} >
                                                <HStack justify={'space-between'} py={2}>
                                                    <Text>{handleConvertHTML(subjectData?.subject?.pay_description)}</Text>
                                                </HStack>{subjectData?.subject?.pay_certificate &&
                                                    <Button onClick={openPayModal} >{t('subjectPage.slip')}</Button>
                                                }
                                            </Box>
                                        </>
                                        }
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Box>
                    </>
                }
            </Container>

            <DialogEnrollSubject
                dialogEnrollSubjectToggle={dialogEnrollSubjectToggle}
                closeDialogEnrollSubject={closeDialogEnrollSubject}
                handleSubmitEnrollSubject={handleSubmitEnrollSubject}
                enrollState={enrollState}
                setEnrollState={setEnrollState} />

            <DialogUnEnrollSubject
                handleSubmitUnEnrollSubject={handleSubmitUnEnrollSubject}
                dialogUnEnrollSubjectToggle={dialogUnEnrollSubjectToggle}
                closeDialogUnEnrollSubject={closeDialogUnEnrollSubject} />
        </Fragment>
    )
}

export default SubjectPage

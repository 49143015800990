import gql from 'graphql-tag'

export default gql`
  mutation (
    $_id: MongoID!
    $name: String
    $type: EnumContentType
    $doctype: String
    $text: String
    $texteditor: UpdateByIdContentTexteditorInput
    $link: String
    $carddescription: String
    $duration: Float
    $description: UpdateByIdContentHomeworkconfigDescriptionInput
    $duedate: Date
    $exampleFiletype: String
    $exampleFile: String
    $typefile: String
    $expiredate: Date
    $statusActive: Boolean
    ){
    updateContent(_id: $_id,
      record:{
        name: $name,
        type: $type,
        doctype: $doctype,
        text: $text,
        texteditor: $texteditor,
        link: $link,
        duration: $duration,
        expiredate: $expiredate,
        statusActive: $statusActive,
        carddescription:   $carddescription,
        homeworkconfig:
          {
            description: $description,
            duedate: $duedate,
            exampleFile: $exampleFile,
            exampleFiletype: $exampleFiletype,
            typefile: $typefile,
          }
      })
      {
        recordId
      }
  }
`
import gql from "graphql-tag";

export default gql`
  query ($_id: MongoID) {
    configforums(filter:{_id: $_id}) {
        _id
        name
        image 
        description{
            blocks
            entityMap
        }
        updatedAt
       
        
    }
  }
`;
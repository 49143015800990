import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Box,
	Radio,
	RadioGroup,
	Flex,
	FormControl,
	FormLabel,
	HStack,
	Stack,
	VStack,
	Wrap,
	WrapItem,
	Text,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Checkbox,
	CheckboxGroup,
	Grid,
	GridItem,
} from '@chakra-ui/react'
import AuthContext from '../contexts/AuthContext'
import scoreQuestion from '../graphql/mutations/createScoreQuestion'
import createQuestion from '../graphql/mutations/createQuestion'
import progressUpdate from '../graphql/mutations/createProgress'

import { useMutation, } from '@apollo/client'
import axios from 'axios'

const Question = ({
	data,
	content,
	onAnswerUpdate,
	numberOfQuestions,
	activeQuestion,
	onSetActiveQuestion,
	onSetStep,
	contentId,
	subjectId,
	questionName,
}) => {
	let blockNumber = 0

	const URL_SEND_ANSWER = process.env.REACT_APP_SEND_ANSWER

	const [error, setError] = useState('')
	const { user } = useContext(AuthContext)
	const myCurrentDate = new Date()
	const [selectanswer, setSelectAnswer] = useState({})
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [answercheck, setAnswerCheck] = useState(false)

	const [no, setNo] = useState(1)
	const { t } = useTranslation()

	const [setUpdateProgress] = useMutation(progressUpdate)
	const [setCreateScore] = useMutation(scoreQuestion)
	const [setQuestion] = useMutation(createQuestion)

	useEffect(() => {
		if (!selectanswer[activeQuestion]) {
			setSelectAnswer({ ...selectanswer, [activeQuestion]: [] })
		}

	}, [activeQuestion, answercheck])

	const checkSelectAnswerHandler = () => {
		// console.log('CALL')
		let emptySelect = []
		Object.keys(selectanswer).map((item, i) =>
			selectanswer[item] === '' ? emptySelect.push(item) : null
		)
		// console.log(emptySelect)
		if (!emptySelect.length) {
			setAnswerCheck(false)
			onOpen()
		} else {
			setError(emptySelect.map((item) => parseInt(item) + 1))
			setAnswerCheck(true)
			onOpen()
		}
	}

	const submitClickHandler = async (e) => {
		try {
			const response = await axios.post(URL_SEND_ANSWER, {
				answer: selectanswer,
				contentId: content,
			})
			// console.log("res:", response)

			const { data: question } = await setQuestion({
				variables: {
					studentId: user?._id,
					contentId: contentId,
					submitdate: myCurrentDate,
					answer: selectanswer,
				},
			})
			if (question) {
			}
			const { data: score } = await setCreateScore({
				variables: {
					subject: subjectId,
					student: user?._id,
					content: contentId,
					name: questionName,
					score: response?.data?.score,
					answer: response?.data?.checkanswer
				},
			})
			if (score) {
				// console.log("create question score sucess : ", score)
			}
			const { data: ProgressData } = await setUpdateProgress({
				variables: {
					content: contentId,
					student: user?._id,
					progress: 100,
					path: window.location.href
				}
			})
			if (ProgressData) {
				// console.log(ProgressData)
			}
			onAnswerUpdate(selectanswer)
			onSetStep(3)
		} catch (error) {
			console.error(error)
		}
	}




	const nextClickHandler = (e) => {
		if (activeQuestion < numberOfQuestions - 1) {
			onSetActiveQuestion(activeQuestion + 1)
			setNo(no + 1)
		}
	}

	const backClickHandler = (e) => {
		onSetActiveQuestion(activeQuestion - 1)
		setNo(no - 1)
	}

	const changeHandler = (activeQuestion, e) => {
		setSelectAnswer({ ...selectanswer, [activeQuestion]: e })
	}
	// console.log(selectanswer)


	return (
		<div style={{ textAlign: "-webkit-center" }}>
			<Box
				maxW="600px"
				mx="auto"
				h="full"
				rounded="lg"
				shadow="md"
				border={`1px solid rgba(90,90,90,0.3)`}
			>
				<Grid
					p={10}
					h="full"
				>
					<GridItem p='0'>
						<FormLabel textAlign={'end'}>{`${no} / ${numberOfQuestions}`}</FormLabel>
					</GridItem>
					<GridItem>
						<FormLabel >{`ข้อที่ ${no} `}{data?.question}</FormLabel>
					</GridItem>
					<GridItem minH={"20vh"} py='2'>
						{data?.questiontype === 'GENERAL' &&
							<Box>
								<RadioGroup
									onChange={(e) => changeHandler(activeQuestion, e)}
									value={selectanswer[activeQuestion]}
								>
									<Stack direction='column'>
										<Radio value='true'> ถูก</Radio>
										<Radio value='false'>ผิด</Radio>
									</Stack>
								</RadioGroup>
							</Box>
						}
						{data?.questiontype === 'ONEANSWER' &&
							<Box>
								<RadioGroup
									onChange={(e) => changeHandler(activeQuestion, e)}
									value={selectanswer[activeQuestion]}
								>
									<VStack alignItems={'flex-start'}>
										{data.choices.map((choice, i) => (
											<Radio key={i} value={choice}>
												{choice}
												<br></br>
											</Radio>
										))}
									</VStack>
								</RadioGroup>
							</Box>
						}
						{data?.questiontype === 'MANYANSWER' &&
							<Box >
								<CheckboxGroup
									onChange={(e) => changeHandler(activeQuestion, e)}
									value={selectanswer[activeQuestion]}
								>
									<VStack alignItems={'flex-start'}>
										{data?.mutichoices.map((mutichoice, i) => (
											<Checkbox key={i} value={mutichoice.choice}>
												{mutichoice.choice}
											</Checkbox>
										))}
									</VStack>
								</CheckboxGroup>
							</Box>
						} </GridItem>
					<GridItem  >
						<HStack justify={'space-between'}>
							<Box>
								{activeQuestion !== blockNumber && (
									<Button bg={'primary.700'} color={'white'} size='sm' w="100px"
										_hover={{
											bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
										}}
										onClick={backClickHandler}>
										{t('QuizPage.back')}
									</Button>
								)}
							</Box>
							<Box >
								{activeQuestion < numberOfQuestions - 1 && (
									<Button bg={'primary.700'} color={'white'} size='sm' w="100px"
										_hover={{
											bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
										}}
										onClick={nextClickHandler}>
										{t('QuizPage.next')}
									</Button>
								)}
								{activeQuestion === numberOfQuestions - 1 && (
									<Button bg={'tomato'} color={'white'} size='sm' w="100px"
										_hover={{
											bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
										}} onClick={checkSelectAnswerHandler}>{t('QuizPage.submit')} </Button>
								)}
							</Box>
						</HStack>
					</GridItem>
				</Grid>
			</Box>

			<Modal isOpen={isOpen} onClose={onClose} size="lg">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('QuizPage.submit')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Table variant="simple" colorScheme="teal">
							<Thead>
								<Tr >
									<Th textAlign="left" >{t('QuizPage.no')}</Th>
									<Th textAlign="center">{t('QuizPage.answer')}</Th>
									{/* <Th>{t('QuizPage.status')}</Th> */}
								</Tr>
							</Thead>
							<Tbody>
								{Object.entries(selectanswer).map((answer, i) => (
									<Tr key={i}>
										<Td textAlign="left">{i + 1}</Td>
										<Td textAlign="center">{selectanswer[i] ? selectanswer[i] : '-'}</Td>
										{/* <Td>{answer ? 'active' : 'empty'}</Td> */}
									</Tr>
								))}
							</Tbody>
						</Table>
						{/* {console.log("EEEE", error)} */}
						{error &&
							<Text mb="1rem" margin={1}>
								{t('QuizPage.selectanswer')} :{error}
							</Text>
						}

					</ModalBody>

					<ModalFooter>
						<Button bg={'primary.700'} color={'white'}
							_hover={{
								bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
							}} mr={3} onClick={onClose}>
							{t('QuizPage.close')}
						</Button>
						<Button bg={'primary.700'} color={'white'}
							_hover={{
								bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
							}} onClick={submitClickHandler}>
							{t('QuizPage.submit')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</div>
	)
}

export default Question
import gql from 'graphql-tag'

export default gql`
    mutation (
        $configforum: MongoID
        $user: MongoID
        $image: String
        $comment: ForumCommentInput
        $replydate: Date
    ) {
        createForum(
            record: {
                configforum: $configforum
                user: $user
                image: $image
                comment: $comment
                replydate: $replydate
            }
        ) {
            recordId
        }
    }
  `
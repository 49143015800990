import gql from "graphql-tag";

export default gql`
query ($subject: MongoID) {
    contents(filter: { subject: $subject}) {
    _id
    name
    type
    text
    duration
    doctype
    carddescription
    texteditor{
        blocks
        entityMap
    }
    link
    expiredate
    statusActive
    homeworkconfig{
        description{
            blocks
            entityMap
        }
        duedate
        exampleFile
        exampleFiletype
        typefile
    }
    homework{ 
        updatedAt
        _id
        status
        file
        filename
        submitdate 
        student{
            _id
            firstname
            lastname
        }
        
    }
    surveyconfig {
        surveyQuestions{
            questions
        }
        description{
            blocks
            entityMap
        }
    }
    }
}
`
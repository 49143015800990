import React, { Fragment } from "react";
import {
    Box,
    useColorModeValue,
    HStack,
    SkeletonCircle,
    Skeleton,
    SkeletonText
} from '@chakra-ui/react';
export const BlogAuthor = (props) => {
    return (
        <Fragment>
            <HStack ml={2} marginTop="0" spacing="2" display="flex" alignItems="center">
                <SkeletonCircle
                    // borderRadius="full"
                    size="30px"
                />
                <Skeleton height='10px' />
            </HStack>
        </Fragment>
    );
};

const SubjectLayoutSkeleton = () => {

    // console.log('sub', subject);
    return (
        <Fragment>
            <Box
                marginTop={{ base: '1', sm: '5' }}
                display="flex"
                maxW="500px"
                flexDirection={{ base: 'column', sm: 'row' }}
                justifyContent="center">
                <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    justifyContent='center'
                    position="relative"
                    alignItems="center">
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%">
                        {/* <Link textDecoration="none" _hover={{ textDecoration: 'none' }}> */}
                        <Skeleton
                            borderRadius="lg"
                            height="110px"
                            w='196px'
                        />
                        {/* </Link> */}
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box
                            bgGradient={useColorModeValue(
                                'radial(orange.600 1px, transparent 1px)',
                                'radial(orange.300 1px, transparent 1px)'
                            )}
                            backgroundSize="20px 20px"
                            opacity="0.4"
                            height="100%"
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex="1"
                    overflow='hidden'
                    textOverflow='ellipsis'
                    // width={{ base: '100%', sm: '85%' }}
                    flexDirection="column"
                    maxH='130px'
                    width='278px'
                    justifyContent="flex-start"
                    marginLeft={{ base: '5%', sm: '5%' }}
                    marginTop={{ base: '1', sm: '1' }}>
                    <Skeleton height='10px' />
                    <Skeleton height='10px' />
                    {/* <BlogTags tags={[subject?.contentfor]} /> */}
                    <BlogAuthor />
                    <SkeletonText noOfLines={3} />
                </Box>
            </Box>
        </Fragment>
    );
}

export default SubjectLayoutSkeleton

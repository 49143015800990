import { useTranslation } from 'react-i18next'
import {
    Box,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'

const DialogRemove = ({ status, handleSubmit, name, onCloseDialog, }) => {

    const { t } = useTranslation()

    return (
        <AlertDialog isOpen={status} onClose={onCloseDialog} >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <form onSubmit={handleSubmit}>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('managesubjectPage.deleteSubject')}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            คุณต้องการลบ  {name}
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Box justify='space-between'>
                                <Button onClick={onCloseDialog} color={'gray.500'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}>
                                    {t('Button.close')}
                                </Button>
                                <Button bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }} type='submit' ml={3}>
                                    {t('Button.delete')}
                                </Button>
                            </Box>
                        </AlertDialogFooter>
                    </form>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default DialogRemove

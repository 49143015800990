import gql from 'graphql-tag'

export default gql`
  mutation (
    $_id: MongoID!
    $name: String
    $description: UpdateByIdconfigForumDescriptionInput
    $image: String
      ){
    updateConfigForum(_id:$_id,
      record:{
        name: $name,
        description: $description,
        image: $image,
      })
      {
        recordId
      }
  }
`
import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
	Box,
	Flex,
	Container,
	Stack,
	Select,
	MenuItem,
	Text,
	Heading,
	Button,
	useToast,
	GridItem,
	Grid,
	Center,
	Input,
	FormControl,
	FormLabel,
	Textarea,
	Tooltip,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	useDisclosure,
	Image,
	HStack,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useColorModeValue,
	useRadio,
	Radio,
	RadioGroup,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	IconButton,
	TableContainer,
} from "@chakra-ui/react"
import { FaTrash, FaCaretRight } from 'react-icons/fa'
import { TextField } from '@mui/material'
import DateTimePicker from '@mui/lab/DateTimePicker'
import { DesktopDatePicker, TimePicker } from '@mui/lab'
// import Hero from "../components/sections/Hero";
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import THLocale from 'dayjs/locale/th'
import { deepOrange } from '@mui/material/colors'
import dayjs from 'dayjs'
import { useMultiState } from '../lib/hooks'
import { useMutation, useQuery } from '@apollo/client'
import streamQuery from '../graphql/queries/streambyteacherId'
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'
import streamMutaion from '../graphql/mutations/createstream'
import streamRemoveMutaion from '../graphql/mutations/removeStream'
import listzoomQuery from '../graphql/queries/listzoom'

import axios from 'axios'
import { useTranslation } from 'react-i18next'
const theme = createTheme({
	palette: {
		primary: {
			main: '#0CA25F',
		},
		secondary: deepOrange,
	},
})


const URL_ZOOM = process.env.REACT_APP_ZOOM_API
const URL = process.env.REACT_APP_UPLOAD_IMAGE

const ManageStreamPage = () => {
	const { t } = useTranslation()

	const initialStream = {
		name: '',
		description: '',
		date: dayjs(Date.now()),
		starttime: dayjs(Date.now()),
		endtime: dayjs(Date.now()),
		thumbnail: '',
		link: '',
		index: '',
	}

	const { user } = useContext(AuthContext)
	const history = useHistory()
	const { isOpen: showRemove, onOpen: openRemove, onClose: closeRemove } = useDisclosure()
	const [setStreamMutation] = useMutation(streamMutaion)
	const [removeMutation] = useMutation(streamRemoveMutaion)
	const [stream, setStream] = useMultiState(initialStream)
	const [removeState, setRemoveState] = useState()
	const [type, setType] = useState('1')

	const toast = useToast()

	const [loading, setLoading] = useState(false)
	const { data: streamData, refetch } = useQuery(streamQuery, {
		variables: {
			teacher: user?._id,
		},
	})

	const { data: listzoomData } = useQuery(listzoomQuery)
	// console.log('listzoomData', listzoomData)
	useEffect(() => {
		if (user?.role !== 'TEACHER') {
			setTimeout(() => {
				history.push('/login')
			}, 100)
		}
		if (loading) {
			refetch({
				teacher: user?._id,
			})
		}
	}, [history, user, refetch])

	const handleAddStream = async (e) => {
		e.preventDefault()
		const responsePhoto = await axios.post(URL, {
			photo: newphotoData?.base64,
		})
		console.log(newphotoData?.base64)

		if (!stream?.name) {
			await toast({
				position: 'top',
				title: 'warning',
				description: `${t('manageStreamPage.alertWarningName')}`,
				status: 'warning',
				duration: 3000,
				isClosable: true,
			})
		} else if (type === '1') {
			if (!stream?.link) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('manageStreamPage.alertWarningLink')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else {
				const { data: streamdata } = await setStreamMutation({
					variables: {
						name: stream?.name,
						description: stream?.description,
						date: stream?.date,
						starttime: stream?.starttime,
						endtime: stream?.endtime,
						teacher: user?._id,
						thumbnail: responsePhoto ? responsePhoto?.data?.url : "",
						startlink: stream?.link,
						link: stream?.link,
						contentfor: 'VIP',
					},
				})
				if (streamdata) {
					await toast({
						position: 'top',
						title: 'Success',
						description: `${t('manageStreamPage.alertAddComplete')}`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					})
					refetch({
						teacher: user?._id,
					})
				}
			}
		} else {
			if (!listzoomData?.listzooms[stream?.index]) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('manageStreamPage.alertWarningZoomId')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else {
				let zoomUser = listzoomData?.listzooms[stream?.index]
				// console.log('infunction')
				// console.log("zoom", zoomUser);
				const response = await axios.post(URL_ZOOM, {
					topic: stream?.name,
					start_time: stream?.starttime,
					duration: 60,
					agenda: stream?.description,
					userId: zoomUser?.userId,
					token: zoomUser?.token,
				})
				// console.log("response", response.data);
				if (response) {
					// console.log(response.data)
					let room = response.data
					const { data: streamdata } = await setStreamMutation({
						variables: {
							name: stream?.name,
							description: stream?.description,
							date: stream?.date,
							starttime: stream?.starttime,
							endtime: stream?.endtime,
							teacher: user?._id,
							thumbnail: responsePhoto ? responsePhoto?.data?.url : "",
							startlink: room?.start_url,
							link: room?.join_url,
							contentfor: 'VIP',
						},
					})
					if (streamdata) {
						await toast({
							position: 'top',
							title: 'Success',
							description: `${t('manageStreamPage.alertAddComplete')}`,
							status: 'success',
							duration: 3000,
							isClosable: true,
						})
						refetch({
							teacher: user?._id,
						})
					}
				}
			}
		}
		setLoading(false)
		setStream(initialStream)
	}

	const handleRemoveStream = async (e) => {
		e.preventDefault()
		console.log('handleremove', removeState)
		try {
			const { data: streamdata } = await removeMutation({
				variables: {
					_id: removeState,
				},
			})
			if (streamdata) {
				await toast({
					position: 'top',
					title: 'Success',
					description: `${t('manageStreamPage.alertDeleteComplete')}`,
					status: 'success',
					duration: 3000,
					isClosable: true,
				})

				refetch({
					teacher: user?._id,
				})
			}

		} catch (Err) {
			alert(`${Err?.graphQLErrors[0]?.message}`)
		}
		setLoading(false)
		closeRemove()

	}

	const [newphotoData, setnewphotoData] = useMultiState({
		data: null,
		base64: "",
	})

	const selectPhotoFile = (e) => {
		new Promise((resolve, reject) => {
			e.preventDefault()
			const file = e.currentTarget.files[0]
			setnewphotoData({
				data: file,
			})
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				resolve(reader.result)
				setnewphotoData({
					base64: reader.result,
				})
			}
			reader.onerror = (error) => reject(error)
		})
	}

	return (
		<Fragment>
			<Box w="100%" px={20} >
				<Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
					<Stack spacing={0} align={"center"}>
						<Heading variant="h4">{t('manageStreamPage.title')}</Heading>
						<Text>
							{t('manageStreamPage.subtitle')}
						</Text>
					</Stack>
					<form onSubmit={handleAddStream}>
						<Box>
							<FormControl id="" >
								<FormLabel py={2}>{t('manageStreamPage.nameSubject')}</FormLabel>
								<Input
									value={stream.name ? stream.name : ''}
									placeholder={t('manageStreamPage.streamNameDetail')}
									onChange={(e) => setStream({ name: e.target.value })}
								/>
							</FormControl>
							<FormControl id="">
								<FormLabel py={2}>{t('manageStreamPage.description')}</FormLabel>
								<Textarea
									value={stream.description ? stream.description : ''}
									placeholder={t('manageStreamPage.streamDetail')}
									onChange={(e) => setStream({ description: e.target.value })}
								/>
							</FormControl>
							<FormControl id="">
								<FormLabel py={2}>{t('manageStreamPage.date')}</FormLabel>
								<Grid templateColumns='repeat(3, 1fr)' gap={3}>
									<GridItem>
										<ThemeProvider theme={theme}>
											<LocalizationProvider dateAdapter={AdapterDayjs} >
												<DateTimePicker
													// disableFuture
													label={t('manageStreamPage.startTime')}
													// openTo="year"
													// views={['year', 'month', 'day']}
													value={stream.starttime ? stream.starttime : ''}
													onChange={(e) => setStream({ starttime: e })}
													renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
												/>
											</LocalizationProvider>
										</ThemeProvider>
									</GridItem>
									<GridItem>
										<ThemeProvider theme={theme}>
											<LocalizationProvider dateAdapter={AdapterDayjs} >
												<DateTimePicker
													// disableFuture
													label={t('manageStreamPage.endTime')}
													minDate={dayjs(
														`${dayjs(stream?.date).format('YYYY-MM-DD')} ${dayjs(stream?.starttime).format('HH:mm:ss')}`
													)}
													maxDate={dayjs(`${dayjs(stream?.date).format('YYYY-MM-DD')} 23:59:00`)}
													value={stream.endtime ? stream.endtime : ''}
													onChange={(e) => setStream({ endtime: e })}
													renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
												/>
											</LocalizationProvider>
										</ThemeProvider>
									</GridItem>
									<GridItem>
										{stream?.starttime && stream?.endtime
											? `${t('manageStreamPage.time')} ${dayjs(stream.endtime).diff(stream?.starttime, 'h', true)?.toFixed(2)} ${t('manageStreamPage.hour')}`
											: ''}
									</GridItem>
								</Grid>
							</FormControl>
							<FormControl id="" >
								<FormLabel py={2}>{t('manageStreamPage.create')}</FormLabel>
								<RadioGroup onChange={(e) => { setType(e) }} value={type}>
									<Stack direction='row'>
										<Radio value='1'>link</Radio>
										<Radio value='2'>zoom id</Radio>
									</Stack>
								</RadioGroup>

								{type === '1' ?
									<Input
										label={t('manageStreamPage.link')}
										value={stream.link ? stream.link : ''}
										onChange={(e) => setStream({ link: e.target.value })}
									/>
									: <FormControl>
										<FormLabel>{t('manageStreamPage.zoomId')}</FormLabel>
										<Select
											label={t('manageStreamPage.zoomId')}
											onChange={(e) => setStream({ index: e.target.value })}
											color="info"
										>
											{listzoomData?.listzooms?.map((listzoom, i) => (
												<MenuItem key={listzoom?.name} value={i}>{listzoom?.name}</MenuItem>
											))}
										</Select>
									</FormControl>
								}
							</FormControl>
							<HStack>
								<Button size="sm" as="label" mt={4} bg={'primary.700'} color={'white'} _hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}}>
									{t('manageStreamPage.addphotoButton')}
									<Input
										onChange={selectPhotoFile}
										accept="image/*"
										id="photo-project-file"
										type="file"
										style={{ display: "none" }}
									/>
								</Button>
								<Text>{newphotoData?.data?.name}</Text>
							</HStack>
							<Center>
								<Button type="submit " bg={'primary.700'} color={'white'} _hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}}>
									{t('Button.confirm')}
								</Button>
							</Center>
						</Box>
					</form>

					<Box mt={10} sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} border="10px solid" borderColor="gray.100" borderRadius="md">
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>{t('manageStreamPage.nameSubject')}</Th>
									<Th align="right">{t('manageStreamPage.description')}</Th>
									<Th align="right">{t('manageStreamPage.date')}</Th>
									<Th align="right">{t('manageStreamPage.startTime')}</Th>
									<Th align="right">{t('manageStreamPage.endTime')}</Th>
									<Th align="right">{t('manageStreamPage.startMeet')}</Th>
									<Th align="right">{t('manageStreamPage.manage')}</Th>
								</Tr>
							</Thead>

							<Tbody>
								{streamData?.streams?.map((row) => (
									<Tr key={row?._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<Td component="th" scope="row">
											{row.name}
										</Td>
										<Td >{row?.description}{console.log(row?.description)}</Td>
										<Td >{dayjs(row?.date).format('DD/MM/YYYY')}</Td>
										<Td >{dayjs(row?.starttime).format('HH:mm')}</Td>
										<Td >{dayjs(row?.endtime).format('HH:mm')}</Td>
										<Td >
											<IconButton
												href={`${row?.startlink}`} target="_blank"
												icon={<FaCaretRight />}
											/>

										</Td>
										<Td >
											<IconButton
												onClick={(e) => {
													e.preventDefault()
													openRemove()
													setRemoveState(row?._id)
												}}
												color="info"
												icon={<FaTrash />}
											/>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Box>
				</Container>
			</Box >

			<AlertDialog isOpen={showRemove} onClose={closeRemove}>
				<AlertDialogOverlay>
					<form onSubmit={handleRemoveStream} as={GridItem} colSpan={[6, 3]}>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('manageStreamPage.remove')}
							</AlertDialogHeader>
							<AlertDialogBody><Center>{t('manageStreamPage.alertDeleteQuestionComplete')}</Center></AlertDialogBody>
							<AlertDialogFooter>
								<Button onClick={closeRemove}>	{t('managerQuestionPage.buttoncancel')}</Button>
								<Button bg={'primary.700'} color={'white'} _hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}} type="submit" ml={3}>
									{t('managerQuestionPage.buttondelete')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</form>
				</AlertDialogOverlay>
			</AlertDialog>
		</Fragment >
	)
}
export default ManageStreamPage

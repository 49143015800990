import gql from "graphql-tag";

export default gql`
  query ($_id: MongoID) {
    content(filter: { _id: $_id })  {
      _id
      name
      type
      text
      carddescription
      duration
      texteditor{
        blocks
        entityMap
      }
      link
      expiredate
      statusActive
      homeworkconfig{
            description{
                  blocks
                  entityMap
            }
            duedate
            exampleFile
            typefile
      }
      homework{
        submitdate
        status
        file
        student{
          _id
          firstname
        }
      }
    }
  }
`;
import gql from 'graphql-tag'

export default gql`
  mutation (
    $_id: MongoID!
    $name: String
    $description: String
    $photo: String
    $sorting: [JSON]
    $teacher: MongoID
    $status: EnumSubjectStatus
    $editor: UpdateByIdSubjectPay_descriptionInput
    $pay_certificate: Boolean
    $course_certificate:Boolean
      ){
    updateSubject(_id:$_id,
      record:{
        name: $name,
        description: $description,
        photo: $photo,
        sorting: $sorting,
        teacher: $teacher,
        status: $status,
        pay_certificate: $pay_certificate,
        pay_description: $editor,
        course_certificate: $course_certificate,
      })
      {
        recordId
      }
  }
`
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next'
import {
    Box,
    Flex,
    // Stat,
    // StatLabel,
    // StatNumber,
    // useColorModeValue,
    Container,
    Stack,
    Heading,
    Text,
    Avatar,
    Wrap,
    WrapItem,
    Image,
    AspectRatio,
    HStack,
    Tooltip,
    useDisclosure,
    Badge,
    IconButton,
    Link,
    Center,

} from '@chakra-ui/react';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,

} from '@choc-ui/chakra-autocomplete';
import { MdClose } from 'react-icons/md'
import SubjectLayoutSkeleton from '../components/skeleton/SubjectLayoutSkeleton';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
// import { useHistory } from 'react-router';
// import AuthContext from '../contexts/AuthContext';
// import { useTokenMeet } from '../contexts/TokenMeetContext';
import streamQuery from '../graphql/queries/streams'
import ModalPricingLayout from '../components/layouts/ModalPricingLayout';
import StreamLayoutSkeleton from '../components/skeleton/StreamLayoutSkeleton';
require('dayjs/locale/th')
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

// function StatsCard(props) {
//     const { name, stat, avatar } = props;
//     return (
//         <Stat
//             px={{ base: 2, md: 4 }}
//             py={'5'}
//             shadow={'xl'}
//             border={'1px solid'}
//             boxShadow={'2xl'}
//             borderColor={useColorModeValue('gray.800', 'gray.500')}
//             rounded={'lg'}>
//             <Flex justifyContent={'space-between'}>
//                 <Box
//                     my={'auto'}
//                     color={useColorModeValue('gray.800', 'gray.200')}
//                     alignContent={'center'}>
//                     <Avatar
//                         size={'md'}
//                         src={
//                             `${props?.avatar}`
//                         }
//                         alt={'Avatar Alt'}
//                         objectFit='fiil'
//                         mb={4}
//                         pos={'relative'}
//                         _after={{
//                             content: '""',
//                             w: 4,
//                             h: 4,
//                             bg: 'green.300',
//                             border: '2px solid white',
//                             rounded: 'full',
//                             pos: 'absolute',
//                             bottom: 0,
//                             right: 0,
//                         }}
//                     />
//                 </Box>
//                 <Box pl={{ base: 2, md: 4 }}>
//                     <StatLabel fontWeight={'medium'} wordBreak={'break-word'} isTruncated>
//                         {name}
//                     </StatLabel>
//                     <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
//                         {stat}
//                     </StatNumber>
//                 </Box>
//             </Flex>
//         </Stat>
//     );
// }

const StreamLayout = (props) => {
    // console.log('day', props?.title, dayjs(Date.now()).isAfter(dayjs(props?.endtime), 'minute'));
    // Sample card from Airbnb
    return (
        <Tooltip label={props?.title}>
            <Box
                maxW="md"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
            // _disabled={dayjs(Date.now()).isBefore(dayjs(`${dayjs(props?.date).format('YYYY-MM-DD')} ${dayjs(props?.endtime).format('HH:mm:00')}`), 'minute')}
            >
                <AspectRatio minW={"300px"} ratio={16 / 12}>
                    <Image
                        src={props?.thumbnail ? props?.thumbnail : '/images/psuLogo.png'}
                        alt={props?.imageAlt}


                        // boxSize="268px"

                        // width="lg"
                        objectFit="contain"
                    />
                </AspectRatio>
                <Box minW={'300px'} maxW={'1.5'} p="2">
                    <HStack>
                        <Avatar boxSize={'30px'} name={props?.name} src={props?.avatar} />
                        <Flex direction={"column"}>
                            <HStack>
                                <Box
                                    color={props?.date &&
                                        dayjs(Date.now()).isSameOrBefore(dayjs(`${dayjs(props?.date).format('YYYY-MM-DD')} ${dayjs(props?.starttime).format('HH:mm:00')}`), 'minute') ? 'green.500' : 'red'}
                                    // fontWeight="semibold"
                                    // letterSpacing="wide"
                                    as="i"
                                    fontSize={'xs'}
                                    // textTransform="uppercase"
                                    isTruncated
                                    mb={'-5px'}
                                // ml="px"
                                >
                                    {props?.date &&
                                        dayjs(Date.now()).isBefore(dayjs(`${dayjs(props?.date).format('YYYY-MM-DD')} ${dayjs(props?.endtime).format('HH:mm:00')}`), 'minute') ?
                                        dayjs(`${dayjs(props?.date).format('YYYY-MM-DD')} ${dayjs(props?.starttime).format('HH:mm:00')}`).locale('th').fromNow()
                                        : 'เสร็จสิ้น'}
                                </Box>
                            </HStack>
                            <Box
                                mt="px"
                                fontWeight="bold"
                                as="h6"
                                // fontSize={'xl'}
                                w={'95%'}
                                minW={'200px'}
                                lineHeight="tight"
                                // white-space="nowrap"
                                // overflow="hidden"
                                // text-overflow="ellipsis"
                                isTruncated
                            >
                                <Link href={`${props.link}`}>{props?.title}</Link>
                            </Box>
                            <Box
                                as="h6"
                                color="gray.600"
                                w={'90%'}
                                mt={'-px'}
                                minW={'200px'}
                                // white-space="nowrap"
                                // overflow="hidden"
                                // text-overflow="ellipsis"
                                isTruncated
                                fontSize="xs">
                                {props?.name}
                            </Box>
                            <Box
                                color="gray.500"
                                // fontWeight="semibold"
                                // letterSpacing="wide"
                                fontSize="xs"
                                textTransform="uppercase"
                                isTruncated

                            // ml="px"
                            >
                                {props?.date ? dayjs(props?.date).locale('th').format(`dddd •  ${dayjs(props?.starttime).locale('th').format("HH:mm")}
                            - ${dayjs(props?.endtime).locale('th').format("HH:mm")} DD MMMM YYYY`) : null}
                            </Box>

                        </Flex>
                    </HStack>
                    {/* <Box d="flex" alignItems="baseline">
                <Badge borderRadius="full" px="2" colorScheme="teal">
                New
                </Badge>
                <Box
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
                ml="2"
                >
                {props?.beds} beds &bull; {props?.baths} baths
                </Box>
            </Box> */}


                    {/* <Box d="flex" mt="2" alignItems="center">
                {Array(5)
                    .fill("")
                    .map((_, i) => (
                        <StarIcon
                        key={i}
                        color={i < props?.rating ? "teal.500" : "gray.300"}
                        />
                        ))}
                        <Box as="span" ml="2" color="gray.600" fontSize="sm">
                        {props?.reviewCount} reviews
                        </Box>
                    </Box> */}
                </Box>
            </Box>
        </Tooltip>
    )
}

// const API_KID = process.env.REACT_APP_API_KID
// const API_MEET = process.env.REACT_APP_API_KEY_JITSI
// const URL = process.env.REACT_APP_GENERATE_TOKEN
const ListStreamPage = () => {
    // const params = useParams()
    // const eventRef = useRef()
    // const { tokenMeet, setTokenMeet } = useTokenMeet()
    // const { user } = useContext(AuthContext)
    // const history = useHistory()
    const { data: streamData, loading, refetch } = useQuery(streamQuery)
    // const [valueauto, setValeauto] = useState([])
    const [streams, setStreams] = useState([])
    const { isOpen, onClose } = useDisclosure()
    const { t } = useTranslation()
    // const [streamId, setStreamId] = useState('')



    // const handleStreamRoute = (e) => {
    //     // e.preventDefault()
    //     const {
    //         id,
    //         contentfor,
    //         endtime,
    //         date,
    //         teacherId,
    //         link,
    //     } = e
    //     if (dayjs(Date.now()).isBefore(dayjs(`${dayjs(date).format('YYYY-MM-DD')} ${dayjs(endtime).format('HH:mm:00')}`), 'minute')) {
    //         alert('เริ่มการสอนสด')
    //     } else {
    //         alert('จบการสอนสด')
    //     }
    //     // console.log('co', id, contentfor);
    // }


    // const handleStartMeeting = async (e) => {
    //     try {
    //         e.preventDefault()
    //         const token = await Axios.post(URL, {
    //             id: user?._id,
    //             name: user?.name,
    //             avatar: user?.avartar,
    //             email: user?.email,
    //             isTeacher: user?.isTeacher ? user?.isTeacher : false,
    //             kid: `${ API_KID }`,
    //             appId: `${ API_MEET }`
    //         })


    //         if (token?.status === 200) {
    //             await setTokenMeet(token?.data)
    //             history.push(`/ meet / ${ params?.subjectId}`)

    //         }
    //     } catch (e) {
    //         console.log('ERROR', e)
    //     }

    //     // console.log('token', token);
    //     // history.push('/course')

    // }
    useEffect(
        () => {
            refetch()

        },
        [streamData, refetch],
    )
    useEffect(() => {
        if (streamData) {
            setStreams(streamData?.streams ? streamData?.streams.filter((e) => ((dayjs(Date.now()).diff(dayjs(e?.date), 'w') <= 1))) : [])
        }

    }, [streamData])

    // console.log("stream",streams);

    // console.log('token', streamData);
    return (
        <Fragment>
            <Box w="100%" px={20} minHeight={'90vh'} >
                <Container maxW={'8xl'} py={16} as={Stack} spacing={12}>
                    <Stack
                        w={'full'}
                        direction={["column", '', "row"]}
                        justifyContent='space-between'
                    >
                        <Stack align={{ base: 'center', md: 'flex-start' }}>
                            <Heading size="xl">{t('streamPage.title')}</Heading>
                            <Text>{t('streamPage.subtitle')}</Text>
                        </Stack>
                        <Stack align={{ base: 'center', md: "flex-end" }}>
                            <Flex
                                boxSize="full"
                                // h="100vh"
                                w={['90%', '', '400px']}
                                pos="relative"
                                // bg={useColorModeValue("gray.400", "gray.600")}
                                // p={30}
                                alignItems='center'
                                justifyContent="center"
                            >
                                <AutoComplete
                                    onChange={(v, i, I) => {
                                        // console.log(v, i, I);
                                    }}
                                    onSelectOption={(e) => { setStreams(streams?.filter((row) => (e?.optionValue === row?._id))) }}
                                    rollNavigation>
                                    <AutoCompleteInput variant="filled" placeholder="Search..." autoFocus />
                                    <AutoCompleteList>

                                        {streamData?.streams.map((option, oid) => (
                                            <AutoCompleteItem
                                                key={`option-${oid}`}
                                                label={`${option?.name} ${option?.teacher?.firstname} ${option?.teacher?.lastname}  `}
                                                // getValue={e => (e || option)}
                                                // op={option}
                                                value={`${option?._id}`}
                                                // value={`${option?.code} ${option?.name} ${option?.teacher?.firstname} ${option?.teacher?.lastname} ${option?.contentfor} `}
                                                textTransform="capitalize"
                                            >
                                                {`${option?.name} ${option?.teacher?.firstname} ${option?.teacher?.lastname}  `}
                                            </AutoCompleteItem>
                                        ))}
                                    </AutoCompleteList>
                                </AutoComplete>
                                <IconButton
                                    variant="ghost"
                                    colorScheme='blue'
                                    pos='absolute'
                                    right={0}
                                    onClick={() => { setStreams(streamData?.streams) }}
                                    aria-label="Call Sage"
                                    fontSize="15px"
                                    icon={<MdClose />}
                                />
                            </Flex>
                        </Stack>
                    </Stack>
                    <Wrap justify={'space-between'} spacing="30px">
                        {loading ? [1, 2, 3, 4].map((row) => (
                            <WrapItem key={row}>
                                <SubjectLayoutSkeleton />
                            </WrapItem>
                        )) :
                            <>
                                {
                                    streams.length === 0 ?
                                        <Box>
                                            <Center>
                                                <Text fontSize={'35'} color={'gray.500'}>ยังไม่มีห้องเรียนออนไลน์ </Text>
                                            </Center>
                                            <Box h='155px'></Box>
                                        </Box>
                                        :
                                        <Wrap justify={'space-around'} spacing="30px">
                                            {streams.map((stream) => {
                                                {/* console.log(dayjs(Date.now()).diff(dayjs(stream?.date), 'w')); */ }
                                                return (
                                                    <WrapItem key={stream._id}>
                                                        <Box >

                                                            <StreamLayout
                                                                thumbnail={stream?.thumbnail}
                                                                avatar={stream?.teacher?.avatar}
                                                                name={`${stream?.teacher?.firstname} ${stream?.teacher?.lastname}`}
                                                                title={stream?.name}
                                                                date={stream?.date}
                                                                starttime={stream?.starttime}
                                                                endtime={stream?.endtime}
                                                                contentfor={stream?.contentfor}
                                                                link={stream?.link}
                                                            />
                                                        </Box>
                                                    </WrapItem>
                                                )
                                            })
                                            }
                                        </Wrap>
                                }
                            </>
                        }
                    </Wrap>
                </Container>
            </Box>
            {/* <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
            <chakra.h1
                textAlign={'center'}
                fontSize={'4xl'}
                py={10}
                fontWeight={'bold'}>
                Our company is expanding, you could be too.
            </chakra.h1>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
               
                <StatsCard
                    title={'Servers'}
                    stat={'1,000'}
                    icon={<FiServer size={'3em'} />}
                />
                <StatsCard
                    title={'Datacenters'}
                    stat={'7'}
                    icon={<GoLocation size={'3em'} />}
                />
            </SimpleGrid>
        </Box> */}
            <ModalPricingLayout isOpen={isOpen} onClose={onClose} />
        </Fragment >
    );
}

export default ListStreamPage

import gql from "graphql-tag";

export default gql`
  mutation (
    $subject: MongoID
    $name: String!
    $description: configForumDescriptionInput
    $image: String
  ) {
    createConfigForum(
      record: {
        subject: $subject
        name: $name
        description: $description
        image: $image
      }
    ) {
      recordId
    }
  }
`;

import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link as RouteLink } from 'react-router-dom'
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    useToast,
    Link
} from '@chakra-ui/react';
// import { useApp } from "../contexts/AppContext";
import loginMutation from '../graphql/mutations/login'
import AuthContext from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'
import { useHistory } from "react-router";



const LoginPage = () => {

    // const { lang, switchLang } = useApp()
    const { t } = useTranslation()
    const toast = useToast()
    const history = useHistory()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const { user, setToken } = useContext(AuthContext)
    const [login] = useMutation(loginMutation)


    useEffect(() => {
        // storing input name
    }, [user])


    useEffect(
        () => {
            if (user) {
                setTimeout(() => {
                    history.push(`/courses`)
                }, 100)
            }
        },
        [history, user],
    )


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { data } = await login({ variables: { username, password } })

            if (data) {
                // const decoded = await jwtDecode(data?.login)
                // const { data: dataProject } = await projectData.refetch({ _id: decoded?.project })
                setToken(data.login)
                // setProject(dataProject)
                await toast({
                    position: "top",
                    title: "Success",
                    description: `${t('loginPage.loginCompleteDecription')}`,
                    status: "success",
                    duration: 6000,
                    isClosable: true,
                })
                history.push('/courses')
            } else {
                console.log('Incorrect username or password')
            }
        } catch (err) {
            console.error(err)
            toast({
                position: "top",
                title: "ERROR",
                description: `${err?.graphQLErrors[0]?.message}`,
                status: "error",
                duration: 6000,
                isClosable: true,
            })
        }
    }

    // console.log('lang', user);
    return (
        <Flex
            minH={['90vh', '80vh', '100vh', '80vh']}
            align={'center'}
            justify={'center'}
        // bg={useColorModeValue('gray.50', 'gray.800')}
        >
            <form onSubmit={handleSubmit}>
                <Stack spacing={8} mx={'auto'} maxW={'lg'}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'}>{t('loginPage.title')}</Heading>
                        <Text fontSize={'lg'} color={'gray.600'}>
                            {t('loginPage.subtitle')}✌️
                        </Text>
                    </Stack>
                    <Box
                        rounded={'lg'}
                        bg={useColorModeValue('white', 'gray.700')}
                        borderWidth={'1px'}
                        boxShadow={'lg'}
                        p={8}>
                        <Stack spacing={4}>
                            <FormControl id="username-nuukeng" isRequired>
                                <FormLabel>{t('loginPage.usernameInput')}</FormLabel>
                                <Input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </FormControl>
                            <FormControl id="password-nuukeng" isRequired>
                                <FormLabel>{t('loginPage.passwordInput')}</FormLabel>
                                <Input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>
                            <Stack spacing={10}>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align='flex-end'
                                    justify={'flex-end'}>
                                    {/* <Checkbox>{t('loginPage.rememberCheckbox')}</Checkbox> */}
                                    <Link as={RouteLink} to={'forgot'} alignSelf={'flex-end'} color={'blue.400'}>{t('loginPage.forgotLink')}</Link>
                                </Stack>
                                <Button
                                    bg={'blue.400'}
                                    color={'white'}
                                    type='submit'
                                    _hover={{
                                        bg: 'blue.500',
                                    }}>
                                    {t('loginPage.signinButton')}
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                    {/* <Stack
                        mt={'20px'}
                        direction={{ base: 'row', sm: 'row' }}
                        align='flex-end'
                        justify={'flex-end'}>
                        <Fragment />
                        <Checkbox>{t('loginPage.rememberCheckbox')}</Checkbox>
                        <Link as={RouteLink} to={'/signup'} mt={'-10px'} color={'blue.400'}>{t('loginPage.createAccount')}</Link>
                    </Stack> */}
                </Stack>
            </form>
        </Flex>
    )
};

export default LoginPage;

import React, {
    Fragment,
    useContext,
    useEffect,
    useState
} from "react";
import {
    Stack,
    FormControl,
    Input,
    Button,
    useColorModeValue,
    Heading,
    Text,
    Container,
    Box,
    Center,
    useToast
} from '@chakra-ui/react';
import AuthContext from "../contexts/AuthContext";
import { useHistory } from "react-router";
import axios from "axios";

const URL = process.env.REACT_APP_FORGOT_PASSWORD
const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const toast = useToast()
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const handleSendEmailReset = async (e) => {
        e.preventDefault()
        if (!email) {
            await toast({
                position: "top",
                title: 'กรุณากรอกอีเมล',
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else {
            const response = await axios.post(URL, { email: email })
            if (response?.data.success)
                await toast({
                    position: "top",
                    title: 'ระบบได้ส่งลิ้งค์รีเซ็ตรหัสผ่านไปยังอีเมลของท่านเรียบร้อยแล้ว',
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            history.push('/login')
        }

    }
    useEffect(
        () => {
            if (user) {
                setTimeout(() => {
                    history.push('/')
                }, 100)
            }
        },
        [history, user],
    )
    return (
        <Fragment>
            <Box
                py={24}
                w={'full'}
            >
                <Center h={'50vh'}>
                    <Container
                        maxW={'lg'}
                        bg={useColorModeValue('white', 'whiteAlpha.100')}
                        boxShadow={'xl'}
                        rounded={'lg'}
                        p={6}
                        direction={'column'}>
                        <Heading
                            as={'h2'}
                            fontSize={{ base: 'xl', sm: 'xl' }}
                            textAlign={'center'}
                            mb={5}>
                            กรุณากรอกอีเมลของท่านเพื่อทำการรีเซ็ตรหัสผ่าน
                        </Heading>
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            as={'form'}
                            spacing={'12px'}
                            onSubmit={handleSendEmailReset}>
                            <FormControl>
                                <Input
                                    variant={'solid'}
                                    borderWidth={1}
                                    color={'gray.800'}
                                    _placeholder={{
                                        color: 'gray.400',
                                    }}
                                    borderColor={useColorModeValue('gray.300', 'gray.700')}
                                    id={'email'}
                                    type={'email'}
                                    required
                                    placeholder={'Your Email'}
                                    aria-label={'Your Email'}
                                    value={email}
                                    onChange={(e) =>
                                        setEmail(e.target.value)
                                    }
                                />
                            </FormControl>
                            <FormControl w={{ base: '100%', md: '40%' }}>
                                <Button
                                    colorScheme={'green'}
                                    w="100%"
                                    type={'submit'}>
                                    ยืนยัน
                                </Button>
                            </FormControl>
                        </Stack>
                        <Text
                            mt={2}
                            textAlign={'center'}>
                            ท่านจะได้รับลิ้งเพื่อรีเซ็ตรหัสผ่านในอีเมลของท่าน
                        </Text>
                    </Container>
                </Center>
            </Box>
        </Fragment>
    )

}
export default ForgotPasswordPage
import React, { Fragment, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    GridItem,
    Box,
    Heading,
    Text,
    Container,
    Link,
    Button,
    Flex,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Center,
    Tooltip,
    useToast,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
    AlertIcon,
} from '@chakra-ui/react'

import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'
import renderHTML from 'react-render-html'
import draftToHtml from 'draftjs-to-html'
import { Dropzone, FileItem } from '@dropzone-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import { useMultiState } from '../lib/hooks'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router'

import HomeworkMutation from '../graphql/mutations/createHomework'
import HomeworkRemoveMutation from '../graphql/mutations/removeHomework'
import HomeworkUpdateMutation from '../graphql/mutations/updateHomework'
import contentById from '../graphql/queries/contentbyId'
import HomeworkQueries from '../graphql/queries/homeworkbyStudent'
import scorebystudentQueries from '../graphql/queries/scorebystudent'

import dayjs from 'dayjs'

import AuthContext from '../contexts/AuthContext'
import axios from 'axios'
import { AiOutlineFilePpt, AiOutlineFileImage } from 'react-icons/ai'

const URL_SEND = process.env.REACT_APP_SEND_HOMEWORK
const URL_DELETE = process.env.REACT_APP_DELETE_HOMEWORK
const URL_EDIT = process.env.REACT_APP_EDIT_HOMEWORK
const URLHomework = process.env.REACT_APP_UPLOAD_HOMEWORK

require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const PreviewHomeworkPage = () => {

    const {
        isOpen: isAddopen,
        onOpen: onAddopen,
        onClose: onAddclose,
    } = useDisclosure()
    const {
        isOpen: isEditopen,
        onOpen: onEditopen,
        onClose: onEditclose,
    } = useDisclosure()
    const toast = useToast()
    const { t } = useTranslation()

    const [statusremove, setStatusremove] = useState(false)
    const [statusEdit, setStatusEdit] = useState(false)

    const [Statusbutton, setStatusbutton] = useState(false)
    const initialRef = React.useRef()
    const finalRef = React.useRef()
    const { user } = useContext(AuthContext)
    const [contentState, setContentState] = useState()
    const [setHomeworkMutation] = useMutation(HomeworkMutation)
    const [setHomeworkRemoveMutation] = useMutation(HomeworkRemoveMutation)
    const [setHomeworkUpdateMutation] = useMutation(HomeworkUpdateMutation)
    const params = useParams()
    const myCurrentDate = new Date()
    const bg = useColorModeValue('white', 'gray.800')

    const studentname = user.firstname + user.lastname

    const [newphotoData, setnewphotoData] = useMultiState({
        data: null,
        base64: '',
    })

    const { data: scoreAddData } = useQuery(scorebystudentQueries, {
        variables: {
            student: user?._id,
            id: params?.contentId,
        },
    })

    const [filesImg, setFilesImg] = useState([])
    const onDeleteImg = (id) => {
        setFilesImg(filesImg.filter((x) => x.id !== id))
    }

    const updateStatusEdit = () => {
        setStatusEdit(true)
    }

    const handleUpdateStatus = () => {
        setStatusEdit(false)
        onEditclose(true)
    }

    const { data: Content } = useQuery(contentById, {
        variables: {
            _id: params?.contentId,
        },
    })

    const { data: homeworkData, refetch } = useQuery(HomeworkQueries, {
        variables: {
            student: user?._id,
            content: params?.contentId,
        },
    })

    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }

    useEffect(() => {
        if (Content) {
            setContentState(Content?.content)
        }

    }, [Content])

    const updateFilesImg = (incommingFiles, e) => {
        setFilesImg(incommingFiles)
        if (incommingFiles.length !== 0) {
            new Promise((resolve, reject) => {
                setnewphotoData({ data: incommingFiles[0]?.file })
                if (incommingFiles[0]?.file !== []) {
                    const reader = new FileReader()
                    reader.readAsDataURL(incommingFiles[0]?.file)
                    reader.onload = () => {
                        resolve(reader.result)
                        setnewphotoData({
                            base64: reader.result,
                        })
                    }
                    reader.onerror = (error) => reject(error)
                }
            })
        }
    }
    console.log("Content", Content)
    const handleSendhomework = async (homework, firstname, lastname, typefile, filename) => {
        console.log('homework', homework)
        const response = await axios.post(URL_SEND, {
            namehomework: homework,
            firstnamestudent: firstname,
            lastnamestudent: lastname,
            photo: newphotoData?.base64,
            typefile: typefile,
            filename: filename,
        })
        onAddclose(true)
        console.log(response)
        const { data: homeworkAddData } = await setHomeworkMutation({
            variables: {
                content: params?.contentId,
                file: response ? response?.data?.url : '',
                status: 'submit',
                student: user?._id,
                submitdate: myCurrentDate,
            },
        })
        if (homeworkAddData) {
            await toast({
                position: 'top',
                title: 'Success',
                description: `${t('SendHomeworkPage.altadd')}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            refetch({
                student: user?._id,
            })

            setnewphotoData({ data: null, base64: '' })
            setFilesImg([])
        }
    }

    const handleUpdatehomework = async (homework, firstname, lastname, typefile, filenamenew, filenameold, id) => {
        const response = await axios.post(URL_EDIT, {
            namehomework: homework,
            firstnamestudent: firstname,
            lastnamestudent: lastname,
            photo: newphotoData?.base64,
            typefile: typefile,
            filenamenew: filenamenew,
            filenameold: filenameold,
            id: id,
        })
        setStatusEdit(false)
        onEditclose(true)
        try {
            const { data: homeworkAddData } = await setHomeworkUpdateMutation({
                variables: {
                    _id: id,
                    file: response ? response?.data?.url : '',
                    status: 'ส่งเเล้ว',
                    student: user?._id,
                    submitdate: myCurrentDate,
                },
            })
            if (homeworkAddData) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('SendHomeworkPage.edit')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    student: user?._id,
                })
                setnewphotoData({ data: null, base64: '' })
                setFilesImg([])
            }
        } catch (Error) {
            // console.log('in Create submit', Error)
        }
    }

    //Alert dialog remove chapter
    const initialRemoveHomework = { rehomework: '', refirstname: '', relastname: '', retypefile: '', refilename: '', reid: '', }

    const [showRemoveHomework, setShowRemoveHomework] = useState(false)
    const [removeHomeworkIndex, setRemoveHomeworkIndex] = useMultiState(initialRemoveHomework)

    const handleOpenRemoveHomework = async (homework, firstname, lastname, typefile, filename, id) => {
        setRemoveHomeworkIndex({ rehomework: homework, refirstname: firstname, relastname: lastname, retypefile: typefile, refilename: filename, reid: id, })
        setShowRemoveHomework(true)
    }

    const handleCloseRemoveHomework = () => {
        setShowRemoveHomework(false)
    }

    const handleRemovehomework = async (e) => {
        e.preventDefault()
        // console.log(removeHomeworkIndex?.rehomework, removeHomeworkIndex?.refirstname)
        try {
            const response = await axios.post(URL_DELETE, {
                namehomework: removeHomeworkIndex?.rehomework,
                firstnamestudent: removeHomeworkIndex?.refirstname,
                lastnamestudent: removeHomeworkIndex?.relastname,
                typefile: removeHomeworkIndex?.retypefile,
                filename: removeHomeworkIndex?.refilename,
            })
            const { data: homeworkAddData } = await setHomeworkRemoveMutation({
                variables: {
                    _id: removeHomeworkIndex?.reid,
                },
            })
            // console.log(homeworkAddData)
            if (homeworkAddData) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('SendHomeworkPage.altremove')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    student: user?._id,
                })
                onEditclose(true)
            } else {
                onEditclose(true)
                setStatusEdit(false)
                await toast({
                    position: 'top',
                    title: 'Error',
                    description: 'ลบข้อมูลไม่สำเร็จ',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    student: user?._id,
                })
            }
            handleCloseRemoveHomework()
        } catch (Err) {
            // console.log(Err)
            // alert(`${Err?.graphQLErrors[0]?.message}`);
        }
    }

    const statusAlert = (status) => {
        if (status == 'submit') {
            return (<Alert status='success'>
                <AlertIcon />
                ส่งแล้ว
            </Alert>)
        } else if (status == 'scoresubmit') {
            return (<Alert status='success'>
                <AlertIcon />
                ให้คะแนนแล้ว
            </Alert>)
        } else {
            return (<Alert status='warning'>
                <AlertIcon />
                ยังไม่ส่ง
            </Alert>)
        }
    }
    console.log(homeworkData)

    return (
        <Fragment>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isAddopen}
                onClose={onAddclose}
            >
                <ModalOverlay />
                <ModalContent>
                    <Box>
                        <form>
                            <ModalHeader>{contentState?.homeworkconfig.name}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                                <Dropzone
                                    require
                                    onChange={updateFilesImg}
                                    value={filesImg}
                                    accept={contentState?.homeworkconfig?.typefile=='video'?'.mp4':contentState?.homeworkconfig?.typefile}
                                    maxFiles="1"
                                    view="grid"
                                >
                                    {filesImg.map((file) => (
                                        <FileItem {...file} key={file.id} onDelete={onDeleteImg} preview={true} />
                                    ))}
                                </Dropzone>

                                <FormControl>
                                    <FormLabel mt={3}>
                                        {t('SendHomeworkPage.uploadname')} : {user?.firstname} {user?.lastname}
                                    </FormLabel>
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                {filesImg.length > 0 ? (
                                    <Box>
                                        <Button mr={3} onClick={onAddclose}>
                                            {t('SendHomeworkPage.cancel')}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                handleSendhomework(
                                                    contentState?.name,
                                                    user?.firstname,
                                                    user?.lastname,
                                                    contentState?.homeworkconfig?.typefile,
                                                    filesImg[0].file.name
                                                )
                                            }}
                                            colorScheme="blue"
                                            mr={3}
                                        >
                                            {t('SendHomeworkPage.save')}
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Button mr={3} onClick={onAddclose}>
                                            {t('SendHomeworkPage.cancel')}
                                        </Button>
                                        <Button disabled colorScheme="blue">
                                            {t('SendHomeworkPage.save')}
                                        </Button>
                                    </Box>
                                )}
                            </ModalFooter>
                        </form>
                    </Box>

                </ModalContent>
            </Modal>

            <AlertDialog isOpen={showRemoveHomework} onClose={handleCloseRemoveHomework}>
                <AlertDialogOverlay>
                    <form onSubmit={handleRemovehomework} as={GridItem} colSpan={[6, 3]}>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                {t('SendHomeworkPage.removefile')}
                            </AlertDialogHeader>

                            <AlertDialogBody>{t('SendHomeworkPage.removedetail')}</AlertDialogBody>

                            <AlertDialogFooter>
                                <Button onClick={handleCloseRemoveHomework}>Cancel</Button>
                                <Button colorScheme="red" type="submit" ml={3}>
                                    {t('SendHomeworkPage.delete')}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </form>
                </AlertDialogOverlay>
            </AlertDialog>

            <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isEditopen} onClose={handleUpdateStatus} >
                <ModalOverlay />
                <ModalContent>

                    <form>
                        <ModalHeader>เเก้ไข {contentState?.homeworkconfig?.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <Flex justifyContent="space-between">
                                <Center>
                                    {contentState?.homeworkconfig?.typefile === '.pdf' ? (
                                        <AiOutlineFilePpt size={70} />
                                    ) : (
                                        <AiOutlineFileImage size={70} />
                                    )}
                                    <Text> {homeworkData?.file} </Text>
                                </Center>
                                <Center>
                                    <Tooltip label={t('SendHomeworkPage.edit')} fontsize="md">
                                        <Button backgroundColor={bg} onClick={updateStatusEdit} mr={1}>
                                            <FaPencilAlt />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip label={t('SendHomeworkPage.delete')} fontsize="md">
                                        <Button
                                            backgroundColor={bg}
                                            onClick={() => {
                                                handleOpenRemoveHomework(
                                                    contentState.homeworkconfig.name,
                                                    user.firstname,
                                                    user.lastname,
                                                    contentState.homeworkconfig.typefile,
                                                    homeworkData?.file,
                                                    homeworkData?._id
                                                )
                                            }}
                                        >
                                            <FaTrashAlt />
                                        </Button>
                                    </Tooltip>
                                </Center>
                            </Flex>

                            {statusremove === true && (
                                <Box>
                                    <Dropzone
                                        require
                                        onChange={updateFilesImg}
                                        value={filesImg}
                                        accept={contentState.homeworkconfig.typefile}
                                        maxFiles="1"
                                        view="grid"
                                    >
                                        {filesImg.map((file) => (
                                            <FileItem
                                                {...file}
                                                key={file.id}
                                                onDelete={onDeleteImg}
                                                // localization={"EN-en"}
                                                // resultOnTooltip
                                                preview={true}
                                            />
                                        ))}
                                    </Dropzone>
                                    <ModalFooter>
                                        {filesImg?.length > 0 ? (
                                            <Box>
                                                <Button onClick={onEditclose}>{t('SendHomeworkPage.cancel')}</Button>
                                                <Button
                                                    onClick={() => {
                                                        handleSendhomework(
                                                            contentState?.name,
                                                            user?.firstname,
                                                            user?.lastname,
                                                            contentState?.homeworkconfig?.typefile,
                                                            filesImg[0]?.file.name,
                                                            homeworkData?.file
                                                        )
                                                    }}
                                                    bg={'primary.700'} color={'white'}
                                                    mr={3}
                                                >
                                                    {t('SendHomeworkPage.save')}
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <Button mr={3} onClick={onEditclose}>
                                                    {t('SendHomeworkPage.cancel')}
                                                </Button>
                                                <Button disabled bg={'primary.700'} color={'white'}>
                                                    {t('SendHomeworkPage.save')}
                                                </Button>
                                            </Box>
                                        )}
                                    </ModalFooter>
                                </Box>
                            )}
                            {statusEdit === true && (
                                <Box>
                                    <Dropzone
                                        require
                                        onChange={updateFilesImg}
                                        value={filesImg}
                                        accept={contentState.homeworkconfig.typefile}
                                        maxFiles="1"
                                        view="grid"
                                    >
                                        {filesImg.map((file) => (
                                            <FileItem
                                                {...file}
                                                key={file.id}
                                                onDelete={onDeleteImg}
                                                // localization={"EN-en"}
                                                // resultOnTooltip
                                                preview={true}
                                            />
                                        ))}
                                    </Dropzone>
                                    <ModalFooter>
                                        {filesImg.length > 0 ? (
                                            <Box>
                                                <Button onClick={handleUpdateStatus}>{t('SendHomeworkPage.cancel')}</Button>
                                                <Button
                                                    onClick={() => {
                                                        handleUpdatehomework(
                                                            contentState.homeworkconfig.name,
                                                            user.firstname,
                                                            user.lastname,
                                                            contentState.homeworkconfig.typefile,
                                                            filesImg[0].file.name,
                                                            homeworkData?.file,
                                                            homeworkData._id
                                                        )
                                                    }}
                                                    bg={'primary.700'} color={'white'}
                                                    mr={3}
                                                >
                                                    {t('SendHomeworkPage.save')}
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <Button mr={3} onClick={handleUpdateStatus}>
                                                    {t('SendHomeworkPage.cancel')}
                                                </Button>
                                                <Button disabled bg={'primary.700'} color={'white'}>
                                                    {t('SendHomeworkPage.save')}
                                                </Button>
                                            </Box>
                                        )}
                                    </ModalFooter>
                                </Box>
                            )}
                            <FormControl>
                                <FormLabel mt={3}>
                                    {t('SendHomeworkPage.uploadname')} : {user.firstname} {user.lastname}
                                </FormLabel>
                            </FormControl>
                        </ModalBody>
                    </form>
                </ModalContent>
            </Modal>


            <Box w="100%" px={'20'}>
                <Container maxW={'8xl'} py={16} as={Stack} spacing={12}>
                    <Heading size="xl" mt={2}>
                        {contentState?.name}
                    </Heading>
                    <Box p={'4'} bg={'gray.300'} borderRadius="lg" >
                        {handleConvertHTML(contentState?.homeworkconfig?.description)}
                    </Box>

                    {contentState?.homeworkconfig.exampleFile && (
                        <Link href={`${contentState?.homeworkconfig.exampleFile}`} isExternal>
                            <Text mt={1} ml={1}>
                                {t('SendHomeworkPage.samplefile')} <ExternalLinkIcon />
                            </Text>
                        </Link>
                    )}

                    <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} >
                        <Table variant="striped" mt={3}>
                            <Thead>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.deadline')}</Td>
                                    <Td> {dayjs(contentState?.homeworkconfig?.duedate).format('DD/MM/YYYY , HH:mm:ss')}</Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.status')}</Td>
                                    <Td>
                                        <Box>
                                            {statusAlert(homeworkData?.homework?.status)}
                                        </Box>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.senddate')}</Td>
                                    <Td>
                                        <Box>
                                            {homeworkData?.homework?.file && homeworkData?.homework?.student?._id === user._id && (
                                                <Text>{dayjs(homeworkData?.homework?.submitdate).format('DD/MM/YYYY , HH:mm:ss')}</Text>
                                            )}
                                        </Box>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.file')}</Td>
                                    <Td>
                                        <Box >
                                            <Link
                                                href={`${URLHomework}/${contentState?.homeworkconfig?.name}/${studentname}/${homeworkData?.homework?.file}`}
                                                isExternal
                                                mb={1}
                                            >
                                                <Text>{homeworkData?.homework?.file}</Text>
                                            </Link>
                                        </Box>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.score')}</Td>
                                    <Td>
                                        {scoreAddData?.scores?.map((scorehomework) => (
                                            <Box key={scorehomework?._id} > {scorehomework?.homework[0]?.score} </Box>
                                        ))}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('SendHomeworkPage.comment')}</Td>
                                    <Td>
                                        {scoreAddData?.scores?.map((scorehomework) => (
                                            <Box key={scorehomework?._id}>
                                                <Box>
                                                    {handleConvertHTML(scorehomework?.homework[0]?.comment)}
                                                    {scorehomework?.homework[0].file &&
                                                        <Flex mt={1}>
                                                            <Center>
                                                                <AiOutlineFilePpt />
                                                                <Link
                                                                    href={`${scorehomework?.homework[0]?.file}`}
                                                                    isExternal
                                                                >
                                                                    <Text>{t('SendHomeworkPage.files')}</Text>
                                                                </Link></Center>
                                                        </Flex>
                                                    }
                                                </Box>
                                            </Box>
                                        ))}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </Container>
            </Box>
        </Fragment>
    )
}
export default PreviewHomeworkPage

import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const colors = {
  primary: {
    100: "#BBDEFB",
    200: "#90CAF9",
    300: "#90CAF9",
    400: "#64B5F6",
    500: "#42A5F5",
    600: "#2196F3",
    700: "#1E88E5", //สีปุ่ม
    800: "#6495ED", //สีกรอบภาษา,สีชื่อข้างโลโก้
    900: "#003399"  //สีเมนู,footer 
  }, brand: {
    100: "#EDF2F7",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923",
  },
  status: {
    100: "#FFC000",
    200: "#32CD32",
  },
  button: {
    100: "#A82121",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923",
  },
};

// const breakpoints = createBreakpoints({
//   sm: '30em',
//   md: '48em',
//   lg: '62em',
//   xl: '80em',
//   '2xl': '96em',
// })

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}
const steps = {
  components: [Steps]

}

const customTheme = extendTheme({
  colors,
  components: { Steps },
  config,
  // breakpoints

});

export default customTheme;

import React, { Fragment, useState, useEffect, useContext } from 'react'
import { OrderGroup, OrderItem, arrayMove } from 'react-draggable-order'
import { useTranslation } from 'react-i18next'
import {
	Tooltip,
	Center,
	Box,
	Container,
	Stack,
	Text,
	Textarea,
	Input,
	RadioGroup,
	Radio,
	Heading,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Select,
	InputGroup,
	InputLeftAddon,
	InputRightElement,
	useToast,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	IconButton,
	InputRightAddon,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Checkbox,
	Switch,
	Badge,
	VStack,
	useColorModeValue,
	useColorMode,
} from '@chakra-ui/react'

import { useMutation, useQuery } from '@apollo/client'
import { EditIcon, AddIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router'
import { useMultiState } from '../lib/hooks'
import updateQuestion from '../graphql/mutations/updateQuestion'
import updateListQuestion from '../graphql/mutations/updateListQuestion'
import QuestionId from '../graphql/queries/question'
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { deepOrange } from '@mui/material/colors'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import { TextField } from '@mui/material'
import dayjs from "dayjs";


const theme = createTheme({
	palette: {
		primary: {
			main: '#0CA25F',
		},
		secondary: deepOrange,
	},
})

const initialContent = {
	name: '',
	describe: '',
	statusActive: '',
	statusExpireDate: false,
	statusStartDate: false
}

const initialQuestionForm = {
	question: '',
	choices: '',
	answer: '',
	questiontype: '',
	descrip: '',
	mutichoices: [],
	mutianswer: [],
	pointanswer: [],
}

const ManageQuestionPage = () => {
	localStorage.setItem('refetchPage', true)
	const { t } = useTranslation()
	const param = useParams()
	const history = useHistory()
	const [list, setList] = useState()
	const { colorMode } = useColorMode()
	const [refetchList, setRefetchList] = useState(false)
	const { user } = useContext(AuthContext)
	const [showEditQuestion, setShowEditQuestion] = useState(false)
	const [showEditContent, setShowEditContent] = useState(false)
	const [showRemoveContent, setShowRemoveContent] = useState(false)
	const [setUpdateQuestion] = useMutation(updateQuestion)
	const [setUpdateListQuestion] = useMutation(updateListQuestion)

	const bgGrid = useColorModeValue('#E2E8F0', 'gray.900')
	const [questionUpdate, setQuestionUpdate] = useState('')
	const [questionForm, setQuestionForm] = useMultiState(initialQuestionForm)
	const [choice, setChoice] = useState([])
	const [content, setContent] = useMultiState(initialContent)
	const [updateContent, setUpdateContent] = useMultiState(initialContent)

	const toast = useToast()
	const [editIndex, setEditIndex] = useState('')
	const [RemoveIndex, setRemoveIndex] = useState('')


	const { data: Questions, refetch, loading } = useQuery(QuestionId, {
		variables: {
			_id: param?.contentId,
		},
	})
	// console.log(Questions?.content?.questionconfig?.startdate)


	useEffect(() => {
		if (Questions) {
			setQuestionUpdate(Questions)
			setContent({
				name: Questions?.content?.name,
				describe: Questions?.content?.questionconfig?.description,
				statusActive: Questions?.content?.statusActive,
				duedate: Questions?.content?.questionconfig?.duedate,
				startdate: Questions?.content?.questionconfig?.startdate,
				expiredate: Questions?.content?.expiredate,
			})
			setUpdateContent({
				name: Questions?.content?.name,
				describe: Questions?.content?.questionconfig?.description,
				statusActive: Questions?.content?.statusActive,
				duedate: Questions?.content?.questionconfig?.duedate,
				startdate: Questions?.content?.questionconfig?.startdate,
				expiredate: Questions?.content?.expiredate,
				statusExpireDate: Questions?.content?.expiredate ? true : false,
				statusStartDate: Questions?.content?.questionconfig?.startdate ? true : false,
			})
		}
		if (user?.role === 'STUDENT') {
			setTimeout(() => {
				history.push('/login')
			}, 100)
		}
		if (loading) {
			refetch({
				teacher: user?._id,
			})
		}
		if (Questions?.content?.expiredate) {
			setContent({ statusExpireDate: true })
		}
		setList(Questions?.content?.questionconfig?.questionList)
	}, [Questions, questionUpdate, history, user, refetch,])

	// console.log("CCCCC", list)
	useEffect(async () => {
		try {
			if (refetchList) {
				console.log("ARRAY", list)
				const { data: ListQuestionData } = await setUpdateListQuestion({
					variables: {
						_id: param?.contentId,
						questionList: list,
					},
				})
				if (ListQuestionData) {
					// console.log(QuestionData)
					await refetch({
						_id: param?.contentId,
					})
					setRefetchList(false)
					// await toast({
					//     position: 'top',
					//     title: 'Success',
					//     description: `${t('ManageSurveyPage.altsuccesquestion')}`,
					//     status: 'success',
					//     duration: 3000,
					//     isClosable: true,
					// })
				}
			}
		} catch (Error) {
			console.log('in Create submit', Error)
		}
	}, [refetchList, list, setRefetchList])

	const handleOnOpenEditQuestion = (index) => {
		const questionEdit = questionUpdate?.content?.questionconfig?.questionList[index]
		setQuestionForm({ answer: questionEdit?.answer, question: questionEdit?.question, questiontype: questionEdit?.questiontype, choices: [], descrip: questionEdit?.descrip, })
		setMutiAnswer(questionEdit?.mutianswer)
		setMutiChoice(questionEdit?.mutichoices)
		setChoice({ choice_1: questionEdit?.choices[0], choice_2: questionEdit?.choices[1], choice_3: questionEdit?.choices[2], choice_4: questionEdit?.choices[3] })
		setEditIndex(index)
		setShowEditQuestion(true)
	}

	const handleOnCloseEditQuestion = () => {
		setShowEditQuestion(false)
	}

	//Remove
	const handleOnOpenRemoveContent = (index) => {
		setRemoveIndex(index)
		setShowRemoveContent(true)
	}

	const handleOnCloseRemoveContent = () => {
		setShowRemoveContent(false)
	}
	// Content
	const handleOnOpenContent = () => {
		setShowEditContent(true)
	}

	const handleOnCloseContent = () => {
		setShowEditContent(false)
	}

	// Add Question
	const [showAddQuestion, setShowAddQuestion] = useState(false)
	const handleOnCloseAddQuestion = () => {
		setQuestionForm({ choices: [], answer: '', question: '', questiontype: '', mutichoices: [], mutianswer: [], })
		setChoice('')
		setShowAddQuestion(false)
	}

	const handleOnOpenAddQuestion = () => {
		setShowAddQuestion(true)
	}

	const handleTypeQuestion = async (question) => {
		// console.log("Incunction", question)
		if (question?.questiontype === "GENERAL") {
			// console.log("inclase")
			if (!question?.question) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('managerQuestionPage.altwarningquestion')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else if (!question?.answer) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('managerQuestionPage.altwarninganswer')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else {
				// console.log("return true")
				return true
			}
		} else if (question?.questiontype === "ONEANSWER") {
			if (!question?.question) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('managerQuestionPage.altwarningquestion')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else if (!choice?.choice_1) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('managerQuestionPage.altwarningchoice1')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else if (!choice?.choice_2) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('managerQuestionPage.altwarningchoice2')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else if (!choice?.choice_3) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('managerQuestionPage.altwarningchoice3')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else if (!choice?.choice_4) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('managerQuestionPage.altwarningchoice4')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else if (!questionForm?.answer) {
				await toast({
					position: 'top',
					title: 'warning',
					description: `${t('managerQuestionPage.altwarninganswer')}`,
					status: 'warning',
					duration: 3000,
					isClosable: true,
				})
			} else {

				return true
			}

		} else if (question?.questiontype === "MANYANSWER") {
			// console.log("inc:")
			// setChoice(mutiChoice)
			return true

		} else {
			return false
		}

	}


	const handleAddQuestionContent = async (e) => {
		e.preventDefault()
		if (await handleTypeQuestion(questionForm)) {
			try {
				// console.log("questionForm", questionForm)
				let addQuestion = questionUpdate?.content?.questionconfig?.questionList?.map(
					(row) => ({
						question: row.question,
						answer: row.answer,
						choices: row.choices,
						mutichoices: row.mutichoices,
						mutianswer: row.mutianswer,
						descrip: row.descrip,
						questiontype: row.questiontype,
						pointanswer: row.pointanswer
					})
				)
				if (questionForm?.questiontype === "GENERAL") {
					// questionForm?.choices?.push(...Object.values(choice))
				} else if (questionForm?.questiontype === "MANYANSWER") {
					questionForm?.mutichoices?.push(...Object.values(mutiChoice))
					questionForm?.mutianswer?.push(...Object.values(mutiAnswer))
				}
				else {
					questionForm?.choices?.push(...Object.values(choice))
				}

				addQuestion.push(questionForm)
				// console.log(questionForm);
				// convert to array
				// let convert = mutiChoice.map(obj => Object.values(obj)[0])
				// console.log("convert", convert);
				const { data: QuestionData } = await setUpdateQuestion({
					variables: {
						_id: param?.contentId,
						name: content?.name,
						nameQuestion: content?.name,
						description: content?.describe,
						statusActive: content?.statusActive,
						duedate: content?.statusStartDate,
						startdate: content?.statusStartDate,
						expiredate: content?.statusExpireDate,
						questionList: addQuestion,
					},
				})
				if (QuestionData) {
					// console.log(QuestionData)
					await toast({
						position: 'top',
						title: 'Success',
						description: `${t('managerQuestionPage.altsuccesquestion')}`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					})
					refetch({
						_id: param?.contentId,
					})
				}
			} catch (Error) {
				console.log('in Create submit', Error)
			}
			setQuestionForm({ choices: [], answer: '', question: '', questiontype: '', mutichoices: [], mutianswer: [] })
			setMutiChoice([{ choice: "", index: "" }])
			setMutiAnswer([{ answer: "", index: "" }])
			setChoice('')
			handleOnCloseAddQuestion()
		} else {
			console.log("Good")
		}
	}


	const handleUpdateQuestionContent = async (e) => {
		e.preventDefault()
		try {

			// console.log("QQQQQQQQ", questionForm)
			if (questionForm?.questiontype === "GENERAL") {
				// questionForm?.choices?.push(...Object.values(choice))
			} else if (questionForm?.questiontype === "MANYANSWER") {
				questionForm?.mutichoices?.push(...Object.values(mutiChoice))
				questionForm?.mutianswer?.push(...Object.values(mutiAnswer))
			}
			else {
				questionForm?.choices?.slice(0, 3, Object.values(choice))
				questionForm?.choices?.push(...Object.values(choice))
			}
			questionUpdate?.content?.questionconfig?.questionList?.splice(
				editIndex,
				1,
				questionForm
			)



			let updateQuestion = questionUpdate?.content?.questionconfig?.questionList?.map(
				(row) => ({
					question: row.question,
					answer: row.answer,
					choices: row.choices,
					mutichoices: row.mutichoices,
					mutianswer: row.mutianswer,
					descrip: row.descrip,
					questiontype: row.questiontype,
					pointanswer: row.pointanswer
				})
			)


			const { data: QuestionData } = await setUpdateQuestion({
				variables: {
					_id: param?.contentId,
					name: content?.name,
					nameQuestion: content?.name,
					description: content?.describe,
					statusActive: content?.statusActive,
					duedate: content?.statusStartDate,
					startdate: content?.statusStartDate,
					expiredate: content?.statusExpireDate,
					questionList: updateQuestion,
				},
			})
			if (QuestionData) {
				// console.log(QuestionData)
				await toast({
					position: 'top',
					title: 'Success',
					description: `${t('managerQuestionPage.alteditquestion')}`,
					status: 'success',
					duration: 3000,
					isClosable: true,
				})
				refetch({
					_id: param?.contentId,
				})
			}
			setQuestionForm({ choices: [], answer: '', question: '', questiontype: '', mutichoices: [], mutianswer: [] })
			setMutiChoice([{ choice: "", index: "" }])
			setMutiAnswer([{ answer: "", index: "" }])
			handleOnCloseEditQuestion()
			setChoice('')
		} catch (Error) {
			console.log('in Create submit', Error)
		}
	}

	const handleRemoveQuestion = async (e) => {
		e.preventDefault()
		// console.log('remove')
		try {
			questionUpdate?.content?.questionconfig?.questionList?.splice(RemoveIndex, 1)
			let removeQuestion = questionUpdate?.content?.questionconfig?.questionList?.map(
				(row) => ({
					question: row.question,
					answer: row.answer,
					choices: row.choices,
					mutichoices: row.mutichoices,
					mutianswer: row.mutianswer,
					descrip: row.descrip,
					questiontype: row.questiontype,
					pointanswer: row.pointanswer
				})
			)
			// console.log(removeQuestion)
			const { data: QuestionData } = await setUpdateQuestion({
				variables: {
					_id: param?.contentId,
					name: content?.name,
					nameQuestion: content?.name,
					description: content?.describe,
					statusActive: content?.statusActive,
					duedate: content?.statusStartDate,
					startdate: content?.statusStartDate,
					expiredate: content?.statusExpireDate,
					questionList: removeQuestion,
				},
			})
			if (QuestionData) {
				// console.log(QuestionData)
				await toast({
					position: 'top',
					title: 'Success',
					description: 'ลบข้อมูลสำเร็จ',
					status: 'success',
					duration: 3000,
					isClosable: true,
				})
				refetch({
					_id: param?.contentId,
				})
			}
			handleOnCloseRemoveContent()
		} catch (Error) {
			console.log('in Create submit', Error)
		}
	}

	const handleContentUpdate = async (e) => {
		e.preventDefault()
		// console.log('content:', content?.name, content?.describe)
		if (!updateContent?.name) {
			await toast({
				position: 'top',
				title: 'warning',
				description: `${t('managerQuestionPage.altwarningcontent')}`,
				status: 'warning',
				duration: 3000,
				isClosable: true,
			})
		} else {
			try {
				let questionList = questionUpdate?.content?.questionconfig?.questionList?.map(
					(row) => ({
						question: row.question,
						answer: row.answer,
						choices: row.choices,
						mutichoices: row.mutichoices,
						mutianswer: row.mutianswer,
						descrip: row.descrip,
						questiontype: row.questiontype,
						pointanswer: row.pointanswer
					})
				)
				const { data: ContentData } = await setUpdateQuestion({
					variables: {
						_id: param?.contentId,
						name: updateContent?.name,
						nameQuestion: updateContent?.name,
						description: updateContent?.describe,
						statusActive: updateContent?.statusActive,
						duedate: updateContent?.statusStartDate === true ? dayjs(updateContent?.duedate) : null,
						startdate: updateContent?.statusStartDate === true ? dayjs(updateContent?.startdate) : null,
						expiredate: updateContent?.statusExpireDate === true ? dayjs(updateContent?.expiredate) : null,
						questionList: questionList
					},
				})
				if (ContentData) {
					await toast({
						position: 'top',
						title: 'Success',
						description: `${t('managerQuestionPage.altsuccesscontent')}`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					})
					refetch({
						_id: param?.contentId,
					})
				}
				handleOnCloseContent()
			} catch (Error) {
				console.log('in Create submit', Error)
			}
		}
	}

	const [mutiChoice, setMutiChoice] = useState([{ choice: "", index: "" }])

	let handleChangeMutiChoice = (i, e) => {
		e.preventDefault()
		let newmutiChoice = [...mutiChoice];
		newmutiChoice[i][e.target.name] = e.target.value;
		newmutiChoice[i]["index"] = i;
		setMutiChoice(newmutiChoice);
	}


	let addFormFieldsMutiChoice = () => {
		setMutiChoice([...mutiChoice, { choice: "" }])
	}

	let removeFormFieldsMutiChoice = (i) => {
		let newmutiChoice = [...mutiChoice];
		newmutiChoice.splice(i, 1);
		setMutiChoice(newmutiChoice)
	}

	const [mutiAnswer, setMutiAnswer] = useState([{ answer: "", index: "" }])

	let handleChangeMutiAnswer = (i, e) => {
		e.preventDefault()
		let newmutiAnswer = [...mutiAnswer];
		newmutiAnswer[i][e.target.name] = e.target.value;
		newmutiAnswer[i]["index"] = i;
		setMutiAnswer(newmutiAnswer);

	}

	let addFormFieldsMutiAnswer = () => {
		setMutiAnswer([...mutiAnswer, { answer: "" }])
	}

	let removeFormFieldsMutiAnswer = (i) => {
		let newmutiAnswer = [...mutiAnswer];
		newmutiAnswer.splice(i, 1);
		setMutiAnswer(newmutiAnswer)
	}



	return (
		<Fragment>
			<Modal isOpen={showAddQuestion} onClose={handleOnCloseAddQuestion} size={'5xl'}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('managerQuestionPage.addquestion')}</ModalHeader>
					<ModalCloseButton />
					<form onSubmit={handleAddQuestionContent} as={GridItem} colSpan={[6, 3]}>
						<ModalBody>
							<Select defaultValue=' ' onChange={(e) => setQuestionForm({ questiontype: e.target.value })} >
								<option value=' '>{t('managerQuestionPage.choosetype')}</option>
								<option value='GENERAL'>{t('managerQuestionPage.general')}</option>
								<option value='ONEANSWER'>{t('managerQuestionPage.oneanswer')}</option>
								<option value='MANYANSWER'>{t('managerQuestionPage.manyanswer')}</option>
							</Select>
							{questionForm?.questiontype === 'GENERAL' && (
								<Box>
									<FormLabel fontSize="sm" fontWeight="md">
										<Text fontSize="lg">{t('managerQuestionPage.question')}</Text>
									</FormLabel>
									<FormControl id="">
										<Textarea
											resize="vertical"
											size="lg"
											mt={2}
											placeholder={t('managerQuestionPage.questionplaceholder')}
											onChange={(e) => (setQuestionForm({ question: e.target.value }, setQuestionForm({ choices: ["true", "false"] })))}
										/>
									</FormControl>
									<FormControl id="" p={5}>
										<FormLabel fontSize="sm" fontWeight="md">
											<Text fontSize="lg">{t('managerQuestionPage.selectplaceholder')}</Text>
										</FormLabel>

										<RadioGroup onChange={(e) => setQuestionForm({ answer: e })} value={questionForm?.answer}>
											<Stack direction='row'>
												<Radio value="true" size='lg'>{t('managerQuestionPage.ture')}</Radio>
												<Radio value="false" size='lg'>{t('managerQuestionPage.false')}</Radio>
											</Stack>
										</RadioGroup>

									</FormControl>
									{/* <FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children={t('managerQuestionPage.answer')} />
											<Input
												placeholder={t('managerQuestionPage.answer')}
												value={questionForm?.answer}
											/>
										</InputGroup>
									</FormControl> */}
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children={t('managerQuestionPage.description')} />
											<Input
												placeholder={t('managerQuestionPage.description')}
												onChange={(e) => setQuestionForm({ descrip: e.target.value })}
											/>
										</InputGroup>
									</FormControl>
								</Box>
							)}
							{questionForm?.questiontype === 'ONEANSWER' && (
								<Box>
									<FormLabel fontSize="sm" fontWeight="md">
										<Text fontSize="lg">{t('managerQuestionPage.question')}</Text>
									</FormLabel>
									<FormControl id="">
										<Textarea
											resize="vertical"
											size="lg"
											mt={2}
											placeholder={t('managerQuestionPage.questionplaceholder')}
											onChange={(e) => setQuestionForm({ question: e.target.value })}
										/>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children="1" />
											<Input
												placeholder={t('managerQuestionPage.choice')}
												onChange={(e) => setChoice({ ...choice, 'choice_1': e.target.value })}
											/>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children="2" />
											<Input
												placeholder={t('managerQuestionPage.choice')}
												onChange={(e) => setChoice({ ...choice, 'choice_2': e.target.value })}
											/>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children="3" />
											<Input
												placeholder={t('managerQuestionPage.choice')}
												onChange={(e) => setChoice({ ...choice, 'choice_3': e.target.value })}
											/>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children="4" />
											<Input
												placeholder={t('managerQuestionPage.choice')}
												onChange={(e) => setChoice({ ...choice, 'choice_4': e.target.value })}
											/>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={5}>
											<InputLeftAddon children={t('managerQuestionPage.answer')} />
											<Select
												placeholder={t('managerQuestionPage.selectplaceholder')}
												borderLeftRadius={0}
												onChange={(e) => setQuestionForm({ answer: e.target.value })}
											>
												{choice?.choice_1 && <option value={choice?.choice_1}>{choice?.choice_1}</option>}
												{choice?.choice_2 && <option value={choice?.choice_2}>{choice?.choice_2}</option>}
												{choice?.choice_3 && <option value={choice?.choice_3}>{choice?.choice_3}</option>}
												{choice?.choice_4 && <option value={choice?.choice_4}>{choice?.choice_4}</option>}
											</Select>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children={t('managerQuestionPage.description')} />
											<Input
												placeholder={t('managerQuestionPage.description')}
												onChange={(e) => setQuestionForm({ descrip: e.target.value })}
											/>
										</InputGroup>
									</FormControl>
								</Box>
							)}
							{questionForm?.questiontype === 'MANYANSWER' && (
								<Box>
									<FormLabel fontSize="sm" fontWeight="md">
										<Text fontSize="lg">{t('managerQuestionPage.question')}</Text>
									</FormLabel>
									<FormControl id="">
										<Textarea
											resize="vertical"
											size="lg"
											mt={2}
											placeholder={t('managerQuestionPage.questionplaceholder')}
											onChange={(e) => setQuestionForm({ question: e.target.value })}
										/>
									</FormControl>
									<Grid templateColumns="repeat(5, 1fr)" gap={4}>
										<GridItem colStart={1} colEnd={5} mt={5}>
											<Text>{t('managerQuestionPage.list')}</Text>
										</GridItem>
										<GridItem colStart={6} colEnd={12} mt={5}>
											<Button size="xs" mr={2} rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiChoice()} >{t('managerQuestionPage.addchoice')}</Button>
											<Button p={3} size="xs" rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiAnswer()}>{t('managerQuestionPage.addanswer')}</Button>
										</GridItem>
									</Grid>

									{mutiChoice.map((element, index) => (
										<FormControl id="">
											<InputGroup mt={2}>
												<InputLeftAddon children={index + 1} />
												<Input
													name="choice"
													placeholder={t('managerQuestionPage.choice')}
													onChange={(e) => handleChangeMutiChoice(index, e)}
												/>
												<InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiChoice} />} />
											</InputGroup>
										</FormControl>
									))}
									{mutiAnswer.map((element, index) => (
										<FormControl id="">
											<InputGroup mt={5}>
												<InputLeftAddon children={t('managerQuestionPage.answer')} />
												<Select
													placeholder={t('managerQuestionPage.selectplaceholder')}
													borderLeftRadius={0}
													name="answer"
													onChange={(e) => handleChangeMutiAnswer(index, e)}
												>
													{mutiChoice.map((choices, index) => (<option value={choices.choice}>{choices.choice}</option>))}
												</Select>
												<InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiAnswer} />} />
											</InputGroup>
										</FormControl>
									))}
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children={t('managerQuestionPage.description')} />
											<Input
												placeholder={t('managerQuestionPage.description')}
												onChange={(e) => setQuestionForm({ descrip: e.target.value })}
											/>
										</InputGroup>
									</FormControl>
								</Box>
							)}
						</ModalBody>
						<ModalFooter>
							<Button mr={3} onClick={handleOnCloseAddQuestion}>
								{t('Button.cancel')}
							</Button>
							<Button type="submit" bg={'primary.700'} color={'white'} _hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}} mr={3}>
								{t('Button.add')}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			<Modal isOpen={showEditQuestion} onClose={handleOnCloseEditQuestion} size={'5xl'}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('managerQuestionPage.editquestion')}</ModalHeader>
					<ModalCloseButton />
					<form onSubmit={handleUpdateQuestionContent} as={GridItem} colSpan={[6, 3]}>
						<ModalBody>
							{/* {console.log(questionForm)} */}
							{questionForm?.questiontype === 'GENERAL' && (
								<Box>
									<FormLabel fontSize="sm" fontWeight="md">
										<Text fontSize="lg">{t('managerQuestionPage.question')}</Text>
									</FormLabel>
									<FormControl id="">
										<Textarea
											resize="vertical"
											size="lg"
											mt={2}
											value={questionForm?.question}
											placeholder={t('managerQuestionPage.questionplaceholder')}
											onChange={(e) => (setQuestionForm({ question: e.target.value }, setQuestionForm({ choices: ["true", "false"] })))}
										/>
									</FormControl>
									<FormControl id="" p={5}>
										<FormLabel fontSize="sm" fontWeight="md">
											<Text fontSize="lg">{t('managerQuestionPage.selectplaceholder')}</Text>
										</FormLabel>

										<RadioGroup onChange={(e) => setQuestionForm({ answer: e })} value={questionForm?.answer}>
											<Stack direction='row'>
												<Radio value="true" size='lg'>{t('managerQuestionPage.ture')}</Radio>
												<Radio value="false" size='lg'>{t('managerQuestionPage.false')}</Radio>
											</Stack>
										</RadioGroup>

									</FormControl>
									{/* <FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children={t('managerQuestionPage.answer')} />
											<Input
												placeholder={t('managerQuestionPage.answer')}
												value={questionForm?.answer}
											/>
										</InputGroup>
									</FormControl> */}
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children={t('managerQuestionPage.description')} />
											<Input
												placeholder={t('managerQuestionPage.description')}
												value={questionForm?.descrip}
												onChange={(e) => setQuestionForm({ descrip: e.target.value })}
											/>
										</InputGroup>
									</FormControl>
								</Box>
							)}
							{questionForm?.questiontype === 'ONEANSWER' && (
								<Box>
									<FormLabel fontSize="sm" fontWeight="md">
										<Text fontSize="lg">{t('managerQuestionPage.question')}</Text>
									</FormLabel>
									<FormControl id="">
										<Textarea
											resize="vertical"
											size="lg"
											mt={2}
											placeholder={t('managerQuestionPage.questionplaceholder')}
											value={questionForm?.question}
											onChange={(e) => setQuestionForm({ question: e.target.value })}
										/>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children="1" />
											<Input
												placeholder={t('managerQuestionPage.choice')}
												value={choice?.choice_1}
												onChange={(e) => setChoice({ ...choice, 'choice_1': e.target.value })}
											/>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children="2" />
											<Input
												placeholder={t('managerQuestionPage.choice')}
												value={choice?.choice_2}
												onChange={(e) => setChoice({ ...choice, 'choice_2': e.target.value })}
											/>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children="3" />
											<Input
												placeholder={t('managerQuestionPage.choice')}
												value={choice?.choice_3}
												onChange={(e) => setChoice({ ...choice, 'choice_3': e.target.value })}
											/>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children="4" />
											<Input
												placeholder={t('managerQuestionPage.choice')}
												value={choice?.choice_4}
												onChange={(e) => setChoice({ ...choice, 'choice_4': e.target.value })}
											/>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={5}>
											<InputLeftAddon children={t('managerQuestionPage.answer')} />
											<Select
												placeholder={t('managerQuestionPage.selectplaceholder')}
												borderLeftRadius={0}
												onChange={(e) => setQuestionForm({ answer: e.target.value })}
											>
												{choice?.choice_1 && <option value={choice?.choice_1}>{choice?.choice_1}</option>}
												{choice?.choice_2 && <option value={choice?.choice_2}>{choice?.choice_2}</option>}
												{choice?.choice_3 && <option value={choice?.choice_3}>{choice?.choice_3}</option>}
												{choice?.choice_4 && <option value={choice?.choice_4}>{choice?.choice_4}</option>}
											</Select>
										</InputGroup>
									</FormControl>
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children={t('managerQuestionPage.description')} />
											<Input
												placeholder={t('managerQuestionPage.description')}
												value={questionForm?.descrip}
												onChange={(e) => setQuestionForm({ descrip: e.target.value })}
											/>
										</InputGroup>
									</FormControl>
								</Box>
							)}
							{questionForm?.questiontype === 'MANYANSWER' && (
								<Box>
									<FormLabel fontSize="sm" fontWeight="md">
										<Text fontSize="lg">{t('managerQuestionPage.question')}</Text>
									</FormLabel>
									<FormControl id="">
										<Textarea
											resize="vertical"
											size="lg"
											mt={2}
											value={questionForm?.question}
											placeholder={t('managerQuestionPage.questionplaceholder')}
											onChange={(e) => setQuestionForm({ question: e.target.value })}
										/>
									</FormControl>
									<Grid templateColumns="repeat(5, 1fr)" gap={4}>
										<GridItem colStart={1} colEnd={5} mt={5}>
											<Text>{t('managerQuestionPage.list')}</Text>
										</GridItem>
										<GridItem colStart={6} colEnd={12} mt={5}>
											<Button size="xs" mr={2} rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiChoice()} >{t('managerQuestionPage.addchoice')}</Button>
											<Button p={3} size="xs" rightIcon={<AddIcon />} onClick={() => addFormFieldsMutiAnswer()}>{t('managerQuestionPage.addanswer')}</Button>
										</GridItem>
									</Grid>
									{mutiChoice.map((element, index) => (
										<FormControl id="">
											<InputGroup mt={2}>
												<InputLeftAddon children={index + 1} />
												<Input
													name="choice"
													value={element?.choice}
													placeholder={t('managerQuestionPage.choice')}
													onChange={(e) => handleChangeMutiChoice(index, e)}
												/>
												<InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiChoice} />} />
											</InputGroup>
										</FormControl>
									))}
									{mutiAnswer.map((element, index) => (
										<FormControl id="">
											<InputGroup mt={5}>
												<InputLeftAddon children={t('managerQuestionPage.answer')} />
												<Select
													placeholder={t('managerQuestionPage.selectplaceholder')}
													borderLeftRadius={0}
													name="answer"
													value={element?.answer}
													onChange={(e) => handleChangeMutiAnswer(index, e)}
												>
													{mutiChoice.map((choices, index) => (<option value={choices.choice}>{choices.choice}</option>))}
												</Select>
												<InputRightAddon children={<IconButton icon={<CloseIcon />} size="xs" onClick={removeFormFieldsMutiAnswer} />} />
											</InputGroup>
										</FormControl>
									))}
									<FormControl id="">
										<InputGroup mt={2}>
											<InputLeftAddon children={t('managerQuestionPage.description')} />
											<Input
												placeholder={t('managerQuestionPage.description')}
												value={questionForm?.descrip}
												onChange={(e) => setQuestionForm({ descrip: e.target.value })}
											/>
										</InputGroup>
									</FormControl>
								</Box>
							)}
							{/* <FormLabel fontSize="sm" fontWeight="md">
								<Text fontSize="lg">{t('managerQuestionPage.question')}</Text>
							</FormLabel>
							<FormControl id="">
								<Textarea
									resize="vertical"
									size="lg"
									mt={2}
									placeholder={t('managerQuestionPage.questionplaceholder')}
									value={questionForm?.question}
									onChange={(e) => setQuestionForm({ question: e.target.value })}
								/>
							</FormControl>
							<FormControl id="">
								<InputGroup mt={5}>
									<InputLeftAddon children="1" />
									<Input
										placeholder={t('managerQuestionPage.choice')}
										value={choice?.choice_1}
										onChange={(e) => setChoice({ choice_1: e.target.value })}
									/>
								</InputGroup>
							</FormControl>
							<FormControl id="">
								<InputGroup mt={2}>
									<InputLeftAddon children="2" />
									<Input
										placeholder={t('managerQuestionPage.choice')}
										value={choice?.choice_2}
										onChange={(e) => setChoice({ choice_2: e.target.value })}
									/>
								</InputGroup>
							</FormControl>
							<FormControl id="">
								<InputGroup mt={2}>
									<InputLeftAddon children="3" />
									<Input
										placeholder={t('managerQuestionPage.altsuccesscontent')}
										value={choice?.choice_3}
										onChange={(e) => setChoice({ choice_3: e.target.value })}
									/>
								</InputGroup>
							</FormControl>
							<FormControl id="">
								<InputGroup mt={2}>
									<InputLeftAddon children="4" />
									<Input
										placeholder={t('managerQuestionPage.choice')}
										value={choice?.choice_4}
										onChange={(e) => setChoice({ choice_4: e.target.value })}
									/>
								</InputGroup>
							</FormControl>
							<FormControl id="">
								<InputGroup mt={5}>
									<InputLeftAddon children={t('managerQuestionPage.answer')} />
									<Select
										borderLeftRadius={0}
										placeholder={t('managerQuestionPage.selectplaceholder')}
										onChange={(e) => setQuestionForm({ answer: e.target.value })}
									>
										{choice?.choice_1 && <option value={choice?.choice_1}>{choice?.choice_1}</option>}
										{choice?.choice_2 && <option value={choice?.choice_2}>{choice?.choice_2}</option>}
										{choice?.choice_3 && <option value={choice?.choice_3}>{choice?.choice_3}</option>}
										{choice?.choice_4 && <option value={choice?.choice_4}>{choice?.choice_4}</option>}
									</Select>
								</InputGroup>
							</FormControl> */}
						</ModalBody>
						<ModalFooter>
							<Button mr={3} onClick={handleOnCloseEditQuestion}>
								{t('Button.cancel')}
							</Button>
							<Button type="submit " bg={'primary.700'} color={'white'} _hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}} mr={3}>
								{t('Button.save')}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			<Modal isOpen={showEditContent} onClose={handleOnCloseContent}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('managerQuestionPage.editcontent')}</ModalHeader>
					<ModalCloseButton />
					<form onSubmit={handleContentUpdate} as={GridItem} colSpan={[6, 3]}>
						<ModalBody>
							<FormControl id="">
								<FormLabel as="legend">{t('managerQuestionPage.name')}</FormLabel>
								<InputGroup mt={2}>
									<Input
										resize="vertical"
										size="lg"
										mt={2}
										placeholder={t('managerQuestionPage.contentplaceholder')}
										value={updateContent?.name}
										onChange={(e) => setUpdateContent({ name: e.target.value })}
									/>
								</InputGroup>
							</FormControl>

							<FormControl id="" mt={2}>
								<FormLabel as="legend">{t('managerQuestionPage.description')}</FormLabel>
								<Textarea
									resize="vertical"
									size="lg"
									height="100px"
									mt={2}
									// placeholder={t('managerQuestionPage.contentplaceholder')}
									value={updateContent?.describe}
									onChange={(e) => setUpdateContent({ describe: e.target.value })}
								/>
							</FormControl>
							<FormControl>
								<FormLabel mt={3} mb={2}> {t('managerQuestionPage.time')}</FormLabel>
							</FormControl>
							<Checkbox size='md' isChecked={updateContent?.statusStartDate} colorScheme='green' onChange={(e) => setUpdateContent({ statusStartDate: e.target.checked })}>
								{t('managerQuestionPage.starttime')}
							</Checkbox>
							<FormControl>
								{updateContent?.statusStartDate === true ?
									<Box>
										{console.log(updateContent.startdate)}
										<Box mb={3} mt={3}>
											<ThemeProvider theme={theme}>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DateTimePicker
														// disableFuture
														label={t('managerQuestionPage.datetimeStart')}
														// openTo="year"
														// minDate={dayjs(Date.now())}
														// views={['year', 'month', 'day']}

														value={updateContent.startdate ? dayjs(updateContent.startdate) : ''}
														onChange={(e) => setUpdateContent({ startdate: e })}
														renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
													/>
												</LocalizationProvider>
											</ThemeProvider>
										</Box>
										{updateContent.startdate &&
											<ThemeProvider theme={theme}>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DateTimePicker
														// disableFuture
														label={t('managerQuestionPage.datetimeEnd')}
														// openTo="year"
														// minDate={content.startdate ? content.startdate : ""}
														minDateTime={updateContent.startdate ? dayjs(updateContent.startdate) : ""}
														// views={['year', 'month', 'day']}
														value={updateContent.duedate ? dayjs(updateContent.duedate) : ''}
														onChange={(e) => setUpdateContent({ duedate: e })}
														renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
													/>
												</LocalizationProvider>
											</ThemeProvider>}
									</Box> : null}
							</FormControl>

							<Checkbox isChecked={updateContent?.statusExpireDate} size='md' colorScheme='green' onChange={(e) => setUpdateContent({ statusExpireDate: e.target.checked })}>
								{t('managerQuestionPage.expiredate')}
							</Checkbox>

							<FormControl>
								{updateContent?.statusExpireDate === true &&
									<Box>
										<Box mb={3} mt={3}>
											<ThemeProvider theme={theme}>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DateTimePicker
														// disableFuture
														label={t('managerQuestionPage.datetimeExpiredate')}
														// openTo="year"
														// minDate={dayjs(Date.now())}
														// views={['year', 'month', 'day']}
														value={updateContent.expiredate ? dayjs(updateContent.expiredate) : ""}
														onChange={(e) => setUpdateContent({ expiredate: e })}
														renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
													/>
												</LocalizationProvider>
											</ThemeProvider>
										</Box>
									</Box>
								}

								<FormControl>
									<FormLabel mt={3} mb={2}> {t('managerQuestionPage.status')}</FormLabel>
									<Switch size='lg' mr={'2'} colorScheme='green' isChecked={updateContent?.statusActive} onChange={(e) => setUpdateContent({ statusActive: e.target.checked })} />
									{updateContent?.statusActive ? t('managerQuestionPage.public') : t('managerQuestionPage.nonepublic')}
								</FormControl>
							</FormControl>
						</ModalBody>
						<ModalFooter>
							<Button mr={3} onClick={handleOnCloseContent}>
								{t('Button.close')}
							</Button>
							<Button type="submit " bg={'primary.700'} color={'white'} _hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}} mr={3}>
								{t('Button.save')}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			<AlertDialog isOpen={showRemoveContent} onClose={handleOnCloseRemoveContent}>
				<AlertDialogOverlay>
					<form onSubmit={handleRemoveQuestion} as={GridItem} colSpan={[6, 3]}>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('managerQuestionPage.removequestion')}
							</AlertDialogHeader>
							<AlertDialogBody><Center>{t('managerQuestionPage.removedescription')}</Center></AlertDialogBody>
							<AlertDialogFooter>
								<Button onClick={handleOnCloseRemoveContent}>	{t('Button.cancel')}</Button>
								<Button bg={'primary.700'} color={'white'} _hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}} type="submit" ml={3}>
									{t('Button.delete')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</form>
				</AlertDialogOverlay>
			</AlertDialog>

			<Box w="100%" px={[2, 5, 15, 20]} >
				<Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
					<Stack spacing={0} align={'center'}>
						<Heading variant="h4">
							{t('managerQuestionPage.title')}
						</Heading>
						<Text >{t('managerQuestionPage.subtitle')}</Text>
					</Stack>
					<Box spacing={0} borderRadius='lg' overflow='hidden' p={2}>
						<FormControl id="">
							<InputGroup py={3}>
								<Text as="legend" fontSize="2xl" fontWeight="extrabold">{t('managerQuestionPage.name')} {content?.statusActive ? <Badge colorScheme='green' ml='1'>{t('managerQuestionPage.public')}</Badge> : <Badge ml='1'>{t('managerQuestionPage.nonepublic')}</Badge>}</Text>
								<InputRightElement width="4.7rem" mt={3}>
									<Tooltip label={t('managerQuestionPage.tooltipedit')} fontSize="sm">
										<Button size='sm' leftIcon={<EditIcon />} fontSize="sm" colorScheme="teal" variant="solid"
											onClick={(e) => {
												e.preventDefault()
												handleOnOpenContent()
											}}>
											{t('ManageSurveyPage.edit')}
										</Button>
									</Tooltip>
								</InputRightElement>
							</InputGroup>
							<Text borderWidth='2px' borderRadius='lg' overflow='hidden' p={2} fontSize='md' mt={1} >{content?.name}</Text>
						</FormControl>

						<FormControl mt={2} id="" >
							<FormLabel as="legend" fontSize="xl" fontWeight="extrabold">{t('managerQuestionPage.description')}</FormLabel>
							<Text mt={2} borderWidth='2px' borderRadius='lg' overflow='hidden' minH={'10vh'} p={2}>{content?.describe}</Text>
						</FormControl>

						<Grid templateColumns="repeat(5, 1fr)" gap={4} py={3}>
							<GridItem colStart={1} colEnd={5} py={2}>
								<Text as="legend" fontSize="2xl" fontWeight="extrabold">{t('managerQuestionPage.list')}</Text>
							</GridItem>
							<GridItem colStart={6} colEnd={12} py={2}>
								<Tooltip label={t('ManageSurveyPage.addsurvey')} fontSize="md">
									<Button leftIcon={<AddIcon />} colorScheme="teal" variant="solid" size='sm' onClick={handleOnOpenAddQuestion}>
										{t('managerQuestionPage.addquestion')}
									</Button>
								</Tooltip>
							</GridItem>
						</Grid>

						<FormControl id="" mt={5}>
							<Box borderWidth='2px' borderRadius='lg' overflow='hidden' p={2} >
								<OrderGroup className={'group'} mode={'between'} >
									{list?.map((question, i) => {
										return (
											<OrderItem key={i}
												index={i}
												onMove={(to) => {
													setRefetchList(true)
													setList(arrayMove(list, i, to))
												}}
												wrapperClassName={'wrapper'}
												wrapperStyle={{
													width: 'revert', left: '0px', '@media screen and (maxWidth: 600px)': {
														left: '40px'
													}
												}}
												inactiveStyle={{ background: colorMode === 'light' ? 'gray.200' : '#2f3748' }}
												wrapperHoverClassName={'hover'}
												className={'item'}
												grabbingClassName={'grabbing'}
												style={{
													width: 'auto', height: "150px", borderWidth: '1px', overflowX: 'auto'
												}}
											>

												<OrderItem.Handle className={'handle'}>
													<VStack color={colorMode === 'light' ? 'black' : 'white'}>
														<HamburgerIcon />
														<Tooltip label={t('managesubjectPage.forum')} fontSize="md">
															<IconButton icon={<EditIcon />} size='25px' mr={2} onClick={(e) => {
																e.preventDefault()
																handleOnOpenEditQuestion(i)
																// setSurveyFormUpdate(survey)
															}} />
														</Tooltip>
														<Tooltip label={t('managesubjectPage.forum')} fontSize="md">
															<IconButton icon={<CloseIcon />} size='22px' mr={2} onClick={(e) => {
																e.preventDefault()
																handleOnOpenRemoveContent(i)
															}} />
														</Tooltip>
													</VStack>
												</OrderItem.Handle>
												<Grid
													className={'content'}
													p={2}
													templateRows='repeat(2, 1fr)'
													templateColumns='repeat(5, 1fr)'
													gap={5}
													overflowX={'auto'}
													w={'full'}
												>
													<GridItem colSpan={5}  >
														<InputGroup borderRadius={'md'} borderWidth={'1px'} >
															<InputLeftAddon borderRadius={'md'} children={t('managerQuestionPage.question')} />
															<Text isReadOnly p={'2'}
																style={{
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	maxWidth: '400px'
																}}>{question?.question}</Text>
														</InputGroup>
													</GridItem>
													<GridItem colSpan={[5, 3]}>
														<InputGroup borderRadius={'md'} borderWidth={'1px'}>
															<InputLeftAddon borderRadius={'md'} children={t('managerQuestionPage.descrip')} />
															<Text isReadOnly p={'2'} >{question?.descrip ? question?.descrip : '-'}</Text>
														</InputGroup>
													</GridItem>
													{/* {console.log("question", question)} */}
													<GridItem colSpan={[5, 2]}>
														<InputGroup borderRadius={'md'} borderWidth={'1px'}>
															<InputLeftAddon borderRadius={'md'} children={t('managerQuestionPage.type')} />
															<Text isReadOnly p={'2'} >{question?.questiontype}</Text>
														</InputGroup>
													</GridItem>
												</Grid>
											</OrderItem>
										)
									})}
								</OrderGroup>

								{list?.length == 0 &&
									<Box align="center" w={'full'} py={'20'} borderRadius={'md'} color={'gray.500'} bg={'gray.200'}><Heading>{t('managerQuestionPage.no_question')}</Heading></Box>
								}
							</Box>
						</FormControl>
					</Box>
				</Container>
			</Box >
		</Fragment >

	)
}

export default ManageQuestionPage
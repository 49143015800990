import { useContext, useEffect, useState, useRef } from 'react';
import {
    Box,
    Heading,
    Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import AuthContext from '../contexts/AuthContext';
import Iframe from 'react-iframe'
import ContentById from '../graphql/queries/contentbyId'
import { useMultiState } from '../lib/hooks';
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router';
import axios from 'axios'

import progressbyId from '../graphql/queries/progressbyId'
import ProgressMutation from '../graphql/mutations/updateProgress'

require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const STATUS = {
    STARTED: "Started",
    STOPPED: "Stopped"
};

const URL_VIDEO = process.env.REACT_APP_URL_CLOUDFLARE
const VideoPlayerPage = () => {
    const { user } = useContext(AuthContext)
    const [progressState, setProgressState] = useMultiState({ progress: 0, activetime: 0, _id: '' })
    const [duration, setDuration] = useState()
    const [setUpdateProgress] = useMutation(ProgressMutation)

    const param = useParams()

    const [player, setPlayer] = useState(null)
    const [status, setStatus] = useState(STATUS.STOPPED);
    const [tracking, setTracking] = useState(-1)

    const { data: Content } = useQuery(ContentById, {
        variables: {
            _id: param?.contentId
        },
    })

    const { data: userprogress } = useQuery(progressbyId, {
        variables: {
            content: param?.contentId,
            student: user?._id
        },
    })

    useEffect(() => {
        if (Content) {
            handleGetDuration()
        }
    }, [Content]);


    useEffect(() => {
        setProgressState({ progress: userprogress?.progress?.progress, activetime: userprogress?.progress?.activetime, _id: userprogress?.progress?._id })
        if (!player) {
            initialiseJitsi();
        }
    }, [player, userprogress]);


    const handleGetDuration = async () => {
        if (Content?.content?.link) {
            let videoId = Content?.content?.link.match(/\b[\w=.]+$/g).toString()
            var config = {
                headers: {
                    'X-Auth-Email': 'kaseampong.kt@streamsouth.tech',
                    'X-Auth-Key': '483ae948176fd81daa6015d16e1f7868b8b1b'
                },
            };
            console.log("ID:", videoId)
            await axios.get(`${URL_VIDEO}/${videoId}`, config).then((response) => {
                console.log(response.data);
                setDuration(response.data?.result?.duration
                )
            });
        }

    }

    const loadStreamScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://embed.videodelivery.net/embed/sdk.latest.js";
            script.async = true;
            script.onload = resolve
            document.body.appendChild(script);
        })
    };

    const initialiseJitsi = async () => {
        if (!window.Stream) {
            await loadStreamScript();
        }
        const _stream = window.Stream(document.getElementById('stream-player'));
        setPlayer(_stream)
    };


    if (player) {
        player.addEventListener('play', () => {
            setStatus(STATUS.STARTED);
        });
        player.addEventListener('pause', () => {
            setStatus(STATUS.STOPPED);
        });
    }
    const useInterval = (callback, delay) => {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    };

    useEffect(async () => {
        if (tracking % 10 == 0 && progressState?.progress != 100) {
            console.log(((progressState?.activetime / duration) * 100).toFixed(3))
            var perc = ((progressState?.activetime / duration) * 100).toFixed(3);
            if (user?.role == 'STUDENT') {
                const { data: progressupdate } = await setUpdateProgress({
                    variables: {
                        _id: progressState?._id,
                        activetime: progressState?.activetime,
                        progress: parseInt(perc)
                    },
                })
                if (progressupdate) {
                    console.log("update", progressupdate)
                }
            }
            setProgressState({ progress: parseInt(perc) })
        }
    }, [tracking]);

    useInterval(
        () => {
            if (progressState?.activetime >= 0 && status == STATUS.STARTED) {
                setTracking(progressState?.activetime + 1)
                setProgressState({ activetime: progressState?.activetime + 1 });
            } else {
                setStatus(STATUS.STOPPED);
            }
        },
        status === STATUS.STARTED ? 1000 : null
        // passing null stops the interval
    );
    return (
        <Box
            w={"100%"}
            mt={10}
        >
            <Box px={[2, 2, 10, 20]}>
                <Iframe
                    allowFullScreen
                    url={Content?.content?.link}
                    width={'100%'}
                    height={"400px"}
                    display="initial"
                    position="relative"
                    id="stream-player" />
                <Heading py={'4'}>{Content?.content?.name}</Heading>
                <Text p={'2'}>{Content?.content?.carddescription}</Text>
            </Box>

            {/* <Text>
                {progressState?.activetime}{`TRACK ${tracking % 10} `}
            </Text> */}
        </Box>
    );
};

export default VideoPlayerPage;
import gql from "graphql-tag";

export default gql`
  mutation (
    $subject: MongoID
    $student:  MongoID
    $id: String
    $name: String
    $score: Float
    $comment: ScoreHomeworkCommentInput
    $file: String
    ){
    createScore(
      record: {
        subject:  $subject
        student: $student
        homework:{
            contentid: $id
            name: $name
            score: $score
            comment: $comment
            file: $file
        }
      }){
        recordId
    }
  }
`

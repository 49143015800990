import gql from "graphql-tag";

export default gql`
  query ($_id: MongoID) {
    forums(filter: { configforum: $_id}) {
        _id
        user{
            _id
            firstname
            lastname
            avatar
        }
        replydate
        image
        comment{
            blocks
            entityMap
        }
        updatedAt
    }
  }
`;
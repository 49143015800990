import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Text,
    Container,
    Avatar,
    useColorModeValue,
    HStack,
    Image,
    Link,
    Button,
    Tabs,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    IconButton,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    AccordionItem,
    createIcon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client'
import subjectQuery from '../graphql/queries/subject'
import videoOfSubjectQuery from '../graphql/queries/videoofsubjectbyid'
import documentOfSubjectQuery from '../graphql/queries/documentofsubject'
// import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router';
import dayjs from 'dayjs';
import { FaYoutube, FaInstagram, FaFacebook } from "react-icons/fa"
// import AuthContext from '../contexts/AuthContext';
// import Axios from 'axios';
// import { useTokenMeet } from '../contexts/TokenMeetContext';
// import videoofsubject from '../graphql/queries/videoofsubject';
import Iframe from 'react-iframe'
import AuthContext from '../contexts/AuthContext';

require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

// const BlogTags = (props) => {
//     return (
//         <HStack spacing={2} marginTop={props.marginTop}>
//             {props.tags.map((tag) => {
//                 return (
//                     <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
//                         {tag}
//                     </Tag>
//                 );
//             })}
//         </HStack>
//     );
// };

const Instructure = (props) => {
    return (<Fragment>
        <Box
            maxW={'320px'}
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}>
            <Avatar
                size={'xl'}
                src={
                    `${props.avatar}`
                }
                alt={'Avatar Alt'}
                mb={4}
                pos={'relative'}
                _after={{
                    content: '""',
                    w: 4,
                    h: 4,
                    bg: 'green.300',
                    border: '2px solid white',
                    rounded: 'full',
                    pos: 'absolute',
                    bottom: 0,
                    right: 3,
                }}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'}>
                {props?.name}
            </Heading>
            <HStack
                whiteSpace={"nowrap"}
                // maxH={" 50px "}
                align="center"
                overflow={"hidden"}
                textOverflow={"ellipsis"}
            >
                <Link target="_blank" as='a' href={props?.youtube?.link ? props?.youtube?.link : '#'}>
                    <HStack>
                        <IconButton
                            variant="link"
                            colorScheme="red"
                            aria-label="Call Sage"
                            fontSize="20px"
                            icon={<FaYoutube />}
                        />
                        <Text>
                            {props?.youtube?.name ? props?.youtube?.name : 'ยังไม่ได้ระบุ'}
                        </Text>
                    </HStack>
                </Link>
            </HStack>
            <HStack>
                <Link target="_blank" as='a' href={props?.facebook?.link ? props?.facebook?.link : '#'}>
                    <HStack>
                        <IconButton
                            variant="link"
                            colorScheme="blue"
                            aria-label="Call Sage"
                            fontSize="20px"
                            icon={<FaFacebook />}
                        />
                        <Text>
                            {props?.facebook?.name ? props?.facebook?.name : 'ยังไม่ได้ระบุ'}
                        </Text>
                    </HStack>
                </Link>
            </HStack>
            <HStack>
                <Link target="_blank" as='a' href={props?.instagram?.link ? props?.instagram?.link : '#'}>
                    <HStack>
                        <IconButton
                            variant="link"
                            colorScheme="blue"
                            aria-label="Call Sage"
                            fontSize="20px"
                            icon={<FaInstagram />}
                        />
                        <Text>
                            {props?.instagram?.name ? props?.instagram?.name : 'ยังไม่ได้ระบุ'}
                        </Text>
                    </HStack>
                </Link>
            </HStack>
        </Box>
    </Fragment>)

}

export const BlogAuthor = (props) => {
    return (
        <HStack ml={10} marginTop="0" spacing="2" display="flex" alignItems="center">
            <Avatar
                borderRadius="full"
                boxSize="40px"
                src={props.avatar}
                name={`${props.name}`}
            />
            <Text fontWeight="medium">{props.name}</Text>
            <Text>—</Text>
            <Text>{dayjs(props?.date).format('DD MMMM YYYY')}</Text>
        </HStack>
    );
};
// const API_KID = process.env.REACT_APP_API_KID
// const API_MEET = process.env.REACT_APP_API_KEY_JITSI
// const URL = process.env.REACT_APP_GENERATE_TOKEN

const SubjectPage = () => {
    // const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [modalData, setModalData] = useState({})
    const [video, setVideo] = useState([])
    const params = useParams()
    const { user } = useContext(AuthContext)
    const history = useHistory()
    // const { setTokenMeet } = useTokenMeet()
    const { data: subjectData } = useQuery(subjectQuery, {
        variables: {
            _id: "61594d29f14d1e001251d759"
        },
    })
    const { data: videoOfSubjectData } = useQuery(videoOfSubjectQuery, {
        variables: {
            subject: "615966b2f14d1e001251d776"
        },
    })
    const { data: documentOfSubjectData } = useQuery(documentOfSubjectQuery, {
        variables: {
            subject: "615966b2f14d1e001251d776"
        },
    })
    // useEffect(
    //     () => {
    //         if (subjectData?.subject?.contentfor === 'VIP') {
    //             if (user?.member !== subjectData?.subject?.contentfor) {
    //                 if (user?._id === subjectData?.subject?.teacher?._id) {
    //                     return
    //                 } else {
    //                     setTimeout(() => {
    //                         history.push('/courses')
    //                     }, 100)
    //                 }

    //             }
    //         }
    //     },
    //     [history, user],
    // )
    useEffect(
        () => {
            if (videoOfSubjectData) {
                setVideo([videoOfSubjectData?.videoofsubject])
            }
        },
        [videoOfSubjectData,],
    )
    const PlayIcon = createIcon({
        displayName: 'PlayIcon',
        viewBox: '0 0 58 58',
        d:
            'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
    });
    const ModalPlayVideo = () => (
        <Modal id={modalData?.id} onClose={onClose} size={"full"} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{modalData?.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* <div style="position: relative; padding-top: 56.25%;"> */}
                    <Iframe
                        src={modalData?.link}
                        width={'100%'}
                        height={'800px'}

                        // style={{ border: 'none', position: 'absolute', top: 0, height: '100 %', width: '100 %' }}
                        allow={"fullscreen; accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"}
                        allowFullScreen={true}>
                    </Iframe>
                    {/* </div> */}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
    // console.log(subjectData);
    // console.log('user', user, URL,API_KID);
    // console.log(LocalizedFormat);
    // console.log(video);
    return (
        <Fragment>
            <Container maxW={'7xl'} p="12">
                <Heading as="h1">{`${subjectData?.subject?.code}   ${subjectData?.subject?.name}`}</Heading>
                <Box
                    marginTop={{ base: '1', sm: '5' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'row' }}
                    justifyContent="space-between">
                    <Box
                        display="flex"
                        flex="1"
                        marginRight="3"
                        position="relative"
                        alignItems="center">
                        <Box
                            width={{ base: '100%', sm: '85%' }}
                            zIndex="2"
                            marginLeft={{ base: '0', sm: '5%' }}
                            marginTop="5%">
                            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    borderRadius="lg"
                                    src={
                                        subjectData?.subject?.photo ? subjectData?.subject.photo : 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                                    }
                                    alt="some good alt text"
                                    objectFit="contain"
                                />
                            </Link>
                        </Box>
                        <Box zIndex="1" width="100%" position="absolute" height="100%">
                            <Box
                                bgGradient={useColorModeValue(
                                    'radial(orange.600 1px, transparent 1px)',
                                    'radial(orange.300 1px, transparent 1px)'
                                )}
                                backgroundSize="20px 20px"
                                opacity="0.4"
                                height="100%"
                            />
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flex="1"
                        flexDirection="column"
                        justifyContent="center"
                        marginTop={{ base: '3', sm: '0' }}>
                        {/* <BlogTags tags={['Engineering', 'Product']} /> */}
                        <Heading marginTop="1">
                            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                รายละเอียดวิชา
                            </Link>
                        </Heading>
                        <BlogAuthor
                            name={`${subjectData?.subject?.teacher?.firstname} ${subjectData?.subject?.teacher?.lastname}`}
                            date={subjectData?.subject?.createAt}
                            avatar={subjectData?.subject?.teacher?.avatar ? subjectData?.subject?.teacher?.avatar : ''}

                        />
                        <Text
                            as="p"
                            wordBreak='break-word'
                            marginTop="2"
                            color={useColorModeValue('gray.700', 'gray.200')}
                            fontSize="lg">
                            {subjectData?.subject?.description ? subjectData.subject?.description : ''}
                        </Text>
                    </Box>
                </Box>
                <Box
                    marginTop={{ base: '1', sm: '5' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'row' }}
                    justifyContent="space-between"
                >
                    <Tabs size="md" variant="enclosed">
                        <TabList>
                            {/* <Tab>Conference</Tab> */}
                            {videoOfSubjectData?.videoofsubjects?.length <= 0 ? null : <Tab>วีดีโอ</Tab>}
                            {documentOfSubjectData?.documentofsubjects?.length <= 0 ? null : <Tab>เอกสาร</Tab>}
                            <Tab>วิทยากร</Tab>
                        </TabList>
                        <TabPanels>
                            {/* <TabPanel>
                                <p>
                                    <Heading as="h4" size="md">
                                        รายละเอียดการสอน
                                    </Heading>
                                    <HStack ml={10} marginTop="0" spacing="2" display="flex" alignItems="center">

                                        <Text fontWeight="medium">เวลาสอนสด</Text>
                                        <Text>:</Text>
                                        <Text>{dayjs(subjectData?.subject?.createAt).locale('th').format('dddd, MMMM D, YYYY')}</Text>
                                    </HStack>
                                    <HStack>

                                        <Button leftIcon={<MdVideocam />} onClick={handleStartMeeting} colorScheme="green" variant="outline">
                                            เข้าเพื่อสอนสด
                                        </Button>
                                    </HStack>
                                </p>
                            </TabPanel> */}

                            <TabPanel>
                                <Heading as="h4" size="md">
                                    วีดีโอประกอบการสอน
                                </Heading>
                                <Accordion defaultIndex={[0]} allowMultiple>
                                    {
                                        video?.map((row, index) => (
                                            <AccordionItem id={row?._id}>
                                                <AccordionButton>
                                                    <Box flex="1" textAlign="left">
                                                        {`${index + 1}. ${row.name}`}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <Box
                                                        marginTop={{ base: '1', sm: '5' }}
                                                        display="flex"
                                                        flexDirection={{ base: 'column', sm: 'row' }}
                                                        justifyContent="space-between"
                                                    >
                                                        <Box
                                                            position={'relative'}
                                                            height={'178px'}
                                                            // w={'260px'}
                                                            rounded={'2xl'}
                                                            boxShadow={'2xl'}
                                                            // width={'full'}
                                                            overflow={'hidden'}>
                                                            <IconButton
                                                                aria-label={'Play Button'}
                                                                variant={'ghost'}
                                                                _hover={{ bg: 'transparent' }}
                                                                icon={<PlayIcon w={12} h={12} />}
                                                                size={'lg'}
                                                                color={'gray.600'}
                                                                position={'absolute'}
                                                                left={'50%'}
                                                                top={'50%'}
                                                                onClick={() => {
                                                                    setModalData({
                                                                        id: row?._id,
                                                                        name: row?.name,
                                                                        link: row?.link
                                                                    })
                                                                    onOpen()
                                                                }}
                                                                transform={'translateX(-50%) translateY(-50%)'}
                                                            />
                                                            <Image
                                                                alt={'Hero Image'}
                                                                fit={'cover'}
                                                                align={'center'}
                                                                w={'100%'}
                                                                h={'100%'}
                                                                src={
                                                                    row?.thumbnail
                                                                }
                                                            />
                                                        </Box>
                                                        <Box
                                                            // display="flex"
                                                            // flex="1"
                                                            ml="10"
                                                            flexDirection="column"
                                                            // justifyContent="center"
                                                            marginTop={{ base: '3', sm: '0' }}
                                                        >
                                                            <Heading as="h4" size="md">
                                                                {row.name}
                                                            </Heading>
                                                            {/* <Text
                                                                as="p"
                                                                wordBreak='break-word'
                                                                marginTop="2"
                                                                // color={useColorModeValue('gray.700', 'gray.200')}
                                                                fontSize="lg">
                                                                {subjectData?.subject?.teacher?.description ? subjectData?.subject?.teacher?.description : ''}
                                                            </Text> */}
                                                        </Box>
                                                    </Box>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        ))
                                    }
                                </Accordion>
                            </TabPanel>
                            <TabPanel>
                                <Heading as="h4" size="md">
                                    เอกสารประกอบการสอน
                                </Heading>
                                <Accordion defaultIndex={[0]} allowMultiple>
                                    {
                                        documentOfSubjectData?.documentofsubjects?.map((row, index) => (
                                            <AccordionItem id={row?._id}>
                                                <AccordionButton>
                                                    <Box flex="1" textAlign="left">
                                                        {`${index + 1}. ${row.name}`}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <Box
                                                        marginTop={{ base: '1', sm: '5' }}
                                                        display="flex"
                                                        flexDirection={{ base: 'column', sm: 'row' }}
                                                        justifyContent="space-between"
                                                    >
                                                        <Box
                                                            position={'relative'}
                                                            height={'178px'}
                                                            // w={'260px'}
                                                            rounded={'2xl'}
                                                            boxShadow={'2xl'}
                                                            // width={'full'}
                                                            overflow={'hidden'}>
                                                            <IconButton
                                                                aria-label={'Play Button'}
                                                                variant={'ghost'}
                                                                _hover={{ bg: 'transparent' }}
                                                                icon={<PlayIcon w={12} h={12} />}
                                                                size={'lg'}
                                                                color={'gray.600'}
                                                                position={'absolute'}
                                                                left={'50%'}
                                                                top={'50%'}
                                                                onClick={() => {
                                                                    setModalData({
                                                                        id: row?._id,
                                                                        name: row?.name,
                                                                        link: row?.link
                                                                    })
                                                                    onOpen()
                                                                }}
                                                                transform={'translateX(-50%) translateY(-50%)'}
                                                            />
                                                            <Image
                                                                alt={'Hero Image'}
                                                                fit={'cover'}
                                                                align={'center'}
                                                                w={'100%'}
                                                                h={'100%'}
                                                                src={
                                                                    row?.thumbnail
                                                                }
                                                            />
                                                        </Box>
                                                        <Box
                                                            // display="flex"
                                                            // flex="1"
                                                            ml="10"
                                                            flexDirection="column"
                                                            // justifyContent="center"
                                                            marginTop={{ base: '3', sm: '0' }}
                                                        >
                                                            <Heading as="h4" size="md">
                                                                {row.name}
                                                            </Heading>
                                                            {/* <Text
                                                                as="p"
                                                                wordBreak='break-word'
                                                                marginTop="2"
                                                                // color={useColorModeValue('gray.700', 'gray.200')}
                                                                fontSize="lg">
                                                                {subjectData?.subject?.teacher?.description ? subjectData?.subject?.teacher?.description : ''}
                                                            </Text> */}
                                                        </Box>
                                                    </Box>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        ))
                                    }
                                </Accordion>
                            </TabPanel>
                            <TabPanel>
                                <Box
                                    marginTop={{ base: '1', sm: '5' }}
                                    display="flex"
                                    flexDirection={{ base: 'column', sm: 'row' }}
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        <Instructure
                                            avatar={subjectData?.subject?.teacher?.avatar ? subjectData?.subject?.teacher?.avatar : ''}
                                            name={subjectData?.subject?.teacher?.firstname ? `${subjectData?.subject?.teacher?.firstname} ${subjectData?.subject?.teacher?.lastname}` : ''}
                                            facebook={subjectData?.subject?.teacher?.facebook ? subjectData?.subject?.teacher?.facebook : ''}
                                            instagram={subjectData?.subject?.teacher?.instagram ? subjectData?.subject?.teacher?.instagram : ''}
                                            youtube={subjectData?.subject?.teacher?.youtube ? subjectData?.subject?.teacher?.youtube : ''}

                                        // description={subjectData?.subject?.teacher?.description ? subjectData?.subject?.teacher?.description : ''} 


                                        />
                                    </Box>
                                    <Box
                                        display="flex"
                                        flex="1"
                                        ml="10"
                                        flexDirection="column"
                                        // justifyContent="center"
                                        marginTop={{ base: '3', sm: '0' }}
                                    >
                                        <Heading as="h4" size="md">
                                            ข้อมูลวิทยากร
                                        </Heading>
                                        <Text
                                            as="p"
                                            wordBreak='break-word'
                                            marginTop="2"
                                            color={useColorModeValue('gray.700', 'gray.200')}
                                            fontSize="lg">
                                            {subjectData?.subject?.teacher?.description ? subjectData?.subject?.teacher?.description : ''}
                                        </Text>
                                    </Box>

                                </Box>

                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Container>
            <ModalPlayVideo />
        </Fragment>
    );
};

export default SubjectPage;
import {
    GridItem,
    Box,
    Text,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Checkbox,
    VStack,
} from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'




const DialogEnrollSubject = ({ dialogEnrollSubjectToggle, closeDialogEnrollSubject, handleSubmitEnrollSubject, enrollState, setEnrollState }) => {

    const { t } = useTranslation()
    return (
        <Modal size={'lg'} isOpen={dialogEnrollSubjectToggle} onClose={closeDialogEnrollSubject}>
            <ModalOverlay />
            <form onSubmit={handleSubmitEnrollSubject} as={GridItem} colSpan={[6, 3]}>
                <ModalContent>
                    <ModalHeader>
                        {t('subjectPage.enroll')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack w='full' align='start'>
                            <Checkbox onChange={(e) => setEnrollState({ creditstatus: e.target.checked })}>
                                <Text>{t('subjectPage.request_credit')}</Text>
                            </Checkbox>
                            <Checkbox onChange={(e) => setEnrollState({ certificatestatus: e.target.checked })}>
                                <Text>{t('subjectPage.request_certificate')}</Text>
                            </Checkbox>
                            {enrollState?.certificatestatus &&
                                <Box w='full' py={3}>
                                    <Text>{t('subjectPage.name_certificate')}</Text>
                                    <Input placeholder={t('subjectPage.name_placeholder')} onChange={(e) => setEnrollState({ name: e.target.value })} />
                                    <Text color={'red.500'}>{t('subjectPage.text_enroll')}</Text>
                                </Box>
                            }
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={closeDialogEnrollSubject}>
                            {t('Button.close')}
                        </Button>
                        <Button variant='solid' colorScheme="red" type='submit'>
                            {t('Button.confirm')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )


}

export default DialogEnrollSubject
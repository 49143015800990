import React, { Fragment, useState } from "react";
import { Link, useHistory } from 'react-router-dom'
import {
    Stack,
    FormControl,
    Input,
    Button,
    useColorModeValue,
    Heading,
    Text,
    Container,
    Box,
    Center,
    FormLabel,
    useToast,
    FormErrorMessage
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useMultiState } from "../lib/hooks";
import setPasswordMutation from '../graphql/mutations/setPassword'
import { useMutation } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { useParams } from "react-router";
import dayjs from "dayjs";


const ResetPasswordPage = () => {
    const [setPassword] = useMutation(setPasswordMutation)
    const params = useParams()
    const history = useHistory()
    const decoded = jwtDecode(params?.token)
    const toast = useToast()
    const initialPass = {
        password: '',
        confirm_pass: ''
    }
    const [passwordData, setPasswordData] = useMultiState(
        initialPass
    )
    const handleResetPassword = async (e) => {
        e.preventDefault()
        try {

            const { data: passworddata, errors: passwordErr } = await setPassword({
                variables: {
                    _id: params?.Id,
                    password: passwordData?.password,
                },
            })
            if (passworddata) {

                // console.log('finally')
                await toast({
                    position: "top",
                    title: 'เพิ่มข้อมูลเรียบร้อย',
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
                setPasswordData(initialPass)
                history.push('/login')
            }

        } catch (Error) {

            // console.log('in Reset Password submit', Error)
            toast({
                position: "top",
                title: "ERROR",
                description: `${Error?.graphQLErrors[0]?.message}`,
                status: "error",
                duration: 6000,
                isClosable: true,
            })
            // enqueueSnackbar(Error, { variant: 'error' })
        }
    }
    if (!(decoded?.exp > Math.round(new Date().getTime() / 1000))) {
        return (
            <Box
                py={24}
                w={'full'}
            >
                <Center h={'50vh'}>
                    <Stack alignSelf='center'>
                        <Heading alignSelf={'center'} size='xl'>
                            ลิ้งหมดอายุ? หรือ ลิ้งค์ไม่มีอยู่
                        </Heading>
                        <Text alignSelf={'center'} py={5} mr={10}>หากท่านยังไม่ได้รีเซ็ตรหัสผ่าน กรุณาทำการร้องขอการรีเซ็ตรหัสผ่านใหม่อีกครั้ง</Text>
                        <Box boxSize={50}>
                            <Button colorScheme="green" as={Link} to={'/'}>กลับหน้าหลัก</Button>
                        </Box>
                    </Stack>
                </Center>
            </Box>
        )
    }
    // console.log(decoded, 1637833438 > Math.round(new Date().getTime() / 1000));
    return (
        <Fragment>
            <Box
                py={24}
                w={'full'}
            >
                <Center h={'50vh'}>
                    <Container
                        maxW={'lg'}
                        // bg={useColorModeValue('white', 'whiteAlpha.100')}
                        boxShadow={'xl'}
                        rounded={'lg'}
                        p={6}
                        direction={'column'}>
                        <Heading
                            as={'h2'}
                            fontSize={{ base: 'xl', sm: 'xl' }}
                            textAlign={'center'}
                            mb={5}>
                            กรุณากรอกอีเมลของท่านเพื่อทำการรีเซ็ตรหัสผ่าน
                        </Heading>
                        <Stack
                            direction={{ base: 'column', md: 'column' }}
                            as={'form'}
                            spacing={'12px'}
                            onSubmit={handleResetPassword}>
                            <FormControl isRequired>
                                <FormLabel htmlFor={'new-password-nuukeng'}>กรอกรหัสผ่าน</FormLabel>
                                <Input
                                    variant={'solid'}
                                    borderWidth={1}
                                    color={'gray.800'}
                                    _placeholder={{
                                        color: 'gray.400',
                                    }}
                                    // borderColor={useColorModeValue('gray.300', 'gray.700')}
                                    id={'new-password-nuukeng'}
                                    type={'password'}
                                    required
                                    placeholder={'กรอกรหัสที่ท่านต้องการเปลี่ยน'}
                                    aria-label={'Your Email'}
                                    value={passwordData?.password}
                                    onChange={(e) =>
                                        setPasswordData({ password: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl
                                isRequired
                                isDisabled={!passwordData?.password}
                                isInvalid={passwordData?.confirm_pass && !(passwordData?.password === passwordData?.confirm_pass)}
                            >
                                <FormLabel htmlFor={'comfirm-password-nuukeng'}>ยืนยันรหัสผ่าน</FormLabel>
                                <Input
                                    variant={'solid'}
                                    borderWidth={1}
                                    color={'gray.800'}
                                    _placeholder={{
                                        color: 'gray.400',
                                    }}

                                    id={'comfirm-password-nuukeng'}
                                    type={'password'}
                                    required
                                    placeholder={'ยืนยันรหัส'}
                                    value={passwordData?.confirm_pass}
                                    onChange={(e) =>
                                        setPasswordData({ confirm_pass: e.target.value })
                                    }
                                />
                                <FormErrorMessage >รหัสผ่านไม่ตรงกัน</FormErrorMessage>
                            </FormControl>
                            <FormControl alignSelf='center' w={{ base: '100%', md: '40%' }}>
                                <Button
                                    colorScheme={'green'}
                                    w="100%"
                                    type={'submit'}>
                                    รีเซ็ตรหัสผ่าน
                                </Button>
                            </FormControl>
                        </Stack>

                    </Container>
                </Center>
            </Box>
        </Fragment>
    )

}
export default ResetPasswordPage
import gql from 'graphql-tag'

export default gql`
query ($subject: MongoID){
    studentofsubjects(filter:{ subject: $subject},limit: 10000){
      _id
      subject{
        _id
        name
      }
      student{
        _id
        email
        mobile
        firstname
        lastname
        role
        avatar
      }
      certificatestatus
      creditstatus
      time
      name
      status
    }
  }
`

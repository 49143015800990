import { useTranslation } from 'react-i18next'
import {
    GridItem,
    Button,
    Box,
    FormControl,
    FormLabel,
    ModalFooter,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    Text,
    Grid,
    Progress,
    Input,
} from '@chakra-ui/react'
import { Dropzone, FileItem } from '@dropzone-ui/react'


const DialogCreateHomwork = ({ typeSendFile, setHomeWork, homework, contentState, uploadState, isAddopen, onAddclose, user, handleSendhomework, onDeleteFile, updateFiles, files, onDeleteVdo, updateFilesVdo, filesVdo }) => {

    const { t } = useTranslation()
    console.log(homework)

    const handleChangeStateValue = (e) => {
        setHomeWork({ ...homework, [e.target.name]: e.target.value })
    }

    return (
        <Modal
            isOpen={isAddopen}
            onClose={onAddclose}
        >
            <ModalOverlay />
            <ModalContent>
                <Box>
                    <form>
                        <ModalHeader>{contentState?.homeworkconfig.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {contentState?.homeworkconfig?.typefile == 'video' ?
                                <FormControl>
                                    <Dropzone
                                        onChange={updateFilesVdo}
                                        value={filesVdo}
                                        accept="video"
                                        maxFiles="1"
                                        view="grid"
                                        required
                                    //localization={"ES-es"}
                                    //disableScroll
                                    >
                                        {filesVdo.map((file) => (
                                            <FileItem
                                                {...file}
                                                key={file.id}
                                                onDelete={onDeleteVdo}
                                                //localization={"EN-en"}
                                                //resultOnTooltip
                                                preview={true}
                                            />
                                        ))}
                                    </Dropzone>
                                    <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'} display={'flex'}>
                                        <GridItem colSpan={8} flexGrow={5} alignSelf={'center'}>
                                            <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                        </GridItem>
                                        <GridItem colSpan={1} align={'center'} flexGrow={1}>
                                            <Text>{`${uploadState} %`}</Text>
                                        </GridItem>
                                    </Grid>
                                    {homework?.url &&
                                        <Input id='name' name='name' value={homework?.name} onChange={handleChangeStateValue} />
                                    }
                                </FormControl>
                                :
                                <FormControl>
                                    <Dropzone
                                        require
                                        onChange={updateFiles}
                                        value={files}
                                        accept={`.${typeSendFile}`}
                                        maxFiles="1"
                                        view="grid"
                                    >
                                        {files.map((file) => (
                                            <FileItem {...file} key={file.id} onDelete={onDeleteFile} preview={true} />
                                        ))}
                                    </Dropzone>
                                    <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'} display={'flex'}>
                                        <GridItem colSpan={8} flexGrow={8} alignSelf={'center'}>
                                            <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                        </GridItem>
                                        <GridItem colSpan={1} align={'center'} flexGrow={1}>
                                            <Text>{`${uploadState} %`}</Text>
                                        </GridItem>
                                    </Grid>
                                    {homework?.url &&
                                        <Input id='name' name='name' value={homework?.name} onChange={handleChangeStateValue} />
                                    }
                                </FormControl>
                            }
                            <FormControl>
                                <FormLabel mt={3}>
                                    {t('SendHomeworkPage.uploadname')} : {user}
                                </FormLabel>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={3} onClick={onAddclose}>
                                {t('SendHomeworkPage.cancel')}
                            </Button>
                            <Button
                                disabled={!homework?.url}
                                onClick={handleSendhomework}
                                colorScheme="blue"
                                mr={3}
                            >
                                {t('SendHomeworkPage.save')}
                            </Button>
                        </ModalFooter>
                    </form>
                </Box>
            </ModalContent>
        </Modal>
    )

}

export default DialogCreateHomwork

import gql from "graphql-tag"

export default gql`
mutation ($teacher: MongoID $name: String! $description: String $photo: String  , $editor: SubjectPay_descriptionInput
    ){createSubject(record: {
            teacher: $teacher,
            name: $name,
            photo: $photo,
            description:$description,
            pay_description: $editor,
        }) {
        recordId
    }
}
`

import gql from "graphql-tag";

export default gql`
query($student: MongoID,$content: MongoID) {
    homeworks(filter: { student: $student, content:$content}) {
        _id
        status
        file
        filename
        submitdate
        student{
            _id
            firstname
            lastname
        }
    }
}
`
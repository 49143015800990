import gql from 'graphql-tag'

export default gql`
   mutation (
    $_id: MongoID!
    $alive_subject: EnumSubjectAlive_subject
    ){
    updateSubject(_id:$_id,
        record:{
            alive_subject: $alive_subject
        })
    {
        recordId
    }
  }
`
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'

import "./survey.css";
import {
    Box,
    Heading,
    Container,
    Stack,
} from '@chakra-ui/react';

import ProgressMutation from '../graphql/mutations/updateProgress'
import { useMutation, useQuery } from '@apollo/client'
import * as Survey from "survey-react";
import progressbyId from '../graphql/queries/progressbyId'

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import surveybyContentId from '../graphql/queries/surveybyContentId';
import createSurvey from '../graphql/mutations/createSurvey';
import AuthContext from '../contexts/AuthContext';
import renderHTML from 'react-render-html';
import draftToHtml from 'draftjs-to-html';


require('dayjs/locale/th')
require('dayjs/locale/en')

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const SendSurveyPage = () => {
    const param = useParams()
    const { t } = useTranslation()
    const { user } = useContext(AuthContext)
    const [setUpdateProgress] = useMutation(ProgressMutation)

    const [setSurveyMutation] = useMutation(createSurvey)
    const [isCompleted, setisCompleted] = useState(false)
    const [answers, setAnswers] = useState('')
    const [surveyRender, setSurveyRender] = useState(null)


    const { data: Surveys } = useQuery(surveybyContentId, {
        variables: {
            _id: param?.contentId,
        },
    })

    const { data: userprogress } = useQuery(progressbyId, {
        variables: {
            content: param?.contentId,
            student: user?._id
        },
    })

    const onCompleteComponent = async (survey) => {
        setisCompleted(true)
        try {
            const { data: surveyResponse } = await setSurveyMutation({
                variables: {
                    content: param?.contentId,
                    student: user?._id,
                    surveyAnswers: survey?.data,
                    status: 'submit',
                },
            })
            if (surveyResponse) {
                const { data: ProgressData } = await setUpdateProgress({
                    variables: {
                        _id: userprogress?.progress?._id,
                        progress: 100
                    }
                })
                if (ProgressData) {
                    console.log(ProgressData)
                }
            }
            var results = JSON.stringify(survey.data);
            survey.showPreviewBeforeComplete = results
            setAnswers(survey.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }

    useEffect(() => {
        if (!isCompleted && surveyRender === null && Surveys) {
            var survey = <Survey.Survey
                json={{ questions: Surveys?.content?.surveyconfig?.surveyQuestions?.questions }}
                showCompletedPage={true}
                onComplete={onCompleteComponent}
                showPreviewBeforeComplete='noPreview'
                // isEditingSurveyElement={false}
                completedHtml={`<h1>ขอบคุณที่ตอบแบบสอบถาม</h1>`}
            // mode={'display'}
            />
            setSurveyRender(survey)
        }
    }, [answers, isCompleted, surveyRender, Surveys])

    return (
        <Fragment>
            <Box w="100%" px={"20"} minHeight={'90vh'}>
                <Container maxW={'8xl'} mt={2} py={10} as={Stack} spacing={12}>
                    <Box>
                        <Heading size="xl" mt={2}>
                            {Surveys?.content?.surveyconfig?.name}
                        </Heading>
                        {handleConvertHTML(Surveys?.content?.surveyconfig?.description)}
                        {surveyRender}
                    </Box>
                </Container>
            </Box>
        </Fragment>
    );
};

export default SendSurveyPage;
import React, { Fragment, useState, useContext, useEffect } from 'react'
import {
    Tag,
    TagLabel,
    Box,
    Image,
    Container,
    Stack,
    Text,
    Heading,
    Button,
    Table,
    IconButton,
    Tr,
    Th,
    Td,
    Tbody,
    Thead,
    Center,
    Input,
    FormControl,
    FormLabel,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    useDisclosure,
    GridItem,
    useToast,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    HStack,
    Switch,
    Textarea,
    Flex,
    Select,
} from '@chakra-ui/react'
import { Link as RouteLink } from "react-router-dom";

import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import { MdClose } from 'react-icons/md'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { AddIcon } from '@chakra-ui/icons'
import { MdForum } from "react-icons/md"
import { AiOutlineFileDone } from "react-icons/ai"
import { Link } from "react-router-dom"
import { useMutation, useQuery } from '@apollo/client'
import { useMultiState } from '../lib/hooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import AuthContext from '../contexts/AuthContext'

import subjectQuery from '../graphql/queries/subjects'
import teacherQuery from '../graphql/queries/teachers'
import SubjectMutation from '../graphql/mutations/createSubject'
import UpdateSubjectMutation from '../graphql/mutations/updateSubject'
import RemoveSubjectMutation from '../graphql/mutations/removeSubject'
import removeEnrollsMutation from "../graphql/mutations/removeEnrolls"
import SubjectRemoveMutaion from "../graphql/mutations/removeSubject"



import axios from "axios";

const URL = process.env.REACT_APP_UPLOAD_IMAGE
const SubjectController = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const toast = useToast()

    const { data: subjectData, refetch, loading } = useQuery(subjectQuery)
    const { data: teacherData, } = useQuery(teacherQuery)


    const [setSubjectMutation] = useMutation(SubjectMutation)
    const [setUpdateSubjectMutation] = useMutation(UpdateSubjectMutation)
    const [setRemoveSubjectMutation] = useMutation(RemoveSubjectMutation)
    const [setRemoveEnrollsSubjectMutation] = useMutation(removeEnrollsMutation)
    const [setRemoveSubject] = useMutation(SubjectRemoveMutaion)

    const [subjectState, setSubjectState] = useState([])
    const [teacherState, setTeacherState] = useState([])


    useEffect(() => {
        if (subjectData) {
            setSubjectState(subjectData?.subjects ? subjectData?.subjects : [])
        }
        if (teacherState) {
            setTeacherState(teacherData?.teacherUsers ? teacherData?.teacherUsers : [])
        }

        if (user?.role !== 'ADMIN') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
        if (loading) {
            refetch()
        }

    }, [subjectData, teacherData, history, user, refetch]);

    const [newphotoData, setnewphotoData] = useMultiState({
        data: null,
        base64: "",
    })

    const selectPhotoFile = (e) => {
        new Promise((resolve, reject) => {
            e.preventDefault()
            const file = e.currentTarget.files[0]
            setnewphotoData({
                data: file,
            })
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
                setnewphotoData({
                    base64: reader.result,
                })
            }
            reader.onerror = (error) => reject(error)
        })
    }

    const { isOpen: preview, onOpen: openPreview, onClose: closePreview } = useDisclosure()
    const { isOpen: modalAddSubject, onOpen: modalAddSubjectOpen, onClose: modalAddSubjectClose, } = useDisclosure()
    const { isOpen: modalUpdateSubject, onOpen: modalUpdateSubjectOpen, onClose: modalUpdateSubjectClose, } = useDisclosure()
    const { isOpen: modalRemoveSubject, onOpen: modalRemoveSubjectOpen, onClose: modalRemoveSubjectClose, } = useDisclosure()

    const initialSubject = {
        _id: '',
        name: '',
        description: '',
        teacher: '',
        status: '',
    }

    const [subjectAddData, setSubjectAddData] = useMultiState(initialSubject)
    const [updateSubjectData, setUpdateSubjectData] = useMultiState(initialSubject)
    const [removeSubjectData, setRemoveSubjectData] = useMultiState(initialSubject)

    const [photoData, setPhoto] = useState("")
    const handleAddSubject = async (e) => {
        e.preventDefault()
        if (!subjectAddData?.name) {
            await toast({
                position: "top",
                title: "Warning",
                description: `${t('SubjectControllerPage.toastAddWarningSubject')}`,
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else if (!subjectAddData?.teacher || subjectAddData?.teacher == " ") {
            await toast({
                position: "top",
                title: "Warning",
                description: `${t('SubjectControllerPage.toastAddWarningStaff')}`,
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else {
            try {
                const response = await axios.post(URL, { photo: newphotoData?.base64 })
                const { data: subjectData, errors: subjectErr } = await setSubjectMutation({
                    variables: {
                        name: subjectAddData?.name,
                        description: subjectAddData?.description,
                        photo: response ? response?.data?.url : "",
                        teacher: subjectAddData?.teacher
                    },
                })
                if (subjectData) {
                    await toast({
                        position: "top",
                        title: 'Success',
                        description: `${t('SubjectControllerPage.toastAddSuccessCourse')}`,
                        status: 'success',
                        duration: 6000,
                        isClosable: true,
                    })
                    setSubjectAddData(initialSubject)
                    modalAddSubjectClose(true)
                    setnewphotoData({
                        data: null,
                        base64: "",
                    })
                    refetch()
                }
            } catch (subjectErr) {
                toast({
                    position: "top",
                    title: "ERROR",
                    description: `${subjectErr}`,
                    status: "error",
                    duration: 6000,
                    isClosable: true,
                })
            }
        }
    }

    const handleUpdateSubject = async (e) => {
        e.preventDefault()
        if (!updateSubjectData?.name) {
            await toast({
                position: "top",
                title: "Warning",
                description: `${t('SubjectControllerPage.toastAddWarningSubject')}`,
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else if (!updateSubjectData?.teacher || updateSubjectData?.teacher == " ") {
            await toast({
                position: "top",
                title: "Warning",
                description: `${t('SubjectControllerPage.toastAddWarningStaff')}`,
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else {
            try {
                const response = await axios.post(URL, { photo: newphotoData?.base64 })
                const { data: UpdateSubject, errors: subjectErr } = await setUpdateSubjectMutation({
                    variables: {
                        _id: updateSubjectData._id,
                        name: updateSubjectData?.name,
                        description: updateSubjectData?.description,
                        photo: response ? response?.data?.url : "",
                        teacher: updateSubjectData?.teacher,
                        status: updateSubjectData?.status ? "public" : "unpublic",
                    },
                })
                if (UpdateSubject) {
                    await toast({
                        position: "top",
                        title: 'Success',
                        description: `${t('SubjectControllerPage.toastEditSuccessCourse')}`,
                        status: 'success',
                        duration: 6000,
                        isClosable: true,
                    })
                    setUpdateSubjectData(initialSubject)
                    modalUpdateSubjectClose(true)
                    setnewphotoData({
                        data: null,
                        base64: "",
                    })
                    refetch()
                }
            } catch (subjectErr) {
                toast({
                    position: "top",
                    title: "ERROR",
                    description: `${subjectErr}`,
                    status: "error",
                    duration: 6000,
                    isClosable: true,
                })
            }
        }
    }

    //Alert dialog

    const handleRemoveSubject = async (e) => {
        e.preventDefault()
        try {
            const { data: responseRemoveSubject } = await setRemoveSubject({
                variables: {
                    _id: removeSubjectData?._id,
                    alive_subject: "SUBJECT_DELETE"
                }
            })
            if (responseRemoveSubject) {
                await toast({
                    position: "top",
                    title: "Success",
                    description: `${t('managesubjectPage.toastRemoveSuccessCourse')}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
            }
            refetch({
                user: user?._id,
            })
            setRemoveSubjectData(initialSubject)
            modalRemoveSubjectClose()
            refetch()

        } catch (error) {
            // alert(`${Err?.graphQLErrors[0]?.message}`)
            await toast({
                position: "top",
                title: "ERROR",
                description: `${error}`,
                status: "error",
                duration: 6000,
                isClosable: true,
            })
        }
    }

    // const handleRemoveSubject = async (e) => {
    //     e.preventDefault()
    //     try {
    //         const { data: RemoveEnrolls } = await setRemoveEnrollsSubjectMutation({
    //             variables: {
    //                 subject: removeSubjectData?._id,
    //             }
    //         })

    //         const { data: RemoveSubject } = await setRemoveSubjectMutation({
    //             variables: {
    //                 _id: removeSubjectData?._id,
    //             }
    //         })
    //         if (RemoveSubject && RemoveEnrolls) {
    //             await toast({
    //                 position: "top",
    //                 title: "Success",
    //                 description: `${t('SubjectControllerPage.toastRemoveSuccessCourse')}`,
    //                 status: "success",
    //                 duration: 3000,
    //                 isClosable: true,
    //             })
    //             refetch({
    //                 user: user?._id,
    //             })
    //             setRemoveSubjectData(initialSubject)
    //             modalRemoveSubjectClose()
    //             refetch()
    //         }

    //     } catch (subjectErr) {

    //     }
    // }

    // console.log("subjectData?.subjects", subjectData?.subjects)

    return (
        <Fragment>

            <Modal isOpen={modalAddSubject} onClose={modalAddSubjectClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('SubjectControllerPage.addSubjectButton')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleAddSubject}>
                        <ModalBody>
                            <FormControl id="">
                                <FormLabel> {t('SubjectControllerPage.subject')} </FormLabel>
                                <Input
                                    value={subjectAddData?.name}
                                    onChange={(e) => setSubjectAddData({ name: e.target.value })}
                                />
                            </FormControl>
                            <FormControl id="">
                                <FormLabel>{t('SubjectControllerPage.description')}</FormLabel>
                                <Textarea
                                    value={subjectAddData?.description}
                                    onChange={(e) => setSubjectAddData({ description: e.target.value })}
                                />
                            </FormControl>
                            <FormControl id="">
                                <FormLabel>{t('SubjectControllerPage.teacher')}</FormLabel>
                                <Select
                                    value={subjectAddData?.teacher}
                                    onChange={(e) => setSubjectAddData({ teacher: e.target.value })}
                                >
                                    <option value=' '>{t('SubjectControllerPage.choosestaff')}</option>
                                    {teacherState?.map((user, i) => (<option key={i} value={user?._id}>{user?.username}</option>))}
                                </Select>
                            </FormControl>
                            <HStack>
                                <Button size="sm" as="label" mt={5}>
                                    {t('SubjectControllerPage.addphotoButton')}
                                    <input
                                        onChange={selectPhotoFile}
                                        accept="image/*"
                                        id="photo-project-file"
                                        type="file"
                                        style={{ display: "none" }}
                                    />
                                </Button>
                                <Text>{newphotoData?.data?.name}</Text>
                            </HStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={(e) => {
                                e.preventDefault()
                                modalAddSubjectClose()
                                setnewphotoData({
                                    data: null,
                                    base64: "",
                                })
                            }} mr={5}>
                                {t('SubjectControllerPage.closeButton')}
                            </Button>
                            <Button bg={'primary.700'} color={'white'} _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                                type="submit"
                            >
                                {t('SubjectControllerPage.saveButton')}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            <Modal isOpen={modalUpdateSubject} onClose={modalUpdateSubjectClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('SubjectControllerPage.editButton')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleUpdateSubject}>
                        <ModalBody>
                            <FormControl id="">
                                <FormLabel> {t('SubjectControllerPage.subject')} </FormLabel>
                                <Input
                                    value={updateSubjectData?.name}
                                    onChange={(e) => setUpdateSubjectData({ name: e.target.value })}
                                />
                            </FormControl>
                            <FormControl id="">
                                <FormLabel>{t('SubjectControllerPage.description')}</FormLabel>
                                <Textarea
                                    value={updateSubjectData?.description}
                                    onChange={(e) => setUpdateSubjectData({ description: e.target.value })}
                                />
                            </FormControl>
                            <FormControl id="">
                                <FormLabel>{t('SubjectControllerPage.teacher')}</FormLabel>
                                <Select
                                    value={updateSubjectData?.teacher}
                                    onChange={(e) => setUpdateSubjectData({ teacher: e.target.value })}
                                >
                                    <option value=' '>{t('SubjectControllerPage.choosestaff')}</option>
                                    {teacherState?.map((user, i) => (<option key={i} value={user?._id}>{user?.username}</option>))}
                                </Select>
                            </FormControl>
                            <HStack>
                                <Button size="sm" as="label" mt={5}>
                                    {t('SubjectControllerPage.addphotoButton')}
                                    <input
                                        onChange={selectPhotoFile}
                                        accept="image/*"
                                        id="photo-project-file"
                                        type="file"
                                        style={{ display: "none" }}
                                    />
                                </Button>
                                <Text>{newphotoData?.data?.name}</Text>
                            </HStack>
                            <FormControl>
                                <FormLabel>{t('managecontentPage.status')}</FormLabel>
                                <Switch size='lg' mr={'2'} colorScheme='green' isChecked={updateSubjectData?.status} onChange={(e) => setUpdateSubjectData({ status: e.target.checked })} />
                                {updateSubjectData?.status ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={(e) => {
                                e.preventDefault()
                                modalUpdateSubjectClose()
                                setnewphotoData({
                                    data: null,
                                    base64: "",
                                })
                            }} mr={5}>
                                {t('SubjectControllerPage.closeButton')}
                            </Button>
                            <Button bg={'primary.700'} color={'white'} _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                                type="submit"
                            >
                                {t('SubjectControllerPage.saveButton')}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            <Modal MaxW={"100%"} isOpen={preview} onClose={closePreview}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('SubjectControllerPage.examplePhoto')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center>
                            <Image
                                boxSize="500px"
                                objectFit="cover"
                                src={photoData}
                                alt="Image"
                            />
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <AlertDialog isOpen={modalRemoveSubject} onClose={modalRemoveSubjectClose} >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <form onSubmit={handleRemoveSubject}>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {t('SubjectControllerPage.deleteSubject')}
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                {t('SubjectControllerPage.deleteCourse')}
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button onClick={modalRemoveSubjectClose}>
                                    {t('SubjectControllerPage.closeButton')}
                                </Button>
                                <Button bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }} type='submit' ml={3}>
                                    {t('SubjectControllerPage.removeButton')}
                                </Button>
                            </AlertDialogFooter>
                        </form>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Box mt={20} w="100%" style={{ zIndex: 2000 }} minHeight={'90vh'}>
                <Container maxW={'7xl'} py={5} as={Stack} spacing={12}>
                    <Stack spacing={0} align={'center'}>
                        <Heading variant="h4">{t('SubjectControllerPage.title')}</Heading>
                        <Text>{t('SubjectControllerPage.subtitle')}</Text>
                    </Stack>
                    <Stack
                        direction={["column", "", "row"]}
                        justifyContent='space-between'
                    >
                        <Stack align={{ base: 'center', md: 'flex-start' }}>
                            <Button
                                bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}
                                leftIcon={<AddIcon />}
                                size='lg'
                                mb={3}
                                onClick={() => modalAddSubjectOpen()}
                            >
                                {t('SubjectControllerPage.addSubjectButton')}
                            </Button>
                        </Stack>

                        <Stack align={{ base: 'center', md: "flex-end" }}>
                            <Flex
                                boxSize="full"
                                w={['90%', '', '400px']}
                                pos="relative"
                                alignItems='center'
                                justifyContent="center"
                            >
                                <AutoComplete
                                    onChange={(v, i, I) => {
                                        // console.log(v, i, I);
                                    }}
                                    onSelectOption={(e) => { setSubjectState(subjectState?.filter((row) => (e?.optionValue === row?._id))) }}
                                    rollNavigation>
                                    <AutoCompleteInput size='lg' variant="filled" placeholder="Search..." autoFocus />
                                    <AutoCompleteList>
                                        {subjectData?.subjects.map((option, oid) => (
                                            <AutoCompleteItem
                                                key={`option-${oid}`}
                                                label={` ${option?.name} ${option?.teacher?.username}  `}
                                                value={`${option?._id}`}
                                                textTransform="capitalize"
                                            >
                                                {` ${option?.name} ${option?.teacher?.username}`}
                                            </AutoCompleteItem>
                                        ))}
                                    </AutoCompleteList>
                                </AutoComplete>
                                <IconButton
                                    variant="ghost"
                                    colorScheme='blue'
                                    pos='absolute'
                                    right={0}
                                    onClick={() => { setSubjectState(subjectData?.subjects) }}
                                    aria-label="Call Sage"
                                    fontSize="15px"
                                    icon={<MdClose />}
                                />
                            </Flex>
                        </Stack>
                    </Stack>

                    <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} borderWidth={'1px'} >
                        <Table variant="striped" backgroundColor="white">
                            <Thead height="50px">
                                <Tr>
                                    <Th textAlign="center">{t('SubjectControllerPage.no')}</Th>
                                    <Th textAlign="center">{t('SubjectControllerPage.photo')}</Th>
                                    <Th textAlign="center">{t('SubjectControllerPage.subjectname')} </Th>
                                    <Th textAlign="center">{t('SubjectControllerPage.status')}</Th>
                                    <Th textAlign="center">{t('SubjectControllerPage.teacher')}</Th>
                                    <Th textAlign="center">alive</Th>
                                    <Th textAlign="center">{t('SubjectControllerPage.manage')}</Th>
                                </Tr>
                            </Thead>
                            {subjectState?.map((subject, i) => (
                                <Tbody bg='gray.400'>
                                    <Tr key={subject?._id}>
                                        <Td textAlign="center" w="5vh">{i + 1}</Td>
                                        <Td minWidth="15vh" textAlign="center">
                                            <Tooltip label={t('managesubjectPage.photo')} fontSize="md">
                                                <Button
                                                    onClick={(e) => {
                                                        openPreview()
                                                        setPhoto(subject?.photo)
                                                    }}
                                                >
                                                    <Image
                                                        borderRadius={'5px'}
                                                        boxSize="40px"
                                                        objectFit="cover"
                                                        src={subject?.photo ? subject?.photo : 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </Td>
                                        <Td textAlign="center" maxWidth={'20vh'}>
                                            <Link as={RouteLink} to={`/subject/${subject?._id}`}>
                                                {subject?.name}
                                            </Link>
                                        </Td>

                                        <Td maxW={'30vh'} textAlign="center">
                                            <Box w='100%' color='white'>
                                                <Center>
                                                    {subject?.status == "public" ?
                                                        <Tag
                                                            borderRadius='full'
                                                            boxShadow={'lg'}
                                                            variant='solid'
                                                            colorScheme='green'
                                                            w={"full"}
                                                            py={1}
                                                            justifyContent={"center"}
                                                        >
                                                            <TagLabel>{t("managecontentPage.public")}</TagLabel>
                                                        </Tag>
                                                        :
                                                        <Tag
                                                            borderRadius='full'
                                                            boxShadow={'lg'}
                                                            variant='solid'
                                                            w={"full"}
                                                            py={1}
                                                            justifyContent={"center"}
                                                        >
                                                            <TagLabel>{t("managecontentPage.nonepublic")}</TagLabel>
                                                        </Tag>}
                                                </Center>
                                            </Box>
                                        </Td>
                                        <Td textAlign="center" maxWidth={'20vh'}>
                                            <Link as={RouteLink} to={`/showprofile/${subject?.teacher?._id}`}>
                                                {`${subject?.teacher?.firstname} ${subject?.teacher?.lastname}`}
                                            </Link>
                                        </Td>


                                        <Td maxW={'30vh'} textAlign="center">
                                            <Box w='100%' color='white'>
                                                <Center>
                                                    {subject?.alive_subject == "SUBJECT_ALIVE" &&
                                                        <Tag
                                                            borderRadius='full'
                                                            boxShadow={'lg'}
                                                            variant='solid'
                                                            colorScheme='green'
                                                            w={"full"}
                                                            py={1}
                                                            justifyContent={"center"}
                                                        >
                                                            <TagLabel>{'SUBJECT_ALIVE'}</TagLabel>
                                                        </Tag>
                                                    }
                                                    {subject?.alive_subject == "SUBJECT_DELETE" &&
                                                        <Tag
                                                            borderRadius='full'
                                                            boxShadow={'lg'}
                                                            variant='solid'
                                                            colorScheme='red'
                                                            w={"full"}
                                                            py={1}
                                                            justifyContent={"center"}
                                                        >
                                                            <TagLabel>{'SUBJECT_DELETE'}</TagLabel>
                                                        </Tag>
                                                    }
                                                </Center>
                                            </Box>
                                        </Td>
                                        <Td maxW={'40vh'} textAlign="center">
                                            <Flex justify="end" >

                                                <Tooltip label={t('SubjectControllerPage.addcontentButton')} fontSize="md">
                                                    <IconButton boxShadow={'lg'} colorScheme='blue' icon={<AddIcon />} mr={2} as={Link} to={`managecontent/${subject?._id}`}
                                                        _hover={{
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: 'lg',
                                                        }} />
                                                </Tooltip>

                                                <Tooltip label={t('SubjectControllerPage.editSubject')} fontsize="md">
                                                    <IconButton boxShadow={'lg'} colorScheme='blue' icon={<FaPencilAlt />} mr={2}
                                                        _hover={{
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: 'lg',
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            modalUpdateSubjectOpen()
                                                            setUpdateSubjectData({
                                                                _id: subject?._id, name: subject?.name, description: subject?.description, photo: subject?.photo, status: subject?.status == "public" ? true : false, pay_certificate: subject?.pay_certificate, course_certificate: subject?.course_certificate,
                                                            })
                                                            setnewphotoData({
                                                                data: null,
                                                                base64: "",
                                                            })
                                                        }}
                                                    />
                                                </Tooltip>
                                                {console.log("SSSSSSSSS", subject)}

                                                <Tooltip label={t('SubjectControllerPage.enroll')} fontsize="md">
                                                    <IconButton boxShadow={'lg'} colorScheme='blue' icon={<AiOutlineFileDone />} mr={2} as={Link} to={`students/${subject?._id}`}
                                                        _hover={{
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: 'lg',
                                                        }}
                                                    />
                                                </Tooltip>

                                                <Tooltip label={t('SubjectControllerPage.forum')} fontsize="md">
                                                    <IconButton boxShadow={'lg'} colorScheme='blue' icon={<MdForum />} mr={2} as={Link} to={`manageforum/${subject?._id}`}
                                                        _hover={{
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: 'lg',
                                                        }}
                                                    />
                                                </Tooltip>
                                                <Tooltip label={t('SubjectControllerPage.removeButton')} fontSize="md">
                                                    <IconButton boxShadow={'lg'} colorScheme='red' icon={<FaTrashAlt />} mr={2}
                                                        _hover={{
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: 'lg',
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            modalRemoveSubjectOpen()
                                                            setRemoveSubjectData(subject)
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            ))}

                        </Table>
                    </Box>
                </Container>
            </Box>
        </Fragment >
    )
}

export default SubjectController

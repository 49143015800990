import gql from "graphql-tag";

export default gql`
query (
    $student: MongoID
    $content: MongoID
    ) {
    progress(filter: { student: $student, content: $content}) {
        _id
        progress 
        content {
            _id
            name
        }
        path   
        activetime
    }
}
`;
import {
    Box,
    Text,
    Heading,
    Button,
    Modal,
    HStack,
    VStack,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalCloseButton,
    InputGroup,
    InputLeftAddon,
    useColorMode,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { OrderGroup, OrderItem, arrayMove } from 'react-draggable-order'
import { HamburgerIcon } from '@chakra-ui/icons'



const DialogSortingContent = ({ list, isContentSorting, onSortingClose, contentState, sortingState, setRefetchList, setSortingState }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <Modal isOpen={isContentSorting} onClose={onSortingClose} size={'5xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('managecontentPage.sortcontent')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {contentState?.length != 0 &&
                        <Box borderWidth='2px' borderRadius='lg' overflow='hidden' p={2} >
                            <OrderGroup className={'group'} mode={'between'} >
                                {sortingState?.map((content, i) => (
                                    list?.map((data) => {
                                        if (data?._id == content) {
                                            return (
                                                <>
                                                    <OrderItem key={i}
                                                        index={i}
                                                        onMove={(to) => {
                                                            setRefetchList(true)
                                                            setSortingState(arrayMove(sortingState, i, to))
                                                        }}
                                                        wrapperClassName={'wrapper'}
                                                        wrapperStyle={{
                                                            width: 'revert', left: '0px', '@media screen and (maxWidth: 600px)': {
                                                                left: '40px'
                                                            }
                                                        }}
                                                        inactiveStyle={{ background: colorMode === 'light' ? 'gray.500' : '#2f3748' }}
                                                        wrapperHoverClassName={'hover'}
                                                        className={'item'}
                                                        grabbingClassName={'grabbing'}
                                                        style={{
                                                            width: 'auto', height: "80px", borderWidth: '1px', overflowX: 'auto'
                                                        }}
                                                    >
                                                        <OrderItem.Handle className={'handle'}>
                                                            <VStack>
                                                                <HamburgerIcon color={colorMode === 'light' ? 'black' : 'white'} />
                                                            </VStack>
                                                        </OrderItem.Handle>

                                                        <HStack p={2} justifyContent={'space-between'} spacing='10px' w='full' direction={'row'} overflowX={'auto'}>
                                                            <InputGroup colorScheme={'twitter'} size={['xs', 'sm', 'lg']} boxShadow={'md'} borderRadius={'md'} borderWidth={'1px'} maxWidth={'800px'}>
                                                                <InputLeftAddon sx={{ fontSize: ['xs', 'sm', 'md'] }} borderRadius={'md'} children={t('managecontentPage.name')} height="3vh" p={5} />
                                                                <Text p={'2'}
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        maxWidth: '400px'
                                                                    }}>{data?.name}</Text>
                                                            </InputGroup>
                                                            <InputGroup colorScheme={'twitter'} size={['xs', 'sm', 'lg']} boxShadow={'md'} borderRadius={'md'} borderWidth={'1px'} maxWidth={'200px'}>
                                                                <InputLeftAddon sx={{ fontSize: ['xs', 'sm', 'md'] }} borderRadius={'md'} children={t('managecontentPage.type')} height="3vh" p={5} />
                                                                <Text p={'2'}>{data?.type}</Text>
                                                            </InputGroup>
                                                        </HStack>
                                                    </OrderItem>
                                                </>)
                                        }
                                    })
                                ))}
                            </OrderGroup>
                        </Box>
                    }
                    {contentState?.length == 0 &&
                        <Box align="center" borderRadius={'lg'} w={'full'} py={'20'} color={'gray.500'} bg={'gray.200'}><Heading>{t('managecontentPage.warningcontentempty')}</Heading></Box>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onSortingClose}>
                        {t('Button.close')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}

export default DialogSortingContent
import gql from "graphql-tag";

export default gql`
query {
    progresss(sort:_ID_DESC){
        _id
        progress 
        content {
            _id
            name
        }
        student{
            _id
            firstname
            lastname
            role
        }
        path   
    }
}
`;
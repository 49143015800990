import gql from 'graphql-tag'

export default gql`
  mutation (
    $_id: MongoID!
    $contentid: String
    $name: String
    $score: Float
    $comment: UpdateByIdScoreHomeworkCommentInput
    $file: String

    ){
    updateScore(_id:$_id,
      record:{
        homework:{
            contentid : $contentid
            name : $name
            score : $score
            comment : $comment
            file : $file
        }
      })
      {
        recordId
      }
  }
`
import gql from 'graphql-tag'

export default gql`
query($student: MongoID
      $id: String){
    scores(filter: {student: $student,  quiz:[{contentid:$id}]}){
        _id
        student{
            _id
            firstname
          }
        quiz{
            _id
            contentid
            name
            score
            answer    
          }
    }
  }
`
import React, { useState, useEffect } from "react";
import {
    Box,
    Heading,
    Text,
    useColorModeValue,
    Button,
    SimpleGrid,
    GridItem,
    Flex,
    Image,
    VStack,
} from '@chakra-ui/react';
import { Link as Routelink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

function ProfileList({ subjectState, curPage, itemLimit }) {
    const { t } = useTranslation()
    const bg = useColorModeValue('white', 'gray.900')
    const [curItems, setCurItems] = useState([]);

    useEffect(() => {
        const offset = curPage * itemLimit;
        const getList = (curPage, itemLimit) => {
            setCurItems(subjectState.slice(offset, offset + itemLimit));
        };

        getList(curPage, itemLimit);
    }, [curPage, itemLimit, subjectState]);
    // console.log(teacherState, curPage, itemLimit );


    return (
        <SimpleGrid
            columns={{
                base: 1,
                sm: 2,
                md: 3,
                lg: 4,
            }}
            spacingX={{
                base: 5,
                lg: 10,
            }}
            w={'full'}
        >
            {curItems?.map((row) => (
                <GridItem key={row?._id} w='100%' sm={'12'} >
                    <Flex
                        alignContent={'center'}
                        borderWidth={'1px'}
                        borderRadius={'lg'}
                        direction={['row', 'row', 'row', 'column']}
                        h={'full'}
                        bg={bg}
                        p={'3'}
                        boxShadow={'2xl'}
                        rounded={'lg'}
                        textAlign={'center'}
                    >
                        <Image
                            flex={'1'}
                            boxSize="250px"
                            borderWidth={'1px'}
                            borderRadius={'lg'}
                            src={row.photo ? row.photo : '../images/ppi.png'}
                            objectFit="cover"
                        />
                        <VStack>
                            <Box>
                                <Heading fontSize={'15px'} fontFamily={'body'} >
                                    {row?.firstname}  {row?.lastname}
                                </Heading>
                            </Box><Box>
                                <Text fontWeight={600} color={'gray.500'} mb={4} >
                                    {row?.email}
                                </Text>

                            </Box>
                            <Box>
                                <Button
                                    w={'auto'}
                                    mt={2}
                                    bg={'primary.700'}
                                    color={'white'}
                                    rounded={'md'}
                                    _hover={{
                                        transform: 'translateY(-2px)',
                                        boxShadow: 'lg',
                                    }}
                                    as={Routelink}
                                    to={`/subject/${row._id}`}>
                                    {t('showprofilePage.courseButton')}
                                </Button>
                            </Box>
                        </VStack>
                    </Flex>
                </GridItem>
            ))
            }
        </SimpleGrid>
    );
}
export default ProfileList;
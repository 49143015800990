
import {
    GridItem,
    Button,
    Center,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'



const DialogUnEnrollSubject = ({ handleSubmitUnEnrollSubject, dialogUnEnrollSubjectToggle, closeDialogUnEnrollSubject }) => {

    const { t } = useTranslation()

    return (
        <AlertDialog isOpen={dialogUnEnrollSubjectToggle} onClose={closeDialogUnEnrollSubject}>
            <AlertDialogOverlay>
                <form onSubmit={handleSubmitUnEnrollSubject}>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t('subjectPage.cancelheader')}
                        </AlertDialogHeader>
                        <AlertDialogBody><Center>{t('subjectPage.removedetail')}</Center></AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={closeDialogUnEnrollSubject}>{t('Button.cancel')}</Button>
                            <Button colorScheme="red" type="submit" ml={3}>
                                {t('Button.confirm')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </form>
            </AlertDialogOverlay>
        </AlertDialog>

    )
}

export default DialogUnEnrollSubject
import gql from 'graphql-tag'

export default gql`
	mutation (
		$_id: MongoID!
		$name: String
		$type: EnumContentType
		$surveyQuestions: [JSON]
		$surveydescription: UpdateByIdContentSurveyconfigDescriptionInput
		$statusActive: Boolean
		$startdate: Date
		$duedate: Date
		$expiredate: Date
	) {
		updateContent(
			_id: $_id
			record: {
				name: $name
				type: $type
				statusActive: $statusActive
				expiredate: $expiredate
				surveyconfig: {
					description:  $surveydescription
					startdate: $startdate
					duedate: $duedate
					surveyQuestions: {
						questions: $surveyQuestions
					}
				}
			}
		) {
			recordId
		}
	}
`



import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom"

import {
    Box,
    Button,
    Heading,
    Text,
    useColorMode,
    Link,
    VStack,
    Flex,
    Image,
} from '@chakra-ui/react'

const MenuDashboard = ({ setStudent, openStudentListDialog, SubjectData, subjectId, }) => {

    const { t } = useTranslation()
    const history = useHistory()
    const { colorMode } = useColorMode()

    return (
        <Box
            p={2}
            mt={3}
            w={['100%', '100%', '20%', '25%']}
            bg={colorMode === 'light' ? 'gray.200' : 'brand.800'}
        >

            <VStack w='full' mt={'2'}>
                <Box
                    w={"100%"}
                    p={'2'}
                    boxShadow={'lg'}
                    borderWidth={'1px'}
                    borderRadius={'lg'}
                    bg={colorMode === 'light' ? 'gray.200' : 'brand.700'}
                >
                    <Flex direction={'row'}>
                        <Image
                            boxSize='60px'
                            boxShadow={'xl'}
                            objectFit='cover'
                            borderRadius={'lg'}
                            src={SubjectData?.subject?.photo ? SubjectData?.subject?.photo : 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'} />

                        <Link href={`/subject/${SubjectData?.subject?._id}`} isExternal >
                            <Heading as='h4' size='md' p={5}>{SubjectData?.subject?.name}</Heading>
                        </Link>
                    </Flex>
                </Box>

                <Button
                    w='100%'
                    variant='solid'
                    size='lg'
                    boxShadow={'lg'}
                    borderWidth={'2px'}
                    bg={colorMode === 'light' ? 'gray.200' : 'brand.700'}
                    onClick={(e) => {
                        e.preventDefault()
                        setStudent('')
                    }}>
                    <Text fontSize='md' color={colorMode === 'light' ? 'primary.900' : 'white'} p={2}>{t('ManageEnrollSubjectPage.dashboard')}</Text>
                </Button>

                <Button
                    w='100%'
                    variant='solid'
                    size='lg'
                    boxShadow={'lg'}
                    borderWidth={'2px'}
                    bg={colorMode === 'light' ? 'gray.200' : 'brand.700'}
                    onClick={(e) => {
                        e.preventDefault()
                        history.push(`/managecertificate/${subjectId}`)
                    }}>
                    <Text fontSize='md' color={colorMode === 'light' ? 'primary.900' : 'white'} p={2}>{t('ManageEnrollSubjectPage.manage_certificate')}</Text>
                </Button>

                <Button
                    w='100%'
                    variant='solid'
                    size='lg'
                    boxShadow={'lg'}
                    borderWidth={'2px'}
                    bg={colorMode === 'light' ? 'gray.200' : 'brand.700'}
                    onClick={(e) => {
                        e.preventDefault()
                        openStudentListDialog()
                    }}>
                    <Text fontSize='md' color={colorMode === 'light' ? 'primary.900' : 'white'} p={2}>{t('ManageEnrollSubjectPage.student_list')}</Text>
                </Button>
            </VStack>

        </Box>
    )
}

export default MenuDashboard

import { Fragment } from "react";
import {
    Stack,
    Heading,
    Image,
    HStack,
    Text,
    useColorModeValue,
    Button,
    Box,
} from "@chakra-ui/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Grid, TextField } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { DesktopDatePicker, TimePicker } from "@mui/lab";
import THLocale from 'dayjs/locale/th';



const theme = createTheme({
    palette: {
        primary: {
            main: "#0CA25F",
        },
        secondary: deepOrange,
    },
});
export const InsertSlip = ({ fileSlip, setFileSlip, user, dateSlip, setDateSlip }) => {

    const selectFileSlip = (e) => new Promise((resolve, reject) => {
        e.preventDefault()
        const file = e.currentTarget.files[0]
        setFileSlip({ data: file })
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            resolve(reader.result)
            setFileSlip({ base64: reader.result })
        }
        reader.onerror = (error) => reject(error)
    })
    // console.log('user', fileSlip);
    return (

        <Fragment>
            <Stack maxW={'900px'} align={'center'} spacing={2} >
                <Heading
                    textTransform={'uppercase'}
                    fontSize={'3xl'}
                    color={useColorModeValue('gray.800', 'gray.200')}>
                    กรุณาแนบสลิปของท่าน
                </Heading>

                <ThemeProvider theme={theme}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Grid
                            container
                            spacing={3}
                            style={{ display: 'block' }}
                        >
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="name-stream"
                                    label="ชื่อผู้ใช้"
                                    required
                                    variant="outlined"
                                    value={user?.username ? user?.username : ''}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="name-stream"
                                    label="ชื่อ-สกุล"
                                    required
                                    variant="outlined"
                                    value={user?.firstname ? `${user?.firstname} ${user?.lastname}` : ''}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        // disableFuture
                                        label="วันที่"
                                        // openTo="year"
                                        // minDate={dayjs(Date.now())}
                                        inputFormat='DD/MM/YYYY'
                                        // views={['year', 'month', 'day']}
                                        value={dateSlip.date ? dateSlip.date : ''}
                                        onChange={(e) => setDateSlip({ date: e })}
                                        renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} locale={THLocale}>
                                    <TimePicker
                                        value={dateSlip.time ? dateSlip.time : ''}
                                        onChange={(e) => setDateSlip({ time: e })}
                                        label="เวลาเริ่ม"
                                        renderInput={(params) => <TextField fullWidth required size="small"  {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </div>
                </ThemeProvider>
                <Stack align='center' py={'20px'}>
                    {/* <AspectRatio maxW="560px" ratio={'9/16'}> */}
                    {/* <Box
                        w={'100px'}
                        h={'300px'}
                    > */}
                    <Image
                        objectFit='cover'
                        w={'100px'}
                        // boxShadow={'100px'}
                        src={fileSlip?.base64}
                        alt="กรุณาเลือกรูปภาพ" />
                    {/* </Box> */}
                    {/* </AspectRatio> */}
                    <Box maxW={'400px'} >
                        <HStack>
                            <Button
                                variant="solid"
                                as="label"
                                color='green.500'

                            >
                                เลือกรูปภาพ
                                <input
                                    onChange={selectFileSlip}
                                    accept="image/*"
                                    id="photo-project-file"
                                    type="file"
                                    style={{ display: 'none' }}
                                />
                            </Button>
                            <Text isTruncated mt={10} align={'center'} fontSize={'lg'} color={'gray.500'}>
                                {fileSlip?.data?.name}
                            </Text>
                        </HStack>
                    </Box>
                </Stack>
            </Stack >
        </Fragment >
    )
};
export default InsertSlip
import gql from 'graphql-tag'

export default gql`
  query (
      $student: MongoID
      $content: MongoID
    ) {
      Quiz(filter: { student: $student,content:$content}) {
        _id
        status
        answer
        submitdate
        student{
            _id
            firstname
            lastname
        }
    }
  }
  `;
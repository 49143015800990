import gql from 'graphql-tag'

export default gql`
  mutation (
    $_id: MongoID!  
    $image: String
    $comment: UpdateByIdForumCommentInput
    $replydate: Date
    ){
    updateForum(_id:$_id,
      record:{
        image: $image,
        comment: $comment,
        replydate: $replydate
      })
      {
        recordId
      }
  }
`
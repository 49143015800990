import gql from "graphql-tag";

export default gql`
  mutation (
    $subject: MongoID
    $student: MongoID
    $certificatestatus: Boolean
    $creditstatus: Boolean
    $name: String 
  
  ) {
    createStudentOfSubject(
      record: {
        subject: $subject
        student: $student
        certificatestatus: $certificatestatus
        creditstatus: $creditstatus
        name: $name
      }
    ) {
      recordId
    }
  }
`;
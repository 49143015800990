import gql from 'graphql-tag'

export default gql`
    mutation (
        $subject: MongoID
        $name: String!
        $type: EnumContentType
        $text: String
        $link: String
        $duration: Float
        $carddescription: String
        $doctype: String
        $description: ContentHomeworkconfigDescriptionInput
        $questiondescription: String
        $surveydescription: ContentSurveyconfigDescriptionInput
        $texteditor: ContentTexteditorInput
        $startdate: Date
        $exampleFiletype: String
        $duedate: Date
        $exampleFile: String
        $typefile: String
        $expiredate: Date
        $statusActive: Boolean
    ) {
        createContent(
            record: {
                subject: $subject
                name: $name
                type: $type
                doctype: $doctype
                text: $text
                carddescription: $carddescription
                texteditor: $texteditor
                link: $link
                duration: $duration
                expiredate: $expiredate
                statusActive: $statusActive
                surveyconfig: {  description:  $surveydescription, startdate:$startdate, duedate: $duedate }
                homeworkconfig: { description: $description,duedate: $duedate,exampleFile: $exampleFile, exampleFiletype:$exampleFiletype, typefile: $typefile }
                questionconfig: { description: $questiondescription, startdate:$startdate, duedate: $duedate }
            }
        ) {
            recordId
        }
    }
  `
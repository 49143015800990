import gql from "graphql-tag";

export default gql`
  query ($_id: MongoID! ) {
    studentofsubjectId(_id:$_id) {
        _id
        name
        subject{
            name
            certificateconfig{
                status
                timestatus
                certificatetime
                name
                certificateCondition
                signature{
                    typesign
                    firstname
                    lastname
                    signatureURL
                }
                position
                logoURL
                backgroundURL
                description
            },
        }
        status
        certificatestatus
        creditstatus
    }
  }
`;
import gql from "graphql-tag";

export default gql`
  mutation (
    $_id: MongoID!
    $status: EnumStudentOfSubjectStatus 
    $certificatestatus: Boolean
    $creditstatus: Boolean
    $payment_image: String
) {
    updateStudentOfSubject(_id:$_id,
    record: {
        status: $status
        certificatestatus:  $certificatestatus
        creditstatus: $creditstatus
        payment_image: $payment_image
    }
    ) {
        recordId
        record{
            name
            certificatestatus
            creditstatus
            status
        }
    }
  }
`;
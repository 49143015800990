import {
    Box,
    Heading,
    Avatar,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    useColorModeValue,
    useDisclosure,
    Button,
    Link,
    Stack
} from '@chakra-ui/react'
import React, { Fragment } from 'react'


const PersonDetailPage = () => {
    return (
        <Fragment>
            <Box
                w='full'
                marginTop={20}
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}
                justifyContent="space-between"
            >
                <Box>
                    <Heading py={4} as="h4" size="md">
                        ข้อมูลวิทยากร
                    </Heading>
                    <Box
                        maxW={'320px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'md'}
                        rounded={'lg'}
                        p={6}
                        textAlign={'center'}>
                        <Avatar
                            size={'xl'}
                            src={
                                'https://images.unsplash.com/photo-1520810627419-35e362c5dc07?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ'
                            }
                            alt={'Avatar Alt'}
                            mb={4}
                            pos={'relative'}

                        />
                        <Heading fontSize={'2xl'} fontFamily={'body'}>
                            Lindsey James
                        </Heading>
                        <Text fontWeight={600} color={'gray.500'} mb={4}>
                            @lindsey_jam3s
                        </Text>
                        <Text
                            textAlign={'center'}
                            color={useColorModeValue('gray.700', 'gray.400')}
                            px={3}>
                            Actress, musician, songwriter and artist. PM for work inquires or{' '}
                            <Link href={'#'} color={'blue.400'}>
                                #tag
                            </Link>{' '}
                            me in your posts
                        </Text>

                        <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>

                        </Stack>

                        {/* <Stack mt={8} direction={'row'} spacing={4}>
                            <Button
                                flex={1}
                                fontSize={'sm'}
                                rounded={'full'}
                                _focus={{
                                    bg: 'gray.200',
                                }}>
                                Message
                            </Button>
                            <Button
                                flex={1}
                                fontSize={'sm'}
                                rounded={'full'}
                                bg={'blue.400'}
                                color={'white'}
                                boxShadow={
                                    '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                                }
                                _hover={{
                                    bg: 'blue.500',
                                }}
                                _focus={{
                                    bg: 'blue.500',
                                }}>
                                Follow
                            </Button>
                        </Stack> */}
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex="1"
                    ml="10"
                    flexDirection="column"
                    // justifyContent="center"
                    marginTop={{ base: '3', sm: '0' }}
                >
                    <Heading py={5} as="h4" size="md">
                        ข้อมูลวิชาและการสอนสด
                    </Heading>
                    <Text
                        as="p"
                        wordBreak='break-word'
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="lg">
                        {/* {subjectData?.subject?.teacher?.description ? subjectData?.subject?.teacher?.description : ''} */}
                    </Text>
                </Box>

            </Box>
        </Fragment>
    )

}

export default PersonDetailPage
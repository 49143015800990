import React from 'react';
import {
    Box,
    Heading,
    Stack,
    Container,
    Image,
    Avatar,
    Badge,
    Button,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { useQuery, useMutation } from '@apollo/client'
import paymentsQuery from '../graphql/queries/payments'
// import SubjectLayout from '../components/layouts/SubjectLayout';
import { useHistory } from 'react-router';
import { useContext, useEffect } from 'react';
import AuthContext from '../contexts/AuthContext';
import dayjs from 'dayjs';
import { useState } from 'react';
import updateUserMutaion from '../graphql/mutations/updateUser'
// import payments from '../graphql/queries/payments';


const ManagePaymentsPage = () => {
    const { data: paymentsData } = useQuery(paymentsQuery)
    const [updateUser] = useMutation(updateUserMutaion)
    // console.log('subject', subjectData);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user } = useContext(AuthContext)
    const history = useHistory()
    const toast = useToast()
    const [modalData, setModalData] = useState({})
    // const [acceptData, setAcceptData] = useState({})

    useEffect(
        () => {
            if (user?.role !== 'ADMIN') {
                setTimeout(() => {
                    history.push('/login')
                }, 100)
            }
        }, [history, user],
    )
    // console.log('payment', paymentsData);
    const handleAddPayment = async (dataUser) => {
        try {
            const { data: paymentdata } = await updateUser({
                variables: {
                    _id: dataUser?._id,
                    expire: dayjs(Date.now()).add(1, 'month'),
                    member: 'VIP',
                },
            })
        } catch (Error) {
            // console.log('in Create payment submit', Error)
            // enqueueSnackbar(Error, { variant: 'error' })
        } finally {
            // console.log('finally')
            await toast({
                position: "top",
                title: 'Success',
                description: "เพิ่มข้อมูลเรียบร้อย",
                status: 'success',
                duration: 6000,
                isClosable: true,
            })
        }
    }
    const ModalShowsImageSlip = () => (
        <Modal id={modalData?.id} onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{modalData?.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box boxSize="sm">
                        <Image src={modalData?.slip} alt="Segun Adebayo" />
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
    // console.log('op', isOpen);
    return (
        <Box w="100%" >
            <Container py={16} as={Stack} spacing={12}>
                <Stack spacing={0} align={'center'}>
                    <Heading>จัดการการจ่ายเงินของสมาชิก</Heading>
                    {/* <Text>สามารถเลือกวิชาที่ท่านสนใจเรียนได้</Text> */}
                </Stack>
                <Stack align={'center'}>
                    <Table w={'full'} variant="striped" colorScheme='green'>
                        <TableCaption>Imperial to metric conversion factors</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th>ชื่อผู้ใช้</Th>
                                <Th>ชื่อ-สกุล</Th>
                                <Th>อีเมลล์</Th>
                                <Th>วันที่จ่ายเงิน</Th>
                                <Th>เวลา</Th>
                                <Th>รูปภาพสลิป</Th>
                                <Th>ระดับสมาชิก</Th>
                                <Th>วันหมดอายุ</Th>
                                <Th>การอนุมัติ</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {paymentsData && paymentsData?.payments?.map((payment) => {

                                return (<Tr>
                                    <Td> <Avatar boxSize={'30px'} src={payment?.customer?.avatar} /></Td>
                                    <Td>{payment?.customer?.username}</Td>
                                    <Td>{`${payment?.customer?.firstname} ${payment?.customer?.lastname}`}</Td>
                                    <Td>{payment?.customer?.email}</Td>
                                    <Td>{payment?.date ? dayjs(payment?.date).format('DD/MMMM/YYYY') : 'ไม่มี'}</Td>
                                    <Td>{payment?.time ? dayjs(payment?.time).format('HH:mm') : 'ไม่มี'}</Td>
                                    <Td><Image
                                        boxSize={'20px'}
                                        src={payment?.slip}
                                        onClick={() => {
                                            setModalData({
                                                id: payment?._id,
                                                name: `${payment?.customer?.firstname} ${payment?.customer?.lastname}`,
                                                slip: payment?.slip
                                            })
                                            onOpen()
                                        }}
                                    /></Td>
                                    <Td isNumeric>{
                                        payment?.customer?.member === 'NORMAL' ?
                                            <Badge
                                                // fontSize='0.7em' 
                                                // top={'19px'} 
                                                // left={'93px'} 
                                                // position={'absolute'} 
                                                variant="outline"
                                                colorScheme="gray">
                                                {payment?.customer?.member}
                                            </Badge>
                                            :
                                            <Badge
                                                variant="outline"
                                                colorScheme="yellow"
                                            >
                                                {payment?.customer?.member}
                                            </Badge>
                                    }</Td>
                                    <Td>{payment?.customer?.expire ? dayjs(payment?.customer?.expire).format('DD/MMMM/YYYY') : 'ไม่มี'}</Td>
                                    <Td>
                                        <Button
                                            onClick={() => {
                                                handleAddPayment(payment?.customer)

                                            }}
                                            disabled={payment?.customer?.member === 'VIP'}
                                            colorScheme='green'
                                        >ยอมรับ
                                        </Button></Td>
                                </Tr>)
                            })
                            }
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>To convert</Th>
                                <Th>into</Th>
                                <Th isNumeric>multiply by</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </Stack>
            </Container>
            <ModalShowsImageSlip />
        </Box>
    );
};

export default ManagePaymentsPage;
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import {
    Box,
    Heading,
    Text,
    Stack,
    Container,
    Wrap,
    WrapItem,
    useDisclosure,
    Flex,
    Spacer,
    IconButton,
    Divider,
} from '@chakra-ui/react';
import {
    Previous,
    Paginator,
    PageGroup,
    Page,
    Next,
    generatePages
} from 'chakra-paginator';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import { useQuery } from '@apollo/client'
import subjectsbyAliveSubject from '../graphql/queries/subjectsbyAliveSubject'
import SubjectLayoutAllCourse from '../components/layouts/SubjectLayoutAllCourse';
import AuthContext from '../contexts/AuthContext';
import ModalPricingLayout from '../components/layouts/ModalPricingLayout';
import { MdClose } from 'react-icons/md'
import SubjectLayoutSkeleton from '../components/skeleton/SubjectLayoutSkeleton';


const CoursePage = () => {
    const { user } = useContext(AuthContext)
    const { data: subjectData, loading, refetch, } = useQuery(subjectsbyAliveSubject, { fetchPolicy: "network-only", })
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [subjectsState, setSubjectsState] = useState([])
    const { t } = useTranslation()

    const [curItems, setCurItems] = useState([]);

    const itemLimit = 8;

    const [pagesQuantity, setPagesQuantity] = useState(0);
    const [curPage, setCurPage] = useState(0);


    const handlePageChange = (page) => {
        setCurPage(page - 1);
    };

    useEffect(() => {
        const pagesTotal = Math.ceil(subjectData?.subjects?.length / itemLimit);
        setPagesQuantity(pagesTotal);
        if (subjectData) {
            setSubjectsState(subjectData?.subjects ? subjectData?.subjects : [])
        }
        if (localStorage.getItem('refetchPage') !== true) {
            // console.log("refetPage")
            localStorage.setItem('refetchPage', false)
            refetch()
        }
    }, [subjectData])

    return (
        <Fragment>
            <Box w="100%" px={[1, 1, 2, 20]} minHeight={'90vh'} >
                <Container maxW={'8xl'} py={10} as={Stack} spacing={10}>
                    <Stack
                        direction={["column", "column", "row"]}
                        justifyContent='space-between'
                    >
                        <Stack align={{ base: 'center', md: 'flex-start' }}>
                            <Heading fontSize={['40px', '40px', '25px', '50px']}>{t('coursePage.title')}</Heading>
                            <Text>{t('coursePage.subtitle')}</Text>
                        </Stack>
                        <Stack align={{ base: 'center', md: "flex-end" }}>
                            <Flex
                                boxSize="full"
                                // h="100vh"
                                w={['90%', '', '400px']}
                                pos="relative"
                                // bg={useColorModeValue("gray.400", "gray.600")}
                                // p={30}
                                alignItems='center'
                                justifyContent="center"
                            >
                                <AutoComplete
                                    onChange={(v, i, I) => {
                                        // console.log(v, i, I);
                                    }}
                                    onSelectOption={(e) => { setSubjectsState(subjectsState?.filter((row) => (e?.optionValue === row?._id))) }}
                                    rollNavigation>
                                    <AutoCompleteInput variant="filled" placeholder="Search..." autoFocus />
                                    <AutoCompleteList>

                                        {subjectData?.subjects.map((option, oid) => (
                                            <AutoCompleteItem
                                                key={`option-${oid}`}
                                                label={` ${option?.name} ${option?.teacher?.firstname} ${option?.teacher?.lastname}`}
                                                // getValue={e => (e || option)}
                                                // op={option}
                                                value={`${option?._id}`}
                                                // value={`${option?.code} ${option?.name} ${option?.teacher?.firstname} ${option?.teacher?.lastname} ${option?.contentfor} `}
                                                textTransform="capitalize"
                                            >
                                                {` ${option?.name} ${option?.teacher?.firstname} ${option?.teacher?.lastname}  `}
                                            </AutoCompleteItem>
                                        ))}
                                    </AutoCompleteList>
                                </AutoComplete>
                                <IconButton
                                    variant="ghost"
                                    colorScheme='blue'
                                    pos='absolute'
                                    right={0}
                                    onClick={() => { setSubjectsState(subjectData?.subjects) }}
                                    aria-label="Call Sage"
                                    fontSize="15px"
                                    icon={<MdClose />}
                                />
                            </Flex>
                        </Stack>
                    </Stack>
                    <Divider />
                    <Wrap justify={'flex-start'}>
                        {loading ? [1, 2, 3, 4].map((row) => (
                            <WrapItem key={row}>
                                <SubjectLayoutSkeleton />
                            </WrapItem>
                        )) :
                            <Box w="full">
                                {
                                    subjectsState.length !== 0 ?
                                        // console.log('date', dayjs(row?.updatedAt).format('DD MMMM YYYY'));
                                        (
                                            <WrapItem justifyContent={'center'}>
                                                <SubjectLayoutAllCourse subjectsState={subjectsState} onOpen={onOpen} user={user} curPage={curPage} itemLimit={itemLimit} curItems={curItems} setCurItems={setCurItems} />
                                            </WrapItem>
                                        )
                                        :
                                        <Box w="full" p={'2'} boxShadow="lg" borderRadius={'lg'} h={"200"} bg={'gray.100'}>
                                            <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} style={{ height: "100%" }}>
                                                <Text fontSize={'35'} color={'gray.500'}>{t('coursePage.alt')}</Text>
                                            </Flex>
                                        </Box>
                                }
                            </Box>
                        }
                    </Wrap>
                    <Box>
                        {pagesQuantity !== 0 ? <Flex>
                            <Spacer />
                            <Paginator
                                onPageChange={handlePageChange}
                                pagesQuantity={pagesQuantity}>
                                <Previous bg={'primary.500'} color={'white'}>
                                    <CgChevronLeft />
                                </Previous>
                                <PageGroup ml={1} mr={1}>
                                    {generatePages(pagesQuantity)?.map((page) => (
                                        <Page
                                            key={`paginator_page_${page}`}
                                            page={page}
                                        />
                                    ))}
                                </PageGroup>
                                <Next bg={'primary.500'} color={'white'}>
                                    <CgChevronRight />
                                </Next>
                            </Paginator>

                        </Flex>
                            :
                            <Box>
                                {/* <Text fontSize={'35'} color={'gray.500'}>{t('teacherPage.alt')}</Text> */}
                                <Box h='145px'>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Container>
            </Box>
            <ModalPricingLayout isOpen={isOpen} onClose={onClose} />
        </Fragment >
    );
};

export default CoursePage;
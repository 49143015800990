import React, { Fragment, useMemo } from "react"
import { Flex } from "@chakra-ui/react"
import Header from "../sections/Header"
import { useLocation } from "react-router"
import FooterLayout from "./FooterLayout"

const LandingLayout = (props) => {
  const location = useLocation()
  // console.log('landing ', location);
  const headerMenu = useMemo(() => {
    const search = `${location?.pathname}`.match(/\/meet\//g)
    const quiz = `${location?.pathname}`.match(/\/quiz/g)
    // console.log('search', search, location?.pathname);
    // console.log('quiz', quiz, location?.pathname);
    let show = search !== null || quiz !== null ? false : true
    if (show) {
      return <Header />
    }
    return <Fragment />
  })

  const FooterMenu = useMemo(() => {
    const search = `${location?.pathname}`.match(/\/meet\//g)
    const quiz = `${location?.pathname}`.match(/\/quiz/g)
    // console.log('search', search, location?.pathname);
    // console.log('quiz', quiz, location?.pathname);
    let show = search !== null || quiz !== null ? false : true
    if (show) {
      return <FooterLayout />
    } else {
      return <Fragment />
    }
  })

  return (
    <Fragment>
      <Flex
        direction="column"
        align="center"
        maxW={{ xl: "1600px" }}
        m="0 auto"
        // mb="10"
        // pe={8}
        {...props}
      >
        {headerMenu}
        <Fragment>
          <div id={props?.location?.key} style={{ marginTop: '60px', width: '100%', minHeight: '700px' }}>
            {props.children}
          </div>
        </Fragment>
      </Flex>
      {FooterMenu}
    </Fragment>
  )
}
export default LandingLayout

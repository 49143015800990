import gql from 'graphql-tag'

export default gql`
   mutation (
    $_id: MongoID!
    $signatureURL: String
    $logoURL: String
    $backgroundURL: String
    $firstname: String
    $lastname: String
    $typesing: EnumSubjectCertificateconfigSignatureTypesign
    $position: String
    $timestatus: EnumSubjectCertificateconfigTimestatus
    $certificatetime: Date
    $description: String
    ){
    updateSubject(_id:$_id,
        record:{
            certificateconfig: {
                signature: {
                    typesign: $typesing
                    firstname: $firstname
                    lastname: $lastname
                    signatureURL: $signatureURL
                }
                description: $description
                certificatetime: $certificatetime
                position: $position
                logoURL:  $logoURL
                backgroundURL: $backgroundURL
                timestatus: $timestatus
            }
        })
    {
        recordId
    }
  }
`
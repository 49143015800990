import gql from 'graphql-tag'

export default gql`
	query ($_id: MongoID) {
		content(filter: { _id: $_id }) {
			_id
			name
			type
			expiredate
			statusActive
			questionconfig {
				description
				duedate
				startdate
				questionList 
			}
		}
	}
`

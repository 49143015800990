import React, { Fragment, useState, useContext, useEffect } from 'react'
import { Link as RouteLink } from "react-router-dom";

import {
    Box,
    Link,
    Container,
    Skeleton,
    Stack,
    Text,
    Heading,
    Button,
    Table,
    IconButton,
    Tr,
    Th,
    Td,
    Tbody,
    Center,
    Input,
    FormControl,
    FormLabel,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    useDisclosure,
    useToast,

    HStack,
    Avatar,
    FormErrorMessage,
    Flex,

} from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import { BiUserPlus } from 'react-icons/bi'
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa"

import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { DragHandleIcon } from '@chakra-ui/icons'
import { EditIcon, DeleteIcon, DownloadIcon } from '@chakra-ui/icons'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import { useMutation, useQuery } from '@apollo/client'
import { useMultiState } from '../lib/hooks'
import { useTranslation } from 'react-i18next'
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'

import DialogRemove from '../components/commons/DialogRemove'
import DialogCreateStudent from '../components/pages/ManageStudentPage/DialogCreateStudent'
import DialogUpdateStudent from '../components/pages/ManageStudentPage/DialogUpdateStudent';


import UpdateMutaion from "../graphql/mutations/updateStudentUser"
import RemoveStudentMutaion from "../graphql/mutations/removeStudentUser"
import setPasswordMutation from '../graphql/mutations/setPassword'
import studentQuery from '../graphql/queries/studentUser'
import createStudentMutation from '../graphql/mutations/createStudentUser'
import axios from "axios"

const initialStudent = {
    _id: '',
    username: '',
    firstname: '',
    lastname: '',
    mobile: '',
    email: '',
    avatar: '',
    password: '',
    confirm_pass: '',
    confirm_email: '',
}

const URL = process.env.REACT_APP_UPLOAD_IMAGE
const ManageStudentPage = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { user } = useContext(AuthContext)
    const toast = useToast()

    const [setPassword] = useMutation(setPasswordMutation)
    const [updateMutation] = useMutation(UpdateMutaion)
    const [removeStudentMutaion] = useMutation(RemoveStudentMutaion)
    const [setStudentUser] = useMutation(createStudentMutation)
    const { data: studentData, refetch, loading } = useQuery(studentQuery)

    const [student, setStudent] = useMultiState(initialStudent)
    const [studentState, setStudentState] = useState([])
    const [passwordEditStatus, setPasswordEditStatus] = useState(false)

    const { isOpen: updateDialogStatus, onOpen: openUpdateDialogStatus, onClose: closeUpdateDialogStatus } = useDisclosure()
    const { isOpen: createDialogStatus, onOpen: openCreateDialogStatus, onClose: closeCreateDialogStatus, } = useDisclosure()
    const { isOpen: removeDialogStatus, onOpen: openRemoveDialogStatus, onClose: closeRemoveDialogStatus } = useDisclosure()


    useEffect(() => {
        if (studentData) {
            setStudentState(studentData?.studentUsers ? studentData?.studentUsers : [])
        }
        if (user?.role !== 'ADMIN') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
        if (loading) {
            refetch({
                user: user?._id,
            })
        }
    }, [studentData, history, user, refetch]);



    const [fileavatar, setFileAvatar] = useMultiState({ data: null, base64: '' })

    const selectAvatarFile = (e) => new Promise((resolve, reject) => {
        e.preventDefault()
        const file = e.currentTarget.files[0]
        setFileAvatar({ data: file })
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            resolve(reader.result)
            setFileAvatar({ base64: reader.result })
        }
        reader.onerror = (error) => reject(error)
    })

    const handleCreateStudent = async (e) => {
        e.preventDefault()
        if (!student?.username) {
            await toast({
                position: "top",
                title: "Warning",
                description: `${t('ManageStudentPage.altwarningUsername')}`,
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else if (!student?.firstname) {
            await toast({
                position: "top",
                title: "Warning",
                description: `${t('ManageStudentPage.altwarningName')}`,
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else if (!student?.lastname) {
            await toast({
                position: "top",
                title: "Warning",
                description: `${t('ManageStudentPage.altwarningLastname')}`,
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else {
            try {
                const response = await axios.post(URL, { photo: fileavatar.base64 })
                // console.log(response)
                const { data: responseCreateStudent, } = await setStudentUser({
                    variables: {
                        firstname: student?.firstname,
                        lastname: student?.lastname,
                        mobile: student?.mobile,
                        username: student?.username,
                        email: student?.email,
                        avatar: response ? response?.data?.url : '',
                    },
                })
                if (responseCreateStudent) {
                    const { data: responseSetPassword, } = await setPassword({
                        variables: {
                            _id: responseCreateStudent?.createStudentUser?.recordId,
                            password: student?.password,
                        },
                    })
                    if (responseSetPassword) {
                        // console.log('finally')
                        await toast({
                            position: "top",
                            title: 'Success',
                            description: `${t('ManageStudentPage.toastAddTeacherSuccess')}`,
                            status: 'success',
                            duration: 6000,
                            isClosable: true,
                        })
                        closeCreateDialogStatus()

                    }
                }
            } catch (Error) {
                await toast({
                    position: "top",
                    title: "ERROR",
                    description: `${Error?.graphQLErrors[0]?.message}`,
                    status: "error",
                    duration: 6000,
                    isClosable: true,
                })
                // enqueueSnackbar(Error, { variant: 'error' })
            }
            refetchPage()
        }
    }

    const handleUpdateStudent = async (e) => {
        e.preventDefault();
        // const response = await axios.post(URL, { photo: fileavatar.base64 })
        try {
            const { data: responseUpdateStudent } = await updateMutation({
                variables: {
                    _id: student?._id,
                    firstname: student?.firstname,
                    lastname: student?.lastname,
                    mobile: student?.mobile,
                    username: student?.username,
                    email: student?.email,
                },
            })
            if (responseUpdateStudent) {
                await toast({
                    position: "top",
                    title: "Success",
                    description: `${t('ManageStudentPage.toastEditTeacherSuccess')}`,
                    status: "success",
                    duration: 6000,
                    isClosable: true,
                })
                closeUpdateDialogStatus()
            }
        } catch (error) {
            console.log(error)
            await toast({
                position: "top",
                title: "warning",
                description: `${t(error)}`,
                status: "success",
                duration: 3000,
                isClosable: true,
            })
        }
        refetchPage()
    }

    const handleRemoveStudent = async (e) => {
        e.preventDefault()
        try {
            const { data: responseRemoveStudent } = await removeStudentMutaion({
                variables: {
                    _id: student?._id,
                }
            })
            if (responseRemoveStudent) {
                await toast({
                    position: "top",
                    title: "Success",
                    description: `${t('ManageStudentPage.toastAddSuccessRemove')}`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                closeRemoveDialogStatus()
            }
        } catch (error) {
            console.log(error)
            await toast({
                position: "top",
                title: "Success",
                description: `${t(error)}`,
                status: "success",
                duration: 3000,
                isClosable: true,
            })
        }
        refetchPage()
    }

    const toggleEditPasswordStatus = () => {
        console.log("ACTIVE1", passwordEditStatus)
        setPasswordEditStatus(!passwordEditStatus)
    }
    console.log("ACTIVE2", passwordEditStatus)

    const refetchPage = () => {
        refetch()
        setStudent(initialStudent)
        setFileAvatar({ data: null, base64: '' })
    }


    return (
        <Fragment>
            <Container maxW={'7xl'} py={5} as={Stack} minH={'90vh'} mt={5} w="100%">
                <Stack spacing={0} align={'center'} >
                    <Heading variant="h4">{t('ManageStudentPage.title')}</Heading>
                    <Text>{t('ManageStudentPage.subtitle')}</Text>
                </Stack>
                <Flex
                    py={2}
                    direction={["column", "", "row"]}
                    justifyContent='space-between'

                >
                    <Stack align={{ base: 'center', md: "flex-end" }}>
                        <Flex
                            boxSize="full"
                            // h="100vh"
                            w={['90%', '', '400px']}
                            pos="relative"
                            // bg={useColorModeValue("gray.400", "gray.600")}
                            // p={30}
                            alignItems='center'
                            justifyContent="center">
                            <AutoComplete
                                onChange={(v, i, I) => {
                                    // console.log(v, i, I);
                                }}
                                onSelectOption={(e) => { setStudentState(studentState?.filter((row) => (e?.optionValue === row?._id))) }}
                                rollNavigation>
                                <AutoCompleteInput size='lg' variant="filled" placeholder="Search..." autoFocus />
                                <AutoCompleteList>

                                    {studentData?.studentUsers.map((option, oid) => (
                                        <AutoCompleteItem
                                            key={`option-${oid}`}
                                            label={`${option?.firstname} ${option?.lastname}`}
                                            value={`${option?._id}`}
                                            textTransform="capitalize"
                                        >
                                            {` ${option?.firstname} ${option?.lastname} `}
                                        </AutoCompleteItem>
                                    ))}
                                </AutoCompleteList>
                            </AutoComplete>
                            <IconButton
                                variant="ghost"
                                colorScheme='blue'
                                pos='absolute'
                                right={0}
                                aria-label="Call Sage"
                                fontSize="15px"
                                onClick={() => { setStudentState(studentData?.studentUsers) }}
                                icon={<MdClose />}
                            />
                        </Flex>
                    </Stack>
                    <Button

                        bg={'primary.700'} color={'white'} _hover={{
                            transform: 'translateY(-2px)',
                            boxShadow: 'lg',
                        }}
                        leftIcon={<BiUserPlus />}
                        size='lg'
                        mb={3}
                        onClick={openCreateDialogStatus}
                    >
                        {t('ManageStudentPage.addTeacherButton')}
                    </Button>
                </Flex>
                {loading ?
                    <Stack py={20}>
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                    </Stack>
                    :
                    <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} boxShadow={'xl'} borderWidth={'1px'}  >
                        <Table variant="striped" backgroundColor="white">
                            <Tr>
                                <Th textAlign="center">	{t('ManageStudentPage.no')}</Th>
                                <Th textAlign="center">{t('ManageStudentPage.name-surname')} </Th>
                                <Th textAlign="center">{t('ManageStudentPage.manage')}</Th>
                            </Tr>
                            {studentState?.map((student, i) => (
                                <Tbody>
                                    {console.log("SS", student)
                                    }
                                    <Tr key={student?._id}>
                                        <Td textAlign="center">{i + 1}</Td>
                                        <Td textAlign="center">
                                            <Link as={RouteLink} isExternal to={`/showprofile/${student?._id}`} >
                                                {student?.firstname} {student?.lastname}
                                            </Link>
                                        </Td>
                                        <Td>
                                            <Center>
                                                <Tooltip label={t('ManageStudentPage.editButton')} fontSize="md">
                                                    <IconButton boxShadow={'lg'} colorScheme='blue' icon={<FaPencilAlt />} mr={2}
                                                        _hover={{
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: 'lg',
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setStudent({
                                                                _id: student?._id,
                                                                username: student?.username,
                                                                firstname: student?.firstname,
                                                                lastname: student?.lastname,
                                                                mobile: student?.mobile,
                                                                email: student?.email,
                                                                avatar: student?.avatar,
                                                            })
                                                            openUpdateDialogStatus()
                                                        }}
                                                    />
                                                </Tooltip>
                                                <Tooltip label={t('ManageStudentPage.removeButton')} fontSize="md">
                                                    <IconButton boxShadow={'lg'} colorScheme='red' icon={<FaTrashAlt />} mr={2}
                                                        _hover={{
                                                            transform: 'translateY(-2px)',
                                                            boxShadow: 'lg',
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setStudent({
                                                                _id: student?._id,
                                                                username: student?.username,
                                                                firstname: student?.firstname,
                                                                lastname: student?.lastname,
                                                                mobile: student?.mobile,
                                                                email: student?.email,
                                                                avatar: student?.avatar,
                                                            })
                                                            openRemoveDialogStatus()
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Center>
                                        </Td>
                                    </Tr>
                                </Tbody>))}
                        </Table>
                    </Box>
                }
            </Container>
            <DialogRemove
                status={removeDialogStatus}
                handleSubmit={handleRemoveStudent}
                name={`${student.firstname} ${student.lastname}`}
                onCloseDialog={closeRemoveDialogStatus}
            />

            <DialogCreateStudent
                student={student}
                setStudent={setStudent}
                createDialogStatus={createDialogStatus}
                closeCreateDialogStatus={closeCreateDialogStatus}
                handleCreateStudent={handleCreateStudent}
                selectAvatarFile={selectAvatarFile}
            />
            <DialogUpdateStudent
                passwordEditStatus={passwordEditStatus}
                toggleEditPasswordStatus={toggleEditPasswordStatus}
                student={student}
                setStudent={setStudent}
                updateDialogStatus={updateDialogStatus}
                closeUpdateDialogStatus={closeUpdateDialogStatus}
                handleUpdateStudent={handleUpdateStudent}
                selectAvatarFile={selectAvatarFile}
            />
        </Fragment >
    )
}

export default ManageStudentPage

import { useTranslation } from 'react-i18next'
import {
    GridItem,
    Button,
    Box,
    FormControl,
    FormLabel,
    ModalFooter,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    Text,
    Grid,
    Progress,
    Input,
    Tooltip,
    Flex,
    Link,
} from '@chakra-ui/react'
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'
import { AiOutlineFilePpt, AiOutlineFileImage } from 'react-icons/ai'
import { Dropzone, FileItem } from '@dropzone-ui/react'


const DialogUpdateHomework = ({ typeSendFile, statusEdit, handleUpdatehomework, toggleEditButton, toggleRemoveButton, setHomeWork, homework, contentState, uploadState, handleUpdateStatus, homeworkData, isEditopen, onEditclose, user, onDeleteFile, updateFiles, files, onDeleteVdo, updateFilesVdo, filesVdo, statusremove }) => {

    const { t } = useTranslation()


    const handleChangeStateValue = (e) => {
        setHomeWork({ ...homework, [e.target.name]: e.target.value })
    }

    return (
        <Modal isOpen={isEditopen} onClose={handleUpdateStatus} >
            <ModalOverlay />
            <ModalContent>
                <form>
                    <ModalHeader>เเก้ไข {contentState?.homeworkconfig?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Flex justifyContent="space-between" my={2}>

                            {/* {contentState?.homeworkconfig?.typefile === '.pdf' ? (
                                <AiOutlineFilePpt size={70} />
                            ) : (
                                <AiOutlineFileImage size={70} />
                            )} */}
                            <Link
                                placeSelf='center'
                                alignItems={'center'}
                                href={`${homeworkData?.homework?.file}`}
                                isExternal
                                mb={1}
                                display={'flex'}
                            >
                                <AiOutlineFilePpt size={20} /> <Text ml={2}>{homeworkData?.homework?.filename}</Text>
                            </Link>
                            <Box>
                                <Tooltip label={t('SendHomeworkPage.edit')} fontsize="md">
                                    <Button onClick={toggleEditButton} mr={1}>
                                        <FaPencilAlt />
                                    </Button>
                                </Tooltip>
                                <Tooltip label={t('SendHomeworkPage.delete')} fontsize="md">
                                    <Button
                                        onClick={toggleRemoveButton}
                                    >
                                        <FaTrashAlt />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Flex>

                        {statusremove === true && (
                            <Box>
                                <Dropzone
                                    require
                                    onChange={updateFiles}
                                    value={files}
                                    accept={`.${typeSendFile}`}
                                    maxFiles="1"
                                    view="grid"
                                >
                                    {files.map((file) => (
                                        <FileItem
                                            {...file}
                                            key={file.id}
                                            onDelete={onDeleteFile}
                                            // localization={"EN-en"}
                                            // resultOnTooltip
                                            preview={true}
                                        />
                                    ))}
                                </Dropzone>
                                <ModalFooter>
                                    <Button mr={3} onClick={onEditclose}>
                                        {t('SendHomeworkPage.cancel')}
                                    </Button>
                                    <Button disabled bg={'primary.700'} color={'white'}>
                                        {t('SendHomeworkPage.save')}
                                    </Button>
                                </ModalFooter>
                            </Box>
                        )}
                        {statusEdit === true && (
                            <Box>
                                {contentState?.homeworkconfig?.typefile == 'video' ? <Box>
                                    <FormControl>
                                        <Dropzone
                                            onChange={updateFilesVdo}
                                            value={filesVdo}
                                            accept="video"
                                            maxFiles="1"
                                            view="grid"
                                            required
                                        //localization={"ES-es"}
                                        //disableScroll
                                        >
                                            {filesVdo.map((file) => (
                                                <FileItem
                                                    {...file}
                                                    key={file.id}
                                                    onDelete={onDeleteVdo}
                                                    //localization={"EN-en"}
                                                    //resultOnTooltip
                                                    preview={true}
                                                />
                                            ))}
                                        </Dropzone>
                                        <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'}>
                                            <GridItem colSpan={8} >
                                                <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                            </GridItem>
                                            <GridItem colSpan={1} align={'center'}>
                                                <Text>{`${uploadState} %`}</Text>
                                            </GridItem>
                                        </Grid>
                                    </FormControl>
                                </Box> :
                                    <FormControl>
                                        <Dropzone
                                            require
                                            onChange={updateFiles}
                                            value={files}
                                            accept={`.${typeSendFile}`}
                                            maxFiles="1"
                                            view="grid"
                                        >
                                            {files.map((file) => (
                                                <FileItem {...file} key={file.id} onDelete={onDeleteFile} preview={true} />
                                            ))}
                                        </Dropzone>
                                        <Grid templateColumns='repeat(9, 1fr)' gap={1} py={'2'} display={'flex'}>
                                            <GridItem colSpan={8} flexGrow={8} alignSelf={'center'}>
                                                <Progress value={uploadState} size="lg" borderRadius={'lg'} colorScheme='green' />
                                            </GridItem>
                                            <GridItem colSpan={1} align={'center'} flexGrow={1}>
                                                <Text>{`${uploadState} %`}</Text>
                                            </GridItem>
                                        </Grid>
                                        {homework?.url &&
                                            <Input id='name' name='name' value={homework?.name} onChange={handleChangeStateValue} />
                                        }
                                    </FormControl>
                                }
                            </Box>
                        )}
                        <FormControl>
                            <FormLabel mt={3}>
                                {t('SendHomeworkPage.uploadname')} : {user}
                            </FormLabel>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} onClick={onEditclose}>
                            {t('SendHomeworkPage.cancel')}
                        </Button>
                        <Button
                            disabled={!homework?.url}
                            onClick={handleUpdatehomework}
                            colorScheme="blue"
                            mr={3}
                        >
                            {t('SendHomeworkPage.save')}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )

}

export default DialogUpdateHomework

import {
    Box,
    Heading,
    Text,
    Image,
    Button,
    Center,
    Stack,
    useColorMode,
} from '@chakra-ui/react'

import { useHistory } from 'react-router'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { BiCheckCircle } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { BlogAuthor } from '../../commons/BlogAuthor'


const SubjectCard = ({ subjectData, user, enroll, openDialogEnrollSubject, openDialogUnEnrollSubject }) => {
    const history = useHistory()

    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <Box
            boxShadow={'xl'}
            p={5}
            // border={`1px solid`}
            borderRadius={'3xl'}
            marginTop={{ base: '1', sm: '5' }}
            bg={colorMode === 'light' ? 'brand.100' : 'brand.200'}
            display="flex"
            flexDirection={{ base: 'column', sm: 'row' }}
            justifyContent="space-between"
            minH={['full', 'full', '250px', '450px']}
            minW={['full', 'full', '250px', '450px']}
        >
            <Box display="flex"
                flex="1"
                mr="3"
                position="relative"
                alignItems="center"
                justifyContent="center"
                borderRadius={'lg'}>
                <Stack zIndex="4">
                    <Image
                        maxH={['full', 'full', '250px', '450px']}
                        borderRadius="lg"
                        src={
                            subjectData?.subject?.photo
                                ? subjectData?.subject?.photo
                                : 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                        }
                        objectFit="contain"
                    />
                </Stack>

                <Box zIndex="1" width="100%" position="absolute" height="100%">
                    <Box
                        bgGradient={colorMode === 'light' ?
                            'radial(primary.800  1px, transparent 1px)' :
                            'radial(primary.900 1px, transparent 1px)'
                        }
                        backgroundSize="20px 20px"
                        opacity="0.9"
                        height="100%"
                    />
                </Box>
            </Box>
            <Box
                display="flex"
                flex="1"
                flexDirection="column"
                justifyContent="center"
                marginTop={{ base: '3', sm: '0' }}
                p={5}
                color={colorMode === 'light' ? 'brand.800' : 'brand.800'}
            >
                <Heading as="h1" size='lg' flexGrow="2">
                    {`${subjectData?.subject?.name} `}
                </Heading>
                {/* <Heading as="h3" size='md' marginTop="1" >
                    <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                        {t('subjectPage.subjectDetail')}
                    </Link>
                </Heading> */}
                <BlogAuthor
                    flexGrow="2"
                    name={`${subjectData?.subject?.teacher?.firstname} ${subjectData?.subject?.teacher?.lastname}`}
                    date={subjectData?.subject?.createAt}
                    avatar={
                        subjectData?.subject?.teacher?.avatar ? subjectData?.subject?.teacher?.avatar : ''
                    }
                />
                <Box flexGrow="4">
                    <Text
                        as="p"
                        wordBreak="break-word"
                        marginTop="2"
                        fontSize="lg"
                    >
                        {subjectData?.subject?.description ? subjectData?.subject?.description : ''}
                    </Text>

                    {user === null ?
                        <Center>
                            <Button mt={4}
                                boxShadow={'lg'}
                                leftIcon={<IoIosAddCircleOutline />}
                                colorScheme={'telegram'}
                                variant="solid"
                                onClick={() => { history.push('/login') }}
                            >
                                {t('subjectPage.join')}
                            </Button>
                        </Center>
                        :
                        <Box>
                            {((subjectData?.subject?.teacher?._id !== user?._id) && (user?.role !== "TEACHER")) && (user?.role !== "ADMIN") && (
                                <Center>
                                    {!enroll ? (
                                        <Button
                                            mt={4}
                                            boxShadow={'lg'}
                                            leftIcon={<IoIosAddCircleOutline />}
                                            variant="solid"
                                            colorScheme={'telegram'}
                                            onClick={openDialogEnrollSubject}
                                        >
                                            {t('subjectPage.join')}
                                        </Button>
                                    ) : (
                                        <Box>
                                            {enroll?.subject?._id === subjectData?.subject?._id && enroll?.student?._id === user?._id && (
                                                <Button
                                                    boxShadow={'lg'}
                                                    leftIcon={<BiCheckCircle />}
                                                    colorScheme="green"
                                                    variant="solid"
                                                    onClick={openDialogUnEnrollSubject}
                                                >
                                                    {t('subjectPage.joinComplete')}
                                                </Button>
                                            )}
                                        </Box>
                                    )}
                                </Center>
                            )}
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )

}

export default SubjectCard

import {
    Grid,
    GridItem,
    Box,
    Heading,
    Text,
    HStack,
    Link,
    Button,
    Center,
    Flex,
    Progress,
    Stack,
    useColorMode,
} from '@chakra-ui/react'

import { Link as domlink } from 'react-router-dom'
import { AiOutlineFilePpt } from 'react-icons/ai'
import { MdOndemandVideo } from 'react-icons/md'
import { VscBook } from 'react-icons/vsc'
import { BsQuestionSquare } from 'react-icons/bs'
import { FaClipboardList } from 'react-icons/fa'
import { ExternalLinkIcon } from '@chakra-ui/icons'




const icons = [
    { type: 'document', icon: <AiOutlineFilePpt mr='2' /> },
    { type: 'video', icon: <MdOndemandVideo mr='2' /> },
    { type: 'quiz', icon: <BsQuestionSquare mr='2' /> },
    { type: 'survey', icon: <FaClipboardList mr='2' /> },
    { type: 'link', icon: <ExternalLinkIcon mr='2' /> },
    { type: 'homework', icon: <VscBook mr='2' /> },
]


const ContentCard = ({
    content,
    index,
    progress,
    doctype,
    handleUpdateProgress,
    alertJoincourse,
    openModal,
    setModalDisplay,
    subjectId,
    enroll,
    user,
}) => {
    const iconsContent = icons.find(({ type }) => type === content?.type)

    const { colorMode } = useColorMode()
    const handleCheckProgress = (content, value) => {
        if (!progress) {
            handleUpdateProgress(content, value)
        }
    }

    return (
        <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}  >
            <Grid
                boxShadow={'md'}
                templateRows='repeat(2, 1fr)'
                templateColumns='repeat(8, 1fr)'
                borderWidth={'2px'}
                borderRadius="xl"
            >
                <GridItem rowSpan={2} colSpan={1} bg={'primary.900'} borderLeftRadius="xl" w={'full'} h={'full'} >
                    <Center mt={5}>
                        <Stack>
                            <Flex
                                boxShadow={'lg'}
                                borderWidth="5px"
                                borderColor={colorMode === 'light' ? 'primary.400' : 'brand.700'}
                                w={14}
                                h={14}
                                align={'center'}
                                justify={'center'}
                                color={'primary.900'}
                                rounded={'full'}
                                bg={'white'}
                                mb={1}>
                                <Text fontSize={'18px'} as="samp" >{index + 1}</Text>
                            </Flex>
                        </Stack>
                    </Center>
                </GridItem>
                <GridItem colSpan={7} p='2' bg={colorMode === 'light' ? 'brand.100' : 'brand.700'} borderTopRightRadius="xl">
                    <Heading as='h4' size='md' py="3" ml={'5'}>
                        {content?.name}
                    </Heading>
                    <Text fontSize="sx" as='samp' ml={'5'}>{content?.carddescription}</Text>
                </GridItem>
                <GridItem colSpan={7} p='2' bg={colorMode === 'light' ? 'brand.100' : 'brand.700'} borderBottomRightRadius="xl">
                    <Box py='2'>
                        <Progress value={progress} borderRadius="lg" borderWidth="2px" hasStripe colorScheme='green' />
                    </Box>
                    <Box borderRadius="lg" borderWidth="2px" p={'2'} alignContent={'end'} boxShadow={'md'} bg={colorMode === 'light' ? 'white' : 'brand.700'}>
                        {!enroll ? <>
                            {user?.role == 'ADMIN' || user?.role == 'TEACHER' ?
                                <>
                                    {content?.type == 'document' &&
                                        <Link onClick={() => {
                                            setModalDisplay({ name: content?.name, type: content?.type, link: content?.link, doctype: doctype })
                                            // handleUpdateProgress(content, 100)
                                            openModal()
                                            // handelOpenModalType(type, link, name)
                                        }}   >
                                            <Button w='full' justifyContent="space-between"  >
                                                <HStack spacing='2'>
                                                    {iconsContent?.icon}
                                                    <Text fontSize="15px" as='samp' >
                                                        {content?.name}
                                                    </Text>
                                                </HStack>
                                                <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                            </Button>
                                        </Link>
                                    }{content?.type == 'link' &&
                                        <Link href={content?.link} isExternal
                                        // onClick={() => { handleUpdateProgress(content, 100) }} 
                                        >
                                            <Button w='full' justifyContent="space-between"  >
                                                <HStack spacing='2'>
                                                    {iconsContent?.icon}
                                                    <Text fontSize="15px" as='samp' >
                                                        {content?.name}
                                                    </Text>
                                                </HStack>
                                                <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                            </Button>
                                        </Link>
                                    }{content?.type == 'survey' &&
                                        <Link href={`/subject/survey/${content?._id}`} isExternal
                                        // onClick={() => { handleUpdateProgress(content, 100) }} 
                                        >
                                            <Button w='full' justifyContent="space-between"  >
                                                <HStack spacing='2'>
                                                    {iconsContent?.icon}
                                                    <Text fontSize="15px" as='samp' >
                                                        {content?.name}
                                                    </Text>
                                                </HStack>
                                                <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                            </Button>
                                        </Link>
                                    }{content?.type == 'homework' &&
                                        <Link href={`/subject/sendhomework/${content?._id}`} isExternal >
                                            <Button w='full' justifyContent="space-between"  >
                                                <HStack spacing='2'>
                                                    {iconsContent?.icon}
                                                    <Text fontSize="15px" as='samp' >
                                                        {content?.name}
                                                    </Text>
                                                </HStack>
                                                <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                            </Button>
                                        </Link>
                                    }{content?.type == 'quiz' &&
                                        <Link href={`/subject/questiondetail/${content?._id}/${subjectId}`} isExternal >
                                            <Button w='full' justifyContent="space-between"  >
                                                <HStack spacing='2'>
                                                    {iconsContent?.icon}
                                                    <Text fontSize="15px" as='samp' >
                                                        {content?.name}
                                                    </Text>
                                                </HStack>
                                                <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                            </Button>
                                        </Link>
                                    }{content?.type == 'video' &&
                                        <Link as={domlink} to={`/video/${content?._id}`} isExternal
                                            onClick={() => {
                                                // handleUpdateProgress(content, 0)
                                            }}>
                                            <Button w='full' justifyContent="space-between" >
                                                <HStack spacing='2'>
                                                    {iconsContent?.icon}
                                                    <Text fontSize="15px" as='samp' >
                                                        {content?.name}
                                                    </Text>
                                                </HStack>
                                                <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                            </Button>
                                        </Link>
                                    }
                                </> :
                                <Link onClick={() => { alertJoincourse() }} >
                                    <Button w='full' justifyContent="space-between"  >
                                        <HStack spacing='2'>
                                            {iconsContent?.icon}
                                            <Text fontSize="15px" as='samp' >
                                                {content?.name}
                                            </Text>
                                        </HStack>
                                        <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                    </Button>
                                </Link>
                            }
                        </>
                            :
                            <>
                                {content?.type == 'document' &&
                                    <Link onClick={() => {
                                        setModalDisplay({ name: content?.name, type: content?.type, link: content?.link, doctype: doctype })
                                        handleCheckProgress(content, 100)
                                        openModal()
                                        // handelOpenModalType(type, link, name)
                                    }}   >
                                        <Button w='full' justifyContent="space-between"  >
                                            <HStack spacing='2'>
                                                {iconsContent?.icon}
                                                <Text fontSize="15px" as='samp' >
                                                    {content?.name}
                                                </Text>
                                            </HStack>
                                            <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                        </Button>
                                    </Link>
                                }{content?.type == 'link' &&
                                    <Link href={content?.link} isExternal onClick={() => { handleCheckProgress(content, 100) }} >
                                        <Button w='full' justifyContent="space-between"  >
                                            <HStack spacing='2'>
                                                {iconsContent?.icon}
                                                <Text fontSize="15px" as='samp' >
                                                    {content?.name}
                                                </Text>
                                            </HStack>
                                            <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                        </Button>
                                    </Link>
                                }{content?.type == 'survey' &&
                                    <Link href={`/subject/survey/${content?._id}`} isExternal onClick={() => { handleCheckProgress(content, 100) }} >
                                        <Button w='full' justifyContent="space-between"  >
                                            <HStack spacing='2'>
                                                {iconsContent?.icon}
                                                <Text fontSize="15px" as='samp' >
                                                    {content?.name}
                                                </Text>
                                            </HStack>
                                            <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                        </Button>
                                    </Link>
                                }{content?.type == 'homework' &&
                                    <Link href={`/subject/sendhomework/${content?._id}`} isExternal >
                                        <Button w='full' justifyContent="space-between"  >
                                            <HStack spacing='2'>
                                                {iconsContent?.icon}
                                                <Text fontSize="15px" as='samp' >
                                                    {content?.name}
                                                </Text>
                                            </HStack>
                                            <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                        </Button>
                                    </Link>
                                }{content?.type == 'quiz' &&
                                    <Link href={`/subject/questiondetail/${content?._id}/${subjectId}`} isExternal >
                                        <Button w='full' justifyContent="space-between"  >
                                            <HStack spacing='2'>
                                                {iconsContent?.icon}
                                                <Text fontSize="15px" as='samp' >
                                                    {content?.name}
                                                </Text>
                                            </HStack>
                                            <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                        </Button>
                                    </Link>
                                }{content?.type == 'video' &&
                                    <Link as={domlink} to={`/video/${content?._id}`} isExternal
                                        onClick={() => {
                                            handleCheckProgress(content, 0)
                                        }}>
                                        <Button w='full' justifyContent="space-between" >
                                            <HStack spacing='2'>
                                                {iconsContent?.icon}
                                                <Text fontSize="15px" as='samp' >
                                                    {content?.name}
                                                </Text>
                                            </HStack>
                                            <Text fontSize="15px" as='samp'>{`${progress} %`}</Text>
                                        </Button>
                                    </Link>
                                }
                            </>
                        }
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    );
}
export default ContentCard
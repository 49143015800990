import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'

import * as Survey from "survey-react";
import {
    Box,
    Heading,
    Text,
    Container,
    Button,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    useDisclosure,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Th,
    Icon,
    FormControl,
    FormLabel,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';

import draftToHtml from 'draftjs-to-html'
import renderHTML from 'react-render-html'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import { Link as Routelink } from 'react-router-dom';
import surveybyContentId from '../graphql/queries/surveybyContentId'
import surveybyuserId from "../graphql/queries/surveybyuserId"
import { BiCheckCircle } from "react-icons/bi";
import dayjs from 'dayjs'
import { IoIosAddCircleOutline } from "react-icons/io";


import AuthContext from '../contexts/AuthContext';

const SurveyPage = () => {
    const { user } = useContext(AuthContext)
    const param = useParams()
    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [surveyRender, setSurveyRender] = useState(null)

    const { data: SurveyData } = useQuery(surveybyContentId, {
        variables: {
            _id: param?.contentId,
        },
    })

    const { data: SurveyUser, refetch: refetchSubmit } = useQuery(surveybyuserId, {
        variables: {
            student: user?._id,
            content: param?.contentId,
        },
    })

    useEffect(() => {
        if (localStorage.getItem('refetchPage') === 'true') {
            // console.log("refetPage")
            localStorage.setItem('refetchPage', 'false')
            refetchSubmit({
                student: user?._id,
                content: param?.contentId,
            })
        }
    }, [])

    const handleConvertHTML = (text) => {
        if (text) {
            return renderHTML(draftToHtml(text))
        }
        return ''
    }

    const statusAlert = (status) => {
        if (status == 'submit') {
            return (<Alert status='success'>
                <AlertIcon />
                {t('SurveyPage.submit')}
            </Alert>)
        }
    }


    useEffect(() => {
        console.log("EFFECT")
        if (surveyRender === null && SurveyUser && SurveyData) {
            console.log("Survey render")
            var survey = <Survey.Survey
                json={{ questions: SurveyData?.content?.surveyconfig?.surveyQuestions?.questions }}
                data={SurveyUser?.survey?.surveyAnswers}
                showCompletedPage={false}
                mode="display"
            />
            setSurveyRender(survey)
        }
    }, [surveyRender, SurveyData, SurveyUser])
    console.log(SurveyUser?.survey)
    return (
        <Fragment>
            <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('QuizPage.Checkanswers')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="">
                            <FormLabel>{SurveyData?.content?.name}</FormLabel>
                            {SurveyData?.content?.surveyconfig?.description ? <FormLabel p={2}>{handleConvertHTML(SurveyData?.content?.surveyconfig?.description)}</FormLabel> : null}
                            {surveyRender}
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button bg={'primary.700'} color={'white'}
                            _hover={{
                                bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                            }} mr={3} onClick={onClose}>
                            {t('QuizPage.close')}
                        </Button>
                        {/* <Button variant="ghost" onClick={submitClickHandler}>
							Submit
						</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box w="100%" px={"20"} >
                <Container maxW={'8xl'} py={16} as={Stack} spacing={12} minHeight={'90vh'}>
                    <Box w="100%">
                        <Heading size="xl" > {SurveyData?.content?.name}  </Heading>
                        <Text mt={1} ml={1}>{handleConvertHTML(SurveyData?.content?.surveyconfig?.description)} </Text>
                        <Box mt={5}>
                            <Center>
                                <Box>
                                    {!SurveyUser?.survey ?
                                        <Button
                                            leftIcon={<IoIosAddCircleOutline />} colorScheme='red' variant='solid'
                                            as={Routelink}
                                            target='_blank'
                                            to={`/subject/survey/${param?.contentId}`}
                                        >
                                            {t('SurveyPage.startsurvey')}
                                        </Button>
                                        :
                                        <Box>
                                            <Button leftIcon={<BiCheckCircle />} colorScheme='teal' variant='solid' onClick={onOpen}>
                                                {t('SurveyPage.result')}
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                            </Center>
                        </Box>
                        <Box mt={10} sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} border="10px solid" borderColor="gray.100" borderRadius="md">
                            <Table variant='striped' >
                                <Thead>
                                    <Tr>
                                        <Td> {t('SurveyPage.sendDate')}</Td>
                                        {SurveyUser?.survey?.createdAt ? <Td>{dayjs(SurveyUser?.survey?.createdAt).format('DD/MM/YYYY ,  HH:mm:ss')}</Td> : <Td>-</Td>}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>{t('QuestionPage.status')}</Td>
                                        <Td>
                                            {statusAlert(SurveyUser?.survey?.status)}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Fragment>
    );


}
export default SurveyPage;
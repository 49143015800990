import React, { Fragment, useState, useEffect, useContext } from "react";
import './style.css'
import {
    Box,
    Container,
    Stack,
    Text,
    Heading,
    Button,
    Table,
    Tr,
    Td,
    Center,
    Tooltip,
    useToast,
    GridItem,
    Link,

    Tbody,
    Thead,
    useColorModeValue,
} from "@chakra-ui/react";
import {
    FaTrashAlt,
} from "react-icons/fa";

import axios from "axios";
import HomeworkQuery from "../graphql/queries/homeworkbyID";
import { useParams } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'

import AlertDialog from '../components/pages/SendHomework/AlertDialogHomework'



import ContentbyIdQueries from "../graphql/queries/contentbyId"
import HomeworkRemoveMutation from "../graphql/mutations/removeHomework";
import { useMultiState } from "../lib/hooks";
import { useTranslation } from 'react-i18next'
const URL_DELETE = process.env.REACT_APP_DELETE_HOMEWORK



const HomeworkPage = () => {
    const { t } = useTranslation()
    const toast = useToast();
    const param = useParams();
    const history = useHistory()
    const { user } = useContext(AuthContext)

    const [setHomeworkRemoveMutation] = useMutation(HomeworkRemoveMutation);

    const { data: HomeworkData, refetch, loading } = useQuery(HomeworkQuery, {
        variables: {
            content: param?.contentId,
            student: param?.studentId,
        }
    })

    const { data: ContentData } = useQuery(ContentbyIdQueries, {
        variables: {
            _id: param?.contentId,
        },
    });

    useEffect(() => {
        if (user?.role == 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }
        if (loading) {
            refetch({
                teacher: user?._id,
            })
        }
    }, [history, user, refetch])

    //Alert dialog remove Score
    const initialRemoveHomework = {
        rehomework: '',
        refirstname: '',
        relastname: '',
        retypefile: '',
        refilename: '',
        reid: '',
    }

    const [showRemoveHomework, setShowRemoveHomework] = useState(false)
    const [removeHomeworkIndex, setRemoveHomeworkIndex] = useMultiState(initialRemoveHomework)

    const handleOpenRemoveHomework = async (homework, firstname, lastname, typefile, filename, id) => {
        setRemoveHomeworkIndex({ rehomework: homework, refirstname: firstname, relastname: lastname, retypefile: typefile, refilename: filename, reid: id })
        setShowRemoveHomework(true)
    }

    const handleCloseRemoveHomework = () => {
        setShowRemoveHomework(false)
    }
    const handleRemovehomework = async (e) => {
        e.preventDefault();
        try {
            const { data: responseRemoveHomwork } = await setHomeworkRemoveMutation({
                variables: {
                    _id: removeHomeworkIndex?.reid,
                },
            })
            if (responseRemoveHomwork) {
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('homeworkDetail.alertDeleteComplete')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    content: param?.contentId,
                    student: param?.studentId,
                })
            }
            setRemoveHomeworkIndex(initialRemoveHomework)
            handleCloseRemoveHomework()
        } catch (error) {
            console.log(error);
        }
    };

    // console.log(param?.contentId);
    // console.log("asdasd", ContentData);


    return (
        <Fragment>
            <Box w="100%" px={[3, 10, 20, 20]} minHeight={'90vh'}>
                <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
                    <Stack spacing={0} align={"center"}>
                        <Heading variant="h4">{t('homeworkDetail.title')}</Heading>
                        <Text>
                            {/* {t('homeworkDetail.subtitle')} {ContentData?.contents[0]?.homeworkconfig?.name} */}
                        </Text>
                    </Stack>
                </Container>
                <Stack spacing={3}>
                    <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['none'] }} p={1} borderWidth={'1px'} borderRadius={'lg'}>
                        <Table variant='simple'>
                            <Thead bg={'gray.400'}>
                                <Tr>
                                    <Td textAlign="center">{t('homeworkDetail.name_surname')}</Td>
                                    <Td textAlign="center">{t('homeworkDetail.manager')}</Td>
                                </Tr>
                            </Thead>
                            {HomeworkData?.homeworks?.length != 0 &&
                                <Tbody bg={'gray.200'}>
                                    {HomeworkData?.homeworks?.map((homework) => (
                                        <Tr key={homework?._id}>
                                            <Td textAlign="center">
                                                <Link href={`/managehomework/${homework?.student?._id}/${param?.contentId}/${param?.subjectId}`} isExternal mb={1} >
                                                    {homework?.student?.firstname} {homework?.student?.lastname}
                                                </Link>
                                            </Td>
                                            <Td textAlign="center" maxW={'100px'}>
                                                <Tooltip label={t('homeworkDetail.delete')} fontsize="md">
                                                    <Button onClick={() => { handleOpenRemoveHomework(ContentData?.content?.name, homework?.student?.firstname, homework?.student?.lastname, ContentData?.content?.homeworkconfig?.typefile, homework?.file, homework?._id) }} >
                                                        <FaTrashAlt />
                                                    </Button>
                                                </Tooltip>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            }
                        </Table>
                        {HomeworkData?.homeworks?.length == 0 &&
                            <Box align="center" w={'full'} py={'20'} color={'gray.500'} bg={'gray.200'}><Heading>{t('homeworkDetail.noHomework')}</Heading></Box>
                        }
                    </Box>
                </Stack>
            </Box>
            <AlertDialog showRemoveHomework={showRemoveHomework} handleCloseRemoveHomework={handleCloseRemoveHomework} handleRemovehomework={handleRemovehomework} />
        </Fragment>
    )
}

export default HomeworkPage;
import gql from "graphql-tag";

export default gql`
mutation ($content: MongoID, $student: MongoID, $surveyAnswers: JSON, $status:EnumSurveyStatus) {
    createSurvey(record: {
        content: $content
        student: $student
        surveyAnswers: $surveyAnswers
        status: $status
    }) {
        recordId
    }
}`

import { useTranslation } from 'react-i18next'
import {
    Box,
    Image,
    Stack,
    Tooltip,
    Link,
    IconButton,
    Avatar,
    Flex,
    useColorMode,
    Text,
    Tag,
    TagLabel,
} from '@chakra-ui/react'

import { ExternalLinkIcon, } from '@chakra-ui/icons'

const CardUser = ({ student, }) => {
    const { colorMode } = useColorMode()

    const { t } = useTranslation()
    return (
        <Box
            p={5}
            display={'flex'}
            w={['100%', '100%', '300px', '430px']}
            bg={colorMode === 'light' ? 'brand.100' : 'brand.600'}
            color={colorMode === 'light' ? 'brand.600' : 'brand.100'}
            boxShadow={'lg'}
            borderWidth={'2px'}
            borderRadius={'15px'}
            position={'relative'}
            borderColor={colorMode === 'light' ? 'brand.300' : 'brand.500'}
        >
            <Link
                href={`/showprofile/${student?.student?._id}`}
                position={'absolute'}
                left={'385px'}
                top={'6px'}
                isExternal
            >
                <Tooltip label={`view profile`} fontsize="md">
                    <IconButton
                        size={'lg'}
                        // size={['sx', 'md']}
                        variant='link'
                        colorScheme={colorMode === 'light' ? 'brand.600' : 'brand.100'}
                        aria-label='Call Sage'
                        icon={<ExternalLinkIcon />}
                    />
                </Tooltip>
            </Link>
            <Flex
                flexGrow={1}
            >
                <Avatar
                    borderWidth={'2px'}
                    borderColor={colorMode === 'light' ? 'brand.300' : 'brand.100'}
                    objectFit='cover'
                    zIndex={1}
                    name={student?.student?.firstname ? `${student?.student?.firstname} ${student?.student?.lastname}` : 'Profile'}
                    size={['lg']}
                    src={student?.avatar}
                    alt="กรุณาเลือกรูปภาพ" />
            </Flex>
            <Flex flexGrow={2}
                direction='column'
                minW={'300px'}
            >
                <Text fontWeight={600} as='b' fontSize='xl'>
                    {`${student?.student?.firstname} ${student?.student?.lastname}`}
                </Text>
                <Text fontWeight={600} fontSize='sm'>
                    {`email: ${student?.student?.email}`}
                </Text>
                <Text fontWeight={600} fontSize='sm'>
                    {`mobile: ${student?.student?.mobile}`}
                </Text>
                <Text fontWeight={600} fontSize='sm' display={'flex'} >
                    {`request:`}
                    {student.certificatestatus &&
                        <Tag
                            // flexGrow={1}
                            ml={3}
                            w={'90px'}
                            borderRadius='full'
                            boxShadow={'lg'}
                            variant='solid'
                            colorScheme={'green'}
                            py={1}
                            justifyContent={"center"}
                            color={'brand.100'}
                        >
                            <TagLabel>{`Certificate`}</TagLabel>
                        </Tag>}

                    {student.creditstatus &&
                        <Tag
                            // flexGrow={1}
                            ml={3}
                            w={'90px'}
                            borderRadius='full'
                            boxShadow={'lg'}
                            variant='solid'
                            colorScheme={'orange'}
                            py={1}
                            justifyContent={"center"}
                            color={'brand.100'}
                        >
                            <TagLabel>{`Cradit`}</TagLabel>
                        </Tag>
                    }
                </Text>
            </Flex>
        </Box>
    )
}

export default CardUser
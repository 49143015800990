import React, { Fragment, useContext, useEffect, useState } from 'react';
import axios from "axios";
import { useTranslation } from 'react-i18next'
import {
    Box,
    Text,
    Button,
} from '@chakra-ui/react';

import { useParams } from 'react-router'
import { useMultiState } from '../lib/hooks'
import { useQuery } from '@apollo/client'
import renderHTML from 'react-render-html'
import certificatebyid from '../graphql/queries/certificatebyid';


const CertificatePage = () => {
    const URL_CERTIFICATE = process.env.REACT_APP_CERTIFICATE


    const [loading, setLoading] = useState(false)
    const param = useParams()
    // const { user } = useContext(AuthContext)
    // const { data: certificate, loading, refetch } = useQuery(certificatebyid)
    // const { isOpen, onOpen, onClose } = useDisclosure()
    // const [subjectsState, setSubjectsState] = useState([])
    // const { t } = useTranslation()
    const [certificateOptions, setCertificateOptions] = useMultiState({
        width: '29.7cm',
        height: '21cm',
        position: 'Learning Lead'
    })

    const { data: certificate } = useQuery(certificatebyid, {
        variables: {
            _id: param?.certificateId,
        },
    })


    // useEffect(() => {
    // if (certificate) {
    //     setCertificate({ name: CertificateData?.subject?.certificateconfig?.name, description: CertificateData?.subject?.certificateconfig?.description })
    //     setUpdateCertificateForm({ name: CertificateData?.subject?.certificateconfig?.name, description: CertificateData?.subject?.certificateconfig?.description })
    //     setList(CertificateData?.subject?.certificateconfig?.certificateCondition)
    // }
    // if (user?.role === 'STUDENT') {
    //     setTimeout(() => {
    //         history.push('/login')
    //     }, 100)
    // }

    // let arr = []
    // if (ContentData) {
    //     ContentData?.chapters.map((content) => {
    //         content?.contents.map((data) => {
    //             arr.push(data)
    //         })
    //     })
    //     setContent(arr)
    // }

    // }, [history, CertificateData, user, refetch])


    useEffect(() => {
        if (certificate) {
            let template = certificate?.studentofsubjectId?.subject?.certificateconfig
            // console.log(template?.name)
            setCertificateOptions({ timestatus: template?.timestatus })
            setCertificateOptions({ typesign: template?.signature?.typesign })
            setCertificateOptions({ name: certificate?.studentofsubjectId?.name })
            if (template?.timestatus == 'set') {
                setCertificateOptions({ certificatetime: template?.certificatetime })
            } else {
                setCertificateOptions({ certificatetime: null })
            }
            if (template?.backgroundURL) {
                setCertificateOptions({ background: template?.backgroundURL })
            } else {
                setCertificateOptions({ background: 'https://ppi-course.com/images/5.png' })
            }
            if (template?.logoURL) {
                setCertificateOptions({ logo: template?.logoURL })
            } else {
                setCertificateOptions({ logo: 'http://localhost:3000/images/psuLogo.png', })
            }
            if (template?.signature?.firstname) {
                setCertificateOptions({ firstname: template?.signature?.firstname })
            } else {
                setCertificateOptions({ firstname: '-' })
            }
            if (template?.signature?.lastname) {
                setCertificateOptions({ lastname: template?.signature?.lastname })
            } else {
                setCertificateOptions({ lastname: '-' })
            }
            if (template?.signature?.typesign == 'signature') {
                setCertificateOptions({ signature: template?.signatureURL })
            } else {
                setCertificateOptions({
                    signature: 'https://camo.githubusercontent.com/805e05b94844e39d7edd518f492c8599c71835b3/687474703a2f2f692e696d6775722e636f6d2f646e5873344e442e706e67'
                })
            }
        }
    }, [certificate])

    // console.log(certificateOptions?.name)


    // const [curItems, setCurItems] = useState([]);

    // const itemLimit = 8;

    // const [pagesQuantity, setPagesQuantity] = useState(0);
    // const [curPage, setCurPage] = useState(0);


    // const handlePageChange = (page) => {
    //     setCurPage(page - 1);
    // };


    // console.log('subject', subjectData);
    // useEffect(() => {
    //     const pagesTotal = Math.ceil(subjectData?.subjects?.length / itemLimit);
    //     setPagesQuantity(pagesTotal);

    //     if (subjectData) {
    //         setSubjectsState(subjectData?.subjects ? subjectData?.subjects : [])
    //     }
    //     if (localStorage.getItem('refetchPage') !== true) {
    //         // console.log("refetPage")
    //         localStorage.setItem('refetchPage', false)
    //         refetch()
    //     }
    // }, [subjectData])
    // // console.log('ssss', state);


    ////////////////Certificate Template///////////////////////////////
    // console.log(certificateOptions)
    const html = `<!doctype html>
        <html lang="en-US">
        <head>
            <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
            <title>Ceart</title>
            <link href="https://fonts.googleapis.com/css?family=Satisfy" rel="stylesheet">
            <meta name="description" content="Reset Password Email Template.">
            <style type="text/css">
                @media print {
                    html,
                    body {
                        margin: 0;
                    }
                }

                a:hover {
                    text-decoration: underline !important;
                }

                .cert {
                    border: 13px solid #0072c6 !important ;
                    border-right: 13px solid #0894fb;
                    border-left: 13px solid #0894fb;
                    width: 200mm;
                    height: 94mm;
                    font-family: arial;
                    color: #383737;
                    background-image: url(${certificateOptions?.background});
                    background-repeat: no-repeat;
                    background-size: cover;
                    size: A4 landscape;
                    margin: 0;
                }

                .crt_title {
                    margin-top: 20px;
                    font-family: "Satisfy", cursive;
                    font-size: 40px;
                    letter-spacing: 1px;
                    color: #0060a9;
                }

                .crt_logo img {
                    width: 130px;
                    height: auto;
                    margin: auto;
                    padding: 10px;
                }

                .colorGreen {
                    color: #27ae60;
                }

                .crt_user {
                    display: inline-block;
                    width: 80%;
                    padding: 5px 25px;
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                    font-family: "Satisfy", cursive;
                    font-size: 40px;
                    border-bottom: 1px dashed #cecece;
                }

                .afterName {
                    font-weight: 100;
                    color: #383737;
                }

                .colorGrey {
                    color: grey;
                }

                .certSign {
                    width: 200px;
                }

                html,
                body {
                    margin: 0;
                }
            </style>
        </head>

        <body>
            <table class="cert">
                <tr>
                    <td align="center" class="crt_logo">
                        <img src="${certificateOptions?.logo}" alt="logo">

                    </td>
                </tr>
                <tr>
                    <td align="center">
                        <h1 class="crt_title">Certificate Of Completion
                            <h2>This Certificate is awarded to</h2>
                            <h1 class="colorGreen crt_user">${certificateOptions?.name}</h1>
                            <h3 class="afterName">For his/her completion of HSE Awareness session
                            </h3>
                            <h3>Awarded on 1 March 2020 </h3>
                    </td>
                </tr>
                <tr>
                    <td align="center">
                        ${certificateOptions?.typesign === 'signature' ? (`<img src=${certificateOptions?.signature} class="certSign" alt="sign" />`) : (`<h3 style="font-style: italic;" class="certSign">${certificateOptions?.firstname} ${certificateOptions?.lastname}</h3>`)}
                        <h3>(${certificateOptions?.firstname} ${certificateOptions?.lastname})</h3>
                        <h3>${certificateOptions?.position}</h3>
                        <h3>Department Name</h3>
                    </td>
                </tr>
            </table>
        </body>
        </html>`


    const htmm = `<!doctype html>
    <html lang="en-US">
    
    <head>
        <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
        <title>Ceart</title>
        
        <meta name="description" content="Reset Password Email Template.">
        <style type="text/css">
            @media print {
                html,
                body {
                    margin: 0;
                }
            }
    
            a:hover {
                text-decoration: underline !important;
            }
    
            .cert {
                border: 13px solid #0072c6 !important;
                border-right: 13px solid #0894fb;
                border-left: 13px solid #0894fb;
                width: 29.7cm;
                height: 21cm;
                font-family: arial;
                color: #383737;
                background-image: url(${certificateOptions?.background});
                background-repeat: no-repeat;
                background-size: cover;
                size: A4 landscape;
                margin: 0;
            }
    
            .crt_title {
                margin-top: 20px;
                
                font-size: 40px;
                letter-spacing: 1px;
                color: #0060a9;
            }
    
            .crt_logo img {
                width: 130px;
                height: auto;
                margin: auto;
                padding: 10px;
            }
    
            .colorGreen {
                color: #27ae60;
            }
    
            .crt_user {
                display: inline-block;
                width: 80%;
                padding: 5px 25px;
                margin-bottom: 0px;
                padding-bottom: 0px;
                
                font-size: 40px;
                border-bottom: 1px dashed #cecece;
            }
    
            .afterName {
                font-weight: 100;
                color: #383737;
            }
    
            .colorGrey {
                color: grey;
            }
    
            .certSign {
                width: 200px;
            }
    
            html,
            body {
                margin: 0;
            }
        </style>
    </head>
    
    <body>
        <table class="cert">
            <tr>
                <td align="center" class="crt_logo">
                    <img src="${certificateOptions?.logo}" alt="logo">
    
                </td>
            </tr>
            <tr>
                <td align="center">
                    <h1 class="crt_title">Certificate Of Completion
                        <h2>This Certificate is awarded to</h2>
                        <h1 class="colorGreen crt_user">${certificateOptions?.name}</h1>
                        <h3 class="afterName">For his/her completion of HSE Awareness session
                        </h3>
                        <h3>Awarded on 1 March 2020 </h3>
                </td>
            </tr>
            <tr>
                <td align="center">
                ${certificateOptions?.typesign === 'signature' ? (`<img src=${certificateOptions?.signature} class="certSign" alt="sign" />`) : (`<h3 style="font-style: italic;" class="certSign">${certificateOptions?.firstname} ${certificateOptions?.lastname}</h3>`)}
                    <h3>(${certificateOptions?.firstname} ${certificateOptions?.lastname})</h3>
                    <h3>${certificateOptions?.position}</h3>
                </td>
            </tr>
        </table>
    </body>
    
    </html>`
    ////////////////Certificate Template///////////////////////////////

    var fileDownload = require('js-file-download');
    const handleConvert = async () => {
        setLoading(true)
        // console.log('ffff');
        const response = await axios.post(URL_CERTIFICATE, { content: htmm, format: 'A4' }, { responseType: "blob" })
        await fileDownload(response?.data, 'Certificate.pdf');
        setLoading(false)
    }

    return (
        <Fragment w="100%" px={'20'} >
            <Box align='center' mt={'20'} minHeight={'80vh'}>
                <Button colorScheme='teal' variant='solid' minWidth={'50vh'} disabled={loading} onClick={handleConvert}>{!loading ? "Dowload" : "Downloading..."}</Button>
                <Box
                    position="relative"
                    w="100%"
                    h='10.5cm'
                    mt='25px'>
                    {renderHTML(html)}
                </Box>
            </Box>
        </Fragment>
    );
};

export default CertificatePage;
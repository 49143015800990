import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
    Box,
    Flex,
    useColorModeValue,
    Avatar,
    Heading,
    Stack,
    Text,
    Image,
    Spacer,
    Center,
    HStack,
    VStack,
    Skeleton,

} from "@chakra-ui/react";
import { CgChevronLeft, CgChevronRight, CgMail, CgPhone } from 'react-icons/cg';
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons'
import AuthContext from "../contexts/AuthContext";
import { useMultiState } from "../lib/hooks";
import { useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router';
import subjects from "../graphql/queries/subjectsbyteacherId";
import ProfileList from "../components/ProfileList"
import userbyID from '../graphql/queries/userbyID';


import {
    Previous,
    Paginator,
    PageGroup,
    Page,
    Next,
    generatePages,
} from 'chakra-paginator';
import { useTranslation } from 'react-i18next'


const ShowProfilePage = () => {
    const { t } = useTranslation()
    const bg = useColorModeValue('white', 'gray.900')
    const cardbg = useColorModeValue("gray.50", "inherit")
    const cardbox = useColorModeValue("gray.200", "whiteAlpha.200")
    const cs = useColorModeValue('gray.700', 'gray.400')


    const history = useHistory()
    const { user } = useContext(AuthContext)
    const params = useParams()
    const [profileUser, setProfileUser] = useMultiState()

    const { data: subjectData } = useQuery(subjects, {
        variables: {
            teacher: params?.userId
        },
    })

    const { data: profile, loading } = useQuery(userbyID, {
        variables: {
            _id: params?.userId
        },
    })

    const [subjectState, setSubjectState] = useState([])


    const itemLimit = 4;

    const [pagesQuantity, setPagesQuantity] = useState(0);
    const [curPage, setCurPage] = useState(0);


    const normalStyles = {
        bg: 'white'
    };

    const activeStyles = {
        bg: 'blue.300'
    };

    const handlePageChange = (page) => {
        setCurPage(page - 1);
    };
    // console.log(pagesQuantity)
    useEffect(
        () => {
            const pagesTotal = Math.ceil(subjectData?.subjects?.length / itemLimit);
            setPagesQuantity(pagesTotal);
            setProfileUser(profile?.userId)
            if (subjectData) {
                setSubjectState(subjectData?.subjects ? subjectData?.subjects : [])
            }
        },
        [history, user, subjectData, subjectData?.subjects?.length, curPage, itemLimit, profile],
    )
    // console.log(profileUser)
    return (
        <Fragment>

            <Box bg={cardbg} p={10} mt={7}>
                <Stack align={{ base: 'center', md: 'flex-start' }}>
                    <Heading size="xl" >{t('showprofilePage.firstTitle')}</Heading>
                </Stack>
                <Box visibility={{ base: "hidden", sm: "visible" }} aria-hidden="true">
                    <Box py={2}>
                        <Box
                            borderTop="solid 1px"
                            borderTopColor={cardbox}
                        >

                        </Box>
                    </Box>
                </Box>
                {loading ?
                    <Box w="full" py={2}>
                        <Center>
                            <Stack
                                borderWidth="1px"
                                borderRadius="lg"
                                alignItems="center"
                                w={{ sm: '100%', md: '100%', lg: '100%' }}
                                // h={{ sm: '100%', md: '50%', lg: '100%' }}
                                direction={{ base: 'column', md: 'row' }}
                                bg={bg}
                                boxShadow={'2xl'}
                            >
                                <Skeleton w='full' p={100} />
                            </Stack>
                        </Center>
                    </Box>
                    :
                    <Box w="full" py={2}  >
                        <Center>
                            <Stack
                                minHeight={{ sm: '50vh', md: '80vh' }}
                                minWidth={{ sm: '60vh', md: '70vh' }}
                                borderWidth="1px"
                                borderRadius="lg"
                                alignItems="center"
                                // w={{ sm: '100%', md: '100%', lg: '100%' }}
                                // h={{ sm: '100%', md: '50%', lg: '100%' }}
                                direction={{ base: 'column', md: 'row' }}
                                bg={bg}
                                boxShadow={'2xl'}
                                p={4}>
                                {/* <Flex flex={1} bg="blue.200"> */}
                                <Stack
                                    // flex={1}
                                    // boxShadow={'2xl'}
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    w={{ sm: '70%', md: '50%', lg: '30%' }}
                                    minH={"50%"}
                                    minW={"50%"}
                                    color={'white'}
                                    p={5}
                                >
                                    {profileUser?.avatar ?
                                        <Avatar size='2xl'
                                            borderRadius="xl"
                                            boxShadow={'dark-lg'}
                                            objectFit="cover"
                                            boxSize="100%"
                                            src={profileUser?.avatar}
                                        /> : <Avatar size='2xl'
                                            borderRadius="xl"
                                            boxShadow={'dark-lg'}
                                            objectFit="cover"
                                            boxSize="100%"
                                            bgGradient={'linear(to-r, blackAlpha.300, transparent)'}
                                            src={"../images/avatar.png"}
                                        />}
                                </Stack>
                                <Stack
                                    w={"full"}
                                    justifyContent="center"
                                    alignItems="center"
                                    py={10}>
                                    <Heading fontSize={'2xl'} fontFamily={'body'}>
                                        {`${profileUser?.firstname}  ${profileUser?.lastname}`}
                                    </Heading>
                                    <HStack spacing='10px'>
                                        {profileUser?.email && <>
                                            <EmailIcon color={'gray.500'} />
                                            <Text fontWeight={600} color={'gray.500'} size="sm" mb={4} >
                                                {profileUser?.email}
                                            </Text>
                                        </>}
                                        {profileUser?.mobile && <>
                                            <PhoneIcon w={5} h={3} color={'gray.500'} />
                                            <Text fontWeight={600} color={'gray.500'} size="sm" mb={4}>{profileUser?.mobile}</Text>
                                        </>}
                                    </HStack>
                                    <VStack>
                                        <Box>
                                            <Text
                                                textAlign={'center'}
                                                color={cs}
                                                px={3}>
                                                {profileUser?.description}
                                            </Text>
                                        </Box>
                                    </VStack>
                                </Stack>
                            </Stack>
                        </Center>
                    </Box>
                }
            </Box>


            <Box bg={useColorModeValue("gray.50", "inherit")} p={10} py={7}>
                {profileUser?.role === 'TEACHER' ?
                    <Box>
                        <ProfileList subjectState={subjectState} curPage={curPage} itemLimit={itemLimit} />
                        {pagesQuantity !== 0 ? <Flex p={2}>
                            <Spacer />
                            <Paginator
                                onPageChange={handlePageChange}
                                pagesQuantity={pagesQuantity}>
                                <Previous bg={bg}>
                                    <CgChevronLeft />
                                </Previous>
                                <PageGroup>
                                    {generatePages(pagesQuantity)?.map((page) => (
                                        <Page
                                            key={`paginator_page_${page}`}
                                            page={page}
                                            normalStyles={normalStyles}
                                            activeStyles={activeStyles}
                                        />
                                    ))}
                                </PageGroup>
                                <Next bg={bg}>
                                    <CgChevronRight />
                                </Next>
                            </Paginator>
                        </Flex> : <Text fontSize={'30'} mt={3} color={'gray.500'}>{t('courseteacherPage.alt')}</Text>
                        }
                    </Box> : null
                }
            </Box>
            <Box h='70px'></Box>
        </Fragment >
    );
}
export default ShowProfilePage;
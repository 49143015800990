import React, { Fragment, useState, useContext, useEffect } from 'react'
import { Link as RouteLink } from "react-router-dom";

import {
	Box,
	Link,
	Container,
	Stack,
	Text,
	Heading,
	Button,
	Table,
	IconButton,
	Tr,
	Skeleton,
	Th,
	Td,
	Tbody,
	Center,
	Input,
	FormControl,
	FormLabel,
	Tooltip,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	useDisclosure,
	GridItem,
	useToast,
	Thead,
	HStack,
	Avatar,
	FormErrorMessage,
	Flex,
	Menu,
	MenuButton,
	MenuList,
	MenuItem
} from '@chakra-ui/react'
import {
	AutoComplete,
	AutoCompleteInput,
	AutoCompleteItem,
	AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa"
import { BiUserPlus } from 'react-icons/bi'
import { DragHandleIcon } from '@chakra-ui/icons'
import { EditIcon, DownloadIcon } from '@chakra-ui/icons'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import { MdClose } from 'react-icons/md'
import { useMutation, useQuery } from '@apollo/client'
import { useMultiState } from '../lib/hooks'
import { useTranslation } from 'react-i18next'
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'
import createTeachertMutation from '../graphql/mutations/createTeacherUser'
import UpdateTeacherMutaion from "../graphql/mutations/updateTeacherUser"
import RemoveMutaion from "../graphql/mutations/removeTeacherUser"
import setPasswordMutation from '../graphql/mutations/setPassword'
import teachersQuery from '../graphql/queries/teachers';
import axios from "axios";

import DialogRemove from '../components/commons/DialogRemove'


const initialTeacher = {
	_id: '',
	username: '',
	firstname: '',
	lastname: '',
	mobile: '',
	email: '',
	avatar: '',
	password: '',
	confirm_pass: '',
	confirm_email: '',
}

const URL = process.env.REACT_APP_UPLOAD_IMAGE
const ManageTeacherPage = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const toast = useToast()
	const { user } = useContext(AuthContext)

	const [setTeacherUser] = useMutation(createTeachertMutation)
	const [setPassword] = useMutation(setPasswordMutation)
	const [updateMutation] = useMutation(UpdateTeacherMutaion)
	const [removeMutation] = useMutation(RemoveMutaion)
	const { data: teacherData, refetch, loading } = useQuery(teachersQuery)
	const [teacherState, setTeacherState] = useState([])
	const [teacher, setTeacher] = useState(initialTeacher)


	const { isOpen: updateDialogStatus, onOpen: openUpdateDialogStatus, onClose: closeUpdateDialogStatus } = useDisclosure()
	const { isOpen: createDialogStatus, onOpen: openCreateDialogStatus, onClose: closeCreateDialogStatus, } = useDisclosure()
	const { isOpen: removeDialogStatus, onOpen: openRemoveDialogStatus, onClose: closeRemoveDialogStatus } = useDisclosure()

	const { isOpen: onshowToggle, onToggle, onClose: onclose, } = useDisclosure()

	const {
		isOpen: isAddTeacherModal,
		onOpen: onAddTeacherModalOpen,
		onClose: onAddTeacherModalClose,
	} = useDisclosure()

	useEffect(() => {
		if (teacherData) {
			setTeacherState(teacherData?.teacherUsers ? teacherData?.teacherUsers : [])
		}
		if (user?.role !== 'ADMIN') {
			setTimeout(() => {
				history.push('/login')
			}, 100)
		}
		if (loading) {
			refetch({
				user: user?._id,
			})
		}

	}, [teacherData, history, user, refetch]);


	const [fileavatar, setFileAvatar] = useMultiState({ data: null, base64: '' })


	const [dataTeacher, setdataTeacher] = useMultiState(initialTeacher)
	const [updateTeacher, setupdateTeacher] = useMultiState(initialTeacher)

	const selectAvatarFile = (e) => new Promise((resolve, reject) => {
		e.preventDefault()
		const file = e.currentTarget.files[0]
		setFileAvatar({ data: file })
		const reader = new FileReader()
		reader.readAsDataURL(file)
		console.log("ACTIVE")
		reader.onload = () => {
			resolve(reader.result)
			setFileAvatar({ base64: reader.result })
		}
		reader.onerror = (error) => reject(error)
	})
	const [updateIndex, setUpdateIndex] = useState('')

	const handleEditOpen = (e) => {
		onAddTeacherModalOpen(true)
		setUpdateIndex(e)
	}

	const handleAddTeacher = async (e) => {
		e.preventDefault()
		if (!dataTeacher?.username) {
			await toast({
				position: "top",
				title: "Warning",
				description: `${t('ManageTeacherPage.altwarningUsername')}`,
				status: 'warning',
				duration: 6000,
				isClosable: true,
			})
		} else if (!dataTeacher?.firstname) {
			await toast({
				position: "top",
				title: "Warning",
				description: `${t('ManageTeacherPage.altwarningName')}`,
				status: 'warning',
				duration: 6000,
				isClosable: true,
			})
		} else if (!dataTeacher?.lastname) {
			await toast({
				position: "top",
				title: "Warning",
				description: `${t('ManageTeacherPage.altwarningLastname')}`,
				status: 'warning',
				duration: 6000,
				isClosable: true,
			})
		} else {
			try {
				const response = await axios.post(URL, { photo: fileavatar.base64 })
				console.log("TTTT", response)
				const { data: teacherUserdata } = await setTeacherUser({
					variables: {
						firstname: dataTeacher?.firstname,
						lastname: dataTeacher?.lastname,
						mobile: dataTeacher?.mobile,
						username: dataTeacher?.username,
						email: dataTeacher?.email,
						avatar: response ? response?.data?.url : '',
					},
				})
				const { data: passworddata } = await setPassword({
					variables: {
						_id: teacherUserdata?.createTeacherUser?.recordId,
						password: dataTeacher?.password,
					},
				})
				if (teacherUserdata && passworddata) {
					await toast({
						position: "top",
						title: 'Success',
						description: `${t('ManageTeacherPage.toastAddTeacherSuccess')}`,
						status: 'success',
						duration: 6000,
						isClosable: true,
					})
					setFileAvatar({ data: null, base64: '' })
					setdataTeacher(initialTeacher)
					onclose(true)
					refetch({
						user: user?._id,
					})
				}
			} catch (Error) {
				toast({
					position: "top",
					title: "ERROR",
					description: `${Error?.graphQLErrors[0]?.message}`,
					status: "error",
					duration: 6000,
					isClosable: true,
				})
			}
		}
	}

	const handleUpdateTeacher = async (e) => {
		e.preventDefault();
		console.log(teacherData?.teacherUsers[updateIndex]?.avatar)
		const response = await axios.post(URL, { photo: fileavatar.base64 })
		try {
			const { data: TeacherAddData } = await updateMutation({
				variables: {
					_id: updateTeacher?._id,
					firstname: updateTeacher?.firstname,
					lastname: updateTeacher?.lastname,
					mobile: updateTeacher?.mobile,
					username: updateTeacher?.username,
					email: updateTeacher?.email,
					avatar: teacherData?.teacherUsers[updateIndex]?.avatar || teacherData?.teacherUsers[updateIndex]?.avatar === null ? teacherData?.teacherUsers[updateIndex]?.avatar : response?.data?.url,
				},
			})
			if (TeacherAddData) {
				await toast({
					position: "top",
					title: "Success",
					description: `${t('ManageTeacherPage.toastEditTeacherSuccess')}`,
					status: "success",
					duration: 6000,
					isClosable: true,
				})
				onAddTeacherModalClose(true)
				refetch({
					user: user?._id,
				})

			}
		} catch (Error) {
			console.log("in Create submit", Error)
		}

	}

	//Alert dialog



	const handleRemoveTeacher = async (e) => {
		e.preventDefault()
		try {
			const { data: responseRemoveTeacher } = await removeMutation({
				variables: {
					_id: teacher?._id,
				}
			})
			if (responseRemoveTeacher) {
				await toast({
					position: "top",
					title: "Success",
					description: `${t('ManageTeacherPage.toastAddSuccessRemove')}`,
					status: "success",
					duration: 3000,
					isClosable: true,
				})
				closeRemoveDialogStatus()
			}
		} catch (error) {
			console.error(error)
		}
		refetchPage()
	}

	const refetchPage = () => {
		refetch()
		setTeacher(initialTeacher)
		setFileAvatar({ data: null, base64: '' })
	}

	return (
		<Fragment>
			<Modal isOpen={isAddTeacherModal} onClose={onAddTeacherModalClose} size={'xl'}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('ManageTeacherPage.title')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack align='center' py={'20px'}>
							<HStack align='flex-start'>

								<label style={{ position: 'relative' }} htmlFor="photo-project-file">
									<Avatar
										objectFit='cover'
										zIndex={1}
										name={updateTeacher?.firstname ? `${updateTeacher?.firstname} ${updateTeacher?.lastname}` : 'Profile'}
										size='lg'
										src={updateTeacher?.avatar}
									/>
									<IconButton
										isRound
										as='label'
										bgColor='white'
										htmlFor="photo-project-file"
										position='absolute'
										top={10}
										left={39}
										size='xs'
										fontSize={'15px'}
										zIndex='sticky'
										boxShadow='lg'
										icon={<FaPencilAlt style={{ zIndex: -1 }} />}
										color='green.500'
									/>
									<input
										onChange={selectAvatarFile}
										accept="image/*"
										id="photo-project-file"
										type="file"
										style={{ display: 'none' }}
									/>
								</label>
							</HStack>
						</Stack>
						<Stack spacing={4}>
							<FormControl id="username" isRequired>
								<FormLabel>Username / ชื่อผู้ใช้</FormLabel>
								<Input
									disabled
									value={updateTeacher?.username}
									type="text"
								/>
							</FormControl>
							<HStack>
								<FormControl id="firstname" isRequired>
									<FormLabel>Firstname / ชื่อ</FormLabel>
									<Input
										type="text"
										value={updateTeacher?.firstname}
										onChange={(e) => {
											setupdateTeacher({ firstname: e.target.value })
										}}
									/>
								</FormControl>
								<FormControl id="lastname" isRequired>
									<FormLabel>Lastname / สกุล</FormLabel>
									<Input
										type="text"
										value={updateTeacher?.lastname}
										onChange={(e) => {
											setupdateTeacher({ lastname: e.target.value })
										}}
									/>
								</FormControl>
							</HStack>
							<FormControl id="mobile" >
								<FormLabel>Moblile / เบอร์โทร</FormLabel>
								<Input
									type="tel"
									value={updateTeacher?.mobile}
									onChange={(e) => {
										setupdateTeacher({ mobile: e.target.value })
									}}
								/>
							</FormControl>
							<FormControl id="email" isRequired>
								<FormLabel>Email / อีเมล</FormLabel>
								<Input
									type="email"
									value={updateTeacher?.email}
									onChange={(e) => {
										setupdateTeacher({ email: e.target.value })
									}}
								/>
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button onClick={onAddTeacherModalClose} mr={4}>
							{t('ManageTeacherPage.close')}
						</Button>
						<Button bg={'primary.700'} color={'white'} _hover={{
							transform: 'translateY(-2px)',
							boxShadow: 'lg',
						}}
							onClick={handleUpdateTeacher}>
							{t('ManageTeacherPage.saveButton')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={onshowToggle} onClose={onclose} size={'xl'}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('ManageTeacherPage.createaccount')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack align='center' py={'20px'}>
							<HStack align='flex-start'>
								<label style={{ position: 'relative' }} htmlFor="photo-project-file">
									<Avatar
										objectFit='cover'
										zIndex={1}
										name={dataTeacher?.firstname ? `${dataTeacher?.firstname} ${dataTeacher?.lastname}` : 'Profile'}
										size='lg'
										src={fileavatar?.base64}
										alt="กรุณาเลือกรูปภาพ" />
									<IconButton
										isRound
										as='label'
										bgColor='white'
										htmlFor="photo-project-file"
										position='absolute'
										top={10}
										left={39}
										size='xs'
										fontSize={'15px'}
										zIndex='sticky'
										boxShadow='lg'
										icon={<FaPencilAlt style={{ zIndex: -1 }} />}
										color='green.500'
									/>
									<input
										onChange={selectAvatarFile}
										accept="image/*"
										id="photo-project-file"
										type="file"
										style={{ display: 'none' }}
									/>
								</label>
							</HStack>
						</Stack>

						<Stack spacing={4}>
							<FormControl id="username" isRequired>
								<FormLabel>Username / ชื่อผู้ใช้</FormLabel>
								<Input
									value={dataTeacher?.username}
									onChange={(e) => {
										setdataTeacher({ username: e.target.value })
									}}
									type="text"
								/>
							</FormControl>
							<HStack>
								<FormControl id="firstname" isRequired>
									<FormLabel>Firstname / ชื่อ</FormLabel>
									<Input
										type="text"
										value={dataTeacher?.firstname}
										onChange={(e) => {
											setdataTeacher({ firstname: e.target.value })
										}}
									/>
								</FormControl>
								<FormControl id="lastname" isRequired>
									<FormLabel>Lastname / สกุล</FormLabel>
									<Input
										type="text"
										value={dataTeacher?.lastname}
										onChange={(e) => {
											setdataTeacher({ lastname: e.target.value })
										}}
									/>
								</FormControl>
							</HStack>
							<FormControl id="mobile" >
								<FormLabel>Moblile / เบอร์โทร</FormLabel>
								<Input
									type="tel"
									value={dataTeacher?.mobile}
									onChange={(e) => {
										setdataTeacher({ mobile: e.target.value })
									}}
								/>
							</FormControl>
							<FormControl id="email" isRequired>
								<FormLabel>Email / อีเมล</FormLabel>
								<Input
									type="email"
									value={dataTeacher?.email}
									onChange={(e) => {
										setdataTeacher({ email: e.target.value })
									}}
								/>
							</FormControl>
							<FormControl
								id="email_confirm"
								isDisabled={!dataTeacher?.email}
								isInvalid={dataTeacher?.confirm_email && !(dataTeacher?.email === dataTeacher?.confirm_email)}
								isRequired
							>
								<FormLabel>Confirm Email / ยืนยันอีเมล</FormLabel>
								<Input
									type="email"
									value={dataTeacher?.confirm_email}
									onChange={(e) => {
										setdataTeacher({ confirm_email: e.target.value })
									}}
								/>
								<FormErrorMessage >อีเมลล์ไม่ตรงกัน</FormErrorMessage>
							</FormControl>
							<FormControl id="password" isRequired>
								<FormLabel>Password / รหัสผ่าน</FormLabel>
								<Input
									type="password"
									value={dataTeacher?.password}
									onChange={(e) => {
										setdataTeacher({ password: e.target.value })
									}}
								/>
							</FormControl>
							<FormControl
								isDisabled={!dataTeacher?.password}
								isInvalid={dataTeacher?.confirm_pass && !(dataTeacher?.password === dataTeacher?.confirm_pass)}
								id="password-confirm"
								isRequired
							>
								<FormLabel>Confirm Password / ยืนยันรหัสผ่าน</FormLabel>
								<Input
									value={dataTeacher?.confirm_pass}
									onChange={(e) => {
										setdataTeacher({ confirm_pass: e.target.value })
									}}
									errorBorderColor="red.300"
									type="password"
								/>
								<FormErrorMessage >รหัสผ่านไม่ตรงกัน</FormErrorMessage>
							</FormControl>

						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button onClick={onclose} mr={4}>
							{t('ManageTeacherPage.close')}
						</Button>
						<Button bg={'primary.700'} color={'white'} _hover={{
							transform: 'translateY(-2px)',
							boxShadow: 'lg',
						}}
							onClick={handleAddTeacher}>
							{t('ManageTeacherPage.saveButton')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Box mt={20} w="100%" style={{ zIndex: 2000 }} minHeight={'90vh'}>
				<Container maxW={'7xl'} py={5} as={Stack} spacing={12}>
					<Stack spacing={0} align={'center'}>
						<Heading variant="h4">{t('ManageTeacherPage.title')}</Heading>
						<Text>{t('ManageTeacherPage.subtitle')}</Text>
					</Stack>

					<Stack
						direction={["column", "", "row"]}
						justifyContent='space-between'
					>
						<Stack align={{ base: 'center', md: 'flex-start' }}>
							<Button
								ml={5}
								bg={'primary.700'} color={'white'} _hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}}
								leftIcon={<BiUserPlus />}
								size='lg'
								mb={3}
								onClick={() => {
									onToggle()
								}}>
								{t('ManageTeacherPage.addTeacherButton')}
							</Button>
						</Stack>
						<Stack align={{ base: 'center', md: "flex-end" }}>
							<Flex
								boxSize="full"
								// h="100vh"
								w={['90%', '', '400px']}
								pos="relative"
								// bg={useColorModeValue("gray.400", "gray.600")}
								// p={30}
								alignItems='center'
								justifyContent="center"
							>
								<AutoComplete
									onChange={(v, i, I) => {
										// console.log(v, i, I);
									}}
									onSelectOption={(e) => { setTeacherState(teacherState?.filter((row) => (e?.optionValue === row?._id))) }}
									rollNavigation>
									<AutoCompleteInput size='lg' variant="filled" placeholder="Search..." autoFocus />
									<AutoCompleteList>

										{teacherData?.teacherUsers.map((option, oid) => (
											<AutoCompleteItem
												key={`option-${oid}`}
												label={`${option?.firstname} ${option?.lastname}`}
												value={`${option?.firstname} ${option?.lastname}`}
												textTransform="capitalize"
											>
												{` ${option?.firstname} ${option?.lastname} `}
											</AutoCompleteItem>
										))}
									</AutoCompleteList>
								</AutoComplete>
								<IconButton
									variant="ghost"
									colorScheme='blue'
									pos='absolute'
									right={0}
									// mr={10}
									onClick={() => { setTeacherState(teacherData?.teacherUsers) }}
									aria-label="Call Sage"
									fontSize="15px"
									icon={<MdClose />}
								/>

								{/* <Menu>
									<MenuButton
										as={IconButton}
										aria-label='Options'
										icon={<DragHandleIcon />}
										variant='outline'
										size='md'
										ml={2}
									/>
									<MenuList>
										<MenuItem  >
											<DownloadIcon mr={2} />
											<ReactHTMLTableToExcel
												id="test-table-xls-button"
												className="download-table-xls-button"
												table="table-to-xls"
												filename={t('ManageTeacherPage.filename')}
												sheet={t('ManageTeacherPage.filename')}
												buttonText={t('ManageTeacherPage.exportExcle')}
											/>
										</MenuItem>
									</MenuList>
								</Menu> */}
							</Flex>
						</Stack>
					</Stack>

					{loading ?
						<Stack py={20}>
							<Skeleton height='20px' />
							<Skeleton height='20px' />
							<Skeleton height='20px' />
						</Stack>
						:
						<Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} boxShadow={'xl'} borderWidth={'1px'} mt={5} >
							<Table variant="striped" backgroundColor="white">
								<Thead height="50px">
									<Tr>
										<Th textAlign="center">{t('ManageTeacherPage.no')}</Th>
										<Th textAlign="center">{t('ManageTeacherPage.name-surname')} </Th>
										<Th textAlign="center">{t('ManageTeacherPage.manage')}</Th>
									</Tr>
								</Thead>
								{teacherState?.map((teacher, i) => (
									<Tbody>
										<Tr key={teacher?._id}>
											<Td textAlign="center">{i + 1}</Td>
											<Td textAlign="center">
												<Link as={RouteLink} isExternal to={`/showprofile/${teacher?._id}`} >
													{teacher?.firstname} {teacher?.lastname}
												</Link>
											</Td>
											<Td>
												<Center>
													<Tooltip label={t('ManageTeacherPage.editButton')} fontSize="md">
														<IconButton boxShadow={'lg'} colorScheme='blue' icon={<FaPencilAlt />} mr={2}
															_hover={{
																transform: 'translateY(-2px)',
																boxShadow: 'lg',
															}}
															onClick={(e) => {
																e.preventDefault()
																handleEditOpen(i)
																setupdateTeacher(teacher)
															}}
														/>
													</Tooltip>
													{console.log(teacher)}
													<Tooltip label={t('ManageTeacherPage.removeButton')} fontSize="md">

														<IconButton boxShadow={'lg'} colorScheme='red' icon={<FaTrashAlt />} mr={2}
															_hover={{
																transform: 'translateY(-2px)',
																boxShadow: 'lg',
															}}
															onClick={(e) => {
																e.preventDefault()
																setTeacher({
																	_id: teacher?._id,
																	username: teacher?.username,
																	firstname: teacher?.firstname,
																	lastname: teacher?.lastname,
																	mobile: teacher?.mobile,
																	email: teacher?.email,
																	avatar: teacher?.avatar,
																})
																openRemoveDialogStatus()
															}}
														/>
													</Tooltip>
												</Center>
											</Td>
										</Tr>
									</Tbody>
								))}
							</Table>
						</Box>
					}
				</Container>
			</Box>
			<DialogRemove
				status={removeDialogStatus}
				handleSubmit={handleRemoveTeacher}
				name={`${teacher.firstname} ${teacher.lastname}`}
				onCloseDialog={closeRemoveDialogStatus}
			/>
		</Fragment>
	)
}

export default ManageTeacherPage

import React, {
    Fragment,
    useEffect,
    useState
} from 'react';
import { useHistory, useParams } from 'react-router';
// import { useApp } from '../contexts/AppContext';
import { useTokenMeet } from '../contexts/TokenMeetContext';






const API_MEET = process.env.REACT_APP_API_KEY_JITSI

const MeetingPage = () => {
    const [jitsi, setJitsi] = useState(null);
    // const { user } = useApp()
    const params = useParams()
    const history = useHistory()
    const { tokenMeet } = useTokenMeet()
    // const [tokenMeet, setTokenMeet] = useState('')


    const loadJitsiScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://8x8.vc/external_api.js";
            script.async = true;
            script.onload = resolve
            document.body.appendChild(script);
        })
    };

    const initialiseJitsi = async () => {
        if (!window.JitsiMeetExternalAPI) {
            await loadJitsiScript();
        }

        const _jitsi = new window.JitsiMeetExternalAPI("8x8.vc", {
            roomName: `${API_MEET}/${params?.subjectId}`,
            jwt: tokenMeet,
            parentNode: document.querySelector('#jaas-container')
        });

        setJitsi(_jitsi)
    };

    useEffect(() => {
        if (tokenMeet) {
            initialiseJitsi();
        } else {
            history?.push('/streams')
        }
        return () => jitsi?.dispose();
    }, []);


    useEffect(() => {
        if (jitsi) {
            jitsi?.addEventListeners({
                readyToClose: () => {
                    jitsi.executeCommand("hangup");
                    jitsi?.dispose()
                    history?.push('/streams')
                }
            })
        }

    }, [history, jitsi]);


    // console.log(tokenMeet);
    return (
        <Fragment>
            <div style={{ height: "100%", width: '100%', position: 'fixed', top: 0, left: 0 }} id="jaas-container" />
        </Fragment>
    );
};

export default MeetingPage;
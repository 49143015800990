import { Link as RouteLink } from "react-router-dom";
import {
    Button,
    Heading,
    Flex,
    Box,
    Stack,
    useToast,
    Text
} from "@chakra-ui/react"
import { motion } from 'framer-motion';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { Fragment, useContext } from 'react';
import { PromptPay } from "./componentsPricingPage/PromptPaySection";
import { InsertSlip } from "./componentsPricingPage/InsertSlipSection";
import { useMultiState } from "../lib/hooks";
// import { useApp } from "../contexts/AppContext";
import AuthContext from "../contexts/AuthContext";
import dayjs from "dayjs";
import paymentMutation from '../graphql/mutations/createpayment'
import axios from "axios";
import { useMutation } from "@apollo/client";


const URL = process.env.REACT_APP_UPLOAD_IMAGE

const PricingPage = () => {
    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });
    const toast = useToast()
    const [fileSlip, setFileSlip] = useMultiState({ data: null, base64: '' })
    const [dateSlip, setDateSlip] = useMultiState({
        date: dayjs(Date.now()),
        time: dayjs(Date.now()),
    })
    const { user } = useContext(AuthContext)
    const [setPayment] = useMutation(paymentMutation)

    const steps = [
        { label: 'ชำระเงินผ่านบัญชีธนาคาร', content: <PromptPay /> },
        {
            label: 'แนบสลิปของท่าน',
            content: <InsertSlip
                dateSlip={dateSlip}
                setDateSlip={setDateSlip}
                user={user}
                fileSlip={fileSlip}
                setFileSlip={setFileSlip}
            />
        },
        // { label: 'Step 3', content: <PromptPay /> },
    ];

    const handleAddPayment = async () => {
        if (!dateSlip?.date) {
            await toast({
                position: "top",
                title: "Warning",
                description: "กรุณาเลือกวันที่",
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else if (!dateSlip?.time) {
            await toast({
                position: "top",
                title: "Warning",
                description: "กรุณาเลือกวเวลา",
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else if (!fileSlip?.base64) {
            await toast({
                position: "top",
                title: "Warning",
                description: "กรุณาเลือกรูปภาพสลิป",
                status: 'warning',
                duration: 6000,
                isClosable: true,
            })
        } else {
            try {
                const response = await axios.post(URL, { photo: fileSlip.base64 })
                // console.log(response)
                const { data: paymentdata, errors: paymentErr } = await setPayment({
                    variables: {
                        customer: user?._id,
                        date: dateSlip?.date,
                        time: dateSlip?.time,
                        slip: response ? response?.data?.url : '',
                    },
                })
            } catch (Error) {
                // console.log('in Create payment submit', Error)
                // enqueueSnackbar(Error, { variant: 'error' })
            } finally {
                // console.log('finally')
                await toast({
                    position: "top",
                    title: 'Success',
                    description: "เพิ่มข้อมูลเรียบร้อย",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
                setDateSlip({
                    date: dayjs(Date.now()),
                    time: dayjs(Date.now()),
                })
                setFileSlip({ data: null, base64: '' })
                nextStep()
            }
        }
    }

    const StepButtons = ({
        nextStep,
        prevStep,
        prevDisabled,
        nextDisabled,
        isLast,
        activeStep
    }) => {
        return (
            <Flex width="100%" justify="flex-end">
                <Button
                    mr={4}
                    variant="ghost"
                    size="sm"
                    onClick={prevStep}
                    isDisabled={prevDisabled}
                >
                    Prev
                </Button>
                <Button
                    isDisabled={nextDisabled}
                    size="sm"
                    onClick={() => {
                        // console.log('active', activeStep);
                        if (activeStep === 1) {
                            handleAddPayment()
                        } else {
                            nextStep()
                        }
                    }}
                >
                    {isLast ? 'Finish' : 'Next'}
                </Button>
            </Flex>
        );
    };
    const MotionFlex = motion(Flex);
    const ResetPrompt = ({ onReset, ...rest }) => {
        return (
            <MotionFlex
                px={4}
                py={4}
                width="100%"
                align="center"
                justify="center"
                flexDirection="column"
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
            >
                <Heading fontSize="xl">ทำรายการสำเร็จ กรุณารอการตรวจสอบ
                    และการตอบกลับจากทางเจ้าหน้าที่นะครับ</Heading>
                <Button as={RouteLink} mt={6} size="sm" to='/login'>
                    กลับสู่หน้าหลัก
                </Button>
            </MotionFlex>
        );
    };

    // console.log('active', activeStep)
    return (
        <Fragment>
            <Box py={20} w="100%" >
                <Flex
                    // minH={'100vh'}
                    align={'center'}
                    justify={'center'}
                // py={12}
                // bg={useColorModeValue('gray.50', 'gray.800')}
                >

                    <Stack
                        boxShadow={'2xl'}
                        // bg={useColorModeValue('white', 'gray.700')}
                        rounded={'xl'}
                        p={10}
                        spacing={8}
                        align={'center'}>
                        <Text fontWeight='bold' mt={'-5'} mb={'-10'} fontSize="2xl">สมัครสมาชิก VIP กับพวกเรา</Text>
                        <Steps w={'100%'} activeStep={activeStep}>
                            {steps.map(({ label, content }) => (
                                <Step py={10} label={label} key={label}>

                                    {content}
                                </Step>
                            ))}
                        </Steps>
                        {activeStep === 2 ? (
                            <ResetPrompt onReset={reset} />
                        ) : (
                            <StepButtons
                                {...{ activeStep, nextStep, prevStep }}
                                prevDisabled={activeStep === 0}
                            />
                        )}
                    </Stack>
                </Flex>
            </Box>
        </Fragment>
    );
};
export default PricingPage
import gql from 'graphql-tag'

export default gql`
query{
  teacherUsers{
    _id
    username
    firstname
    lastname
    avatar
    email
    description
    mobile
  }
}
`

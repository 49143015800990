import React, { Fragment, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Container,
    Stack,
    Text,
    Input,
    RadioGroup,
    Radio,
    Heading,
    useDisclosure,
    FormControl,
    FormLabel,
    GridItem,
    useToast,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    HStack,
    VStack,
    useColorMode,
} from '@chakra-ui/react'


import { ThemeProvider, createTheme } from "@mui/material/styles";
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { TextField } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import DateTimePicker from '@mui/lab/DateTimePicker';
import { FiSettings } from "react-icons/fi";
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import { useMultiState } from '../lib/hooks'
import SubjectById from '../graphql/queries/subject'

// import CertificateMutation from '../graphql/mutations/updateCertificate'
import CertificateMutationTemplate from '../graphql/mutations/updateCertificateTemplate'
import AuthContext from '../contexts/AuthContext'
import { useHistory } from 'react-router'
import renderHTML from 'react-render-html'
import axios from 'axios'

const theme = createTheme({
    palette: {
        primary: {
            main: "#0CA25F",
        },
        secondary: deepOrange,

    },
});

const initialContentForm = {
    _id: '',
    name: '',
    description: '',
}

const initialCondition = {
    _id: '',
    name: '',
    type: '',
    score: '',
}


const ManageCertificatePage = () => {
    // localStorage.setItem('refetchPage', true)
    const { colorMode } = useColorMode()
    const URLImage = process.env.REACT_APP_UPLOAD_IMAGE;
    const { t } = useTranslation()
    const toast = useToast()
    const param = useParams()
    const history = useHistory()
    const { user } = useContext(AuthContext)

    const { isOpen: templateCertificate, onOpen: openTemplateCertificate, onClose: closeTemplateCertificate } = useDisclosure()
    // const { isOpen: editCertificate, onOpen: openEditCertificate, onClose: closeEditCertificate } = useDisclosure()
    // const { isOpen: addCondition, onOpen: openAddCondition, onClose: closeAddCondition } = useDisclosure()
    // const { isOpen: editCondition, onOpen: openEditCondition, onClose: closeEditCondition } = useDisclosure()


    const [certificate, setCertificate] = useMultiState(initialContentForm)
    // const [content, setContent] = useState([])
    // const [list, setList] = useState([])
    // const [updateCertificateForm, setUpdateCertificateForm] = useMultiState(initialContentForm)

    // const [conditionForm, setConditionForm] = useMultiState(initialCondition)
    // const [editConditionIndex, setEditConditionIndex] = useState('')
    // const [removeConditionIndex, setRemoveConditionIndex] = useState('')

    const [certificateOptions, setCertificateOptions] = useMultiState({
        width: '29.7cm',
        height: '21cm',
        position: 'Learning Lead'
    })

    const [newphotoLogo, setnewphotoLogo] = useMultiState({
        type: "logo",
        data: null,
        base64: "",
    });

    const [newphotoBackground, setnewphotoBackground] = useMultiState({
        type: "background",
        data: null,
        base64: "",
    });

    const [newphotoSignature, setnewphotoSignature] = useMultiState({
        type: "signature",
        data: null,
        base64: "",
    });

    // const [certificationForm] = useMutation(CertificateMutation)
    const [certificationTemplate] = useMutation(CertificateMutationTemplate)

    const { data: CertificateData, refetch } = useQuery(SubjectById, {
        variables: {
            _id: param?.subjectId,
        },
    })


    useEffect(() => {
        if (CertificateData) {
            setCertificate({ name: CertificateData?.subject?.certificateconfig?.name, description: CertificateData?.subject?.certificateconfig?.description })
            // setUpdateCertificateForm({ name: CertificateData?.subject?.certificateconfig?.name, description: CertificateData?.subject?.certificateconfig?.description })
            // setList(CertificateData?.subject?.certificateconfig?.certificateCondition)
        }
        if (user?.role === 'STUDENT') {
            setTimeout(() => {
                history.push('/login')
            }, 100)
        }


    }, [history, CertificateData, user, refetch])

    useEffect(() => {
        let template = CertificateData?.subject?.certificateconfig
        setCertificateOptions({ timestatus: template?.timestatus })
        setCertificateOptions({ typesign: template?.signature?.typesign })
        if (template?.timestatus == 'set') {
            setCertificateOptions({ certificatetime: template?.certificatetime })
        } else {
            setCertificateOptions({ certificatetime: null })
        }
        if (template?.backgroundURL) {
            setCertificateOptions({ background: template?.backgroundURL })
        } else {
            setCertificateOptions({ background: 'https://ppi-course.com/images/5.png' })
        }
        if (template?.logoURL) {
            setCertificateOptions({ logo: template?.logoURL })
        } else {
            setCertificateOptions({ logo: 'http://localhost:3000/images/psuLogo.png', })
        }
        if (template?.signature?.firstname) {
            setCertificateOptions({ firstname: template?.signature?.firstname })
        } else {
            setCertificateOptions({ firstname: '-' })
        }
        if (template?.signature?.lastname) {
            setCertificateOptions({ lastname: template?.signature?.lastname })
        } else {
            setCertificateOptions({ lastname: '-' })
        }
        if (template?.signature?.typesign == 'signature') {
            setCertificateOptions({ signature: template?.signatureURL })
        } else {
            setCertificateOptions({
                signature: 'https://camo.githubusercontent.com/805e05b94844e39d7edd518f492c8599c71835b3/687474703a2f2f692e696d6775722e636f6d2f646e5873344e442e706e67'
            })
        }
    }, [CertificateData])


    const selectPhotoLogo = (e) => {
        new Promise((resolve, reject) => {
            e.preventDefault()
            const file = e.currentTarget.files[0]
            setnewphotoLogo({
                data: file,
            })
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {
                resolve(reader.result)
                const responselogo = await axios.post(URLImage, {
                    photo: reader.result,
                })
                // console.log(responselogo.data.url)
                setCertificateOptions({ logo: await responselogo.data.url })
            }
            reader.onerror = (error) => reject(error)
        })
    }

    const selectPhotoBackground = (e) => {
        new Promise((resolve, reject) => {
            e.preventDefault()
            const file = e.currentTarget.files[0]
            setnewphotoBackground({
                data: file,
            })
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {
                resolve(reader.result)
                const responsebackground = await axios.post(URLImage, {
                    photo: reader.result,
                })
                // console.log(responsebackground.data.url)
                setCertificateOptions({ background: await responsebackground.data.url })
            }
            reader.onerror = (error) => reject(error)
        })
    }

    const selectPhotoSignature = (e) => {
        new Promise((resolve, reject) => {
            e.preventDefault()
            const file = e.currentTarget.files[0]
            setnewphotoSignature({
                data: file,
            })
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {
                resolve(reader.result)
                const responsesignature = await axios.post(URLImage, {
                    photo: reader.result,
                })
                // console.log(responsesignature.data.url)
                setCertificateOptions({ signature: await responsesignature.data.url })
            }
            reader.onerror = (error) => reject(error)
        })
    }


    ////////////////Certificate Template///////////////////////////////

    const html = `<!doctype html>
	<html lang="en-US">
	<head>
		<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
		<title>Ceart</title>
		<link href="https://fonts.googleapis.com/css?family=Satisfy" rel="stylesheet">
		<meta name="description" content="Reset Password Email Template.">
		<style type="text/css">
			@media print {
				html,
				body {
					margin: 0;
				}
			}
	
			a:hover {
				text-decoration: underline !important;
			}
	
			.cert {
				border: 13px solid #0072c6 !important ;
				border-right: 13px solid #0894fb;
				border-left: 13px solid #0894fb;
				width: ${templateCertificate ? "174mm" : " 28cm"};
				height: ${templateCertificate ? "94mm" : "10cm"};
				font-family: arial;
				color: ${colorMode==='light'?'#383737':'white'};
				background-image: url(${certificateOptions?.background});
				background-repeat: no-repeat;
				background-size: cover;
				size: A4 landscape;
				margin: 0;
			}
	
			.crt_title {
				margin-top: 20px;
				font-family: "Satisfy", cursive;
				font-size: 40px;
				letter-spacing: 1px;
				color: #0060a9;
			}
	
			.crt_logo img {
				width: 130px;
				height: auto;
				margin: auto;
				padding: 10px;
			}
	
			.colorGreen {
				color: #27ae60;
			}
	
			.crt_user {
				display: inline-block;
				width: 80%;
				padding: 5px 25px;
				margin-bottom: 0px;
				padding-bottom: 0px;
				font-family: "Satisfy", cursive;
				font-size: 40px;
				border-bottom: 1px dashed #cecece;
			}
	
			.afterName {
				font-weight: 100;
				color: ${colorMode==='light'?'#383737':'white'};
			}
	
			.colorGrey {
				color: grey;
			}
	
			.certSign {
				width: 200px;
			}
	
			html,
			body {
				margin: 0;
			}
		</style>
	</head>
	
	<body>
		<table class="cert">
			<tr>
				<td align="center" class="crt_logo">
                ${
                    certificateOptions?.logo? `<img src="${certificateOptions?.logo}" alt="logo"/>`:''
                }
				</td>
			</tr>
			<tr>
				<td align="center">
					<h1 class="crt_title">Certificate Of Completion
						<h2>This Certificate is awarded to</h2>
						<h1 class="colorGreen crt_user">Sohrab Zia</h1>
						<h3 class="afterName">For his/her completion of HSE Awareness session
						</h3>
						<h3>Awarded on 1 March 2020 </h3>
				</td>
			</tr>
			<tr>
				<td align="center">
					${certificateOptions?.typesign === 'signature' ? (`<img src=${certificateOptions?.signature} class="certSign" alt="sign" />`) : (`<h3 style="font-style: italic;" class="certSign">${certificateOptions?.firstname} ${certificateOptions?.lastname}</h3>`)}
					<h3>(${certificateOptions?.firstname} ${certificateOptions?.lastname})</h3>
					<h3>${certificateOptions?.position}</h3>
					<h3>Department Name</h3>
				</td>
			</tr>
		</table>
	</body>
	
	</html>`
    ////////////////Certificate Template///////////////////////////////


    // const handleUpdateCertificate = async (e) => {
    //     e.preventDefault()
    //     try {
    //         if (!updateCertificateForm?.name) {
    //             await toast({
    //                 position: 'top',
    //                 title: 'warning',
    //                 description: `${t('managerQuestionPage.altwarningquestion')}`,
    //                 status: 'warning',
    //                 duration: 3000,
    //                 isClosable: true,
    //             })
    //         } else {
    //             const { data: Certificate } = await certificationForm({
    //                 variables: {
    //                     _id: param?.subjectId,
    //                     name: updateCertificateForm?.name,
    //                     description: updateCertificateForm?.description,
    //                 },
    //             })
    //             if (Certificate) {
    //                 // console.log(QuestionData)
    //                 await toast({
    //                     position: 'top',
    //                     title: 'Success',
    //                     description: `${t('managerQuestionPage.altsuccesquestion')}`,
    //                     status: 'success',
    //                     duration: 3000,
    //                     isClosable: true,
    //                 })
    //                 refetch({
    //                     _id: param?.subjectId,
    //                 })
    //             }
    //         }
    //     } catch (Error) {
    //         console.log('in Create submit', Error)
    //     }
    // }

    const handleUpdateCertificateTemplate = async (e) => {
        e.preventDefault()
        // console.log("logo", certificateOptions?.timestatus)
        // console.log("back", certificateOptions?.background)
        try {
            const { data: CertificateTemplate } = await certificationTemplate({
                variables: {
                    _id: param?.subjectId,
                    signatureURL: certificateOptions?.signature,
                    logoURL: certificateOptions?.logo,
                    backgroundURL: certificateOptions?.background,
                    position: certificateOptions?.position,
                    typesing: certificateOptions?.typesing,
                    firstname: certificateOptions?.firstname,
                    lastname: certificateOptions?.lastname,
                    timestatus: certificateOptions?.timestatus,
                    certificatetime: certificateOptions?.certificatetime,
                },
            })
            if (CertificateTemplate) {
                console.log(CertificateTemplate)
                await toast({
                    position: 'top',
                    title: 'Success',
                    description: `${t('managerQuestionPage.altsuccesquestion')}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                refetch({
                    _id: param?.subjectId,
                })
            }
            closeTemplateCertificate()
        } catch (Error) {
            console.log('in Create submit', Error)
        }
    }

    // const handleAddCondition = async (e) => {
    //     e.preventDefault()
    //     try {
    //         if (!conditionForm?._id) {
    //             await toast({
    //                 position: 'top',
    //                 title: 'warning',
    //                 description: `${t('managerQuestionPage.altwarningquestion')}`,
    //                 status: 'warning',
    //                 duration: 3000,
    //                 isClosable: true,
    //             })
    //         } else if (!conditionForm?.score) {
    //             await toast({
    //                 position: 'top',
    //                 title: 'warning',
    //                 description: `${t('managerQuestionPage.altwarningquestion')}`,
    //                 status: 'warning',
    //                 duration: 3000,
    //                 isClosable: true,
    //             })
    //         } else {
    //             let conditionList = list
    //             conditionList.push(conditionForm)
    //             const { data: Certificate } = await certificationForm({
    //                 variables: {
    //                     _id: param?.subjectId,
    //                     condition: conditionList,
    //                 },
    //             })
    //             if (Certificate) {
    //                 // console.log(QuestionData)
    //                 await toast({
    //                     position: 'top',
    //                     title: 'Success',
    //                     description: `${t('managerQuestionPage.altsuccesquestion')}`,
    //                     status: 'success',
    //                     duration: 3000,
    //                     isClosable: true,
    //                 })
    //                 refetch({
    //                     _id: param?.subjectId,
    //                 })
    //             }
    //             setConditionForm(initialCondition)
    //         }
    //     } catch (Error) {
    //         console.log('in Create submit', Error)
    //     }
    // }

    // const handleEditCondition = async (e) => {
    //     e.preventDefault()
    //     try {
    //         if (!conditionForm?.score) {
    //             await toast({
    //                 position: 'top',
    //                 title: 'warning',
    //                 description: `${t('managerQuestionPage.altwarningquestion')}`,
    //                 status: 'warning',
    //                 duration: 3000,
    //                 isClosable: true,
    //             })
    //         } else {
    //             let conditionList = list
    //             conditionList.splice(
    //                 editConditionIndex,
    //                 1,
    //                 conditionForm
    //             )
    //             const { data: Certificate } = await certificationForm({
    //                 variables: {
    //                     _id: param?.subjectId,
    //                     condition: conditionList,
    //                 },
    //             })
    //             if (Certificate) {
    //                 // console.log(QuestionData)
    //                 await toast({
    //                     position: 'top',
    //                     title: 'Success',
    //                     description: `${t('managerQuestionPage.altsuccesquestion')}`,
    //                     status: 'success',
    //                     duration: 3000,
    //                     isClosable: true,
    //                 })
    //                 refetch({
    //                     _id: param?.subjectId,
    //                 })
    //             }
    //             setEditConditionIndex('')
    //             setConditionForm(initialCondition)
    //             closeEditCondition()
    //         }
    //     } catch (Error) {
    //         console.log('in Create submit', Error)
    //     }
    // }

    // const handleRemoveCondition = async (e) => {
    //     e.preventDefault()
    //     try {
    //         let conditionList = list
    //         conditionList.splice(removeConditionIndex, 1)
    //         const { data: Certificate } = await certificationForm({
    //             variables: {
    //                 _id: param?.subjectId,
    //                 condition: conditionList,
    //             },
    //         })
    //         if (Certificate) {
    //             await toast({
    //                 position: 'top',
    //                 title: 'Success',
    //                 description: `${t('managerQuestionPage.altsuccesquestion')}`,
    //                 status: 'success',
    //                 duration: 3000,
    //                 isClosable: true,
    //             })
    //             refetch({
    //                 _id: param?.subjectId,
    //             })
    //         }
    //         setRemoveConditionIndex('')
    //     } catch (Error) {
    //         console.log('in Create submit', Error)
    //     }
    // }


    // const fillterselectId = (id) => {
    //     // console.log("ID", id)
    //     var obj = content.find((x) => (x?._id == id))
    //     // console.log(obj)
    //     setConditionForm({ _id: obj?._id, name: obj?.name, type: obj?.type })
    // }

    // console.log(certificateOptions.timestatus)

    return (
        <Fragment>
            {/* <Modal isOpen={addCondition} onClose={closeAddCondition} size={'5xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('managerQuestionPage.addquestion')}</ModalHeader>
            <ModalHeader>เพิ่มเงื่อนไข</ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleAddCondition} as={GridItem} colSpan={[6, 3]}>
                <ModalBody>
                    <Select defaultValue=' ' py={5} onChange={(e) => {
                        fillterselectId(e.target.value)
                    }} >
                        <option value=' '> {t('AddContentPage.choosetype')}</option>
                        {content.map((content, i) => (<option key={i} value={content._id}>{content.name}</option>))}
                    </Select>
                    <FormControl>
                        <FormLabel htmlFor='email'>conten detail</FormLabel>
                        <FormLabel htmlFor='email'>รายละเอียด</FormLabel>
                        <Stack spacing={4}>
                            <InputGroup>
                                <InputLeftAddon width='5rem' children='ID' />
                                <Input type='tel' placeholder='Content Id' isReadOnly value={conditionForm._id} />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftAddon width='5rem' children='Name' />
                                <InputLeftAddon width='5rem' children='ชื่อ' />
                                <Input type='tel' placeholder='Name' isReadOnly value={conditionForm.name} />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftAddon width='5rem' children='Type' />
                                <InputLeftAddon width='5rem' children='ประเภท' />
                                <Input type='tel' placeholder='Type Content' isReadOnly value={conditionForm.type} />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftAddon width='5rem' children='Score' />
                                <InputLeftAddon width='5rem' children='คะแนน' />
                                <NumberInput onChange={(e) => { setConditionForm({ score: e }) }}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </InputGroup>
                        </Stack>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button mr={3} onClick={closeAddCondition}>
                        {t('managerQuestionPage.buttoncancel')}
                    </Button>
                    <Button type="submit" bg={'primary.700'} color={'white'} _hover={{
                        transform: 'translateY(-2px)',
                        boxShadow: 'lg',
                    }} mr={3}>
                        {t('managerQuestionPage.buttonadd')}
                    </Button>
                </ModalFooter>
            </form>
        </ModalContent>
            </Modal > * /}


{/* <Modal isOpen={editCondition} onClose={closeEditCondition} size={'5xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('managerQuestionPage.addquestion')}</ModalHeader>
                    <ModalHeader>ลบเงื่อนไข</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleEditCondition} as={GridItem} colSpan={[6, 3]}>
                        <ModalBody>
                            <FormControl>
                                <FormLabel htmlFor='email'>conten detail</FormLabel>
                                <FormLabel htmlFor='email'>รายละเอียด</FormLabel>
                                <Stack spacing={4}>
                                    <InputGroup>
                                        <InputLeftAddon width='5rem' children='ID' />
                                        <Input type='tel' placeholder='Content Id' isReadOnly value={conditionForm._id} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputLeftAddon width='5rem' children='Name' />
                                        <InputLeftAddon width='5rem' children='ชื่อ' />

                                        <Input type='tel' placeholder='Name' isReadOnly value={conditionForm.name} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputLeftAddon width='5rem' children='Type' />
                                        <InputLeftAddon width='5rem' children='ประเภท' />
                                        <Input type='tel' placeholder='Type Content' isReadOnly value={conditionForm.type} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputLeftAddon width='5rem' children='Score' />
                                        <InputLeftAddon width='5rem' children='คะแนน' />
                                        <NumberInput value={conditionForm?.score} onChange={(e) => { setConditionForm({ score: e }) }}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </InputGroup>
                                </Stack>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={3} onClick={closeAddCondition}>
                                {t('managerQuestionPage.buttoncancel')}
                            </Button>
                            <Button type="submit" bg={'primary.700'} color={'white'} _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }} mr={3}>
                                ลบ
                                {t('managerQuestionPage.buttonadd')}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>  */}


            <Modal isOpen={templateCertificate} onClose={closeTemplateCertificate} size={'6xl'}>
                {/* {console.log("UPDATEEE", conditionForm)} */}
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>{t('managerQuestionPage.addquestion')}</ModalHeader> */}
                    <ModalHeader>{t('managecertificatePage.editCertificate')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleUpdateCertificateTemplate} as={GridItem} colSpan={[6, 3]}>
                        <ModalBody>
                            {/* <Button colorScheme='teal' variant='solid' disabled={loading} onClick={handleConvert}>{!loading ? "Click" : "Downloading..."}</Button> */}
                            <Stack direction={["column", "column", "column", "row"]} spacing='24px'>
                                <Box  mt='10' borderWidth={'2px'} borderRadius={'lg'} p={1} alignSelf={'center'} sx={{color:colorMode === 'light' ? "black !important":'white !important', overflowX: ['auto', 'auto', 'none'], maxW: ['90%', '400px', '90%', 'none'], maxH: ['250px', '250px', '90%', 'none'] }}>
                                    {renderHTML(html)}
                                </Box>
                                <Box
                                    py='10px'
                                    mt='25px'
                                    p='5'
                                >
                                    <Stack spacing={4}>
                                        <FormControl id="photologo" isRequired>
                                            <FormLabel>{t('managecertificatePage.logo')}</FormLabel>
                                            <HStack>
                                                <Button size="sm" as="label">
                                                    {t('Button.edit')}
                                                    <input
                                                        onChange={selectPhotoLogo}
                                                        accept="image/*"
                                                        id="photo-project-file"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                    />
                                                </Button>
                                                <Text>{newphotoLogo?.data?.name}</Text>
                                            </HStack>
                                        </FormControl>
                                        <FormControl id="photobackground" isRequired>
                                            <FormLabel>{t('managecertificatePage.background')}</FormLabel>
                                            <HStack>
                                                <Button size="sm" as="label">
                                                    {t('Button.edit')}
                                                    <input
                                                        onChange={selectPhotoBackground}
                                                        accept="image/*"
                                                        id="photo-project-file"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                    />
                                                </Button>
                                                <Text>{newphotoBackground?.data?.name}</Text>
                                            </HStack>
                                        </FormControl>
                                        <FormControl as='fieldset'>
                                            <FormLabel as='legend'>{t('managecertificatePage.signature_style')}</FormLabel>
                                            <RadioGroup value={certificateOptions?.typesign} onChange={(e) => { setCertificateOptions({ typesign: e }) }}  >
                                                <HStack spacing='24px'>
                                                    <Radio value='signature'>{t('managecertificatePage.signature')}</Radio>
                                                    <Radio value='name'>{t('managecertificatePage.name_surname')}</Radio>
                                                </HStack>
                                            </RadioGroup>
                                        </FormControl>
                                        {certificateOptions?.typesign === 'name' ? null : <FormControl id="photobackground" isRequired>
                                            <FormLabel>{t('managecertificatePage.signature_image')}</FormLabel>
                                            <HStack>
                                                <Button size="sm" as="label">
                                                    {t('Button.edit')}
                                                    <input
                                                        onChange={selectPhotoSignature}
                                                        accept="image/*"
                                                        id="photo-project-file"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                    />
                                                </Button>
                                                <Text>{newphotoSignature?.data?.name}</Text>
                                            </HStack>
                                        </FormControl>}
                                        <HStack>
                                            <FormControl id="firstname" isRequired>
                                                <FormLabel>Firstname / ชื่อ</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={certificateOptions?.firstname}
                                                    onChange={(e) => {
                                                        setCertificateOptions({ firstname: e.target.value })
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl id="lastname" isRequired>
                                                <FormLabel>Lastname / สกุล</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={certificateOptions?.lastname}
                                                    onChange={(e) => {
                                                        setCertificateOptions({ lastname: e.target.value })
                                                    }}
                                                />
                                            </FormControl>
                                        </HStack>
                                        <FormControl id="position" isRequired>
                                            <FormLabel>{t('managecertificatePage.position')}</FormLabel>
                                            <Input
                                                placeholder='กรอกตำแหน่ง'
                                                value={certificateOptions?.position}
                                                onChange={(e) => {
                                                    setCertificateOptions({ position: e.target.value })
                                                }}
                                                type="text"
                                            />
                                        </FormControl>
                                        <FormControl as='fieldset'>
                                            <FormLabel as='legend'>{t('managecertificatePage.set_time_certificate')}</FormLabel>
                                            {console.log(certificateOptions?.timestatus)}
                                            <RadioGroup value={certificateOptions?.timestatus} onChange={(e) => { setCertificateOptions({ timestatus: e }) }}  >
                                                <HStack spacing='24px'>
                                                    <Radio value='set'>{t('managecertificatePage.set_time')}</Radio>
                                                    <Radio value='noset'>{t('managecertificatePage.current_time')}</Radio>
                                                </HStack>
                                            </RadioGroup>
                                        </FormControl>
                                        {certificateOptions?.timestatus === 'noset' ? null : <FormControl id="photobackground" isRequired>
                                            <HStack>
                                                <ThemeProvider theme={theme}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            // disableFuture
                                                            label={t('managecertificatePage.date')}
                                                            // openTo="year"
                                                            // minDate={dayjs(Date.now())}
                                                            // views={['year', 'month', 'day']}
                                                            value={certificateOptions?.certificatetime ? certificateOptions?.certificatetime : ''}
                                                            onChange={(e) => setCertificateOptions({ certificatetime: e })}
                                                            renderInput={(params) => <TextField fullWidth required size="small" {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider>
                                            </HStack>
                                        </FormControl>}
                                    </Stack>
                                </Box>
                            </Stack>
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={3} onClick={closeTemplateCertificate}>
                                {t('Button.cancel')}
                            </Button>
                            <Button type="submit" bg={'primary.700'} color={'white'} _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }} mr={3}>
                                {t('Button.save')}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            {/* 
            <Modal isOpen={editCertificate} onClose={closeEditCertificate}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('managecertificatePage.editCertificate')}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleUpdateCertificate}>
                        <ModalBody>
                            <FormControl id="">
                                <FormLabel> {t('managesubjectPage.subject')} </FormLabel>
                                <Input
                                    value={updateCertificateForm?.name}
                                    onChange={(e) => setUpdateCertificateForm({ name: e.target.value })}
                                />
                            </FormControl>
                            <FormControl id="">
                                <FormLabel>{t('managesubjectPage.description')}</FormLabel>
                                <Textarea
                                    value={updateCertificateForm?.description}
                                    onChange={(e) => setUpdateCertificateForm({ description: e.target.value })}
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Center>
                                <Button type="submit" bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}>
                                    {t('Button.confirm')}
                                </Button>
                            </Center>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal> */}


            {/* <AlertDialog isOpen={removeCondition} onClose={closeRemoveCondition}>
                <AlertDialogOverlay>
                    <form onSubmit={handleRemoveCondition} as={GridItem} colSpan={[6, 3]}>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                {t('managerQuestionPage.removequestion')}
                            </AlertDialogHeader>
                            <AlertDialogBody><Center>{t('managerQuestionPage.removedescription')}</Center></AlertDialogBody>
                            <AlertDialogFooter>
                                <Button onClick={closeRemoveCondition}>	{t('managerQuestionPage.buttoncancel')}</Button>
                                <Button bg={'primary.700'} color={'white'} _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }} type="submit" ml={3}>
                                    {t('managerQuestionPage.buttondelete')}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </form>
                </AlertDialogOverlay>
            </AlertDialog> */}

            <Box w="100%" p={[2, 5, 15, 20]}>
                <Container maxW={"7xl"} as={Stack} spacing={12}>
                    <Stack spacing={0} align={'center'}>
                        <Heading variant="h4">
                            {t('managecertificatePage.title')}
                        </Heading>
                        <Text>{t('managecertificatePage.descrip')}</Text>
                    </Stack>
                    {/* <VStack w='100%' marginTop={{ base: '1', sm: '5' }}
                        display="flex"
                        flexDirection={{ base: 'column', sm: 'row' }}
                        justifyContent="center"  >
                        <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} minHeight={'40vh'}>
                            {renderHTML(html)}
                        </Box>

                    </VStack> */}

                    <VStack>
                        <Button
                            size={'lg'}
                            leftIcon={<FiSettings size={30} />}
                            _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                                bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                            }}
                            onClick={(e) => {
                                e.preventDefault()
                                openTemplateCertificate()
                            }}>{t('managecertificatePage.editCertificate')}
                        </Button>
                        <Box sx={{ color:colorMode === 'light' ? "black !important":'white !important', overflowX: ['auto', 'auto', 'none'], maxW: ['400px', '400px', '90%', 'none'], maxH: ['250px', '250px', '180px', 'none'] }} minHeight={'40vh'} p={2} borderWidth={'2px'} borderRadius={'lg'}>
                            {renderHTML(html)}
                        </Box>
                    </VStack>
                    {/* <Grid templateColumns="repeat(5, 1fr)" gap={4} py={3}>
                        <GridItem colStart={1} colEnd={5} py={2}>
                            <Text as="legend" fontSize="2xl" fontWeight="extrabold">รายการเงื่อนไขออกใบรับลอง</Text>
                        </GridItem>
                        <GridItem colStart={6} colEnd={12} py={2}>
                            <Tooltip label={t('ManageSurveyPage.addsurvey')} fontSize="md">
                                <Button leftIcon={<AddIcon />} colorScheme="teal" variant="solid" size='sm'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        openAddCondition()
                                    }}>
                                    เพิ่งเงื่อนไข
                                </Button>
                            </Tooltip>
                        </GridItem>
                    </Grid>

                    <Box flex='1' textAlign='left'>
                        เงื่อนไขการออกใบรับรอง
                    </Box>

                    <TableContainer>
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th>no</Th>
                                    <Th>name</Th>
                                    <Th>type</Th>
                                    <Th>score</Th>
                                    <Th>manage</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {CertificateData?.subject?.certificateconfig?.certificateCondition.map((content, i) => (
                                    <Tr key={i}>
                                        <Td>{i + 1}</Td>
                                        <Td>{content?.name}</Td>
                                        <Td>{content?.type}</Td>
                                        <Td>{content?.score}</Td>
                                        <Td>
                                            <Tooltip label={"Edit"} fontsize="md">
                                                <IconButton icon={<EditIcon />} mr={2}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        openEditCondition()
                                                        setConditionForm(content)
                                                        setEditConditionIndex(i)
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip label={"Remove"} fontsize="md">
                                                <IconButton icon={<DeleteIcon />} mr={2}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        openRemoveCondition()
                                                        setRemoveConditionIndex(i)
                                                    }}
                                                />
                                            </Tooltip>
                                        </Td>
                                    </Tr>))}
                            </Tbody>
                        </Table>
                    </TableContainer> */}

                </Container>
            </Box>
        </Fragment >
    )
}

export default ManageCertificatePage
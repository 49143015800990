import React, { Fragment, useContext, useState, useEffect } from "react"
// import { useTranslation } from 'react-i18next'

import {
  Box,
  Stack,
  Center,
  useColorModeValue,
  SimpleGrid,
  TagRightIcon,
  TagLeftIcon,
  Heading,
  Wrap,
  WrapItem,
  Text,
  useDisclosure,
  Link,
  Image,
  useColorMode,
  VStack,
  Flex,
  HStack,
} from "@chakra-ui/react"


import { FaGraduationCap, FaTrophy } from 'react-icons/fa'
import { MdAccountCircle } from "react-icons/md";
import { IoMdChatboxes } from "react-icons/io";
import { useQuery } from '@apollo/client'
import subjectQuery from '../graphql/queries/subjects_public_alive'
import SubjectLayout from '../components/layouts/SubjectLayout';
import AuthContext from '../contexts/AuthContext';
import SubjectLayoutSkeleton from '../components/skeleton/SubjectLayoutSkeleton';


const HomePage = () => {


  const { user } = useContext(AuthContext)
  const { data: subjectsData, loading, refetch } = useQuery(subjectQuery, { fetchPolicy: "network-only", })
  const [subjectsState, setSubjectsState] = useState([])
  const { colorMode, toggleColorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [curItems, setCurItems] = useState([]);

  const itemLimit = 4;

  const [pagesQuantity, setPagesQuantity] = useState(0);
  const [curPage, setCurPage] = useState(0);

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };

  const setSlide = (slide) => {
    setCurrentSlide(slide);
  };

  useEffect(() => {
    const pagesTotal = Math.ceil(subjectsData?.subjects?.length / itemLimit);
    setPagesQuantity(pagesTotal);

    if (subjectsData) {
      setSubjectsState(subjectsData?.subjects ? subjectsData?.subjects.slice(0, 4) : [])
    }
    // if (localStorage.getItem('refetchPage') !== true) {
    //   console.log("refetPage")
    //   localStorage.setItem('refetchPage', false)
    //   refetch()
    // }
  }, [subjectsData])

  const arrowStyles = {
    cursor: "pointer",
    pos: "absolute",
    top: "50%",
    w: "auto",
    mt: "-22px",
    p: "16px",
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    transition: "0.6s ease",
    borderRadius: "0 3px 3px 0",
    userSelect: "none",
    _hover: {
      opacity: 0.8,
      bg: "black",
    },
  };

  const slides = [
    {
      img: "./images/intro_image3.jpg",
      label: "",
      description: "",
    },
    {
      img: "./images/gif1.gif",
      label: "",
      description: "",
    },
    {
      img: "./images/gif2.gif",
      label: "",
      description: "",
    },

  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesCount = slides.length;

  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`,
    background: `linear - gradient(rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3))`

  };

  const SLIDES_INTERVAL_TIME = 6000;
  const ANIMATION_DIRECTION = "right";
  useEffect(() => {
    const prevSlide = () => {
      setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };

    const nextSlide = () => {
      setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };

    const automatedSlide = setInterval(() => {
      ANIMATION_DIRECTION.toLowerCase() === "left" ? prevSlide() : nextSlide();
    }, SLIDES_INTERVAL_TIME);
    return () => clearInterval(automatedSlide);
  }, [slidesCount]);


  return (
    <Fragment>

      <VStack spacing={'0'} align={'stretch'} width={'100%'} height={'100%'}>
        <Flex w="full" overflow="hidden">
          <Flex pos="relative" h={['250px', '500px', '500px', '650px']} w="full" {...carouselStyle} >
            {slides.map((slide, sid) => (
              <Box key={`slide-${sid}`} flex="none" boxSize="full" shadow="md">
                <Text
                  mt={'2'}
                  color="white"
                  fontSize="xs"
                  p="8px 12px"
                  pos="absolute"
                  top="0"
                  whiteSpace="nowrap"
                >
                  {sid + 1} / {slidesCount}
                </Text>
                <Image
                  objectFit={"fill"}
                  src={slide?.img}
                  alt="carousel image"
                  boxSize="full"
                  backgroundSize="cover"
                />

                <Stack
                  // p="90px "
                  pos="absolute"
                  bottom="10px"
                  textAlign="center"
                  w="full"
                  mb="8"
                  color="white"
                >
                </Stack>
                <HStack justify="center" pos="absolute" bottom="8px" w="full">

                  {Array.from({
                    length: slidesCount,
                  }).map((_, slide) => (
                    <Box
                      key={`dots-${slide}`}
                      cursor="pointer"
                      boxSize={["7px", null, "15px"]}
                      m="0 2px"
                      bg={currentSlide === slide ? "blackAlpha.800" : "blackAlpha.500"}
                      rounded="50%"
                      display="inline-block"
                      transition="background-color 0.6s ease"
                      _hover={{
                        bg: "blackAlpha.800",
                      }}
                      onClick={() => setSlide(slide)}
                    ></Box>))}
                </HStack>
              </Box>
            ))}
          </Flex>
        </Flex>

        {/* <Flex
          w={'full'}
          h={['50vh', '60vh', '75vh', '100vh']}
          backgroundImage={'./images/p2.png'}
          backgroundSize={"auto"}
          backgroundPosition={'center center'}
          boxShadow={'xl'}>

          <VStack
            w={'full'}
            justify={'center'}
            px={{ base: 4, md: 8 }}
          // bgGradient={'linear(to-r, blackAlpha.100, transparent)'}
          >
            <Stack align={'center'} spacing={10}>
              <Heading
                as='h1' fontSize={['40px', '60px', '70px', '100px']} isTruncated
                color={colorMode === 'light'
                  ? ['primary.900']
                  : ['rgba(255,255,255,1)']}>
                คอร์สเรียนออนไลน์
              </Heading>
              <Stack direction={'row'}>
                <Heading
                  as='h1' size='lg' isTruncated
                  color={colorMode === 'light'
                    ? ['primary.900']
                    : ['rgba(255,255,255,1)']}>
                  โดยสถาบันนโยบายสาธารณะ
                </Heading>
              </Stack>
            </Stack>
          </VStack>
        </Flex> */}
        <Box
          px={5}
          py={5}
          maxHeight={'200vh'}
        >
          <Stack direction={["column", "", "row"]} justifyContent='space-between' align={{ base: 'center', md: 'flex-start' }}>
            <Link href={"/courses"} >
              <Heading fontSize={['25px', '20px', '30px', '30px']} color={colorMode === 'light'
                ? ['primary.900', 'primary.900', 'primary.900', 'primary.900']
                : ['rgba(255,255,255,1)', 'rgba(255,255,255,1)', 'rgba(255,255,255,1)', 'rgba(255, 255, 255, 1)']}>คอร์สเรียนที่เปิดสอน</Heading>
            </Link>
          </Stack>
          <Stack>
            <Wrap justify={'center'} >
              {loading ? [1, 2, 3, 4].map((row) => (
                <WrapItem key={row}>
                  <SubjectLayoutSkeleton />
                </WrapItem>
              )) :
                <Box>
                  {subjectsState.length !== 0 ?
                    (
                      <WrapItem >
                        <SubjectLayout subjectsState={subjectsState} onOpen={onOpen} user={user} curPage={curPage} itemLimit={itemLimit} curItems={curItems} setCurItems={setCurItems} />
                      </WrapItem>
                    ) :
                    <Box>
                      <Text fontSize={'35'} color={'gray.500'}>ไม่มีคอร์สเรียน</Text>
                      <Box h='145px'></Box>
                    </Box>
                  }
                </Box>
              }
            </Wrap>
          </Stack>
        </Box>
        <Box
          bg={useColorModeValue('gray.50', 'gray.900')}
          color={useColorModeValue('primary.900', 'gray.200')}
          w={'full'}
          align={'center'}
        >
          <Flex
            h={['250px', '500px', '500px', '600px']}
            w="full">
            <Image
              objectFit={"fill"}
              src={'../images/ppi_homepage2.png'}
              alt="carousel image"
              boxSize="full"
              backgroundSize="cover"
            />
          </Flex>
          <Flex
            h={['250px', '500px', '500px', '600px']}
            w="full">
            <Image
              objectFit={"fill"}
              src={'../images/ppi_homepage1.png'}
              alt="carousel image"
              boxSize="full"
              backgroundSize="cover"
            />
          </Flex>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 2 }} spacing='80px' py={5}>
            <Stack align={{ base: 'center', md: "flex-end" }}>
              <Center
                fontSize={{
                  base: '5vw',
                  md: '2.3vw',
                  sm: '3.5vw'
                }}
              >สื่อการเรียนรู้ด้วยตัวเอง
                <TagRightIcon as={MdAccountCircle} /></Center>
              <Center
                fontSize={{
                  base: '4vw',
                  md: '1.5vw',
                  sm: '2.7vw'
                }}>
                ความสะดวกสบายด้วยเข้าถึงเนื้อหาได้จากทุกที่
              </Center>
              <Center
                fontSize={{
                  base: '5vw',
                  md: '2.3vw',
                  sm: '3.5vw'
                }}  >
                ใบรับรองอัตโนมัติ
                <TagRightIcon as={FaGraduationCap} /></Center>
              <Center
                fontSize={{
                  base: '4vw',
                  md: '1.5vw',
                  sm: '2.7vw'
                }}>
                ทดสอบและรับใบรับรองจากเราได้ทันที
              </Center>
            </Stack>
            <Stack
              align={{
                base: 'center',
                md: "flex-start"
              }}
            >
              <Center
                fontSize={{
                  base: '5vw',
                  md: '2.3vw',
                  sm: '3.5vw'
                }}
              >
                <TagLeftIcon as={FaTrophy} />สร้างประวัติของคุณ
              </Center>
              <Center
                fontSize={{
                  base: '4vw',
                  md: '1.5vw',
                  sm: '2.7vw'
                }}
              >เวลาในการแสดงความสามารถของคุณมาถึงแล้ว
              </Center>
              <Center
                fontSize={{
                  base: '5vw',
                  md: '2.3vw',
                  sm: '3.5vw'
                }}
              >
                <TagLeftIcon as={IoMdChatboxes} />กระดานสนทนา
              </Center>
              <Center
                fontSize={{
                  base: '4vw',
                  md: '1.5vw',
                  sm: '2.7vw'
                }}
              >พื้นที่พูดคุยและแลกเปลี่ยนความรู้
              </Center>
            </Stack>
          </SimpleGrid>
        </Box>
      </VStack >

    </Fragment >
  )
}

export default HomePage
import gql from 'graphql-tag'

export default gql`
query ($_id: MongoID!) {
  userId( _id: $_id ){
    _id
    username
    firstname
    lastname
    avatar
    email
    role
    description
    mobile
  }
}
`
import React, { Fragment, useContext, useState, useEffect } from "react"
import { useTranslation } from 'react-i18next'
import { Link as RouteLink } from "react-router-dom"
import {
  Box,
  Flex,
  Container,
  Stack,
  Text,
  Icon,
  Heading,
  Button,
  Table,
  useToast,
  IconButton,
  Tag,
  TagLabel,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Center,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Tooltip,
  useDisclosure,
  Image,
  HStack,
  Link,
} from "@chakra-ui/react"

import {
  FaTrashAlt,
  FaPencilAlt,
} from "react-icons/fa";

import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import { AiOutlineFileDone } from "react-icons/ai";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js"
import { useMutation, useQuery } from "@apollo/client"
import { useHistory } from 'react-router'
import { useMultiState } from "../lib/hooks"

import DialogEditSubject from "../components/pages/ManageSubjectPage/DialogEditSubject";
import DialogRemoveSubject from "../components/pages/ManageSubjectPage/DialogRemoveSubject";
import DialogPreviewImage from "../components/commons/DialogPreviewImage";


import SubjectsByTeacherID from "../graphql/queries/subjectsbyteacherId"
import AuthContext from "../contexts/AuthContext"
import SubjectMutation from "../graphql/mutations/createSubject"
import SubjectRemoveMutaion from "../graphql/mutations/removeSubject"
import UpdateSubject from "../graphql/mutations/updateSubject"

import axios from "axios"
import { MdForum } from "react-icons/md"


const URL = process.env.REACT_APP_UPLOAD_IMAGE

const ManageSubjectPage = () => {
  const { isOpen: previewSubjectImage, onOpen: openPreviewSubjectImage, onClose: closePreviewSubjectImage } = useDisclosure()
  const { isOpen: editDialogStatus, onOpen: openEditDialogStatus, onClose: closeEditDialogStatus } = useDisclosure()
  const { isOpen: removeDialogStatus, onOpen: openRemoveDialogStatus, onClose: closeRemoveDialogStatus } = useDisclosure()


  const toast = useToast()
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const [setUpdateSubject] = useMutation(UpdateSubject)
  const [setSubjectMutation] = useMutation(SubjectMutation)
  const [setRemoveSubject] = useMutation(SubjectRemoveMutaion)
  const { t } = useTranslation()

  const initialSubject = {
    _id: "",
    name: "",
    description: "",
  }

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [subject, setSubject] = useMultiState(initialSubject)

  const { data, refetch, loading } = useQuery(SubjectsByTeacherID, {
    variables: {
      teacher: user?._id,
      alive_subject: "SUBJECT_ALIVE"
    },
  })

  useEffect(() => {
    if (user?.role !== 'TEACHER') {
      setTimeout(() => {
        history.push('/login')
      }, 100)
    }
    if (loading) {
      refetch({
        teacher: user?._id,
      })
    }
  }, [history, user, refetch])

  const [newphotoData, setnewphotoData] = useMultiState({
    data: null,
    base64: "",
  })


  const selectPhotoFile = (e) => {
    new Promise((resolve, reject) => {
      e.preventDefault()
      const file = e.currentTarget.files[0]
      setnewphotoData({
        data: file,
      })
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
        setnewphotoData({
          base64: reader.result,
        })
      }
      reader.onerror = (error) => reject(error)
    })
  }


  const handleAddSubject = async (e) => {
    e.preventDefault()
    try {
      if (!subject?.name) {
        await toast({
          position: "top",
          title: "Warning",
          description: `${t('managesubjectPage.toastAddWarningSubject')}`,
          status: "warning",
          duration: 6000,
          isClosable: true,
        })
      } else {
        const response = await axios.post(URL, {
          photo: newphotoData?.base64,
        })
        // console.log('TEACHER', user)
        const editordata = convertToRaw(editorState.getCurrentContent())
        const { data: responseCreateSubject } = await setSubjectMutation({
          variables: {
            name: subject?.name,
            description: subject?.description,
            photo: response ? response?.data?.url : "",
            teacher: user?._id,
            editor: editordata,
          },
        })
        if (responseCreateSubject) {
          await toast({
            position: "top",
            title: "Success",
            description: `${t('managesubjectPage.toastAddSuccessCourse')}`,
            status: "success",
            duration: 6000,
            isClosable: true,
          })
          refetchPage()
        }
      }
    } catch (error) {
      console.error(error)
      await toast({
        position: "top",
        title: "Warning",
        description: `${t('managesubjectPage.toastAddWarningSubject')}`,
        status: "warning",
        duration: 6000,
        isClosable: true,
      })
    }
    refetchPage()
  }

  const handleUpdateSubject = async (e) => {
    e.preventDefault()
    if (!subject?.name) {
      await toast({
        position: "top",
        title: "Warning",
        description: `${t('managesubjectPage.toastAddWarningSubject')}`,
        status: "warning",
        duration: 6000,
        isClosable: true,
      })
    } else {
      try {
        const response = await axios.post(URL, {
          photo: newphotoData?.base64,
        })
        const editordata = convertToRaw(editorState.getCurrentContent())
        const { data: subjectupdatedata } = await setUpdateSubject({
          variables: {
            _id: subject?._id,
            name: subject?.name,
            description: subject?.description,
            photo: response ? response?.data?.url : subject?.photo,
            status: subject?.status ? "public" : "unpublic",
            editor: editordata,
            pay_certificate: subject?.pay_certificate,
            course_certificate: subject?.course_certificate
          },
        })
        if (subjectupdatedata) {
          await toast({
            position: "top",
            title: "Success",
            description: `${t('managesubjectPage.toastEditSuccessCourse')}`,
            status: "success",
            duration: 6000,
            isClosable: true,
          })
          refetchPage()
          closeEditDialogStatus()
        }
      } catch (error) {
        console.error(error)
        await toast({
          position: "top",
          title: "Warning",
          description: `${t('managesubjectPage.toastAddWarningSubject')}`,
          status: "warning",
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }

  //Alert dialog

  const handleRemoveSubject = async (e) => {
    e.preventDefault()
    try {
      const { data: responseRemoveSubject } = await setRemoveSubject({
        variables: {
          _id: subject?._id,
          alive_subject: "SUBJECT_DELETE"
        }
      })
      if (responseRemoveSubject) {
        await toast({
          position: "top",
          title: "Success",
          description: `${t('managesubjectPage.toastRemoveSuccessCourse')}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        refetchPage()
        closeRemoveDialogStatus()
      }
    } catch (error) {
      console.error(error)
      await toast({
        position: "top",
        title: "Warning",
        description: `${t('managesubjectPage.toastAddWarningSubject')}`,
        status: "warning",
        duration: 6000,
        isClosable: true,
      })
    }
  }

  const refetchPage = () => {
    refetch({
      teacher: user?._id,
    })
    setEditorState(EditorState.createEmpty())
    setnewphotoData({ data: null, base64: "" })
    setSubject(initialSubject)
  }

  return (
    <Fragment>
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12} px={[2, 5, 15, 20]}>
        <Stack spacing={0} align={"center"}>
          <Heading variant="h4">{t('managesubjectPage.title')}</Heading>
          <Text>
            {t('managesubjectPage.subtitle')}
          </Text>
        </Stack>
        <form onSubmit={handleAddSubject}>
          <Box boxShadow={'lg'} p={'5'} borderRadius={'lg'} borderWidth={'1px'} >
            <FormControl id="" py={'2'}>
              <FormLabel>{t('managesubjectPage.subject')}</FormLabel>
              <Input
                value={subject?.name}
                placeholder={t('managesubjectPage.subject')}
                onChange={(e) => setSubject({ name: e.target.value })}
              />
            </FormControl>
            <FormControl id="" py={'2'}>
              <FormLabel>{t('managesubjectPage.description')}</FormLabel>
              <Textarea
                value={subject?.description}
                placeholder={t('managesubjectPage.description')}
                onChange={(e) => setSubject({ description: e.target.value })}
              />
            </FormControl>
            <HStack>
              <Button size="sm" as="label" mt={4} bg={'primary.700'} color={'white'} _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}>
                {t('Button.addphoto')}
                <input
                  onChange={selectPhotoFile}
                  accept="image/*"
                  id="photo-project-file"
                  type="file"
                  style={{ display: "none" }}
                />
              </Button>
              <Text>{newphotoData?.data?.name}</Text>
            </HStack>
            <Center>
              <Button type="submit " bg={'primary.700'} color={'white'} _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}>
                {t('Button.confirm')}
              </Button>
            </Center>
          </Box>
        </form>


        <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} borderWidth={'1px'} >
          <Table variant="striped" backgroundColor="white" >
            <Thead height="50px">
              <Tr>
                <Th textAlign="center">{t('managesubjectPage.photo')}</Th>
                <Th textAlign="center">{t('managesubjectPage.subject')}</Th>
                <Th textAlign="center">{t('managesubjectPage.description')}</Th>
                <Th textAlign="center">{t('managesubjectPage.status')}</Th>
                <Th textAlign="center">{t('managesubjectPage.manage')}</Th>
              </Tr>
            </Thead>
            {data?.subjects?.length != 0 &&
              <>
                {data?.subjects?.map((subject, i) => (
                  <Tbody key={subject?._id} bg='gray.400'>
                    <Tr>
                      <Td width="120px" >
                        <Tooltip label={t('managesubjectPage.photo')} fontSize="md">
                          <IconButton
                            variant='outline'
                            aria-label='Send email'
                            icon={<Image
                              boxSize="60px"
                              objectFit="cover"
                              borderRadius={'5px'}
                              src={subject?.photo ? subject?.photo : 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'}
                            />}
                            onClick={(e) => {
                              e.preventDefault()
                              setSubject({
                                _id: subject?._id, name: subject?.name, description: subject?.description, photo: subject?.photo, status: subject?.status == "public" ? true : false, pay_certificate: subject?.pay_certificate, course_certificate: subject?.course_certificate,
                              })
                              openPreviewSubjectImage()
                            }}
                          />
                        </Tooltip>
                      </Td>
                      <Td maxW={'30vh'}>
                        <Link as={RouteLink} to={`/subject/${subject?._id}`}>
                          {subject?.name}
                        </Link>
                      </Td>
                      <Td maxW={'30vh'} textAlign="center" >{subject?.description}</Td>
                      <Td maxW={'40vh'} textAlign="center">
                        <Box w='100%' color='white'>
                          <Center>
                            {subject?.status == "public" ?
                              <Tag
                                borderRadius='full'
                                boxShadow={'lg'}
                                variant='solid'
                                colorScheme='green'
                                w={"full"}
                                py={1}
                                justifyContent={"center"}
                              >
                                <TagLabel>{t("managecontentPage.public")}</TagLabel>
                              </Tag>
                              :
                              <Tag
                                borderRadius='full'
                                boxShadow={'lg'}
                                variant='solid'
                                w={"full"}
                                py={1}
                                justifyContent={"center"}
                              >
                                <TagLabel>{t("managecontentPage.nonepublic")}</TagLabel>
                              </Tag>}
                          </Center>
                        </Box>
                      </Td>
                      <Td>
                        <Flex justify="end" >
                          <Tooltip label={t('managesubjectPage.managecontent')} fontSize="md">
                            <Link as={RouteLink} to={`/managecontent/${subject?._id}`}>
                              <IconButton boxShadow={'lg'} colorScheme='blue' icon={<AddIcon />} mr={2}
                                _hover={{
                                  transform: 'translateY(-2px)',
                                  boxShadow: 'lg',
                                }} />
                            </Link>
                          </Tooltip>


                          <Tooltip label={t('managesubjectPage.enroll')} fontsize="md">
                            <Link as={RouteLink} to={`/students/${subject?._id}`}>
                              <IconButton boxShadow={'lg'} colorScheme='blue' icon={<AiOutlineFileDone />} mr={2} as={Link} to={`students/${subject?._id}`}
                                _hover={{
                                  transform: 'translateY(-2px)',
                                  boxShadow: 'lg',
                                }}
                              />
                            </Link>
                          </Tooltip>

                          <Tooltip label={t('managesubjectPage.forum')} fontsize="md">
                            <Link as={RouteLink} to={`/manageforum/${subject?._id}`}>
                              <IconButton boxShadow={'lg'} colorScheme='blue' icon={<MdForum />} mr={2}
                                _hover={{
                                  transform: 'translateY(-2px)',
                                  boxShadow: 'lg',
                                }}
                              />
                            </Link>
                          </Tooltip>

                          <Tooltip label={t('managesubjectPage.editsubject')} fontsize="md">
                            <IconButton boxShadow={'lg'} colorScheme='blue' icon={<FaPencilAlt />} mr={2}
                              _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                              }}
                              onClick={(e) => {
                                e.preventDefault()
                                setSubject({
                                  _id: subject?._id, name: subject?.name, description: subject?.description, photo: subject?.photo, status: subject?.status == "public" ? true : false, pay_certificate: subject?.pay_certificate, course_certificate: subject?.course_certificate,
                                })
                                openEditDialogStatus()
                              }}
                            />
                          </Tooltip>



                          <Tooltip label={t('managesubjectPage.deleteSubject')} fontsize="md">
                            <IconButton boxShadow={'lg'} colorScheme='red' icon={<FaTrashAlt />} mr={2}
                              _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                              }}
                              onClick={(e) => {
                                e.preventDefault()
                                setSubject({
                                  _id: subject?._id, name: subject?.name, description: subject?.description, photo: subject?.photo, status: subject?.status == "public" ? true : false, pay_certificate: subject?.pay_certificate, course_certificate: subject?.course_certificate,
                                })
                                openRemoveDialogStatus()
                              }}
                            />
                          </Tooltip>
                        </Flex>
                      </Td>
                    </Tr>
                  </Tbody>
                ))}
              </>}

          </Table>
          {data?.subjects?.length == 0 &&
            <Box w="full" p={'2'} boxShadow="lg" borderRadius={'lg'} h={"200"} bg={'gray.100'}>
              <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} style={{ height: "100%" }}>
                <HStack color={'gray.500'}>
                  <Icon as={CloseIcon} w={10} h={8} />
                  <Text fontSize={'35'} color={'gray.500'}>{t('courseteacherPage.alt')}</Text>
                </HStack>
              </Flex>
            </Box>
          }
        </Box>
      </Container>

      <DialogRemoveSubject
        subject={subject}
        removeDialogStatus={removeDialogStatus}
        openRemoveDialogStatus={openRemoveDialogStatus}
        handleRemoveSubject={handleRemoveSubject}
        closeRemoveDialogStatus={closeRemoveDialogStatus}
      />

      <DialogEditSubject
        subject={subject}
        setSubject={setSubject}
        handleUpdateSubject={handleUpdateSubject}
        selectPhotoFile={selectPhotoFile}
        newphotoData={newphotoData}
        Editor={Editor}
        editorState={editorState}
        setEditorState={setEditorState}
        editDialogStatus={editDialogStatus}
        closeEditDialogStatus={closeEditDialogStatus}
      />

      <DialogPreviewImage status={previewSubjectImage} image={subject?.photo} onCloseDialog={closePreviewSubjectImage} />
    </Fragment>
  )
}

export default ManageSubjectPage

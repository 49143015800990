import React, { Fragment } from "react";
import {
  Flex,
  useColorModeValue,
  Box,
  Text,
  Button,
  ButtonGroup,
  useColorMode,

} from "@chakra-ui/react";
// import { useLocation } from "react-router";

import { useApp } from "../../contexts/AppContext";



const FooterLayout = (props) => {
  const { colorMode } = useColorMode()
  const { lang, switchLang } = useApp()
  // console.log('landing ', location);
  // const ListHeader = ({ children }) => {
  //   return (
  //     <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
  //       {children}
  //     </Text>
  //   );
  // };
  return (
    <Fragment>
      {/* <Box p={16}></Box> */}

      <Box bg={useColorModeValue('gray.50', 'gray.900')} >
        <Flex
          align={'center'}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 0,
          }}>
        </Flex>
        <Box pt={6} fontSize={'md'} textAlign={'center'} py={6}>
          <Text>Copyright © สถาบันนโยบายสาธารณะ</Text>

          <ButtonGroup marginLeft="3">
            <Button
              color={colorMode === 'light' ? ["white", "white", "primary.400", "primary.400"] : ["white", "white", "white", "white"]}
              variant='solid'
              colorScheme={lang !== 'th' ? 'primary.400' : 'white'}
              onClick={() => switchLang('th')}
              borderColor={lang === 'th' ? ["white", "white", "primary.800", "primary.800"] : ["primary.800", "primary.800", "primary.800", "white", "white"]}
              border="2px"
              mr="-px">
              TH
            </Button>
            <Button
              color={colorMode === 'light' ? ["white", "white", "primary.400", "primary.400"] : ["white", "white", "white", "white"]}
              variant='solid'
              borderColor={lang === 'en' ? ["white", "white", "primary.800", "primary.800"] : ["primary.800", "primary.800", "primary.800", "white", "white"]}
              border="2px"
              // variant={lang !== 'th' ? 'contained' : undefined}
              colorScheme={lang !== 'en' ? 'primary.400' : 'white'}
              onClick={() => switchLang('en')}
            >
              EN
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Fragment>

  );
}
export default FooterLayout;





import gql from 'graphql-tag'

export default gql`
query {
    subjects(sort:_ID_DESC) {
      _id
      name
      description
      updatedAt
      photo
      alive_subject
      status
      teacher{
          _id
          username
          firstname
          lastname
          isTeacher
          avatar
          email
          
      }
    }
  }
`



import gql from 'graphql-tag'

export default gql`
    mutation ($_id: MongoID!){
        removeSurvey(_id: $_id){
            recordId
        }
    }
`


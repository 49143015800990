import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {
    Box,
    Heading,
    Text,
    Container,
    Button,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    useDisclosure,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Th,
    Icon,
} from '@chakra-ui/react';

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import { Link as Routelink } from 'react-router-dom';
import questionById from '../graphql/queries/question'
import scorebystudentQueries from "../graphql/queries/scorequestionbyStudent"
import questionsend from "../graphql/queries/questionbyStudent"
import { BiCheckCircle } from "react-icons/bi";
import dayjs from 'dayjs'
import { IoIosAddCircleOutline } from "react-icons/io";


import AuthContext from '../contexts/AuthContext';

const QuestionPage = () => {
    const { user } = useContext(AuthContext)
    const param = useParams()
    const { t } = useTranslation()
    const [question, setQuestion] = useState([])

    const { isOpen, onOpen, onClose } = useDisclosure()

    const { data: QuestionData } = useQuery(questionById, {
        variables: {
            _id: param?.contentId,
        },
    })

    const { data: questionSendData, refetch: refetchSubmit } = useQuery(questionsend, {
        variables: {
            student: user?._id,
            content: param?.contentId,
        },
    })

    const { data: scoreAddData, refetch } = useQuery(scorebystudentQueries, {
        variables: {
            student: user?._id,
            id: param?.contentId
        },
    })

    useEffect(() => {
        if (localStorage.getItem('refetchPage') === 'true') {
            // console.log("refetPage")
            localStorage.setItem('refetchPage', 'false')
            refetchSubmit({
                student: user?._id,
                content: param?.contentId,
            })
        }
        if (QuestionData) {
            setQuestion(QuestionData?.content?.questionconfig?.questionList)
        }
    }, [QuestionData])


    return (
        <Fragment >
            <Modal isOpen={isOpen} onClose={onClose} size="5xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('QuizPage.Checkanswers')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box sx={{ overflowX: ['auto', 'auto', 'none'], maxW: ['350px', 'none'] }} >
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th isNumeric>{t('QuizPage.no')}</Th>
                                        <Th textAlign={'center'}>{t('QuizPage.question')}</Th>
                                        <Th textAlign={'center'}>{t('QuizPage.youranswer')}</Th>
                                        <Th textAlign={'center'}>{t('QuizPage.questionAnswer')}</Th>
                                        <Th textAlign={'center'}>{t('QuizPage.Results')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {scoreAddData?.scores[0]?.quiz[0]?.answer.map((score, i) => (
                                        <Tr key={i}>
                                            <Td isNumeric>{i + 1}</Td>
                                            <Td>{question[i]?.question}</Td>
                                            <Td>{score?.useranswer}</Td>
                                            <Td>{score?.answer}</Td>
                                            <Td textAlign={'center'}>
                                                {score?.status === "correct" ? (
                                                    <Icon mr={2} w={5} h={5} color="green.500" as={CheckIcon} />
                                                ) : (
                                                    <Icon mr={2} w={5} h={4} color="red.500" as={CloseIcon} />
                                                )}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                        <Text mb="1rem" px={4} py={3} margin={1}>
                            {t('QuizPage.score')}: {scoreAddData?.scores[0]?.quiz[0]?.score}
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button bg={'primary.700'} color={'white'}
                            _hover={{
                                bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                            }} mr={3} onClick={onClose}>
                            {t('QuizPage.close')}
                        </Button>
                        {/* <Button variant="ghost" onClick={submitClickHandler}>
							Submit
						</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box w="100%" minHeight={'90vh'}>
                <Container maxW={'8xl'} py={16} as={Stack} spacing={12}>
                    <Box w="100%">
                        <Heading size="xl" > {QuestionData?.content?.name}  </Heading>
                        <Box mt={5} borderWidth={'1px'} borderRadius={'lg'} p={1} minH={'15vh'}>
                            <Text mt={1} ml={1}>{QuestionData?.content?.questionconfig?.description} </Text>
                        </Box>

                        <Box mt={5} >
                            <Center>
                                {scoreAddData?.scores?.length === 0 ?
                                    <Button
                                        leftIcon={<IoIosAddCircleOutline />} colorScheme='red' variant='solid'
                                        as={Routelink}
                                        to={`/quiz/${param?.contentId}/${param?.subjectId}`}
                                    >
                                        {t('QuestionPage.startquiz')}
                                    </Button>
                                    :
                                    <Box>
                                        {scoreAddData?.scores[0]?.quiz[0]?.score || scoreAddData?.scores[0]?.quiz[0]?.score === 0 ?
                                            <Button
                                                leftIcon={<BiCheckCircle />} colorScheme='teal' variant='solid' onClick={onOpen}
                                            >
                                                {t('QuestionPage.result')}
                                            </Button>
                                            :
                                            <Button
                                                leftIcon={<IoIosAddCircleOutline />} colorScheme='red' variant='solid'
                                                as={Routelink}
                                                to={`/quiz/${param?.contentId}/${param?.subjectId}`}
                                            >
                                                {t('QuestionPage.startquiz')}
                                            </Button>
                                        }
                                    </Box>
                                }
                            </Center>
                        </Box>
                        <Box mt={10} borderWidth={'1px'} borderRadius={'lg'} p={1}>
                            <Table variant='striped' >
                                <Thead>
                                    <Tr>
                                        <Td> {t('QuestionPage.sendDate')}</Td>
                                        <Td>{questionSendData?.Quiz?.submitdate ? dayjs(questionSendData?.Quiz?.submitdate).format('วันที่ DD/MM/YYYY , เวลา HH:mm:ss') : "-"}</Td>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>{t('QuestionPage.status')}</Td>
                                        <Td>-</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t('QuestionPage.score')}</Td>
                                        <Td >
                                            {scoreAddData?.scores?.map((score, i) => (
                                                <Box key={i}> {score?.quiz[0]?.score} </Box>
                                            ))}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Fragment >
    );


}
export default QuestionPage;
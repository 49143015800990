import React, { Fragment } from "react";
import { Link as RouteLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Badge,
    HStack,
    Stack,
    Image
} from '@chakra-ui/react';
// import dayjs from "dayjs";


const ModalPricingLayout = ({ modalData, onClose, isOpen }) => {
    return (
        <Fragment>
            <Modal id={modalData?.id} onClose={onClose} isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>กรุณาอัปเกรดระดับสมาชิก</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box boxSize="sm">
                            <HStack>
                                <Text as='h5'>
                                    คอร์สนี้เป็นคอร์สสำหรับสมาชิก
                                </Text>
                                <Badge colorScheme='yellow'>VIP</Badge>
                            </HStack>
                            <ul>
                                <li style={{ marginLeft: '10px' }}>
                                    <Text ml={'5'}>หากสมัครสมาชิก VIP แล้ว กรุณาออกจากระบบ แล้วทำการเข้าสู่ระบบอีกครั้ง</Text>
                                </li>
                                <li style={{ marginLeft: '10px' }}>
                                    <Text ml={'5'}>หากยังไม่สมัครสมาชิก VIP สามารถทำการสมัครสมาชิกแบบ VIP ได้ที่นี่</Text>
                                </li>
                            </ul>
                            <Stack align={'center'}>
                                <Button as={RouteLink} to={'/pricing'} colorScheme='green'>สมัครสมาชิก VIP</Button>
                            </Stack>
                            <Image
                                src="./images/nuukeng1.png"
                                zIndex={-1}
                                opacity={0.09}
                                position='absolute'
                                boxSize='300px'
                                bottom={10}
                                left={'76px'}
                            />
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Fragment>
    );
}

ModalPricingLayout.propTypes = {
    modalData: PropTypes.shape(),
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
};

ModalPricingLayout.defaultProps = {
    modalData: {},
    onClose: () => { },
    isOpen: false,
};
export default ModalPricingLayout

import { useTranslation } from 'react-i18next'
import {
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Switch,
    FormControl,
    ModalCloseButton,
    FormLabel,
    Input,
    Text,
    Textarea,
    HStack,
} from '@chakra-ui/react'

const DialogEditSubject = ({ subject, setSubject, editDialogStatus, closeEditDialogStatus, handleUpdateSubject, selectPhotoFile, newphotoData, Editor, editorState, setEditorState }) => {

    const { t } = useTranslation()
    console.log("subject", subject)

    return (
        <Modal isOpen={editDialogStatus} onClose={closeEditDialogStatus} size={'4xl'} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('managesubjectPage.editsubject')}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleUpdateSubject}>
                    <ModalBody>
                        <FormControl id="" isRequired>
                            <FormLabel> {t('managesubjectPage.subject')} </FormLabel>
                            <Input
                                value={subject?.name}
                                onChange={(e) => setSubject({ name: e.target.value })}
                            />
                        </FormControl>
                        <FormControl id="">
                            <FormLabel>{t('managesubjectPage.description')}</FormLabel>
                            <Textarea
                                value={subject?.description}
                                onChange={(e) => setSubject({ description: e.target.value })}
                            />
                        </FormControl>
                        <HStack py={'2'}>
                            <Button size="sm" as="label">
                                {t('managesubjectPage.updatephoto')}
                                <input
                                    onChange={selectPhotoFile}
                                    accept="image/*"
                                    id="photo-project-file"
                                    type="file"
                                    style={{ display: "none" }}
                                />
                            </Button>
                            <Text>{newphotoData?.data?.name}</Text>
                        </HStack>
                        <FormControl>
                            <FormLabel>{t('managesubjectPage.certificate')}</FormLabel>
                            <Switch size='lg' mr={'2'} colorScheme='green' isChecked={subject?.course_certificate} onChange={(e) => setSubject({ course_certificate: e.target.checked })} />
                            {subject?.course_certificate ? (t('managesubjectPage.open')) : (t('managesubjectPage.close'))}
                        </FormControl>
                        {subject?.course_certificate &&
                            <FormControl>
                                <Box py={'2'}>
                                    <FormLabel>{t('managesubjectPage.pay')}</FormLabel>
                                    <Switch size='lg' mr={'2'} colorScheme='green' isChecked={subject?.pay_certificate} onChange={(e) => setSubject({ pay_certificate: e.target.checked })} />
                                    {subject?.pay_certificate ? (t('managesubjectPage.open')) : (t('managesubjectPage.close'))}
                                </Box>
                                <Box borderRadius={'lg'} borderWidth={'1px'} p={2}>
                                    <FormLabel>{t('managesubjectPage.description')}</FormLabel>
                                    <Box minHeight={'60vh'} borderWidth="1px" p={2} borderRadius={'lg'}>
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                            }}
                                        // editorStyle={{ border: "1px solid" }}
                                        />
                                    </Box>
                                </Box>
                            </FormControl>
                        }
                        <FormControl>
                            <FormLabel>{t('managecontentPage.status')}</FormLabel>
                            <Switch size='lg' mr={'2'} colorScheme='green' isChecked={subject?.status} onChange={(e) => setSubject({ status: e.target.checked })} />
                            {subject?.status ? t('managecontentPage.public') : t('managecontentPage.nonepublic')}
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" bg={'primary.700'} color={'white'} _hover={{
                            transform: 'translateY(-2px)',
                            boxShadow: 'lg',
                        }}>
                            {t('Button.confirm')}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>

    )

}

export default DialogEditSubject

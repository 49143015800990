import gql from 'graphql-tag'

export default gql`
  query ($content: MongoID) {
      Quizs(filter: { content :$content}) {
        _id
        status
        answer
        submitdate
        content{
          _id
          name
        }
        student{
            _id
            firstname
            lastname
        }
    }
  }
  `;
import gql from "graphql-tag";

export default gql`
  query (
      $student: MongoID
      $content: MongoID
    ) {
    homework(filter: { student: $student,content:$content}) {
        _id
        status
        file
        filename
        submitdate
        student{
            _id
        }
    }
  }
  `;


import { useTranslation } from 'react-i18next'
import { Fragment, useContext, useState, useEffect, useCallback } from 'react'

import {
    Divider,
    Button,
    useColorMode,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    ModalContent,
    Box,
    Text,
    Avatar,
    Tag,
    TagLabel,
    Flex,
    AlertTitle,
    AlertDescription,
    Alert,
    AlertIcon,
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'

const DialogCertificate = ({ student, closeCertificateDialog, certificateDialog, handleUpdateCertificateStatus }) => {

    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <Modal isOpen={certificateDialog} onClose={closeCertificateDialog} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Certificate</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleUpdateCertificateStatus}>
                    <ModalBody>
                        <Box
                            w={['100%', '100%', '100%', '100%']}
                            bg={colorMode === 'light' ? 'brand.100' : 'brand.600'}
                            color={colorMode === 'light' ? 'brand.600' : 'brand.100'}
                            boxShadow={'lg'}
                            borderWidth={'2px'}
                            borderRadius={'15px'}
                            borderColor={colorMode === 'light' ? 'brand.300' : 'brand.500'}>
                            <Box
                                p={5}
                                display={'flex'}
                                w={['100%', '100%', '100%', '100%']}
                            >

                                <Flex
                                    flexGrow={1}
                                    justifyContent={'center'}
                                >
                                    <Avatar
                                        borderWidth={'2px'}
                                        borderColor={colorMode === 'light' ? 'brand.300' : 'brand.100'}
                                        objectFit='cover'
                                        zIndex={1}
                                        name={student?.student?.firstname ? `${student?.student?.firstname} ${student?.student?.lastname}` : 'Profile'}
                                        size={['lg']}
                                        src={student?.avatar}
                                        alt="กรุณาเลือกรูปภาพ" />
                                </Flex>
                                <Flex
                                    flexGrow={2}
                                    direction='column'
                                    minW={'300px'}
                                >
                                    <Text fontWeight={600} as='b' fontSize='xl'>
                                        {`${student?.student?.firstname} ${student?.student?.lastname}`}
                                    </Text>
                                    <Text fontWeight={600} fontSize='sm'>
                                        {`email: ${student?.student?.email}`}
                                    </Text>
                                    <Text fontWeight={600} fontSize='sm'>
                                        {`mobile: ${student?.student?.mobile}`}
                                    </Text>
                                    <Text fontWeight={600} fontSize='sm' display={'flex'} >
                                        {`request:`}
                                        {student?.certificatestatus &&
                                            <Tag
                                                // flexGrow={1}
                                                ml={3}
                                                w={'90px'}
                                                borderRadius='full'
                                                boxShadow={'lg'}
                                                variant='solid'
                                                colorScheme={'green'}
                                                py={1}
                                                justifyContent={"center"}
                                                color={'brand.100'}
                                            >
                                                <TagLabel>{`Certificate`}</TagLabel>
                                            </Tag>}

                                        {student?.creditstatus &&
                                            <Tag
                                                // flexGrow={1}
                                                ml={3}
                                                w={'90px'}
                                                borderRadius='full'
                                                boxShadow={'lg'}
                                                variant='solid'
                                                colorScheme={'orange'}
                                                py={1}
                                                justifyContent={"center"}
                                                color={'brand.100'}
                                            >
                                                <TagLabel>{`Cradit`}</TagLabel>
                                            </Tag>
                                        }
                                    </Text>


                                    {/* {student?.status == 'approved' ?
                                    <Alert status='success' variant='subtle' borderWidth={'2px'} borderRadius={'10px'} h={'40px'} mt={2}>
                                        <AlertIcon />
                                        <Text fontWeight={600} fontSize='sm'>
                                            {`Certificate Approved .`}
                                        </Text>
                                    </Alert>
                                    :
                                    <Alert status='warning' variant='subtle' borderWidth={'2px'} borderRadius={'10px'} h={'40px'} mt={2}>
                                        <AlertIcon />
                                        <Text fontWeight={600} fontSize='sm'>
                                            {`Certificate Disapproved .`}
                                        </Text>
                                    </Alert>
                                } */}
                                </Flex>
                            </Box>


                            <Box px={5} py={2}>
                                <Divider orientation='vertical' border={'1px solid'} />
                                <Text fontWeight={700} fontSize='xl' mt={3}>
                                    {`Certificatetion`}
                                </Text>
                                <Text fontWeight={600} fontSize='sm' mb={2}>
                                    {`owner: ${student?.name}`}
                                </Text>

                                {student?.status == 'approved' ?
                                    <Alert
                                        status='success'
                                        variant='subtle'
                                        flexDirection='column'
                                        alignItems='center'
                                        justifyContent='center'
                                        textAlign='center'
                                        borderRadius={'15px'}
                                        borderWidth={'2px'}
                                    >
                                        <AlertIcon boxSize='40px' mr={0} />
                                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                                            Application submitted!
                                        </AlertTitle>
                                        <AlertDescription maxWidth='sm'>
                                            Thanks for submitting your application. Our team will get back to you soon.
                                        </AlertDescription>
                                    </Alert>
                                    :
                                    <Alert
                                        status='warning'
                                        variant='subtle'
                                        flexDirection='column'
                                        alignItems='center'
                                        justifyContent='center'
                                        textAlign='center'
                                        borderRadius={'15px'}
                                        borderWidth={'2px'}
                                    >
                                        <AlertIcon boxSize='40px' mr={0} />
                                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                                            {`Certificate Disapproved .`}
                                        </AlertTitle>
                                        <AlertDescription maxWidth='sm'>
                                            Thanks for submitting your application. Our team will get back to you soon.
                                        </AlertDescription>
                                    </Alert>
                                }

                            </Box>
                        </Box>
                        <Box py={5}>
                            {student?.status == 'approved' ?
                                <Button
                                    color={colorMode === 'light' ? 'brand.100' : 'brand.700'}
                                    colorScheme='red'
                                    variant='solid'
                                    borderRadius={'20px'}
                                    borderWidth={'2px'}
                                    boxShadow={'lg'}
                                    w={'100%'}
                                    type='submit'>
                                    {`Dissaple Certificate .`}
                                </Button>
                                :
                                <Button
                                    leftIcon={<CheckCircleIcon />}
                                    color={colorMode === 'light' ? 'brand.100' : 'brand.700'}
                                    colorScheme='green'
                                    variant='solid'
                                    borderRadius={'20px'}
                                    borderWidth={'2px'}
                                    boxShadow={'lg'}
                                    w={'100%'}
                                    type='submit'>
                                    {`Certificate Approved .`}
                                </Button>
                            }
                        </Box>
                    </ModalBody>
                </form>
            </ModalContent>
        </Modal>
    )

}

export default DialogCertificate

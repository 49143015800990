import { useTranslation } from 'react-i18next'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Image,
    ModalCloseButton,
    Center,
} from '@chakra-ui/react'

const DialogPreviewImage = ({ status, image, onCloseDialog, }) => {

    const { t } = useTranslation()

    return (
        <Modal MaxW={"100%"} isOpen={status} onClose={onCloseDialog}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('managesubjectPage.examplePhoto')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center>
                        <Image
                            boxSize="500px"
                            objectFit="cover"
                            src={image ? image : 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                            }
                            alt="Image"
                        />
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default DialogPreviewImage

import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Box,
    Heading,
    Text,
    useColorModeValue,
    Image,
    Link,
    HStack,
    SimpleGrid,
    GridItem,
    VStack,
    Flex,
    keyframes,
    usePrefersReducedMotion,
    Avatar
} from '@chakra-ui/react';
import dayjs from "dayjs";
export const BlogAuthor = (props) => {
    return (
        <Fragment>
            <HStack ml={2} marginTop="0" spacing="2" display="flex" alignItems="center">
                <Avatar
                    // borderRadius="full"
                    boxSize="30px"
                    src={props.avatar}
                    name={`${props.name}`}
                />
                <Box>
                    <Text as='h6' fontSize='xs' >{props.name}</Text>
                    {/* <Text fontSize='xx-small'>—</Text> */}
                    <Text fontSize='xs'>{dayjs(props?.date).format('DD MMMM YYYY')}</Text>
                </Box>
            </HStack>
        </Fragment>

    );
};

const SubjectLayoutMyCourseStudent = ({ subject, subjectsState, id, user, onOpen, curPage, itemLimit, curItems, setCurItems }) => {
    const history = useHistory()
    const prefersReducedMotion = usePrefersReducedMotion()
    // console.log('user', user);

    useEffect(() => {
        const offset = curPage * itemLimit;
        const getList = (curPage, itemLimit) => {
            setCurItems(subjectsState.slice(offset, offset + itemLimit));
        };
        getList(curPage, itemLimit);
    }, [curPage, itemLimit, subjectsState]);


    const handleSubjectRoute = (subjectId) => {
        if (subject?.contentfor === 'VIP') {
            if (user?.member === 'VIP') {
                history.push(`/subject/${subjectId}`)
            } else {
                if (user?._id === subject?.teacher?._id) {
                    history.push(`/subject/${subjectId}`)
                } else {
                    onOpen()
                }
            }
        } else {
            history.push(`/subject/${subjectId}`)
        }
    }
    const spin = keyframes`
    50% {
        opacity: 0;
    }
`
    const bgGr = useColorModeValue('radial(orange.600 1px, transparent 1px)', 'radial(orange.300 1px, transparent 1px)')
    const color = useColorModeValue('gray.700', 'gray.200')

    const animation = prefersReducedMotion
        ? undefined
        : `${spin} 5s linear infinite`
    // console.log('sub', subject);
    return (
        <Fragment>
            <SimpleGrid
                columns={{
                    base: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                }}
                spacingX={{
                    base: 5,
                    lg: 10,
                }}
                w={'full'}
                minH={'60vh'}>
                {curItems?.map((subject) => (

                    <GridItem key={subject?._id} w='100%' sm={'12'} mt={5}>
                        <Link onClick={(e) => { handleSubjectRoute(subject?._id) }}>
                            <Box
                                p={1}
                                borderRadius={'lg'}
                                borderWidth={'2px'}
                                boxShadow={'2xl'}
                                marginTop={{ base: '1', sm: '1' }}
                                display="flex"
                                maxW="450px"
                                w='100%'
                                // direction={["row", "column", "column", "column",]}
                                flexDirection={{ base: 'row', sm: 'column', lg: 'row' }}
                                justifyContent="center">
                                <Flex
                                    h={'200px'}
                                    maxW={['150px', '150px', '100%', '130px']}
                                    zIndex="2"
                                // position={'inherit'}
                                >
                                    <Image
                                        borderRadius="lg"
                                        src={
                                            subject?.photo ? subject?.photo : 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                                        }
                                        objectFit="cover"
                                    />
                                </Flex>
                                <Box maxW={'20vh'} minW={['200px', 'auto', 'auto', '150px']} minH={['200px', 'auto', '160px', 'auto']}>
                                    <VStack
                                        display="flex"
                                        spacing={1}
                                        minH={'150px'}
                                        alignItems={'start'}
                                        position={'relative'}
                                        w={'full'}
                                        h='full'
                                    >

                                        <Box maxW={'20vh'} minH={'10vh'} p={[2, 2, 2, 3]} h={'full'}>
                                            <Text fontSize='md' marginTop="1" wordBreak={2} noOfLines={3}>
                                                {subject?.name}
                                            </Text>
                                            <Text
                                                marginTop="2"
                                                color={color}
                                                fontSize='xs'
                                                wordBreak={2}
                                                noOfLines={[3, 2, 1, 2]}
                                            >
                                                {subject?.description ? `${subject?.description}` : ''}
                                            </Text>
                                        </Box>
                                        <Flex justifyContent={'flex-end'} alignItems={"flex-end"} h='full' color={color}>
                                            <Text ml={'2'} noOfLines={1} fontSize='10px' fontStyle={"italic"}> {` ${subject?.teacher?.firstname} ${subject?.teacher?.lastname}`}</Text>
                                        </Flex>
                                    </VStack>
                                </Box>
                            </Box>
                        </Link>
                    </GridItem>
                ))}
            </SimpleGrid>
        </Fragment>
    );
}

SubjectLayoutMyCourseStudent.propTypes = {
    subject: PropTypes.shape(),
    user: PropTypes.shape(),
    id: PropTypes.string,
    onOpen: PropTypes.func
};

SubjectLayoutMyCourseStudent.defaultProps = {
    subject: {},
    user: {},
    id: '',
    onOpen: () => { }
};
export default SubjectLayoutMyCourseStudent
